import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class VerificationService {
    private apiUrl = `${environment.apiUrl}/auth/verification`;
    constructor(private http: HttpClient) { }

    verifyOtp(transactionId: string, branchId: string, otpCode: string): Observable<any> {
        return this.http.post<any>(this.apiUrl, {
            transaction_id: transactionId,
            branch_id: branchId,
            otp_code: otpCode,
        }).pipe(
            catchError(this.handleError)
        );
    }

    private handleError(error: HttpErrorResponse) {
        let errorMessage = 'Something bad happened; please try again later.';
        errorMessage = error.error.message;
        return throwError(() => new Error(errorMessage));
    }
}

