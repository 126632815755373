<div class="box xl:p-6 dark:bg-bg4 grid grid-cols-12 gap-4 xxxl:gap-6 items-center shadow-[0px_6px_30px_0px_rgba(0,0,0,0.04)]">
  <div class="col-span-12 lg:col-span-7">
    <form [formGroup]="signUpForm" (ngSubmit)="onSubmit()" id="signupForm" class="box bg-primary/5 dark:bg-bg3 lg:p-6 xl:p-8 border border-n30 dark:border-n500">
      <h3 class="h3 mb-4">Let&apos;s Get Started!</h3>
      <p class="md:mb-6 pb-4 mb-4 md:pb-6 bb-dashed text-sm md:text-base">Please Enter your Email Address to Start your Online Application</p>
      <div class="grid grid-cols-2 gap-x-4 xxxl:gap-x-6">
        <div class="col-span-2 md:col-span-1">
          <label for="name" class="md:text-lg font-medium block mb-1"> First Name </label>
          <input formControlName="firstname" type="name" class="w-full text-sm bg-n0 dark:bg-bg4 border border-n30 dark:border-n500 rounded-3xl px-3 md:px-6 py-2 md:py-3 mb-2" placeholder="Jone" id="fname" />
          @if (signUpForm.get('firstname')?.errors && signUpForm.get('firstname')?.touched) {
          <span class="text-secondary2" *ngIf="signUpForm.get('firstname')?.errors?.['required']">Firstname is required</span>
          <span class="text-secondary2" *ngIf="signUpForm.get('firstname')?.errors?.['minlength']">Firstname must be at least 2 character</span>
          }
        </div>
        <div class="col-span-2 md:col-span-1">
          <label for="lname" class="md:text-lg font-medium block mb-1"> Last Name </label>
          <input formControlName="lastname" type="lname" class="w-full text-sm bg-n0 dark:bg-bg4 border border-n30 dark:border-n500 rounded-3xl px-3 md:px-6 py-2 md:py-3 mb-2" placeholder="Doe" id="lname" />
          @if (signUpForm.get('lastname')?.errors && signUpForm.get('lastname')?.touched) {
          <span class="text-secondary2" *ngIf="signUpForm.get('lastname')?.errors?.['required']">Lastname is required</span>
          <span class="text-secondary2" *ngIf="signUpForm.get('lastname')?.errors?.['minlength']">Lastname must be at least 2 character</span>
          }
        </div>
      </div>
      <div class="pt-4">
        <label for="email" class="md:text-lg font-medium block mb-2"> Enter Your Email </label>
        <input formControlName="email" type="text" class="w-full text-sm bg-n0 dark:bg-bg4 border border-n30 dark:border-n500 rounded-3xl px-3 md:px-6 py-2 md:py-3 mb-2" placeholder="Enter Your Email" id="email" />
        @if (signUpForm.get('email')?.errors && signUpForm.get('email')?.touched) {
        <span class="text-secondary2" *ngIf="signUpForm.get('email')?.errors?.['required']">Email is required</span>
        <span class="text-secondary2" *ngIf="signUpForm.get('email')?.errors?.['email']">Email is not valid</span>
        <span class="text-secondary2" *ngIf="signUpForm.get('email')?.errors?.['minlength']">Email must be at least 2 character</span>
        }
      </div>
      <label for="password" class="md:text-lg font-medium block my-4"> Enter Your Password </label>
      <div class="mb-2">
        <div id="passwordfield" class="bg-n0 mb-2 dark:bg-bg4 border border-n30 dark:border-n500 rounded-3xl px-3 md:px-6 py-2 md:py-2.5 relative">
          <input formControlName="password" [type]="passwordVisible ? 'text' : 'password'" class="w-11/12 text-sm bg-transparent p-0 border-none" placeholder="Enter Password" id="password2" />
          <span (click)="toggleVisibility()" class="absolute eye-icon ltr:right-5 rtl:left-5 top-1/2 -translate-y-1/2 cursor-pointer" >
            @if(passwordVisible){
            <i class="las la-eye"></i>
            } @else {
            <i class="las la-eye-slash"></i>
            }
          </span>
        </div>
        @if (signUpForm.get('password')?.errors && signUpForm.get('password')?.touched) {
        <span class="text-secondary2" *ngIf="signUpForm.get('password')?.errors?.['required']">Password is required</span>
        <span class="text-secondary2" *ngIf="signUpForm.get('password')?.errors?.['minlength']">Password must be at least 4 character</span>
        }
      </div>

      <p>
        By clicking submit, you agree to
        <a class="text-primary" href="#"> Terms of Use </a>
        ,
        <a class="text-primary" href="#"> Privacy Policy </a>
        ,
        <a class="text-primary" href="#"> E-sign </a>
        &
        <a class="text-primary" href="#"> Communication Authorization </a>
        .
      </p>
      <div class="mt-8">
        <button class="btn px-5">Sign Up</button>
      </div>
    </form>
  </div>
  <div class="col-span-12 lg:col-span-5 flex justify-center items-center">
    <img src="assets/images/auth.png" alt="img" width="533" height="560" />
  </div>
</div>
