<app-top-banner title="Tahsilatlar" />

<div class="relative inline-block w-full lg:w-auto">
  <article *ngIf="showDatepicker" class="calendar-wrapper">
    <igx-calendar selection="range" (selected)="onRangeChange($event)">
    </igx-calendar>
  </article>
</div>

<div class="box col-span-12">

  <div class="flex flex-wrap gap-4 justify-between items-center bb-dashed mb-4 pb-4 lg:mb-6 lg:pb-6">
    <div class="order-filters">

      <button *ngIf="showSaveButton == false" (click)="toggleCalendar()"
        class="font-medium text-xs px-4 sm:px-5 xxxl:px-6 py-3 capitalize invoice-active">
        @if(selectedDateRange?.start && selectedDateRange?.end){
        {{selectedDateRange?.start}}/{{selectedDateRange?.end}}
        }@else{Bugün}
        <i class="las la-calendar ml-2 cursor-pointer"></i>
      </button>

      <button *ngIf="showSaveButton" (click)="saveDateRange()"
        class="font-medium text-xs px-4 sm:px-5 xxxl:px-6 py-3 capitalize invoice-active">
        Kaydet
      </button>

      <button (click)="printTable()" class="font-medium text-xs px-4 sm:px-5 xxxl:px-6 py-3 capitalize invoice-active">
        Dışarı Aktar
        <i class="fa-solid fa-download"></i>
      </button>

    </div>

    <div class="chooseFirm">
      <label>Ödeme Tipi:</label>
      <select (change)="onPaymentType($event)"
      class="w-full !py-3 formDevicesSet w-full mb-1 text-sm bg-n0 dark:bg-bg4 border border-n30 dark:border-n500 ng-pristine ng-invalid ng-touched">
      <option (click)="clearFilter()" value="all">Tümü</option>
      <option *ngFor="let item of paymentType" [value]="item">
        {{item}}
      </option>
    </select>

      <label>Kasiyer:</label>
      <p-multiSelect selectedItemsLabel="{0} kasiyer seçildi"
      class="bg-primary/5 dark:bg-bg3 border border-n30 dark:border-n500 px-3 md:px-6 py-2 md:py-3 relative"
      [options]="personelList" optionLabel="name" placeholder="Kasiyer seçiniz"
      (onChange)="onStaffChange($event)" />

      <label>Şube: </label>
      <!-- <select (change)="onItemSelected($event)">
        <option *ngFor="let item of branchList" [value]="item.id">{{ item.title }}</option>
      </select> -->
      <p-multiSelect selectedItemsLabel="{0} şube seçildi"
      class="bg-primary/5 dark:bg-bg3 border border-n30 dark:border-n500 px-3 md:px-6 py-2 md:py-3 relative"
      [options]="branchList" optionLabel="title" placeholder="Şube seçiniz"
      (onChange)="onBranchChange($event)" />
      
    </div>
  </div>

  <div class="overflow-x-auto mb-4 lg:mb-6">
    <table class="w-full whitespace-nowrap">
      <thead>
        <tr class="bg-secondary1/5 dark:bg-bg3">
          <th class="text-start py-5 min-w-[130px]" style="padding: 1rem;">
            <div class="searcHead">
              <p>İşlem Tarihi</p>
              <div
                class="mt-2 bg-primary/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-3  py-2 relative">
                <input [(ngModel)]="searchTerm" class="w-11/12 text-sm bg-transparent p-0 border-none" type="number">
                <span class="absolute eye-icon ltr:right-5 rtl:left-5 top-1/2 -translate-y-1/2">
                  <i class="las la-search"></i>
                </span>
              </div>
            </div>
          </th>
          <th class="text-start py-5 min-w-[130px]">
            <div class="searcHead">
              <p>Ödeme Tipi</p>
              <div
                class="mt-2 bg-primary/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-3  py-2 relative">
                <input [(ngModel)]="searchPaymentTypeTerm" class="w-11/12 text-sm bg-transparent p-0 border-none"
                  type="number">
                <span class="absolute eye-icon ltr:right-5 rtl:left-5 top-1/2 -translate-y-1/2">
                  <i class="las la-search"></i>
                </span>
              </div>
            </div>
          </th>
          <th class="text-start py-5 min-w-[130px]">
            <div class="searcHead">
              <p>Ödeme Methodu</p>
              <div
                class="mt-2 bg-primary/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-3  py-2 relative">
                <input [(ngModel)]="searchPaymentMethodTerm" class="w-11/12 text-sm bg-transparent p-0 border-none"
                  type="text">
                <span class="absolute eye-icon ltr:right-5 rtl:left-5 top-1/2 -translate-y-1/2">
                  <i class="las la-search"></i>
                </span>
              </div>
            </div>
          </th>
          <th class="text-start py-5 min-w-[130px]">
            <div class="searcHead">
              <p>Müşteri</p>
              <div
                class="mt-2 bg-primary/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-3  py-2 relative">
                <input [(ngModel)]="searchCustomerTerm" class="w-11/12 text-sm bg-transparent p-0 border-none"
                  type="text">
                <span class="absolute eye-icon ltr:right-5 rtl:left-5 top-1/2 -translate-y-1/2">
                  <i class="las la-search"></i>
                </span>
              </div>
            </div>
          </th>
          <th class="text-start py-5 min-w-[130px]">
            <div class="searcHead">
              <p>Kasiyer</p>
              <div
                class="mt-2 bg-primary/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-3  py-2 relative">
                <input [(ngModel)]="searchCashierTerm" class="w-11/12 text-sm bg-transparent p-0 border-none"
                  type="text">
                <span class="absolute eye-icon ltr:right-5 rtl:left-5 top-1/2 -translate-y-1/2">
                  <i class="las la-search"></i>
                </span>
              </div>
            </div>
          </th>
          <th class="text-start py-5 min-w-[130px]">
            <div class="searcHead">
              <p>Terminal S.</p>
              <div
                class="mt-2 bg-primary/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-3  py-2 relative">
                <input [(ngModel)]="searchTerminalTerm" class="w-11/12 text-sm bg-transparent p-0 border-none"
                  type="text">
                <span class="absolute eye-icon ltr:right-5 rtl:left-5 top-1/2 -translate-y-1/2">
                  <i class="las la-search"></i>
                </span>
              </div>
            </div>
          </th>
          <th class="text-start py-5 min-w-[130px]">
            <div class="searcHead">
              <p>Sip Özel Kodu</p>
              <div
                class="mt-2 bg-primary/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-3  py-2 relative">
                <input [(ngModel)]="searchSipSpecialCodeTerm" class="w-11/12 text-sm bg-transparent p-0 border-none"
                  type="text">
                <span class="absolute eye-icon ltr:right-5 rtl:left-5 top-1/2 -translate-y-1/2">
                  <i class="las la-search"></i>
                </span>
              </div>
            </div>
          </th>
          <th class="text-start py-5 min-w-[130px]">
            <div class="searcHead">
              <p>Sipariş Kodu</p>
              <div
                class="mt-2 bg-primary/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-3  py-2 relative">
                <input [(ngModel)]="searchOrderCodeTerm" class="w-11/12 text-sm bg-transparent p-0 border-none"
                  type="text">
                <span class="absolute eye-icon ltr:right-5 rtl:left-5 top-1/2 -translate-y-1/2">
                  <i class="las la-search"></i>
                </span>
              </div>
            </div>
          </th>
          <th class="text-start py-5 min-w-[130px]">
            <div class="searcHead">
              <p>Toplam</p>
              <div
                class="mt-2 bg-primary/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-3  py-2 relative">
                <input [(ngModel)]="searchTotalAmountTerm" class="w-11/12 text-sm bg-transparent p-0 border-none"
                  type="text">
                <span class="absolute eye-icon ltr:right-5 rtl:left-5 top-1/2 -translate-y-1/2">
                  <i class="las la-search"></i>
                </span>
              </div>
            </div>
          </th>
          <th class="text-start py-5 min-w-[130px]">Durumu</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of filteredTransactionsCombined; let i = index;"
          [ngClass]="i % 2 === 0 ? 'even-row' : 'odd-row'" (click)="navigateToDetail(item.id)">
          <td class="w-[15%]">
            <div class="bg-secondary1/5 dark:bg-bg3 py-5 px-3"> {{ item.created_at | date:'dd/MM/yyyy hh:mm'}} </div>
          </td>
          <td class="w-[15%]">
            <div class="bg-secondary1/5 dark:bg-bg3 py-5 px-3">{{ item.payment_type }}</div>
          </td>
          <td class="w-[15%]">
            <div class="bg-secondary1/5 dark:bg-bg3 py-5 px-3">{{ item.payment_method }}</div>
          </td>
          <td class="w-[15%]">
            <div class="bg-secondary1/5 dark:bg-bg3 py-5 px-3">
              {{ item.order.customer }}
            </div>
          </td>
          @if(item.invoice_info.number){
          <td class="w-[15%]">
            <div class="bg-secondary1/5 dark:bg-bg3 py-5 px-3">{{ item.invoice_info.number }}</div>
          </td>
          }@else{
          <td class="w-[15%]">
            <div class="bg-secondary1/5 dark:bg-bg3 py-5 px-3">Bulunamadı</div>
          </td>
          }
          <td class="w-[15%]">
            <div class="bg-secondary1/5 dark:bg-bg3 py-5 px-3">{{ item.transaction_id }}</div>
          </td>
          @if(item.order.special_code ){
          <td class="w-[15%]">
            <div class="bg-secondary1/5 dark:bg-bg3 py-5 px-3">{{ item.order.special_code }}</div>
          </td>
          }@else{
          <td class="w-[15%]">
            <div class="bg-secondary1/5 dark:bg-bg3 py-5 px-3">Bulunamadı</div>
          </td>
          }

          <td class="w-[15%]">
            <div class="bg-secondary1/5 dark:bg-bg3 py-5 px-3">{{ item.order.order_code }}</div>
          </td>
          <td class="w-[15%]">
            <div class="bg-secondary1/5 dark:bg-bg3 py-5 px-3">{{ item.total_amount }} ₺</div>
          </td>
          <td>
            <a href="/payment/collection-detail">
              <div class="bg-secondary1/5 dark:bg-bg3 px-3 py-4">
                @if(item.transaction_status == "SUCCESS"){
                <span
                  class="text-primary bg-primary/10 block w-28 rounded-[30px] border border-n30 bg-primary/10 py-2 text-center text-xs text-primary dark:border-n500 dark:bg-bg3 xxl:w-36">
                  Başarılı
                </span>
                }@else if(item.transaction_status == "CANCEL"){
                <span
                class="text-secondary2 bg-secondary2/20 block w-28 rounded-[30px] border border-n30 bg-primary/10 py-2 text-center text-xs text-primary dark:border-n500 dark:bg-bg3 xxl:w-36">

                  İptal Edildi
                </span>
                }@else if(item.transaction_status == "WAITING"){
                <span  class="block w-28 rounded-[30px] border border-n30 py-2 text-center text-xs dark:border-n500 dark:bg-bg3 xxl:w-36 text-secondary3 bg-secondary3/10">
                  Bekliyor
                </span>
                }@else {
                  <span  class="block w-28 rounded-[30px] border border-n30 py-2 text-center text-xs dark:border-n500 dark:bg-bg3 xxl:w-36 text-secondary3 bg-secondary3/10">
                    Başarısız
                  </span>
                }
              </div>
            </a>
          </td>
        </tr>

      </tbody>
    </table>
  </div>

  <div *ngIf="filteredTransactionsCombined.length == 0" class="content-not-found">
    <img src="../../../../assets/images/content-not-found.png" alt="">
    <h4 style="margin-top: 1rem;">İçerik Bulunamadı</h4>
  </div>

  <div *ngIf="pages.length > 1" class="flex col-span-12 gap-4 sm:justify-between justify-center items-center flex-wrap">
    <ul class="flex gap-2 md:gap-3 flex-wrap md:font-semibold items-center">
      <li>
        <button [disabled]="startPage === 1" (click)="prevPageGroup()"
          class="hover:bg-primary rtl:rotate-180 hover:text-n0 border md:w-10 duration-300 md:h-10 w-8 h-8 flex items-center rounded-full justify-center border-primary">
          <i class="las la-angle-left text-lg"></i>
        </button>
      </li>
      <li *ngFor="let page of pages.slice(startPage - 1, endPage)">
        <button (click)="goToPage(page)" [class.bg-primary]="transactions.currentPage === page"
          class="hover:bg-primary hover:text-n0 border md:w-10 duration-300 md:h-10 w-8 h-8 flex items-center rounded-full justify-center border-primary">
          {{ page }}
        </button>
      </li>
      <li>
        <button [disabled]="endPage === pages.length" (click)="nextPageGroup()"
          class="hover:bg-primary hover:text-n0 rtl:rotate-180 border md:w-10 duration-300 md:h-10 w-8 h-8 flex items-center rounded-full justify-center border-primary">
          <i class="las la-angle-right text-lg"></i>
        </button>
      </li>
    </ul>
  </div>


  <div class="col-span-12 lg:col-span-6 xxl:col-span-7 xxxl:col-span-6 mt-4" id="totalDataTable">
  
    <div class="col-span-3 row collectionBox leftSide">
      <div class="column">
        <p>Genel Toplam</p>
      </div>
      <div class="totalSection">
        <p>{{formatAmount(transactionData.total_amount) ?? '0'}} ₺</p>
      </div>
    </div>

  </div>
</div>