import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AddProductService {

    private apiUrl = `${environment.apiUrl}/products`;
    private token = localStorage.getItem('authToken');
    constructor(private http: HttpClient) { }

    addProduct(
        code: string,
        title: string,
        sku: string,
        description: string,
        unit: string,
        product_type: string,
        tax_rate: number,
        purchase_price: number | null,
        price: number,
        price_1: number | null,
        price_2: number | null,
        stock_alert: number| null,
        stock: number| null,
        is_special_card: boolean,
        payment_group: string,
        exemption_code: string | null,
        categories: string[] | null,
    ): Observable<any> {
        const headers = new HttpHeaders()
            .set('Authorization', `Bearer ${this.token}`);
        const urlPath = `${this.apiUrl}`;
        const body = {
            "code": code,
            "title": title,
            "sku": sku,
            "description": description,
            "unit": unit,
            "product_type": product_type,
            "tax_rate": tax_rate,
            "purchase_price": purchase_price,
            "price": price,
            "price_1": price_1,
            "price_2": price_2,
            "stock_alert": stock_alert,
            "stock": stock,
            "is_special_card": is_special_card,
            "payment_group": payment_group,
            "exemption_code": exemption_code,
            "categories": categories,
        };
        return this.http.post<any>(urlPath, body, { headers });
    }

}
