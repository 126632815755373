import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { DropdownComponent } from '@component/shared/dropdown/dropdown.component';
import { OptionsVerticalComponent } from '@component/shared/options-vertical/options-vertical.component';
import { TopBannerComponent } from '@component/shared/top-banner/top-banner.component';
import { latestTransactions } from '@data/transactions/latestTransactions';
import { TableService } from '@service/table.service';
import { CalendarModule } from 'primeng/calendar';
import { FormsModule } from '@angular/forms';
import { ModalService } from 'ngx-modal-ease';
import { AddCardModalComponent } from '@component/shared/add-card-modal/add-card-modal.component';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { OrdersAllService } from '@service/order/order.service';
import { Router } from '@angular/router';
import { OrderListDataModel, OrderListModel } from '../../../models/order-model/order-list.model';
import { LocaleConfig, NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { JsonPipe, registerLocaleData } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { provideNativeDateAdapter } from '@angular/material/core';
import { VERSION as MAT_VERSION, MatNativeDateModule } from '@angular/material/core';
import { IgxCalendarModule, IgxSnackbarModule } from "igniteui-angular";
import { LOCALE_ID } from '@angular/core';
import localeTrExtra from '@angular/common/locales/extra/tr';
import localeTr from '@angular/common/locales/tr';
import { ChangeDetectorRef } from '@angular/core';
import { BaranchListService } from '@service/branches/bracnhs-list-service';
import { BranchListData, BranchListModel } from '../../../models/branches/bracnhs-list-model';
import { MultiSelectModule } from 'primeng/multiselect';
registerLocaleData(localeTr, 'tr-TR', localeTrExtra);


@Component({
  selector: 'tr-app-orders',
  standalone: true,
  imports: [
    TopBannerComponent,
    DropdownComponent,
    OptionsVerticalComponent,
    CommonModule,
    CalendarModule,
    MultiSelectModule,
    IgxCalendarModule,
    IgxSnackbarModule,
    FormsModule,
    MatDatepicker,
    MatFormFieldModule,
    MatDatepickerModule,
    NgxDaterangepickerMd,
    MatMomentDateModule,
    MatFormFieldModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    JsonPipe,
    MatNativeDateModule
  ],
  providers: [
    provideNativeDateAdapter(),
    { provide: LOCALE_ID, useValue: 'tr-TR' }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './orders.component.html'
})
export class OrdersComponent implements OnInit {
  transactions: any;
  searchTerm: string = '';
  orderCodeSearchTerm: string = '';
  specialCodeSearchTerm: string = '';
  customerSearchTerm: string = '';
  totalAmountSearchTerm: string = '';
  remainingAmountSearchTerm: string = '';
  collectedAmountSearchTerm: string = '';

  filters = ['Tümü', 'Başarılı', 'İptal Edildi', 'Devam Ediyor'];
  currentFilter = this.filters[0];
  date: Date | undefined;
  orderList!: OrderListModel;
  orderData!: OrderListDataModel;

  branchList!: BranchListData[];

  pages: number[] = [];
  startPage: number = 1;
  endPage: number = 10;


  validPages: number[] = [];
  showDatepicker: boolean = false;
  showSaveButton: boolean = false;
  selectedDateRange: { start: any, end: any } | null = null;
  selectedBranch!: [];

  locale: LocaleConfig = {
    applyLabel: 'Uygula',
    cancelLabel: 'İptal',
    format: 'DD.MM.YYYY',
    daysOfWeek: ['Pzt', 'Sal', 'Çar', 'Per', 'Cum', 'Cmt', 'Paz'],
    monthNames: ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık']
  };

  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl()
  });

  constructor(private router: Router, private branchesService: BaranchListService, private modalService: ModalService, private cdr: ChangeDetectorRef, private ordersAllService: OrdersAllService) {
    this.transactions = new TableService();
    this.transactions.initialize(latestTransactions, 1);
    NgxDaterangepickerMd.forRoot({
      locale: this.locale
    });
  }

  formatter = new Intl.NumberFormat('tr-TR', {
    currency: 'TRY',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });

  formatAmount(amount: number | undefined): string {
    return this.formatter.format(amount ?? 0);
  }
  
  ngOnInit() {
    this.loadOrders();
    this.branchesService.allData().subscribe(
      response => {
        this.branchList = response.data;
      },
      error => {
        console.error('Hata:', error);
      }
    );
  }

  updatePageRange() {
    this.endPage = Math.min(this.startPage + 9, this.pages.length);
  }
  
  nextPageGroup() {
    if (this.endPage < this.pages.length) {
      this.startPage = this.endPage + 1;
      this.endPage = Math.min(this.startPage + 9, this.pages.length);
      this.cdr.detectChanges();
    }
  }

  prevPageGroup() {
    if (this.startPage > 1) {
      this.endPage = this.startPage - 1;
      this.startPage = Math.max(this.endPage - 9, 1);
      this.cdr.detectChanges();
    }
  }

  loadOrders() {
    const today = new Date();
    const formattedtoday = `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}`;
    if (this.selectedBranch) {
      this.ordersAllService.allOrders("1", "20", formattedtoday, formattedtoday, true, this.selectedBranch).subscribe(
        response => {
          this.orderList = response;
          this.calculatePages();
          this.updateValidPages();
          this.cdr.detectChanges();
        },
        error => {
          console.error('Hata:', error);
        }
      );
    } else {
      this.ordersAllService.allOrders("1", "20", formattedtoday, formattedtoday).subscribe(
        response => {
          this.orderList = response;
          this.calculatePages();
          this.updateValidPages();
          this.cdr.detectChanges();
        },
        error => {
          console.error('Hata:', error);
        }
      );
    }
  }

  calculatePages() {
    const totalPages = Math.ceil(this.orderList.total_count / 20);
    this.pages = Array.from({ length: totalPages }, (_, i) => i + 1);
  }

  updateValidPages() {
    const validPages: number[] = [];
    if (this.selectedDateRange) {
      if (this.selectedBranch) {
        const promises = this.pages.map(page =>
          this.ordersAllService.allOrders(page.toString(), "20", this.selectedDateRange?.start, this.selectedDateRange?.end, true, this.selectedBranch).toPromise().then(
            response => {
              if (response && response.data.length > 0) {
                validPages.push(page);
                this.cdr.detectChanges();
              }
            }
          ).catch(error => {
            console.error('Hata:', error);
          })
        );
        Promise.all(promises).then(() => {
          this.validPages = validPages;
        });
      } else {
        const promises = this.pages.map(page =>
          this.ordersAllService.allOrders(page.toString(), "20", this.selectedDateRange?.start, this.selectedDateRange?.end).toPromise().then(
            response => {
              if (response && response.data.length > 0) {
                validPages.push(page);
                this.cdr.detectChanges();
              }
            }
          ).catch(error => {
            console.error('Hata:', error);
          })
        );
        Promise.all(promises).then(() => {
          this.validPages = validPages;
        });


      }
    } else {
      if (this.selectedBranch) {
        const promises = this.pages.map(page =>
          this.ordersAllService.allOrders(page.toString(), "20", this.selectedDateRange?.start, this.selectedDateRange?.end, true, this.selectedBranch).toPromise().then(
            response => {
              if (response && response.data.length > 0) {
                validPages.push(page);
                this.cdr.detectChanges();
              }
            }
          ).catch(error => {
            console.error('Hata:', error);
          })
        );
        Promise.all(promises).then(() => {
          this.validPages = validPages;
        });
      } else {
        const today = new Date();
        const formattedtoday = `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}`;
        const promises = this.pages.map(page =>
          this.ordersAllService.allOrders(page.toString(), "20", formattedtoday, formattedtoday).toPromise().then(
            response => {
              if (response && response.data.length > 0) {
                validPages.push(page);
                this.cdr.detectChanges();
              }
            }
          ).catch(error => {
            console.error('Hata:', error);
          })
        );
        Promise.all(promises).then(() => {
          this.validPages = validPages;
        });


      }

    }

  }

  goToPage(page: number) {
    if (this.validPages.includes(page)) {
      if (this.selectedDateRange) {
        if (this.selectedBranch) {
          this.ordersAllService.allOrders(page.toString(), "20", this.selectedDateRange.start, this.selectedDateRange.end, true, this.selectedBranch).subscribe(
            response => {
              if (response && response.data.length > 0) {
                this.orderList = response;
                this.transactions.paginate(page);
                this.cdr.detectChanges();
              } else {
                this.updateValidPages();
              }
            },
            error => {
              console.error('Hata:', error);
            }
          );
        } else {
          this.ordersAllService.allOrders(page.toString(), "20", this.selectedDateRange.start, this.selectedDateRange.end).subscribe(
            response => {
              if (response && response.data.length > 0) {
                this.orderList = response;
                this.transactions.paginate(page);
                this.cdr.detectChanges();
              } else {
                this.updateValidPages();
              }
            },
            error => {
              console.error('Hata:', error);
            }
          );
        }
      } else {
        if (this.selectedBranch) {
          const today = new Date();
          const formattedtoday = `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}`;
          this.ordersAllService.allOrders(page.toString(), "20", formattedtoday, formattedtoday, true, this.selectedBranch).subscribe(
            response => {
              if (response && response.data.length > 0) {
                this.orderList = response;
                this.transactions.paginate(page);
                this.cdr.detectChanges();
              } else {
                this.updateValidPages();
              }
            },
            error => {
              console.error('Hata:', error);
            }
          );
        } else {
          const today = new Date();
          const formattedtoday = `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}`;
          this.ordersAllService.allOrders(page.toString(), "20", formattedtoday, formattedtoday).subscribe(
            response => {
              if (response && response.data.length > 0) {
                this.orderList = response;
                this.transactions.paginate(page);
                this.cdr.detectChanges();
              } else {
                this.updateValidPages();
              }
            },
            error => {
              console.error('Hata:', error);
            }
          );
        }
      }
    } else {
      console.warn(`Page ${page} is not valid and will not be displayed.`);
    }
  }

  setFilter(filter: string) {
    this.currentFilter = filter;

    let filterStatus: string | null = null;

    if (filter !== 'Tümü') {
      if (filter === 'Başarılı') {
        filterStatus = 'SUCCESS';
      } else if (filter === 'İptal Edildi') {
        filterStatus = 'CANCEL';
      } else if (filter === 'Devam Ediyor') {
        filterStatus = 'WAITING';
      }
    } else {
      if (this.selectedDateRange) {
        if (this.selectedBranch) {
          this.ordersAllService.allOrders("1", "20", this.selectedDateRange.start, this.selectedDateRange.end, true, this.selectedBranch).subscribe(
            response => {
              this.orderList = response;
              this.calculatePages();
              this.updateValidPages();
              this.transactions.initialize(response.data, 1);
              this.cdr.detectChanges();
            },
            error => {
              console.error('Hata:', error);
            }
          );
        } else {
          this.ordersAllService.allOrders("1", "20", this.selectedDateRange.start, this.selectedDateRange.end).subscribe(
            response => {
              this.orderList = response;
              this.calculatePages();
              this.updateValidPages();
              this.transactions.initialize(response.data, 1);
              this.cdr.detectChanges();
            },
            error => {
              console.error('Hata:', error);
            }
          );
        }
      } else {
        if (this.selectedBranch) {
          const today = new Date();
          const formattedtoday = `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}`;
          this.ordersAllService.allOrders("1", "20", formattedtoday, formattedtoday, true, this.selectedBranch).subscribe(
            response => {
              this.orderList = response;
              this.calculatePages();
              this.updateValidPages();
              this.transactions.initialize(response.data, 1);
              this.cdr.detectChanges();
            },
            error => {
              console.error('Hata:', error);
            }
          );
        } else {
          const today = new Date();
          const formattedtoday = `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}`;
          this.ordersAllService.allOrders("1", "20", formattedtoday, formattedtoday).subscribe(
            response => {
              this.orderList = response;
              this.calculatePages();
              this.updateValidPages();
              this.transactions.initialize(response.data, 1);
              this.cdr.detectChanges();
            },
            error => {
              console.error('Hata:', error);
            }
          );
        }
      }
    }

    if (this.selectedDateRange) {
      if (this.selectedBranch) {
        this.ordersAllService.allOrdersStatus("1", "20", this.selectedDateRange.start, this.selectedDateRange.end, filterStatus!, true, this.selectedBranch).subscribe(
          response => {
            this.orderList = response;
            this.calculatePages();
            this.updateValidPages();
            this.transactions.initialize(response.data, 1);
            this.cdr.detectChanges();
          },
          error => {
            console.error('Hata:', error);
          }
        );
      }
      this.ordersAllService.allOrdersStatus("1", "20", this.selectedDateRange.start, this.selectedDateRange.end, filterStatus!).subscribe(
        response => {
          this.orderList = response;
          this.calculatePages();
          this.updateValidPages();
          this.transactions.initialize(response.data, 1);
          this.cdr.detectChanges();
        },
        error => {
          console.error('Hata:', error);
        }
      );
    } else {
      if (this.selectedBranch) {
        const today = new Date();
        const formattedtoday = `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}`;
        this.ordersAllService.allOrdersStatus("1", "20", formattedtoday, formattedtoday, filterStatus!, true, this.selectedBranch).subscribe(
          response => {
            this.orderList = response;
            this.calculatePages();
            this.updateValidPages();
            this.transactions.initialize(response.data, 1);
            this.cdr.detectChanges();
          },
          error => {
            console.error('Hata:', error);
          }
        );
      }
      const today = new Date();
      const formattedtoday = `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}`;
      this.ordersAllService.allOrdersStatus("1", "20", formattedtoday, formattedtoday, filterStatus!).subscribe(
        response => {
          this.orderList = response;
          this.calculatePages();
          this.updateValidPages();
          this.transactions.initialize(response.data, 1);
          this.cdr.detectChanges();
        },
        error => {
          console.error('Hata:', error);
        }
      );
    }

  }

  formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  }

  openModal() {
    this.modalService.open(AddCardModalComponent, {
      modal: {
        enter: 'enter-going-down 0.3s ease-out',
        leave: 'fade-out 0.5s'
      },
      overlay: {
        leave: 'fade-out 0.5s'
      },
      data: {
        type: 'Angular modal library'
      }
    });
  }

  convertToTurkishCharacters(text: string) {
    return text.replace(/ğ/g, 'g')
      .replace(/Ğ/g, 'G')
      .replace(/ü/g, 'u')
      .replace(/Ü/g, 'U')
      .replace(/ş/g, 's')
      .replace(/Ş/g, 'S')
      .replace(/ı/g, 'i')
      .replace(/İ/g, 'I')
      .replace(/ö/g, 'o')
      .replace(/Ö/g, 'O')
      .replace(/ç/g, 'c')
      .replace(/Ç/g, 'C');
  }

  public printTable() {
    const doc = new jsPDF('l', 'mm', 'a4');

    const head = [
      [
        [this.convertToTurkishCharacters('İşlem Tarihi')],
        [this.convertToTurkishCharacters('Sipariş Kodu')],
        [this.convertToTurkishCharacters('Özel Kod')],
        [this.convertToTurkishCharacters('Müşteri')],
        [this.convertToTurkishCharacters('Toplam Tutar')],
        [this.convertToTurkishCharacters('Kalan Tutar')],
        [this.convertToTurkishCharacters('Tahsil Edilen')]
      ]
    ];
    const data = this.filteredTransactionsCombined.map((item: any) => [
      [item.order_date],
      [item.order_code],
      [item.special_code],
      [item.customer_name],
      [item.total_amount],
      [item.amount_due],
      [item.total_paid]
    ]);

    autoTable(doc, {
      head: head,
      body: data,
      styles: {
        font: 'Poppins',
        fontStyle: 'normal',
      },
      bodyStyles: { fillColor: [200, 200, 200] }
    });

    doc.save('siparisler.pdf');
  }

  onItemSelected(event: any): void {
    this.selectedBranch = event.target.value;
    this.loadOrders();
    this.cdr.detectChanges();
  }

  get filteredTransactionsCombined() {
    const customerTerm = this.customerSearchTerm.toLowerCase();
    const oderCodeTerm = this.orderCodeSearchTerm.toLowerCase();

    return this.orderList.data.filter((item: any) => {
      const customerTitle = item.customer_name.toLowerCase();
      const orderCodeSearch = item.order_code.toLowerCase();

      return (!this.searchTerm || item.order_date.includes(this.searchTerm)) &&
        (!oderCodeTerm || orderCodeSearch.includes(oderCodeTerm)) &&
        (!this.specialCodeSearchTerm || item.special_code.includes(this.specialCodeSearchTerm)) &&
        (!customerTerm || customerTitle.includes(customerTerm)) &&
        (!this.totalAmountSearchTerm || item.total_amount.toString().indexOf(this.totalAmountSearchTerm) !== -1) &&
        (!this.remainingAmountSearchTerm || item.amount_due.toString().indexOf(this.remainingAmountSearchTerm) !== -1) &&
        (!this.collectedAmountSearchTerm || item.total_paid.toString().indexOf(this.collectedAmountSearchTerm) !== -1);
    });
  }

  navigateToDetail(id: string) {
    this.router.navigate(['/order/detail', id]);
  }

  toggleCalendar() {
    this.showDatepicker = !this.showDatepicker;
    if (!this.showDatepicker) {
      this.showSaveButton = false;
    }
  }

  onRangeChange(event: any) {
    const start = new Date(event[0]);
    const endIndex = event.length - 1;
    const end = new Date(event[endIndex]);

    const formattedStart = `${start.getFullYear()}-${(start.getMonth() + 1).toString().padStart(2, '0')}-${start.getDate().toString().padStart(2, '0')}`; // YYYY-MM-DD
    const formattedEnd = `${end.getFullYear()}-${(end.getMonth() + 1).toString().padStart(2, '0')}-${end.getDate().toString().padStart(2, '0')}`; // YYYY-MM-DD

    this.selectedDateRange = {
      start: formattedStart,
      end: formattedEnd,
    };
    this.showSaveButton = true;
  }

  saveDateRange() {
    if (this.selectedDateRange) {
      this.showDatepicker = false;
      this.showSaveButton = false;

      if (this.selectedBranch) {
        this.ordersAllService.allOrders("1", "20", this.selectedDateRange.start, this.selectedDateRange.end, true, this.selectedBranch).subscribe(
          response => {
            this.orderList = response;
            this.calculatePages();
            this.updateValidPages();
            this.cdr.detectChanges();
          },
          error => {
            console.error('Hata:', error);
          }
        );
      } else {
        this.ordersAllService.allOrders("1", "20", this.selectedDateRange.start, this.selectedDateRange.end).subscribe(
          response => {
            this.orderList = response;
            this.calculatePages();
            this.updateValidPages();
            this.cdr.detectChanges();
          },
          error => {
            console.error('Hata:', error);
          }
        );
      }

    } else {
      console.log('Tarih aralığı seçilmedi.');
    }
  }

  onBranchChange(event: any) {
    const selectedCodes = event.value.map((item: any) => item.id);
    this.selectedBranch = selectedCodes
    this.loadOrders();
    this.cdr.detectChanges();
  }

}
