import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TransactionCancelModel } from '../../models/transaction-model/transactions-cancel.model';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class CancelTransActionsService {

    private apiUrl = `${environment.apiUrl}/transactions/cancel`;
    private token = localStorage.getItem('authToken');

    constructor(private http: HttpClient) { }


    allCancelTransActions(
        page: string,
        limit: string,
        startDate: string,
        endDate: string
    ): Observable<TransactionCancelModel> {
        const headers = new HttpHeaders()
            .set('Authorization', `Bearer ${this.token}`);
        const urlPath = `${this.apiUrl}?page=${page}&limit=${limit}&start_date=${startDate}&end_date=${endDate}`;
        const body = {
            "staff_ids": [],
            "firm_ids": [],
        };
        return this.http.post<any>(urlPath, body, { headers });
    }
}
