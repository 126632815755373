<div class=" bg-n0 dark:bg-bg4 box overflow-y-auto max-h-[800px]">
  <div class="flex justify-end items-center bb-dashed pb-6 mb-6">
    <i (click)="closeModal()" class="las cursor-pointer la-times"></i>
  </div>
  <div class="downloadOptions">
    <h4>Dışarı Aktar</h4>
    <p>Seçmiş olduğunuz  kısımları hızlı (metin eklenecek)</p>
    <div class="share-buttons grid-cols-12 gap-4 xxl:gap-6">
     <div class="buttonwtext">
      <div
      class="col-span-12 md:col-span-6 xxl:col-span-4 box xl:p-6 gap-3 bg-dark dark:bg-bg3 border border-n30 dark:border-n500 flex justify-between items-center">
      <img src="../../../../assets/icons/download.svg">
      </div>
      <p>İndir</p> 
     </div>
     <div class="buttonwtext">
      <div
      class="col-span-12 md:col-span-6 xxl:col-span-4 box xl:p-6 gap-3 bg-dark dark:bg-bg3 border border-n30 dark:border-n500 flex justify-between items-center">
      <img src="../../../../assets/icons/message.svg">
      </div>
      <p>Maile Gönder</p> 
     </div>
      <div class="buttonwtext">
        <div
        class="col-span-12 md:col-span-6 xxl:col-span-4 box xl:p-6 gap-3 bg-dark dark:bg-bg3 border border-n30 dark:border-n500 flex justify-between items-center">
        <img src="../../../../assets/icons/message.svg">
        </div>
        <p>SMS Gönder</p> 
       </div>
    </div>
  </div>
</div>
<!-- <form>
  <div class="mt-6 xl:mt-8 grid grid-cols-2 gap-4 xxxl:gap-6">
    <div class="col-span-2">
      <label htmlFor="card-number" class="md:text-lg font-medium block mb-4"> Card Number </label>
      <div class="bg-secondary1/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl relative">
        <input type="number" class="text-sm w-full px-6 py-2.5 md:py-3 bg-transparent rounded-3xl" placeholder="5890 - 6858 - 6332 - 9843" id="card-number" required />
        <img src="assets/images/mastercard.png" class="absolute top-1/2 -translate-y-1/2 ltr:right-4 rtl:left-4" width="{20}" height="{20}" alt="mastercard" />
      </div>
    </div>
    <div class="col-span-2">
      <label htmlFor="holder" class="md:text-lg font-medium block mb-4"> Card Holder </label>
      <input type="text" class="w-full text-sm bg-secondary1/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-6 py-2.5 md:py-3" placeholder="Enter Name" id="holder" required />
    </div>
    <div class="col-span-2 md:col-span-1">
      <label htmlFor="month" class="md:text-lg font-medium block mb-4"> Month </label>
      <input type="number" class="w-full text-sm bg-secondary1/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-6 py-2.5 md:py-3" placeholder="12" id="month" required />
    </div>
    <div class="col-span-2 md:col-span-1">
      <label htmlFor="year" class="md:text-lg font-medium block mb-4"> Year </label>
      <input type="number" class="w-full text-sm bg-secondary1/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-6 py-2.5 md:py-3" placeholder="2027" id="year" required />
    </div>

    <div class="col-span-2 mt-4">
      <button class="btn flex w-full justify-center" type="submit">Add Card</button>
    </div>
  </div>
</form> -->