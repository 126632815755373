import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ProfileUserSettingsModel } from '../../models/profile/profile-user-settings.model';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ProfileUserSettingsService {

    private apiUrl = `${environment.apiUrl}/profiles/`;
    private token = localStorage.getItem('authToken');
    constructor(private http: HttpClient) { }

    allProfilesUserSettings(profileId:string): Observable<ProfileUserSettingsModel[]> {
        const headers = new HttpHeaders()
            .set('Authorization', `Bearer ${this.token}`,)
        return this.http.get<ProfileUserSettingsModel[]>(this.apiUrl+profileId,{headers});
    }

}
