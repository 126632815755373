export const TransactionStatus = {
  Successful: 'successful',
  Cancelled: 'cancelled',
  Pending: 'pending'
}
export const transactionsData = [
  {
    id: 9,
    dueDate: "22.02.2024",
    orderCode: "356425323",
    speciealCode: "GDRFF5",
    title: "Yılmaz Gıda ve Un Mamülleri Marketler Zinciri",
    totalAmount: "145525000",
    remainingAmount: "525000",
    collectedAmount: "525000",
    status: TransactionStatus.Pending,
  },

  {
    id: 10,
    dueDate: "23.02.2024",
    orderCode: "426464223",
    speciealCode: "GSTDF5",
    title: "Yılmaz Gıda ve Un Mamülleri Marketler Zinciri",
    totalAmount: "124525000",
    remainingAmount: "325000",
    collectedAmount: "425000",
    status: TransactionStatus.Cancelled,
  },
  {
    id: 7,
    dueDate: "25.02.2024",
    orderCode: "7666462233",
    speciealCode: "TS2K342",
    title: "File Marketler Zinciri",
    totalAmount: "135678930",
    remainingAmount: "145780",
    collectedAmount: "257942",
    status: TransactionStatus.Cancelled,
  },

  {
    id: 11,
    dueDate: "22.02.2024",
    orderCode: "1216464223",
    speciealCode: "BJKTDF5",
    title: "Yılmaz Gıda ve Un Mamülleri Marketler Zinciri",
    totalAmount: "544525000",
    remainingAmount: "415000",
    collectedAmount: "525000",
    status: TransactionStatus.Successful,
  },

]
