<div class=" bg-n0 dark:bg-bg4 box overflow-y-auto max-h-[800px]">
  <div class="flex justify-between items-center bb-dashed pb-6 mb-6">
    <h4>Bölüm Alanı Ekle</h4><i (click)="closeModal()" class="las cursor-pointer la-times"></i>
  </div>
  <form>
    <div class="mt-6 xl:mt-8 grid gap-4 xxxl:gap-6">
      <div class="col-span-2">
        <label htmlFor="card-number" class="md:text-lg font-medium block mb-4">Bölüm Adı </label>
        <div class="bg-secondary1/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl relative">
          <input type="number" class="text-sm w-full px-6 py-2.5 md:py-3 bg-transparent rounded-3xl"
            placeholder="ER423422" id="card-number" required />
        </div>
      </div>
      
      <div class="col-span-2">
        <label htmlFor="card-number" class="md:text-lg font-medium block mb-4">Kat Bilgisi </label>
        <div class="bg-secondary1/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl relative">
          <input type="number" class="text-sm w-full px-6 py-2.5 md:py-3 bg-transparent rounded-3xl"
            placeholder="ER423422" id="card-number" required />
        </div>
      </div>
      
      <div class="col-span-2 mt-4">
        <button class="btn flex w-full justify-center" type="submit">Onayla</button>
      </div>
    </div>
  </form>
</div>