import { Component, OnInit } from '@angular/core';
import { providersTabData } from '@data/payment/providers';
import { PasswordInputComponent } from '@component/shared/password-input/password-input.component';
import { DropdownComponent } from '@component/shared/dropdown/dropdown.component';
import { GetWorkingHoursService } from '@service/settings/get-working-hours-service';
import { WorkingDaysEnum, getEnumValues } from '@data/enums/working-hours-enum';
import { GetWorkingHoursModel, GetWorkingHoursTimeInterval } from '../../../models/settings/get-working-house-response.model';
import { CommonModule } from '@angular/common';
import { ModalService } from 'ngx-modal-ease';
import { WorkingHoursModalComponent } from '../working-hours-add-modal/working-hours-modal.component';
import { FirmDataService } from '@service/firm/firm-service';
import { BaranchListService } from '@service/branches/bracnhs-list-service';
import { BranchListData, BranchListModel } from '../../../models/branches/bracnhs-list-model';
import { DeleteWorkHoursModalComponent } from '../delete-work-hours-modal/delete-service-modal.component';

@Component({
  selector: 'app-working-hours-settings',
  standalone: true,
  imports: [PasswordInputComponent, DropdownComponent, CommonModule],
  templateUrl: './working-hours-settings.component.html'
})
export class WorkingHoursSettingsComponent implements OnInit {
  providers = providersTabData;
  activeTab = this.providers[0].title;
  getDefaultHoursData: GetWorkingHoursModel[] = [];
  firmData: any;
  branchId!: any
  getTimeInterData: GetWorkingHoursTimeInterval[] = [];
  workingDaysEnumKeys: WorkingDaysEnum[] = Object.values(WorkingDaysEnum);


  weekOfDays = [
    'Pazartesi',
    'Salı',
    'Çarşamba',
    'Perşembe',
    'Cuma',
    'Cumartesi',
    'Pazar',
  ];
  workingHoursByDay: any;
  WorkingDaysEnumValues = getEnumValues(WorkingDaysEnum);
  WorkingDaysEnum = WorkingDaysEnum;
  apiDays: string[] = [];
  dayTranslations: { [key in WorkingDaysEnum]: string } = {
    [WorkingDaysEnum.MONDAY]: 'Pazartesi',
    [WorkingDaysEnum.TUESDAY]: 'Salı',
    [WorkingDaysEnum.WEDNESDAY]: 'Çarşamba',
    [WorkingDaysEnum.THURSDAY]: 'Perşembe',
    [WorkingDaysEnum.FRIDAY]: 'Cuma',
    [WorkingDaysEnum.SATURDAY]: 'Cumartesi',
    [WorkingDaysEnum.SUNDAY]: 'Pazar',
  };
  constructor(private branchService: BaranchListService, private getWorkingHoursData: GetWorkingHoursService, private modalService: ModalService, private firmDataService: FirmDataService) { }

  ngOnInit(): void {
    this.branchService.allData().subscribe(
      (response: BranchListModel) => {
        this.branchId = response.data[0].id
        this.loadWorkingHoursData();
      },
      error => {
        console.error('Hata:', error);
      }
    );


    this.getWorkingHoursData.allHoursData().subscribe(
      response => {
        this.getDefaultHoursData = response;
        this.workingHoursByDay = this.groupHoursByDay(response);
        this.apiDays = Object.keys(this.workingHoursByDay);
      },
      error => {
        console.error('Hata:', error);
      }
    );

    this.firmDataService.allFirmData().subscribe(
      response => {
        this.firmData = response;
      },
      error => {
        console.error('Hata:', error);
      }
    );
  }

  private loadWorkingHoursData(): void {
    this.getWorkingHoursData.allHoursData().subscribe(
      response => {
        this.getDefaultHoursData = response;
        this.workingHoursByDay = this.groupHoursByDay(response);
      },
      error => {
        console.error('Hata:', error);
      }
    );
  }

  private groupHoursByDay(hoursData: GetWorkingHoursModel[]): { [key in WorkingDaysEnum]?: GetWorkingHoursModel } {
    const hoursByDay: { [key in WorkingDaysEnum]?: GetWorkingHoursModel } = {};
    hoursData.forEach(hour => {
      if (Object.values(WorkingDaysEnum).includes(hour.day_of_week as WorkingDaysEnum)) {
        hoursByDay[hour.day_of_week as WorkingDaysEnum] = hour;
      }
    });
    return hoursByDay;
  }

  get filteredWorkingDays() {
    return this.WorkingDaysEnumValues
      .filter(day => !this.apiDays.includes(day.key))
      .map(day => ({
        key: day.key,
        value: this.dayTranslations[day.key as WorkingDaysEnum]
      }));
  }

  // private groupHoursByDay(hoursData: GetWorkingHoursModel[]): { [key in WorkingDaysEnum]?: GetWorkingHoursModel } {
  //   const hoursByDay: { [key in WorkingDaysEnum]?: GetWorkingHoursModel } = {};
  //   hoursData.forEach(hour => {
  //     if (Object.values(WorkingDaysEnum).includes(hour.day_of_week as WorkingDaysEnum)) {
  //       hoursByDay[hour.day_of_week as WorkingDaysEnum] = hour;
  //     }
  //   });
  //   return hoursByDay;
  // }

  workingHoursAdd() {
    this.modalService.open(WorkingHoursModalComponent, {
      modal: {
        enter: 'enter-going-down 0.3s ease-out',
        leave: 'fade-out 0.5s'
      },
      overlay: {
        leave: 'fade-out 0.5s'
      },
      data: {
        type: 'Angular modal library',
        workingDays: this.filteredWorkingDays,
        firmsInfo: this.firmData,
      }
    })
  }

  onDelete(dayKey: any) {
    const workingHours = this.workingHoursByDay[dayKey];
    if (workingHours) {
      const start = workingHours.time_intervals?.[0]?.start || 'N/A';
      const end = workingHours.time_intervals?.[0]?.end || 'N/A';
      this.modalService.open(DeleteWorkHoursModalComponent, {
        modal: {
          enter: 'enter-going-down 0.3s ease-out',
          leave: 'fade-out 0.5s'
        },
        overlay: {
          leave: 'fade-out 0.5s'
        },
        data: {
          type: 'Angular modal library',
          firmId: this.firmData.id,
          selectedDay: dayKey,
          selectedStartHour: start,
          selectedEndHour: end
        }
      });
    } else {
      console.log('Bulunamadı');
    }
  }
}
