<div class=" bg-n0 dark:bg-bg4 box overflow-y-auto max-h-[800px]" style="width: 50vh;">
  <div class="flex justify-between items-center bb-dashed pb-6 mb-6">
    <h4>Yeni Profil Ekle </h4><i (click)="closeModal()" class="las cursor-pointer la-times"></i>
  </div>
  <form>
    <div class="mt-6 xl:mt-8 grid grid-cols-2 gap-4 xxxl:gap-6">

      <div class="col-span-2">
        <label htmlFor="year" class="md:text-lg font-medium block mb-4"> Profil Adı </label>
        <input type="number"
          class="w-full text-sm bg-secondary1/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-6 py-2.5 md:py-3"
          id="moonlimit" required />
      </div>

      <div class="col-span-2 mt-4">
        <button class="btn flex w-full justify-center" type="submit">Onayla</button>
      </div>
    </div>
  </form>
</div>