import { CommonModule } from '@angular/common'
import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup,  ReactiveFormsModule, Validators } from '@angular/forms'
import { Router } from '@angular/router'

@Component({
  selector: 'app-sign-up',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: './sign-up.component.html'
})
export class SignUpComponent implements OnInit {
  passwordVisible = false
  signUpForm!: FormGroup
  toggleVisibility() {
    this.passwordVisible = !this.passwordVisible
  }

  constructor(private fb: FormBuilder, private router:Router) {}

  ngOnInit() {
    this.signUpForm = this.fb.group({
      firstname: ['', [Validators.required, Validators.minLength(2)]],
      lastname: ['', [Validators.required, Validators.minLength(2)]],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(4)]]
    })
  }

  onSubmit() {
    this.signUpForm.markAllAsTouched()
    if (this.signUpForm.valid) {
      this.signUpForm.reset()
      this.router.navigateByUrl('/auth/sign-in')
    } else {
      console.log('Error')
    }
  }
}
