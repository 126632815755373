import { CommonModule } from '@angular/common'
import { Component } from '@angular/core'
import { OptionsHorizComponent } from '@component/shared/options-horiz/options-horiz.component'
import { TopBannerComponent } from '@component/shared/top-banner/top-banner.component'
import { PasswordInputComponent } from '@component/shared/password-input/password-input.component'
import { DropdownComponent } from '@component/shared/dropdown/dropdown.component'
import { ServiceAreaComponent } from '@component/shared/service-area/service area.component'
import { PrinterSettingsComponent } from '@component/shared/printer-settings/printer-settings.component'
import { ApiEndPointComponent } from '@component/shared/api-endpoint/api-endpoint.component'
import { CompanyInfoComponent } from '@component/shared/company-info/company-info.component'
import { BillingInfoComponent } from '@component/shared/billing-info/billing-info.component'
import { BranchesAreaComponent } from '@component/shared/branches-area/branches-area-component'
import { terminalTabData } from '@data/payment/terminal-tab'
import { DevicesSettingsComponent } from '@component/shared/devices-settings/devices-settings-component'
import { TerminalsComponent } from '@component/shared/terminals/terminals-component'

@Component({
  selector: 'app-terminal-settings',
  standalone: true,
  imports: [
    TopBannerComponent,
    CompanyInfoComponent,
    PrinterSettingsComponent,
    OptionsHorizComponent,
    ServiceAreaComponent,
    CommonModule,
    DevicesSettingsComponent,
    PasswordInputComponent,
    DropdownComponent,
    ApiEndPointComponent,
    BillingInfoComponent,
    BranchesAreaComponent,
    TerminalsComponent
  ],
  templateUrl: './terminal-settings.component.html'
})
export class TerminalSettingsComponent {
  tabs = terminalTabData
  activeTab = this.tabs[0].title
  chatShow = false
  setActiveTab(title: string) {
    this.activeTab = title
  }

}
