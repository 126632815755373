import { Component, Input } from '@angular/core'
import { DropdownComponent } from '../dropdown/dropdown.component'
import { ModalService } from 'ngx-modal-ease'
import { InvoicesTransActionsData } from '../../../models/transaction-model/transactions-invoices.model'
import { CommonModule } from '@angular/common'
import { InvoicesDetailService } from '@service/transaction/invoices-detail-service'
import { InvoicesDetailModel } from '../../../models/transaction-model/invoices-detail-model'

@Component({
  selector: 'app-add-account-modal',
  standalone: true,
  imports: [DropdownComponent,CommonModule],
  templateUrl: './document-detail-modal.component.html'
})
export class DocumentDetailModalComponent {
  @Input() transactionData! :InvoicesTransActionsData;
  invoDetailData! :InvoicesDetailModel;

  constructor(private modalService:ModalService,private invoicesDetailService: InvoicesDetailService){}
  
  closeModal(){
    this.modalService.close('DocumentDetailModalComponent')
  }

  ngOnInit(): void {
    this.invoicesDetailService.allInvoicesData(
      this.transactionData.transaction_id).subscribe(
      (response: InvoicesDetailModel) => {
        this.invoDetailData = response;
      },
      error => {
        console.error('Hata:', error);
      }
    );
  }

}
