<div class="box p-3 md:p-4 xl:p-6 grid grid-cols-12 gap-6 items-center shadow-[0px_6px_30px_0px_rgba(0,0,0,0.04)]">
  <div class="col-span-12 lg:col-span-6">
    <div class="box bg-primary/5 dark:bg-primary/5 lg:p-6 xl:p-8 border border-n30 dark:border-n500 text-center">
      <h3 class="h3 mb-4">Sign In With QR Code</h3>
      <p class="mb-6 lg:mb-8 bb-dashed pb-4 lg:pb-8">Scan this code with mobile app to Sign In Instantly</p>
      <div class="max-w-[400px] mx-auto box rounded-xl border dark:border-n500 p-6 lg:p-8">
        <img [src]="colorMode=='light'?'assets/images/qrcode.png':'assets/images/qrcode-dark.png'" class="qrcode-img" width="336" height="349" alt="qrcode" />
      </div>
      <p class="mt-6 lg:mt-8">
        Sign in with email and password?
        <a routerLink="/auth/sign-in" class="text-primary font-medium"> Click Here </a>
      </p>
    </div>
  </div>
  <div class="col-span-12 lg:col-start-8 lg:col-span-5">
    <img src="assets/images/auth.png" alt="img" width="533" height="560" />
  </div>
</div>
