<div class="calendar-container">
    <app-top-banner title="Valor Raporları"></app-top-banner>

    <div class="box col-span-12" style="width: 100%;">
        <div>
            <input type="month" (input)="onMonthChange($event)" value="{{ initialDate | date: 'yyyy-MM' }}">
            <table class="calendar">
                <thead>
                    <tr>
                        <th>Pazartesi</th>
                        <th>Salı</th>
                        <th>Çarşamba</th>
                        <th>Perşembe</th>
                        <th>Cuma</th>
                        <th>Cumartesi</th>
                        <th>Pazar</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let week of weeks">
                        <ng-container *ngFor="let day of week">
                            <td (click)="previewValor()" *ngIf="day" class="day">
                                <div class="day-box">
                                    <span class="day-number">{{ day | slice: 8 }}</span>
                                    <div *ngFor="let event of getEventsForDay(day)">
                                        <div class="events">
                                            <p>Bozdur</p>
                                            <li>
                                                <p>Tutar: {{ event }}</p>
                                            </li>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td *ngIf="!day" class="empty-day"></td>
                        </ng-container>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>