import { Component,Input } from '@angular/core';
import { GetWorkingHoursService } from '@service/settings/get-working-hours-service';
import { ModalService } from 'ngx-modal-ease';

@Component({
  selector: 'delete-work-hours-modal',
  standalone: true,
  imports: [],
  templateUrl: './delete-work-hours-modal.component.html'
})
export class DeleteWorkHoursModalComponent {
  @Input() firmId!: string;
  @Input() selectedDay!: string;
  @Input() selectedStartHour!: string;
  @Input() selectedEndHour!: string;


  constructor(private modalService:ModalService, private deleteWorkHoursService:GetWorkingHoursService){}
  closeModal(){
    this.modalService.close('DeleteWorkHoursModalComponent')
  }

  onDelete(){
    this.deleteWorkHoursService.deleteWorkHour(
      this.firmId,
      this.selectedDay,
      this.selectedStartHour,
      this.selectedEndHour
    ).subscribe(
      response => {
        window.location.reload();
      },
      error => {
        console.error('Error:', error);
      }
    );
  }
}
