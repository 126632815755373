import { CommonModule, registerLocaleData } from '@angular/common'
import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core'
import { DropdownComponent } from '@component/shared/dropdown/dropdown.component'
import { OptionsHorizComponent } from '@component/shared/options-horiz/options-horiz.component'
import { TopBannerComponent } from '@component/shared/top-banner/top-banner.component'
import { NgApexchartsModule } from 'ng-apexcharts'
import { FormsModule } from '@angular/forms'
import { ModalService } from 'ngx-modal-ease'
import { TableService } from '@service/table.service'
import { transactionsData } from '@data/dashboards/style2Transactions'
import { DocumentDetailModalComponent } from '@component/shared/document-detail-modal/document-detail-modal.component'
import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { ProductSalesReportService } from '@service/reports/product-sales-report-service'
import { ProductSalesDataModel, ProductSalesModel } from '../../../models/reports/product-sales-report.model'
import { BaranchListService } from '@service/branches/bracnhs-list-service'
import { BranchListData } from '../../../models/branches/bracnhs-list-model'
import { provideNativeDateAdapter } from '@angular/material/core';
import { VERSION as MAT_VERSION, MatNativeDateModule } from '@angular/material/core';
import { IgxCalendarModule, IgxSnackbarModule } from "igniteui-angular";
import { LOCALE_ID } from '@angular/core';
import localeTrExtra from '@angular/common/locales/extra/tr';
import localeTr from '@angular/common/locales/tr';
registerLocaleData(localeTr, 'tr-TR', localeTrExtra);


@Component({
  selector: 'rp-app-product-sales-report',
  standalone: true,
  providers: [
    provideNativeDateAdapter(),
    { provide: LOCALE_ID, useValue: 'tr-TR' }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TopBannerComponent, FormsModule, MatNativeDateModule, IgxSnackbarModule, IgxCalendarModule, OptionsHorizComponent, DropdownComponent, NgApexchartsModule, CommonModule],
  templateUrl: './product-sales-report.component.html'
})
export class ProductSalesReportComponent {
  transactions
  paymenyType = [
    'Kredi Kartı',
    'Ön Ödemeli'
  ]
  paymentMethod = [
    'Paye Kart',
    'Paye Kart -2',
    'Paye Kart -3',
  ]
  pages: number[] = [1]
  filters = ['Tümü', 'Başarılı', 'İptal Edildi', 'Devam Ediyor']
  currentFilter = this.filters[0]

  searchProdSaleDate: string = '';
  searchBranchName: string = '';
  searchBranchId: string = '';
  searchTitle: string = '';
  searchTax: number | undefined;
  searchQuantity: number | undefined;
  searchTotal: number | undefined;

  prodSalesData: ProductSalesDataModel[] = [];
  prodSalesModel!: ProductSalesModel;
  branchList!: BranchListData[];

  validPages: number[] = [];
  showDatepicker: boolean = false;
  showSaveButton: boolean = false;
  selectedDateRange: { start: any, end: any } | null = null;
  selectedBranch!: string;

  constructor(private modalService: ModalService, private cdr: ChangeDetectorRef, private prodSalesReportService: ProductSalesReportService, private branchesService: BaranchListService) {
    this.transactions = new TableService()
    this.transactions.initialize(transactionsData, 14)
  }

  areAllItemsChecked(): boolean {
    return this.transactions.tableData.length > 1 && this.transactions.tableData.every((item) => item.isChecked)
  }

  ngOnInit() {
    const today = new Date();
    const formattedtoday = `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}`;
    this.pages = Array.from({ length: this.transactions.totalPages }, (_, i) => i + 1);
    this.prodSalesReportService.allProdReport(formattedtoday, formattedtoday).subscribe(
      response => {
        this.prodSalesData = response.data;
        this.prodSalesModel = response;
      },
      error => {
        console.error('Hata:', error);
      }
    );

    this.branchesService.allData().subscribe(
      response => {
        this.branchList = response.data;
      },
      error => {
        console.error('Hata:', error);
      }
    );
  }
  setFilter(filter: string) {
    this.currentFilter = filter
    if (filter == 'Tümü') {
      this.transactions.initialize(transactionsData, 14)
      this.pages = Array.from({ length: this.transactions.totalPages }, (_, i) => i + 1)
    } else if (filter == 'Başarılı') {
      const result = transactionsData.filter((item) => item.status === 'successful');
      this.transactions.initialize(result, 14);
      this.pages = Array.from({ length: this.transactions.totalPages }, (_, i) => i + 1)
    } else if (filter == 'İptal Edildi') {
      const result = transactionsData.filter((item) => item.status === 'cancelled');
      this.transactions.initialize(result, 14);
      this.pages = Array.from({ length: this.transactions.totalPages }, (_, i) => i + 1)
    }
    else if (filter == 'Devam Ediyor') {
      const result = transactionsData.filter((item) => item.status === 'pending');
      this.transactions.initialize(result, 14);
      this.pages = Array.from({ length: this.transactions.totalPages }, (_, i) => i + 1)
    }
    else {
      const result = transactionsData.filter((item) => item.status == filter)
      this.transactions.initialize(result, 14)
      this.pages = Array.from({ length: this.transactions.totalPages }, (_, i) => i + 1)
    }
  }

  openModal() {
    this.modalService.open(DocumentDetailModalComponent, {
      modal: {
        enter: 'enter-going-down 0.3s ease-out',
        leave: 'fade-out 0.5s'
      },
      overlay: {
        leave: 'fade-out 0.5s'
      },
      data: {
        type: 'Angular modal library'
      }
    })
  }

  get filteredTransactionsCombined() {
    const branchID = this.searchBranchId?.toLowerCase() || '';
    const branchName = this.searchBranchName?.toLowerCase() || '';
    const itemTitle = this.searchTitle?.toLowerCase() || '';

    return this.prodSalesData.filter(item => {
      const itemPaymentType = item.branch_name?.toLowerCase() || '';
      const itembranchId = item.branch_id?.toLowerCase() || '';
      const itemProductName = item.title?.toLowerCase() || '';

      return (!this.searchTax || item.tax_rate?.toString().includes(this.searchTax.toString())) &&
        (!this.searchTotal || item.total?.toString().includes(this.searchTotal.toString())) &&
        (!this.searchQuantity || item.quantity?.toString().includes(this.searchQuantity.toString())) &&
        (!branchName || itemPaymentType.includes(branchName)) &&
        (!branchID || itembranchId.includes(branchID)) &&
        (!itemTitle || itemProductName.includes(itemTitle));
    }) || [];
  }

  formatter = new Intl.NumberFormat('tr-TR', {
    currency: 'TRY',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });

  formatAmount(amount: number | undefined): string {
    return this.formatter.format(amount ?? 0);
  }

  exportToExcel() {
    var table = document.getElementById("productListTable");
    var excel = new ExcelJS.Workbook();
    var sheet = excel.addWorksheet('Sheet1');

    var rows = table!.querySelectorAll('tr');

    rows.forEach(function (row) {
      var rowData: string[] = [];
      var cells = row.querySelectorAll('th, td');
      Array.from(cells).forEach(function (cell: Element) {
        rowData.push((cell as HTMLInputElement).innerText);
      });
      sheet.addRow(rowData);
    });

    excel.xlsx.writeBuffer().then(function (buffer) {
      var blob = new Blob([buffer], { type: 'application/octet-stream' });
      saveAs(blob, 'product_list.xlsx');
    });
  }

  onRangeChange(event: any) {
    const start = new Date(event[0]);
    const endIndex = event.length - 1;
    const end = new Date(event[endIndex]);

    const formattedStart = `${start.getFullYear()}-${(start.getMonth() + 1).toString().padStart(2, '0')}-${start.getDate().toString().padStart(2, '0')}`; // YYYY-MM-DD
    const formattedEnd = `${end.getFullYear()}-${(end.getMonth() + 1).toString().padStart(2, '0')}-${end.getDate().toString().padStart(2, '0')}`; // YYYY-MM-DD

    this.selectedDateRange = {
      start: formattedStart,
      end: formattedEnd,
    };
    this.showSaveButton = true;
  }

  toggleCalendar() {
    this.showDatepicker = !this.showDatepicker;
    if (!this.showDatepicker) {
      this.showSaveButton = false;
    }
  }

  saveDateRange() {
    if (this.selectedDateRange) {
      this.showDatepicker = false;
      this.showSaveButton = false;

      if (this.selectedBranch) {
        this.prodSalesReportService.allProdReport(this.selectedDateRange.start, this.selectedDateRange.end, true, this.selectedBranch).subscribe(
          response => {
            this.prodSalesData = response.data;
            this.prodSalesModel = response;
            this.calculatePages();
            this.updateValidPages();
            this.cdr.detectChanges();
          },
          error => {
            console.error('Hata:', error);
          }
        );
      } else {
        this.prodSalesReportService.allProdReport(this.selectedDateRange.start, this.selectedDateRange.end).subscribe(
          response => {
            this.prodSalesData = response.data;
            this.prodSalesModel = response;
            this.calculatePages();
            this.updateValidPages();
            this.cdr.detectChanges();
          },
          error => {
            console.error('Hata:', error);
          }
        );
      }

    } else {
      console.log('Tarih aralığı seçilmedi.');
    }
  }

  calculatePages() {
    const totalPages = Math.ceil(this.prodSalesModel.total_count / 20);
    this.pages = Array.from({ length: totalPages }, (_, i) => i + 1);
  }

  updateValidPages() {
    const validPages: number[] = [];
    if (this.selectedDateRange) {
      if (this.selectedBranch) {
        const promises = this.pages.map(page =>
          this.prodSalesReportService.allProdReport(this.selectedDateRange?.start, this.selectedDateRange?.end, true, this.selectedBranch).toPromise().then(
            response => {
              if (response && response.data.length > 0) {
                validPages.push(page);
                this.cdr.detectChanges();
              }
            }
          ).catch(error => {
            console.error('Hata:', error);
          })
        );
        Promise.all(promises).then(() => {
          this.validPages = validPages;
        });
      } else {
        const promises = this.pages.map(page =>
          this.prodSalesReportService.allProdReport(this.selectedDateRange?.start, this.selectedDateRange?.end).toPromise().then(
            response => {
              if (response && response.data.length > 0) {
                validPages.push(page);
                this.cdr.detectChanges();
              }
            }
          ).catch(error => {
            console.error('Hata:', error);
          })
        );
        Promise.all(promises).then(() => {
          this.validPages = validPages;
        });


      }
    } else {
      if (this.selectedBranch) {
        const promises = this.pages.map(page =>
          this.prodSalesReportService.allProdReport(this.selectedDateRange?.start, this.selectedDateRange?.end, true, this.selectedBranch).toPromise().then(
            response => {
              if (response && response.data.length > 0) {
                validPages.push(page);
                this.cdr.detectChanges();
              }
            }
          ).catch(error => {
            console.error('Hata:', error);
          })
        );
        Promise.all(promises).then(() => {
          this.validPages = validPages;
        });
      } else {
        const today = new Date();
        const formattedtoday = `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}`;
        const promises = this.pages.map(page =>
          this.prodSalesReportService.allProdReport(formattedtoday, formattedtoday).toPromise().then(
            response => {
              if (response && response.data.length > 0) {
                validPages.push(page);
                this.cdr.detectChanges();
              }
            }
          ).catch(error => {
            console.error('Hata:', error);
          })
        );
        Promise.all(promises).then(() => {
          this.validPages = validPages;
        });


      }

    }

  }

  goToPage(page: number) {
    if (this.validPages.includes(page)) {
      if (this.selectedDateRange) {
        if (this.selectedBranch) {
          this.prodSalesReportService.allProdReport(this.selectedDateRange.start, this.selectedDateRange.end, true, this.selectedBranch).subscribe(
            response => {
              if (response && response.data.length > 0) {
                this.prodSalesData = response.data;
                this.transactions.paginate(page);
                this.cdr.detectChanges();
              } else {
                console.warn(`Page ${page} is empty and will not be displayed.`);
                this.updateValidPages();
              }
            },
            error => {
              console.error('Hata:', error);
            }
          );
        } else {
          this.prodSalesReportService.allProdReport(this.selectedDateRange.start, this.selectedDateRange.end).subscribe(
            response => {
              if (response && response.data.length > 0) {
                this.prodSalesData = response.data;
                this.transactions.paginate(page);
                this.cdr.detectChanges();
              } else {
                console.warn(`Page ${page} is empty and will not be displayed.`);
                this.updateValidPages();
              }
            },
            error => {
              console.error('Hata:', error);
            }
          );
        }
      } else {
        if (this.selectedBranch) {
          const today = new Date();
          const formattedtoday = `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}`;
          this.prodSalesReportService.allProdReport(formattedtoday, formattedtoday, true, this.selectedBranch).subscribe(
            response => {
              if (response && response.data.length > 0) {
                this.prodSalesData = response.data;
                this.transactions.paginate(page);
                this.cdr.detectChanges();
              } else {
                console.warn(`Page ${page} is empty and will not be displayed.`);
                this.updateValidPages();
              }
            },
            error => {
              console.error('Hata:', error);
            }
          );
        } else {
          const today = new Date();
          const formattedtoday = `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}`;
          this.prodSalesReportService.allProdReport(formattedtoday, formattedtoday).subscribe(
            response => {
              if (response && response.data.length > 0) {
                this.prodSalesData = response.data;
                this.transactions.paginate(page);
                this.cdr.detectChanges();
              } else {
                console.warn(`Page ${page} is empty and will not be displayed.`);
                this.updateValidPages();
              }
            },
            error => {
              console.error('Hata:', error);
            }
          );
        }
      }
    } else {
      console.warn(`Page ${page} is not valid and will not be displayed.`);
    }
  }

} 