<div class=" bg-n0 dark:bg-bg4 box overflow-y-auto max-h-[800px]">
  <div class="flex justify-between items-center bb-dashed pb-6 mb-6">
    <h4>Müşteri Güncelle</h4><i (click)="closeModal()" class="las cursor-pointer la-times"></i>
  </div>
  <form>
    <div class="mt-6 xl:mt-8 grid grid-cols-2 gap-4 xxxl:gap-6">
      <div class="col-span-2 md:col-span-1">
        <label htmlFor="fcName" class="md:text-lg font-medium block mb-4"> Adınız</label>
        <input type="text"
          class="w-full text-sm bg-secondary1/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-6 py-2.5 md:py-3"
          placeholder=" Adınız" id="fcName" required />
      </div>
      <div class="col-span-2 md:col-span-1">
        <label htmlFor="fcTc" class="md:text-lg font-medium block mb-4"> Kimlik</label>
        <input type="number"
          class="w-full text-sm bg-secondary1/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-6 py-2.5 md:py-3"
          placeholder=" Kimlik" maxlength="11" id="fcTc" required />
      </div>

      <div class="col-span-2 md:col-span-1">
        <label htmlFor="fcPhone" class="md:text-lg font-medium block mb-4"> Telefon</label>
        <input type="number"
          class="w-full text-sm bg-secondary1/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-6 py-2.5 md:py-3"
          placeholder=" Telefon" id="fcPhone" required />
      </div>
      <div class="col-span-2 md:col-span-1">
        <label htmlFor="fcMail" class="md:text-lg font-medium block mb-4"> E-Posta</label>
        <input type="email"
          class="w-full text-sm bg-secondary1/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-6 py-2.5 md:py-3"
          placeholder=" E-Posta" id="fcMail" required />
      </div>

      <div class="col-span-2 md:col-span-1">
        <label htmlFor="fcAdress1" class="md:text-lg font-medium block mb-4"> Adres</label>
        <input type="text"
          class="w-full text-sm bg-secondary1/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-6 py-2.5 md:py-3"
          placeholder=" Adres" id="fcAdress1" required />
      </div>
      <div class="col-span-2 md:col-span-1">
        <label htmlFor="fcAdress2" class="md:text-lg font-medium block mb-4"> Adres-2</label>
        <input type="text"
          class="w-full text-sm bg-secondary1/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-6 py-2.5 md:py-3"
          placeholder=" Adres -2" id="fcAdress2" required />
      </div>


      <div class="col-span-2 md:col-span-1">
        <label for="location" class="md:text-lg font-medium block mb-4"> İl </label>
        <app-dropdown [options]="city" btnClassProps="rounded-3xl w-full !py-3" dropdownClassProps="w-full" />
      </div>
      <div class="col-span-2 md:col-span-1">
        <label for="district" class="md:text-lg font-medium block mb-4"> İlçe </label>
        <app-dropdown [options]="district" btnClassProps="rounded-3xl w-full !py-3" dropdownClassProps="w-full" />
      </div>

      <div class="col-span-2">
        <label for="hood" class="md:text-lg font-medium block mb-4"> Mahelle </label>
        <app-dropdown [options]="hood" btnClassProps="rounded-3xl w-full !py-3" dropdownClassProps="w-full" />
      </div>

      <div class="col-span-2 mt-4">
        <button class="btn flex w-full justify-center" type="submit">Onayla</button>
      </div>
    </div>
  </form>
</div>