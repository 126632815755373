import { CommonModule } from '@angular/common'
import { Component } from '@angular/core'
import { OptionsHorizComponent } from '@component/shared/options-horiz/options-horiz.component'
import { TopBannerComponent } from '@component/shared/top-banner/top-banner.component'
import { PasswordInputComponent } from '@component/shared/password-input/password-input.component'
import { DropdownComponent } from '@component/shared/dropdown/dropdown.component'
import { ServiceAreaComponent } from '@component/shared/service-area/service area.component'
import { PrinterSettingsComponent } from '@component/shared/printer-settings/printer-settings.component'
import { ApiEndPointComponent } from '@component/shared/api-endpoint/api-endpoint.component'
import { accountTabData } from '@data/payment/account-tab'
import { CompanyInfoComponent } from '@component/shared/company-info/company-info.component'
import { BillingInfoComponent } from '@component/shared/billing-info/billing-info.component'
import { RegionsSettingsComponent } from '@component/shared/regions-settings/regions-settings-component'
import { BranchesAreaComponent } from '@component/shared/branches-area/branches-area-component'

@Component({
  selector: 'app-account-settings',
  standalone: true,
  imports: [
    TopBannerComponent,
    CompanyInfoComponent,
    PrinterSettingsComponent,
    OptionsHorizComponent,
    RegionsSettingsComponent,
    ServiceAreaComponent,
    CommonModule,
    PasswordInputComponent,
    DropdownComponent,
    ApiEndPointComponent,
    BillingInfoComponent,
    BranchesAreaComponent
  ],
  templateUrl: './account-settings.component.html'
})
export class AccountsettingsComponent {
  tabs = accountTabData
  activeTab = this.tabs[0].title
  chatShow = false
  setActiveTab(title: string) {
    this.activeTab = title
  }

}
