<div class=" bg-n0 dark:bg-bg4 box overflow-y-auto max-h-[800px]" id="hoursAddModal">
  <div class="flex justify-between items-center bb-dashed pb-6 mb-6">
    <h4>Çalışma Saati Ekle</h4><i (click)="closeModal()" class="las cursor-pointer la-times"></i>
  </div>
  <form  [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="mt-6 xl:mt-8 grid grid-cols-2 gap-4 xxxl:gap-6">
      <div class="col-span-2">
        <label htmlFor="card-number" class="md:text-lg font-medium block mb-4">Gün Seçiniz </label>
        <select formControlName="fDays">
          <option *ngFor="let item of workDays" [value]="item.key">{{ item.value }}</option>
        </select>
      </div>
      <div class="col-span-2">
        <label class="md:text-lg font-medium block mb-4">Açılış Saati</label>
        <div
          class="bg-primary/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-3 md:px-6 py-2 relative">
          <input formControlName="fOpenHours" type="time" class="w-full text-sm bg-transparent p-0 border-none" />
          <span class="absolute eye-icon ltr:right-5 rtl:left-5 top-1/2 -translate-y-1/2 cursor-pointer">
            <i class="las la-clock"></i>
          </span>
        </div>
      </div>

      <div class="col-span-2">
        <label class="md:text-lg font-medium block mb-4">Kapanış Saati</label>
        <div
          class="bg-primary/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-3 md:px-6 py-2 relative">
          <input formControlName="fCloseHours" type="time" class="w-full text-sm bg-transparent p-0 border-none" />
          <span class="absolute eye-icon ltr:right-5 rtl:left-5 top-1/2 -translate-y-1/2 cursor-pointer">
            <i class="las la-clock"></i>
          </span>
        </div>
      </div>

      <div class="col-span-2 mt-4">
        <button class="btn flex w-full justify-center" type="submit">Onayla</button>
      </div>

    </div>
  </form>
</div>