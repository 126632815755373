import { CommonModule } from '@angular/common'
import { Component, Injectable } from '@angular/core'
import { DropdownComponent } from '@component/shared/dropdown/dropdown.component'
import { OptionsHorizComponent } from '@component/shared/options-horiz/options-horiz.component'
import { TopBannerComponent } from '@component/shared/top-banner/top-banner.component'
import { NgApexchartsModule } from 'ng-apexcharts'
import { ModalService } from 'ngx-modal-ease'
import { TableService } from '@service/table.service'
import { transactionsData } from '@data/dashboards/style2Transactions'
import { DocumentDetailModalComponent } from '@component/shared/document-detail-modal/document-detail-modal.component'
import { FormsModule } from '@angular/forms'
import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

@Component({
  selector: 'rp-app-collection-report',
  standalone: true,
  imports: [TopBannerComponent, FormsModule, OptionsHorizComponent, DropdownComponent, NgApexchartsModule, CommonModule],
  templateUrl: './collection-report.component.html'
})
export class CollectionReportComponent {
  transactions
  paymenyType = [
    'Kredi Kartı',
    'Ön Ödemeli'
  ]
  paymentMethod = [
    'Paye Kart',
    'Paye Kart -2',
    'Paye Kart -3',
  ]
  filters = ['Tümü', 'Başarılı', 'İptal Edildi', 'Devam Ediyor']
  pages: number[] = []

  searchCollectionDate: string = '';
  searchPaymentType: string = '';
  searchPaymentMethod: string = '';
  searchCustomer: string = '';
  searchCashier: string = '';
  searchTerminal: string = '';
  searchSipCode: string = '';
  searchOrderCode: string = '';
  searchTotal: string = '';

  currentFilter = this.filters[0]
  constructor(private modalService: ModalService) {
    this.transactions = new TableService()
    this.transactions.initialize(transactionsData, 1)
  }

  areAllItemsChecked(): boolean {
    return this.transactions.tableData.length > 1 && this.transactions.tableData.every((item) => item.isChecked)
  }

  ngOnInit() {
    this.pages = Array.from({ length: this.transactions.totalPages }, (_, i) => i + 1)
  }

  setFilter(filter: string) {
    this.currentFilter = filter
    if (filter == 'Tümü') {
      this.transactions.initialize(transactionsData, 1)
      this.pages = Array.from({ length: this.transactions.totalPages }, (_, i) => i + 1)
    } else if (filter === 'Başarılı') {
      const result = transactionsData.filter((item) => item.status === 'successful');
      this.transactions.initialize(result, 1);
      this.transactions.paginate(1);
      this.pages = [1];
    } else if (filter === 'İptal Edildi') {
      const result = transactionsData.filter((item) => item.status === 'cancelled');
      this.transactions.initialize(result, 1);
      this.transactions.paginate(1);
      this.pages = [1];
    }
    else if (filter === 'Devam Ediyor') {
      const result = transactionsData.filter((item) => item.status === 'pending');
      this.transactions.initialize(result, 1);
      this.transactions.paginate(1);
      this.pages = [1];
    }
    else {
      const result = transactionsData.filter((item) => item.status == filter)
      this.transactions.initialize(result, 1)
      this.transactions.paginate(1)
      this.pages = [1]
    }
  }

  openModal() {
    this.modalService.open(DocumentDetailModalComponent, {
      modal: {
        enter: 'enter-going-down 0.3s ease-out',
        leave: 'fade-out 0.5s'
      },
      overlay: {
        leave: 'fade-out 0.5s'
      },
      data: {
        type: 'Angular modal library'
      }
    })
  }

  get filteredTransactionsCombined() {
    const paymentType = this.searchPaymentType.toLowerCase();
    const paymentMethod = this.searchPaymentMethod.toLowerCase();
    const customer = this.searchCustomer.toLowerCase();
    const cashier = this.searchCashier.toLowerCase();
    const SipCode = this.searchSipCode.toLowerCase();

    return this.transactions.tableData.filter(item => {
      const itemPaymentType = item.orderCode.toLowerCase();
      const itemPaymentMethod = item.orderCode.toLowerCase();
      const itemCustomer = item.title.toLowerCase();
      const itemCashier = item.title.toLowerCase();
      const itemSipCode = item.speciealCode.toLowerCase();

      return (!this.searchCollectionDate || item.dueDate.includes(this.searchCollectionDate)) &&
        (!this.searchTerminal || item.orderCode.includes(this.searchTerminal)) &&
        (!this.searchOrderCode || item.orderCode.includes(this.searchOrderCode)) &&
        (!this.searchTotal || item.totalAmount.includes(this.searchTotal)) &&
        (!paymentType || itemPaymentType.includes(paymentType)) &&
        (!paymentMethod || itemPaymentMethod.includes(paymentMethod)) &&
        (!customer || itemCustomer.includes(customer)) &&
        (!cashier || itemCashier.includes(cashier)) &&
        (!SipCode || itemSipCode.includes(SipCode));
    });
  }



  exportToExcel() {
    var table = document.getElementById("documenListTable");
    var excel = new ExcelJS.Workbook();
    var sheet = excel.addWorksheet('Sheet1');

    var rows = table!.querySelectorAll('tr');

    rows.forEach(function (row) {
      var rowData: string[] = [];
      var cells = row.querySelectorAll('th, td');
      Array.from(cells).forEach(function (cell: Element) {
        rowData.push((cell as HTMLInputElement).innerText);
      });
      sheet.addRow(rowData);
    });

    excel.xlsx.writeBuffer().then(function (buffer) {
      var blob = new Blob([buffer], { type: 'application/octet-stream' });
      saveAs(blob, 'document_list.xlsx');
    });
  }
}