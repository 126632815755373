import { CommonModule } from '@angular/common'
import { Component } from '@angular/core'
import { DropdownComponent } from '@component/shared/dropdown/dropdown.component'
import { TopBannerComponent } from '@component/shared/top-banner/top-banner.component'
import { marketOverviewData } from '@data/trading/marketOverview'
import { TableService } from '@service/table.service'
import { Router } from '@angular/router';
import { ProductListService } from '@service/products/products-list.service'
import { ProductListModel } from '../../../models/products/products-list-model'
import { OptionsVerticalProductComponent } from '@component/shared/options-vertical-product/options-vertical-product.component'
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'

@Component({
  selector: 'td-app-style-01',
  standalone: true,
  imports: [TopBannerComponent, CommonModule, OptionsVerticalProductComponent, DropdownComponent],
  templateUrl: './style-01.component.html'
})
export class TradingStyle01Component {

  marketOverview
  productList: ProductListModel[] = [];
  displayedProducts: ProductListModel[] = [];
  pages: number[] = [];
  currentPage: number = 1;
  itemsPerPage: number = 12;
  totalPages: number = 1;


  constructor(private router: Router, private productListService: ProductListService) {
    this.marketOverview = new TableService()
    this.marketOverview.initialize(marketOverviewData, 10)
  }

  ngOnInit(): void {
    this.productListService.getProduct().subscribe(
      response => {
        this.productList = response;
        this.totalPages = Math.ceil(this.productList.length / this.itemsPerPage);
        this.pages = Array.from({ length: this.totalPages }, (_, i) => i + 1);
        this.updateDisplayedProducts();
        console.log(this.displayedProducts);
      },
      error => {
        console.error('Error:', error);
      }
    );
  }

  updateDisplayedProducts(): void {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    this.displayedProducts = this.productList.slice(startIndex, endIndex);
  }

  changePage(page: number): void {
    if (page >= 1 && page <= this.totalPages) {
      this.currentPage = page;
      this.updateDisplayedProducts();
    }
  }

  navigateToDetailPage() {
    this.router.navigate(['/product/add']);
  }

  limitStockWidth(stock: number): string {
    return stock > 100 ? '100%' : stock + '%';
  }

  public printTable() {

    const doc = new jsPDF('l', 'mm', 'a4');

    const head = [
      [
        [this.convertToTurkishCharacters('Ürün')],
        [this.convertToTurkishCharacters('KDV / Fiyat')],
        [this.convertToTurkishCharacters('Birim / Stok')],
        [this.convertToTurkishCharacters('Durum')],
      ]]
    const data = this.productList.map((item: any) => [
      [item.title],
      [item.price],
      [item.stock],
      [item.is_active == true ? "Aktif" : "Pasif"]
    ]);


    autoTable(doc, {
      head: head,
      body: data,
      styles: {
        font: "Poppins",
        fontStyle: "normal",
      },
      bodyStyles: { fillColor: [200, 200, 200] },
    });

    doc.save('urunler-listesi.pdf');
  }

  convertToTurkishCharacters(text: string) {
    return text.replace(/ğ/g, 'g')
      .replace(/Ğ/g, 'G')
      .replace(/ü/g, 'u')
      .replace(/Ü/g, 'U')
      .replace(/ş/g, 's')
      .replace(/Ş/g, 'S')
      .replace(/ı/g, 'i')
      .replace(/İ/g, 'I')
      .replace(/ö/g, 'o')
      .replace(/Ö/g, 'O')
      .replace(/ç/g, 'c')
      .replace(/Ç/g, 'C');
  }

  formatAmount(amount: number | undefined): string {
    return this.formatter.format(amount ?? 0);
  }

  formatter = new Intl.NumberFormat('tr-TR', {
    currency: 'TRY',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
}
