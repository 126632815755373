
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BranchListModel } from '../../models/branches/bracnhs-list-model';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class BaranchListService {

    private apiUrl = `${environment.apiUrl}/branchs`;
    private token = localStorage.getItem('authToken');
    constructor(private http: HttpClient) { }

    allData(): Observable<BranchListModel> {
        const headers = new HttpHeaders()
            .set('Authorization', `Bearer ${this.token}`);
        const urlPath = `${this.apiUrl}`;
        return this.http.get<BranchListModel>(urlPath, { headers });
    }
}
