import { NgClass } from '@angular/common';
import { Component, HostListener, Input } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ModalService } from 'ngx-modal-ease'
import { DeleteBranchesModalComponent } from '../delete-branches-modal/delete-branches-modal.component';
@Component({
  selector: 'app-options-vertical-branches',
  standalone: true,
  imports: [NgClass,RouterModule],
  templateUrl: './options-vertical-branches.component.html'
})
export class OptionsVerticalBranchesComponent {
  isOpen = false
  @Input()
  toggleOpen(event:MouseEvent) {
    const allDropdowns = document.querySelectorAll('.dropdown-verti'); // Find all dropdowns
    allDropdowns.forEach(dropdown => {
        if (dropdown !== event.target) {
            dropdown.classList.remove('show');
            dropdown.classList.add('hide');
        }
    });
    this.isOpen = !this.isOpen
  }

  constructor(private modalService: ModalService){}


  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
  const targetElement = event.target as Element;

    // Check if event.target exists and if the click event target is not within the dropdown or the dropdown button
    if (targetElement && !targetElement.closest('#dropdown-verti')) {
      this.isOpen = false; // Close the dropdown
    }
  }

  deleteBranches() {
    this.modalService.open(DeleteBranchesModalComponent, {
      modal: {
        enter: 'enter-going-down 0.3s ease-out',
        leave: 'fade-out 0.5s'
      },
      overlay: {
        leave: 'fade-out 0.5s'
      },
      data: {
        type: 'Angular modal library'
      }
    })
  }
}
