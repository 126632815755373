<app-top-banner title="Ürün Satış Raporları" />

<div class="relative inline-block w-full lg:w-auto">
    <article *ngIf="showDatepicker" class="calendar-wrapper">
        <igx-calendar selection="range" (selected)="onRangeChange($event)">
        </igx-calendar>
    </article>
</div>

<div class="box col-span-12">
    <div class="flex flex-wrap gap-4 justify-between items-center bb-dashed mb-4 pb-4 lg:mb-6 lg:pb-6"
        id="document-list-header">
        <div class="order-filters">
            <button *ngIf="showSaveButton == false" (click)="toggleCalendar()"
                class="font-medium text-xs px-4 sm:px-5 xxxl:px-6 py-3 capitalize invoice-active">
                Bugün <i class="las la-calendar ml-2 cursor-pointer"></i>
            </button>

            <button *ngIf="showSaveButton" (click)="saveDateRange()"
                class="font-medium text-xs px-4 sm:px-5 xxxl:px-6 py-3 capitalize invoice-active">
                Kaydet
            </button>
            <button (click)="exportToExcel()"
                class="font-medium text-xs px-4 sm:px-5 xxxl:px-6 py-3 capitalize invoice-active"
                ng-reflect-ng-class="[object Object]">Dışarı Aktar</button>

        </div>
    </div>

    <div class="overflow-x-auto mb-4 lg:mb-6">
        <table class="w-full whitespace-nowrap" id="productListTable">
            <thead>
                <tr class="bg-secondary1/5 dark:bg-bg3">
                    <th class="text-left p-5">
                        <div class="searcHead">
                            <p>Şube Kodu</p>
                            <div
                                class="mt-2 bg-primary/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-3  py-2 relative">
                                <input [(ngModel)]="searchBranchId"
                                    class="w-11/12 text-sm bg-transparent p-0 border-none" type="text">
                                <span class="absolute eye-icon ltr:right-5 rtl:left-5 top-1/2 -translate-y-1/2">
                                    <i class="las la-search"></i>
                                </span>
                            </div>
                        </div>
                    </th>
                    <th class="text-left p-5">
                        <div class="searcHead">
                            <p>Şube</p>
                            <div
                                class="mt-2 bg-primary/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-3  py-2 relative">
                                <input [(ngModel)]="searchBranchName"
                                    class="w-11/12 text-sm bg-transparent p-0 border-none" type="text">
                                <span class="absolute eye-icon ltr:right-5 rtl:left-5 top-1/2 -translate-y-1/2">
                                    <i class="las la-search"></i>
                                </span>
                            </div>
                        </div>
                    </th>
                    <th class="text-left p-5">
                        <div class="searcHead">
                            <p>Ürün Adı</p>
                            <div
                                class="mt-2 bg-primary/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-3  py-2 relative">
                                <input [(ngModel)]="searchTitle" class="w-11/12 text-sm bg-transparent p-0 border-none"
                                    type="text">
                                <span class="absolute eye-icon ltr:right-5 rtl:left-5 top-1/2 -translate-y-1/2">
                                    <i class="las la-search"></i>
                                </span>
                            </div>
                        </div>
                    </th>
                    <th class="text-left p-5">
                        <div class="searcHead">
                            <p>Vergi Oranı</p>
                            <div
                                class="mt-2 bg-primary/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-3  py-2 relative">
                                <input [(ngModel)]="searchTax" class="w-11/12 text-sm bg-transparent p-0 border-none"
                                    type="number">
                                <span class="absolute eye-icon ltr:right-5 rtl:left-5 top-1/2 -translate-y-1/2">
                                    <i class="las la-search"></i>
                                </span>
                            </div>
                        </div>
                    </th>
                    <th class="text-left p-5">
                        <div class="searcHead">
                            <p>Adet</p>
                            <div
                                class="mt-2 bg-primary/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-3  py-2 relative">
                                <input [(ngModel)]="searchQuantity"
                                    class="w-11/12 text-sm bg-transparent p-0 border-none" type="number">
                                <span class="absolute eye-icon ltr:right-5 rtl:left-5 top-1/2 -translate-y-1/2">
                                    <i class="las la-search"></i>
                                </span>
                            </div>
                        </div>
                    </th>
                    <th class="text-left p-5">
                        <div class="searcHead">
                            <p>Toplam</p>
                            <div
                                class="mt-2 bg-primary/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-3  py-2 relative">
                                <input [(ngModel)]="searchTotal" class="w-11/12 text-sm bg-transparent p-0 border-none"
                                    type="number">
                                <span class="absolute eye-icon ltr:right-5 rtl:left-5 top-1/2 -translate-y-1/2">
                                    <i class="las la-search"></i>
                                </span>
                            </div>
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of filteredTransactionsCombined; let i = index;">
                    <td class="w-[15%]">
                        <div class="bg-secondary1/5 dark:bg-bg3 py-5 px-3"> {{ item.branch_id }} </div>
                    </td>
                    <td class="w-[15%]">
                        <div class="bg-secondary1/5 dark:bg-bg3 py-5 px-3">{{ item.branch_name }}</div>
                    </td>
                    <td class="w-[15%]">
                        <div class="bg-secondary1/5 dark:bg-bg3 py-5 px-3">{{ item.title }}</div>
                    </td>
                    <td class="w-[15%]">
                        <div class="bg-secondary1/5 dark:bg-bg3 py-5 px-3">%{{ item.tax_rate }}</div>
                    </td>
                    <td class="w-[15%]">
                        <div class="bg-secondary1/5 dark:bg-bg3 py-5 px-3">
                            {{ item.quantity }}
                        </div>
                    </td>
                    <td class="w-[15%]">
                        <div class="bg-secondary1/5 dark:bg-bg3 py-5 px-3">{{ formatAmount(item.total) }} ₺</div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <div *ngIf="prodSalesModel.data.length == 0" class="content-not-found">
        <img src="../../../../assets/images/content-not-found.png" alt="">
        <h4 style="margin-top: 1rem;">İçerik Bulunamadı</h4>
    </div>

    <div *ngIf="transactions.totalPages > 1"
        class="flex col-span-12 gap-4 sm:justify-between justify-center items-center flex-wrap">
        <ul class="flex gap-2 md:gap-3 flex-wrap md:font-semibold items-center">
            <li>
                <button [disabled]="transactions.currentPage === 1" (click)="goToPage(transactions.currentPage - 1)"
                    class="hover:bg-primary  rtl:rotate-180 hover:text-n0 border md:w-10 duration-300 md:h-10 w-8 h-8 flex items-center rounded-full justify-center border-primary">
                    <i class="las la-angle-left text-lg"></i>
                </button>
            </li>
            <li *ngFor="let page of pages">
                <button (click)="goToPage(page)" [class.bg-primary]="transactions.currentPage === page"
                    class="hover:bg-primary hover:text-n0 border md:w-10 duration-300 md:h-10 w-8 h-8 flex items-center rounded-full justify-center border-primary">
                    {{ page }}
                </button>
            </li>
            <li>
                <button [disabled]="transactions.currentPage === transactions.totalPages"
                    (click)="goToPage(transactions.currentPage + 1)"
                    class="hover:bg-primary hover:text-n0 rtl:rotate-180 border md:w-10 duration-300 md:h-10 w-8 h-8 flex items-center rounded-full justify-center border-primary">
                    <i class="las la-angle-right text-lg"></i>
                </button>
            </li>
        </ul>
    </div>
</div>