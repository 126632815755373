import { CommonModule } from '@angular/common'
import { Component } from '@angular/core'
import { CreateBankAccountModalComponent } from '@component/shared/create-bank-account-modal/create-bank-account-modal.component'
import { DropdownComponent } from '@component/shared/dropdown/dropdown.component'
import { OptionsHorizComponent } from '@component/shared/options-horiz/options-horiz.component'
import { OptionsVerticalComponent } from '@component/shared/options-vertical/options-vertical.component'
import { TopBannerComponent } from '@component/shared/top-banner/top-banner.component'
import { recentPaymentData } from '@data/payments/recentPayments'
import { ChartOptions } from '@pages/dashboards/style-01/style-01.component'
import { TableService } from '@service/table.service'
import { NgApexchartsModule } from 'ng-apexcharts'
import { ModalService } from 'ngx-modal-ease'
import { ActivatedRoute } from '@angular/router'
import { OrderDetailService } from '@service/order/order.detail.service'

@Component({
  selector: 'app-payment-overview',
  standalone: true,
  imports: [TopBannerComponent, DropdownComponent, OptionsVerticalComponent, OptionsHorizComponent, CommonModule, NgApexchartsModule],
  templateUrl: './payment-overview.component.html'
})
export class PaymentOverviewComponent {
 
  overviewChartOptions!: ChartOptions
  recentPayments
  pages: number[] = []
  orderDetailData!: any;
  id!: string;

  constructor(private modalService: ModalService, private route: ActivatedRoute, private orderDetailService: OrderDetailService) {
    this.recentPayments = new TableService()
    this.recentPayments.initialize(recentPaymentData, 8)
  }

  ngOnInit() {
    this.overviewChartOptions = {
      series: [
        {
          name: 'This Year',
          type: 'line',
          data: [38, 120, 80, 42, 30, 75, 36, 35, 78, 80, 40, 80]
        },
        {
          name: 'Previous Years',
          type: 'line',
          data: [94, 32, 20, 135, 68, 22, 40, 43, 30, 64, 50, 87]
        },
        {
          name: 'Last 5 Years',
          type: 'line',
          data: [10, 40, 34, 50, 48, 61, 68, 90, 148, 48, 90, 30]
        }
      ],
      chart: {
        height: 330,
        type: 'line',
        toolbar: {
          show: false
        }
      },
      legend: {
        show: true,
        position: 'bottom',
        itemMargin: {
          horizontal: 15
        },
        markers: {
          width: 6,
          height: 6,
          offsetX: -6
        }
      },
      colors: ['#63CC8A', '#FFC861', '#4371E9'],
      stroke: {
        width: [3, 3, 3],
        curve: 'smooth',
        lineCap: 'round'
      },
      xaxis: {
        type: 'category',
        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        tickAmount: 12,

        axisTicks: {
          show: false
        },
        axisBorder: {
          show: false
        }
      },
      yaxis: {
        min: 0,
        max: 150,
        tickAmount: 5,
        labels: {
          offsetX: -17,
        }
      },
      fill: {
        opacity: 1
      },
      grid: {
        padding: {
          left: -10,
          bottom: 15
        },
        show: true
      }
    }

    this.pages = Array.from({ length: this.recentPayments.totalPages }, (_, i) => i + 1)

    this.route.params.subscribe(params => {
      this.id = params['id'];
    });

    this.orderDetailService.orderDetail(
      this.id,
    ).subscribe(
      response => {
        this.orderDetailData = response;
      },
      error => {
        console.error('Hata:', error);
      }
    );

  }

  openModal() {
    this.modalService.open(CreateBankAccountModalComponent, {
      modal: {
        enter: 'enter-going-down 0.3s ease-out',
        leave: 'fade-out 0.5s'
      },
      overlay: {
        leave: 'fade-out 0.5s'
      },
      data: {
        type: 'Angular modal library'
      }
    })
  }
}
