import { Component } from '@angular/core';
import { ModalService } from 'ngx-modal-ease';

@Component({
  selector: 'delete-short-code-modal',
  standalone: true,
  imports: [],
  templateUrl: './delete-short-code-modal.component.html'
})
export class DeleteShortCodeModalComponent {
  constructor(private modalService:ModalService){}
  closeModal(){
    this.modalService.close('DeleteShortCodeModalComponent')
  }
}
