import { CommonModule } from '@angular/common'
import { Component } from '@angular/core'
import { AddCardModalComponent } from '@component/shared/add-card-modal/add-card-modal.component'
import { DropdownComponent } from '@component/shared/dropdown/dropdown.component'
import { OptionsHorizComponent } from '@component/shared/options-horiz/options-horiz.component'
import { TopBannerComponent } from '@component/shared/top-banner/top-banner.component'
import { paymentAccountData } from '@data/accounts/paymentAccount'
import { ChartOptions } from '@pages/dashboards/style-01/style-01.component'
import { TableService } from '@service/table.service'
import { NgApexchartsModule } from 'ng-apexcharts'
import { ModalService } from 'ngx-modal-ease'
import { Router } from '@angular/router';
import { OptionsVerticalCustomerComponent } from '@component/shared/options-vertical-customer/options-vertical-customer.component'
import { CreateCustomerModalComponent } from '@component/shared/create-customer-modal/create-customer-modal.component'
import { FormsModule } from '@angular/forms'


@Component({
  selector: 'app-customer-list',
  standalone: true,
  imports: [TopBannerComponent, CommonModule, FormsModule, DropdownComponent, NgApexchartsModule, OptionsHorizComponent, OptionsVerticalCustomerComponent],
  templateUrl: './customer-list.component.html'
})
export class CustomerListviewComponent {
  totalTransferChart!: ChartOptions
  paymentAccounts
  pages: number[] = []
  searchCustomerName: string = '';
  searchCustomerCity: string = '';
  searchCustomerDistrict: string = '';
  searchCustomerAccount: string = '';
  searchCustomerAccountTag: string = '';
  searchAccountStatement: string = '';

  constructor(private modalService: ModalService, private router: Router) {
    this.paymentAccounts = new TableService()
    this.paymentAccounts.initialize(paymentAccountData, 1)
  }

  ngOnInit() {
    this.pages = Array.from({ length: this.paymentAccounts.totalPages }, (_, i) => i + 1)
  }
  getLocale(number: number) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'usd',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(number)
  }
  addAccountModal() {
    this.modalService.open(AddCardModalComponent, {
      modal: {
        enter: 'enter-going-down 0.3s ease-out',
        leave: 'fade-out 0.5s'
      },
      overlay: {
        leave: 'fade-out 0.5s'
      },
      data: {
        type: 'Angular modal library'
      }
    })
  }


  createCustomerModal() {
    this.modalService.open(CreateCustomerModalComponent, {
      modal: {
        enter: 'enter-going-down 0.3s ease-out',
        leave: 'fade-out 0.5s'
      },
      overlay: {
        leave: 'fade-out 0.5s'
      },
      data: {
        type: 'Angular modal library'
      }
    })
  }

  get filteredTransactionsCombined() {
    const customerName = this.searchCustomerName.toLowerCase();
    const customerCity = this.searchCustomerCity.toLowerCase();
    const customerDistrict = this.searchCustomerDistrict.toLowerCase();
    const customerAccount = this.searchCustomerAccount.toLowerCase();
    const customerAccountTag = this.searchCustomerAccountTag.toLowerCase();

    return this.paymentAccounts.tableData.filter(item => {
      const itemCustomerName = item.account.toLowerCase();
      const itemcustomerCity = item.currency.short.toLowerCase();
      const itemCustomerDistrict = item.bank.name.toLowerCase();
      const itemCustomerAccount = item.balance.toLowerCase();
      const itemCustomerAccountTag = item.expire.toLowerCase();

      return (!customerName || itemCustomerName.includes(customerName)) &&
        (!customerCity || itemcustomerCity.includes(customerCity)) &&
        (!customerDistrict || itemCustomerDistrict.includes(customerDistrict)) &&
        (!customerAccount || itemCustomerAccount.includes(customerAccount)) &&
        (!customerAccountTag || itemCustomerAccountTag.includes(customerAccountTag)) &&
        (!this.searchAccountStatement || item.account.includes(this.searchAccountStatement))
    });
  }
}
