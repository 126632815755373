import { Component, Inject, Input } from '@angular/core';
import { ModalService } from 'ngx-modal-ease';
import { DropdownComponent } from '../dropdown/dropdown.component';
import { ProductDeleteService } from '@service/products/products-delete.service';
import { CategoryDeleteService } from '@service/category/category-delete.service';


@Component({
  selector: 'app-delete-category-modal',
  standalone: true,
  imports: [DropdownComponent],
  templateUrl: './delete-category-modal.component.html'
})
export class DeleteCategoryModalComponent {
  @Input() categoryId!: number;
  walletType = ['Ön Ödemeli', 'Test', 'Test-1']

  constructor(private modalService: ModalService, private categoryDeleteService: CategoryDeleteService) { }

  closeModal() {
    this.modalService.close('DeleteCategoryModalComponent')
  }

  deleteCategory() {
    this.categoryDeleteService.deleteCategory(this.categoryId).subscribe(
      response => {
        window.location.reload();
      },
      error => {
        console.error('Error:', error);
      }
    );

  }
}
