import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { DropdownComponent } from '@component/shared/dropdown/dropdown.component';
import { OptionsVerticalComponent } from '@component/shared/options-vertical/options-vertical.component';
import { TopBannerComponent } from '@component/shared/top-banner/top-banner.component';
import { ModalService } from 'ngx-modal-ease';
import { TableService } from '@service/table.service';
import { DocumentDetailModalComponent } from '@component/shared/document-detail-modal/document-detail-modal.component';
import { FormsModule } from '@angular/forms';
import { InvoicesTransActionsService } from '@service/transaction/transactions-invoices.service';
import { InvoicesTransActionsData, InvoicesTransActionsModel } from '../../../models/transaction-model/transactions-invoices.model';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { DatePipe } from '@angular/common';
import { LocaleConfig, NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { JsonPipe, registerLocaleData } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { provideNativeDateAdapter } from '@angular/material/core';
import { VERSION as MAT_VERSION, MatNativeDateModule } from '@angular/material/core';
import { IgxCalendarModule, IgxSnackbarModule } from "igniteui-angular";
import { LOCALE_ID } from '@angular/core';
import localeTrExtra from '@angular/common/locales/extra/tr';
import localeTr from '@angular/common/locales/tr';
import { ChangeDetectorRef } from '@angular/core';
import { CalendarModule } from 'primeng/calendar';
import { BranchListData } from '../../../models/branches/bracnhs-list-model';
import { BaranchListService } from '@service/branches/bracnhs-list-service';
import { MultiSelectModule } from 'primeng/multiselect';
registerLocaleData(localeTr, 'tr-TR', localeTrExtra);

@Component({
  selector: 'tr-app-style-01',
  standalone: true,
  imports: [
    TopBannerComponent,
    CalendarModule,
    NgxDaterangepickerMd,
    MatDatepicker,
    IgxSnackbarModule,
    MultiSelectModule,
    IgxCalendarModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatMomentDateModule,
    JsonPipe,
    ReactiveFormsModule,
    MatNativeDateModule,

    FormsModule,
    DropdownComponent,
    OptionsVerticalComponent,
    CommonModule,
    DatePipe
  ],
  providers: [
    provideNativeDateAdapter(),
    { provide: LOCALE_ID, useValue: 'tr-TR' }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './document-list.component.html'
})
export class DocumentListComponent implements OnInit {
  transactions: any;
  branchOptions = [
    'Şube -1',
    'Şube -2',
    'Şube -3',
  ];
  cashierOption = [
    'Kasiyer -1',
    'Kasiyer -2',
    'Kasiyer -3',
  ];
  terminalOption = [
    'Terminal -1',
    'Terminal -2',
    'Terminal -3',
  ];
  searchDate: string = '';
  searchBranche: string = '';
  searchEttn: string = '';
  searchSeriesNo: string = '';
  searchCasier: string = '';
  searchCustomer: string = '';
  searchDocumentType: string = '';
  searchTUquantity: string = '';
  searchCollectedAmount: string = '';
  searchTaxAmount: string = '';

  selectedBranch!: [];
  branchList!: BranchListData[];

  invoicesList!: InvoicesTransActionsModel;
  invoicesData!: InvoicesTransActionsData;
  pages: number[] = [];
  startPage: number = 1;
  endPage: number = 10;

  validPages: number[] = [];
  showDatepicker: boolean = false;
  showSaveButton: boolean = false;
  selectedDateRange: { start: any, end: any } | null = null;

  locale: LocaleConfig = {
    applyLabel: 'Uygula',
    cancelLabel: 'İptal',
    format: 'DD.MM.YYYY',
    daysOfWeek: ['Pzt', 'Sal', 'Çar', 'Per', 'Cum', 'Cmt', 'Paz'],
    monthNames: ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık']
  };

  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl()
  });

  filters = ['Tümü', 'Başarılı', 'İptal Edildi', 'Devam Ediyor'];
  currentFilter = this.filters[0];

  constructor(private modalService: ModalService, private branchesService: BaranchListService, private cdr: ChangeDetectorRef, private invoicesService: InvoicesTransActionsService) {
    this.transactions = new TableService();
    NgxDaterangepickerMd.forRoot({
      locale: this.locale
    });
  }

  ngOnInit() {
    this.loadPageData();
    this.branchesService.allData().subscribe(
      response => {
        this.branchList = response.data;
      },
      error => {
        console.error('Hata:', error);
      }
    );
  }

  loadPageData(): void {
    const today = new Date();
    const formattedtoday = `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}`;

    if (this.selectedBranch) {
      this.invoicesService.allInvoices(
        "1",
        "20",
        formattedtoday.toString(),
        formattedtoday.toString(),
        undefined,
        true,
        this.selectedBranch
      ).subscribe(
        response => {
          this.invoicesList = response;
          this.calculatePages();
          this.updateValidPages();
          this.cdr.detectChanges();
        },
        error => {
          console.error('Hata:', error);
        }
      );
    } else {
      this.invoicesService.allInvoices("1", "20", formattedtoday.toString(), formattedtoday.toString()).subscribe(
        response => {
          this.invoicesList = response;
          this.calculatePages();
          this.updateValidPages();
          this.cdr.detectChanges();
        },
        error => {
          console.error('Hata:', error);
        }
      );
    }
  }

  calculatePages() {
    const totalPages = Math.ceil(this.invoicesList.total_count / 20);
    this.pages = Array.from({ length: totalPages }, (_, i) => i + 1);
  }

  updateValidPages() {
    const validPages: number[] = [];
    if (this.selectedDateRange) {
      const promises = this.pages.map(page =>
        this.invoicesService.allInvoices(page.toString(), "20", this.selectedDateRange?.start, this.selectedDateRange?.end).toPromise().then(
          response => {
            if (response && response.data.length > 0) {
              validPages.push(page);
            }
          }
        ).catch(error => {
          console.error('Hata:', error);
        })
      );
      Promise.all(promises).then(() => {
        this.validPages = validPages;
      });
    } else {
      const today = new Date();
      const formattedtoday = `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}`;
      const promises = this.pages.map(page =>
        this.invoicesService.allInvoices(page.toString(), "20", formattedtoday, formattedtoday).toPromise().then(
          response => {
            if (response && response.data.length > 0) {
              validPages.push(page);
            }
          }
        ).catch(error => {
          console.error('Hata:', error);
        })
      );
      Promise.all(promises).then(() => {
        this.validPages = validPages;
      });
    }

  }

  setFilter(filter: string) {
    this.currentFilter = filter;

    let filterStatus: string | null = null;

    if (filter !== 'Tümü') {
      if (filter === 'Başarılı') {
        filterStatus = 'SUCCESS';
      } else if (filter === 'İptal Edildi') {
        filterStatus = 'CANCEL';
      } else if (filter === 'Devam Ediyor') {
        filterStatus = 'WAITING';
      }
    } else {
      if (this.selectedDateRange) {
        if (this.selectedBranch) {
          this.invoicesService.allInvoices("1", "20", this.selectedDateRange.start, this.selectedDateRange.end, undefined, true, this.selectedBranch).subscribe(
            response => {
              this.invoicesList = response;
              this.calculatePages();
              this.updateValidPages();
              this.transactions.initialize(response.data, 1);
              this.cdr.detectChanges();
            },
            error => {
              console.error('Hata:', error);
            }
          );
        } else {
          this.invoicesService.allInvoices("1", "20", this.selectedDateRange.start, this.selectedDateRange.end).subscribe(
            response => {
              this.invoicesList = response;
              this.calculatePages();
              this.updateValidPages();
              this.transactions.initialize(response.data, 1);
              this.cdr.detectChanges();
            },
            error => {
              console.error('Hata:', error);
            }
          );
        }
      } else {
        if (this.selectedBranch) {
          const today = new Date();
          const formattedtoday = `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}`;
          this.invoicesService.allInvoices("1", "20", formattedtoday, formattedtoday, undefined, true, this.selectedBranch).subscribe(
            response => {
              this.invoicesList = response;
              this.calculatePages();
              this.updateValidPages();
              this.transactions.initialize(response.data, 1);
              this.cdr.detectChanges();
            },
            error => {
              console.error('Hata:', error);
            }
          );
        } else {
          const today = new Date();
          const formattedtoday = `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}`;
          this.invoicesService.allInvoices("1", "20", formattedtoday, formattedtoday).subscribe(
            response => {
              this.invoicesList = response;
              this.calculatePages();
              this.updateValidPages();
              this.transactions.initialize(response.data, 1);
              this.cdr.detectChanges();
            },
            error => {
              console.error('Hata:', error);
            }
          );
        }
      }
    }

    if (this.selectedDateRange) {
      if (this.selectedBranch) {
        this.invoicesService.allInvoices("1", "20", this.selectedDateRange.start, this.selectedDateRange.end, filterStatus!, true, this.selectedBranch).subscribe(
          response => {
            this.invoicesList = response;
            this.calculatePages();
            this.updateValidPages();
            this.transactions.initialize(response.data, 1);
            this.cdr.detectChanges();
          },
          error => {
            console.error('Hata:', error);
          }
        );
      } else {
        this.invoicesService.allInvoices("1", "20", this.selectedDateRange.start, this.selectedDateRange.end, filterStatus!).subscribe(
          response => {
            this.invoicesList = response;
            this.calculatePages();
            this.updateValidPages();
            this.transactions.initialize(response.data, 1);
            this.cdr.detectChanges();
          },
          error => {
            console.error('Hata:', error);
          }
        );
      }
    } else {
      if (this.selectedBranch) {
        const today = new Date();
        const formattedtoday = `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}`;
        this.invoicesService.allInvoices("1", "20", formattedtoday, formattedtoday, filterStatus!, true, this.selectedBranch).subscribe(
          response => {
            this.invoicesList = response;
            this.calculatePages();
            this.updateValidPages();
            this.transactions.initialize(response.data, 1);
            this.cdr.detectChanges();
          },
          error => {
            console.error('Hata:', error);
          }
        );
      } else {
        const today = new Date();
        const formattedtoday = `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}`;
        this.invoicesService.allInvoices("1", "20", formattedtoday, formattedtoday, filterStatus!).subscribe(
          response => {
            this.invoicesList = response;
            this.calculatePages();
            this.updateValidPages();
            this.transactions.initialize(response.data, 1);
            this.cdr.detectChanges();
          },
          error => {
            console.error('Hata:', error);
          }
        );
      }
    }
  }

  openModal(transactionDatas: InvoicesTransActionsData) {
    this.modalService.open(DocumentDetailModalComponent, {
      modal: {
        enter: 'enter-going-down 0.3s ease-out',
        leave: 'fade-out 0.5s'
      },
      overlay: {
        leave: 'fade-out 0.5s'
      },
      data: {
        type: 'Angular modal library',
        transactionData: transactionDatas
      }
    });
  }

  get filteredTransactionsCombined() {
    const customer = this.searchCustomer ? this.searchCustomer.toLowerCase() : '';
    const ettnTerm = this.searchEttn ? this.searchEttn.toLowerCase() : '';
    const invoiceTypeTerm = this.searchDocumentType ? this.searchDocumentType.toLocaleLowerCase() : '';
    const branchTerm = this.searchBranche ? this.searchBranche.toLocaleLowerCase() : '';
    const seriasTerm = this.searchSeriesNo ? this.searchSeriesNo.toLocaleLowerCase() : '';

    return this.invoicesList.data.filter((item: InvoicesTransActionsData) => {
      const itemCustomer = item.coalesce ? item.coalesce.toLowerCase() : '';
      const itemEttn = item.invoice_info.ettn ? item.invoice_info.ettn.toLowerCase() : '';
      const itemBrancheInvoiceType = item.invoice_info.document_type ? item.invoice_info.document_type.toLowerCase() : '';
      const itemBranche = item.info ? item.info.toLowerCase() : '';
      const itemSerias = item.invoice_info.document_number ? item.invoice_info.document_number.toLowerCase() : '';

      return (!this.searchDate || (item.document_date && item.document_date.includes(this.searchDate))) &&
        (!ettnTerm || itemEttn.includes(ettnTerm)) &&
        (!invoiceTypeTerm || itemBrancheInvoiceType.includes(invoiceTypeTerm)) &&
        (!customer || itemCustomer.includes(customer)) &&
        (!branchTerm || itemBranche.includes(branchTerm)) &&
        (!seriasTerm || itemSerias.includes(seriasTerm)) &&
        (!this.searchCollectedAmount || item.total_amount!.toString().includes(this.searchCollectedAmount));
    });
  }

  convertToTurkishCharacters(text: string) {
    return text.replace(/ğ/g, 'g')
      .replace(/Ğ/g, 'G')
      .replace(/ü/g, 'u')
      .replace(/Ü/g, 'U')
      .replace(/ş/g, 's')
      .replace(/Ş/g, 'S')
      .replace(/ı/g, 'i')
      .replace(/İ/g, 'I')
      .replace(/ö/g, 'o')
      .replace(/Ö/g, 'O')
      .replace(/ç/g, 'c')
      .replace(/Ç/g, 'C');
  }

  public printTable() {
    const doc = new jsPDF('l', 'mm', 'a4');
    const head = [
      [
        [this.convertToTurkishCharacters('Tarih')],
        [this.convertToTurkishCharacters('Şube')],
        [this.convertToTurkishCharacters('Ettn')],
        [this.convertToTurkishCharacters('B. Seri No.')],
        [this.convertToTurkishCharacters('Görevli')],
        [this.convertToTurkishCharacters('Müşteri.')],
        [this.convertToTurkishCharacters('Belge Tipi')],
        [this.convertToTurkishCharacters('T.Ü. Adedi')],
        [this.convertToTurkishCharacters('T. Tutar')],
        [this.convertToTurkishCharacters('Kdv Tutarı')],
      ]
    ];
    const data = this.filteredTransactionsCombined.map((item: any) => [
      [item.document_date],
      [this.convertToTurkishCharacters('Şube')],
      [item.invoice_info.ettn || ""],
      [item.invoice_info.document_number || ""],
      ['Kasiyer'],
      [item.coalesce || ""],
      [item.invoice_info.document_type || ""],
      ['Adet'],
      [item.total_amount],
      [this.convertToTurkishCharacters('KDV')],
    ]);

    autoTable(doc, {
      head: head,
      body: data,
      styles: {
        font: "Poppins",
        fontStyle: "normal",
      },
      bodyStyles: { fillColor: [200, 200, 200] }
    });

    doc.save('belge-listesi.pdf');
  }

  toggleCalendar() {
    this.showDatepicker = !this.showDatepicker;
    if (!this.showDatepicker) {
      this.showSaveButton = false;
    }
  }

  onRangeChange(event: any) {
    const start = new Date(event[0]);
    const endIndex = event.length - 1;
    const end = new Date(event[endIndex]);

    const formattedStart = `${start.getFullYear()}-${(start.getMonth() + 1).toString().padStart(2, '0')}-${start.getDate().toString().padStart(2, '0')}`; // YYYY-MM-DD
    const formattedEnd = `${end.getFullYear()}-${(end.getMonth() + 1).toString().padStart(2, '0')}-${end.getDate().toString().padStart(2, '0')}`; // YYYY-MM-DD

    this.selectedDateRange = {
      start: formattedStart,
      end: formattedEnd,
    };
    this.showSaveButton = true;
  }

  saveDateRange() {
    if (this.selectedDateRange) {
      this.showDatepicker = false;
      this.showSaveButton = false;

      if (this.selectedBranch) {
        this.invoicesService.allInvoices("1", "20", this.selectedDateRange.start, this.selectedDateRange.end, undefined, true, this.selectedBranch).subscribe(
          response => {
            this.invoicesList = response;
            this.calculatePages();
            this.updateValidPages();
            this.cdr.detectChanges();
          },
          error => {
            console.error('Hata:', error);
          }
        );
      } else {
        this.invoicesService.allInvoices("1", "20", this.selectedDateRange.start, this.selectedDateRange.end).subscribe(
          response => {
            this.invoicesList = response;
            this.calculatePages();
            this.updateValidPages();
            this.cdr.detectChanges();
          },
          error => {
            console.error('Hata:', error);
          }
        );
      }
    } else {
      console.log('Tarih aralığı seçilmedi.');
    }
  }

  goToPage(page: number) {
    if (this.validPages.includes(page)) {
      if (this.selectedDateRange) {
        if (this.selectedBranch) {
          this.invoicesService.allInvoices(page.toString(), "20", this.selectedDateRange.start, this.selectedDateRange.end, undefined, true, this.selectedBranch).subscribe(
            response => {
              if (response && response.data.length > 0) {
                this.invoicesList = response;
                this.transactions.paginate(page);
                this.cdr.detectChanges();
              } else {
                console.warn(`Page ${page} is empty and will not be displayed.`);
                this.updateValidPages();
              }
            },
            error => {
              console.error('Hata:', error);
            }
          );
        } else {
          this.invoicesService.allInvoices(page.toString(), "20", this.selectedDateRange.start, this.selectedDateRange.end).subscribe(
            response => {
              if (response && response.data.length > 0) {
                this.invoicesList = response;
                this.transactions.paginate(page);
                this.cdr.detectChanges();
              } else {
                console.warn(`Page ${page} is empty and will not be displayed.`);
                this.updateValidPages();
              }
            },
            error => {
              console.error('Hata:', error);
            }
          );
        }
      } else {
        if (this.selectedBranch) {
          const today = new Date();
          const formattedtoday = `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}`;
          this.invoicesService.allInvoices(page.toString(), "20", formattedtoday, formattedtoday, undefined, true, this.selectedBranch).subscribe(
            response => {
              if (response && response.data.length > 0) {
                this.invoicesList = response;
                this.transactions.paginate(page);
                this.cdr.detectChanges();
              } else {
                console.warn(`Page ${page} is empty and will not be displayed.`);
                this.updateValidPages();
              }
            },
            error => {
              console.error('Hata:', error);
            }
          );
        } else {
          const today = new Date();
          const formattedtoday = `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}`;
          this.invoicesService.allInvoices(page.toString(), "20", formattedtoday, formattedtoday).subscribe(
            response => {
              if (response && response.data.length > 0) {
                this.invoicesList = response;
                this.transactions.paginate(page);
                this.cdr.detectChanges();
              } else {
                console.warn(`Page ${page} is empty and will not be displayed.`);
                this.updateValidPages();
              }
            },
            error => {
              console.error('Hata:', error);
            }
          );
        }
      }
    } else {
      console.warn(`Page ${page} is not valid and will not be displayed.`);
    }
  }

  onItemSelected(event: any): void {
    this.selectedBranch = event.target.value;
    this.loadPageData();
    this.cdr.detectChanges();
  }

  updatePageRange() {
    this.endPage = Math.min(this.startPage + 9, this.pages.length);
  }
  
  nextPageGroup() {
    if (this.endPage < this.pages.length) {
      this.startPage = this.endPage + 1;
      this.endPage = Math.min(this.startPage + 9, this.pages.length);
      this.cdr.detectChanges();
    }
  }

  prevPageGroup() {
    if (this.startPage > 1) {
      this.endPage = this.startPage - 1;
      this.startPage = Math.max(this.endPage - 9, 1);
      this.cdr.detectChanges();
    }
  }

  onBranchChange(event: any) {
    const selectedCodes = event.value.map((item: any) => item.id);
    this.selectedBranch = selectedCodes
    this.loadPageData();
    this.cdr.detectChanges();
  }
}
