<app-top-banner title="Payment Limits" />

<div class="flex flex-col gap-4 xxl:gap-6">
  <!-- Payment Limits -->
  <div class="box xl:p-8">
    <div class="bb-dashed mb-4 flex items-center justify-between pb-4 lg:mb-6 lg:pb-6">
      <h4 class="h4">Payment Limits</h4>
      <app-options-horiz />
    </div>
    <div class="bb-dashed mb-4 flex flex-col gap-4 pb-4 lg:mb-6 lg:pb-6 xxl:gap-6">
      <div class="flex grow flex-wrap items-center justify-between gap-4 rounded-xl border border-n30 bg-primary/5 p-4 dark:border-n500 dark:bg-bg3 md:p-6 xl:px-8">
        <div>
          <p class="mb-2 text-sm">Cash withdrawal</p>
          <p class="text-xl font-medium">Amount spent and setup</p>
        </div>
        <div class="flex grow items-center gap-4 max-sm:flex-wrap sm:justify-end">
          <h5 class="range whitespace-nowrap text-xl font-medium">
            <span> $99.00 </span> /
            <span class="range__value text-primary"> $ {{ value1.toFixed(2) }} </span>
          </h5>
          <div class="w-full max-w-[203px] grow" dir="ltr">
            <form class="range">
              <div class="form-group range__slider">
                <input [(ngModel)]="value1" name="limit" type="range" step="1" />
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="flex grow flex-wrap items-center justify-between gap-4 rounded-xl border border-n30 bg-primary/5 p-4 dark:border-n500 dark:bg-bg3 md:p-6 xl:px-8">
        <div>
          <p class="mb-2 text-sm">Private Transaction</p>
          <p class="text-xl font-medium">Amount spent and setup</p>
        </div>
        <div class="flex grow items-center gap-4 max-sm:flex-wrap sm:justify-end">
          <h5 class="range whitespace-nowrap text-xl font-medium">
            <span> $99.00 </span> /
            <span class="range__value text-primary"> ${{ value2.toFixed(2) }} </span>
          </h5>
          <div class="w-full max-w-[203px] grow" dir="ltr">
            <form class="range">
              <div class="form-group range__slider">
                <input name="value2" [(ngModel)]="value2" type="range" step="1" />
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="flex grow flex-wrap items-center justify-between gap-4 rounded-xl border border-n30 bg-primary/5 p-4 dark:border-n500 dark:bg-bg3 md:p-6 xl:px-8">
        <div>
          <p class="mb-2 text-sm">Online Payment</p>
          <p class="text-xl font-medium">Amount spent and setup</p>
        </div>
        <div class="flex grow items-center gap-4 max-sm:flex-wrap sm:justify-end">
          <h5 class="range whitespace-nowrap text-xl font-medium">
            <span> $99.00 </span> /
            <span class="range__value text-primary"> $ {{ value3.toFixed(2) }} </span>
          </h5>
          <div class="w-full max-w-[203px] grow" dir="ltr">
            <form class="range">
              <div class="form-group range__slider">
                <input name="value3" [(ngModel)]="value3" type="range" step="1" />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="flex gap-4 xl:gap-6">
      <button class="btn">Save Changes</button>
      <button class="btn-outline">Cancel</button>
    </div>
  </div>
  <!-- transaction limits -->
  <div class="box xl:p-8">
    <div class="bb-dashed mb-4 flex items-center justify-between pb-4 lg:mb-6 lg:pb-6">
      <h4 class="h4">Transaction Limits</h4>
      <app-options-horiz />
    </div>
    <div class="bb-dashed mb-4 flex flex-col gap-4 pb-4 lg:mb-6 lg:pb-6 xxl:gap-6">
      <div class="flex grow flex-wrap items-center justify-between gap-4 rounded-xl border border-n30 bg-primary/5 p-4 dark:border-n500 dark:bg-bg3 md:p-6 xl:px-8">
        <div>
          <p class="mb-2 text-sm">One-time purchase in store</p>
          <p class="text-xl font-medium">Account Limits</p>
        </div>
        <div class="flex grow items-center gap-4 max-sm:flex-wrap sm:justify-end">
          <h5 class="range whitespace-nowrap text-xl font-medium">
            <span> $99.00 </span> /
            <span class="range__value text-primary"> $ {{ value4.toFixed(2) }} </span>
          </h5>
          <div class="w-full max-w-[203px] grow" dir="ltr">
            <form class="range">
              <div class="form-group range__slider">
                <input name="value4" [(ngModel)]="value4" type="range" step="1" />
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="bg-primary/5 grow dark:bg-bg3 border rounded-xl gap-4 flex-wrap border-n30 dark:border-n500 p-4 md:p-6 xl:px-8 flex items-center justify-between">
        <div>
          <p class="text-sm mb-2">One-time purchase online</p>
          <p class="font-medium text-xl">Account Limits</p>
        </div>
        <div class="flex items-center max-sm:flex-wrap gap-4 grow sm:justify-end">
          <h5 class="text-xl font-medium whitespace-nowrap range">
            <span> $99.00 </span> /
            <span class="text-primary range__value"> $ {{ value5.toFixed(2) }} </span>
          </h5>
          <div class="grow max-w-[203px] w-full" dir="ltr">
            <form class="range">
              <div class="form-group range__slider">
                <input type="range" step="1" name="value5" [(ngModel)]="value5" />
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="bg-primary/5 grow dark:bg-bg3 border rounded-xl gap-4 flex-wrap border-n30 dark:border-n500 p-4 md:p-6 xl:px-8 flex items-center justify-between">
        <div>
          <p class="text-sm mb-2">One-time p2p transfer</p>
          <p class="font-medium text-xl">Account Limits</p>
        </div>
        <div class="flex items-center max-sm:flex-wrap gap-4 grow sm:justify-end">
          <h5 class="text-xl font-medium whitespace-nowrap range">
            <span> $99.00 </span> /
            <span class="text-primary range__value"> $ {{ value6.toFixed(2) }} </span>
          </h5>
          <div class="grow max-w-[203px] w-full" dir="ltr">
            <form class="range">
              <div class="form-group range__slider">
                <input type="range" step="1" name="value6" [(ngModel)]="value6" />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="flex gap-4 xl:gap-6">
      <button class="btn">Save Changes</button>
      <button class="btn-outline">Cancel</button>
    </div>
  </div>
</div>
