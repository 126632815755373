import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DashboardDailyModel } from '../../models/dashboard/daily-model';
import { DashboardWeekModel } from '../../models/dashboard/week-model';
import { DashboardMonthModel } from '../../models/dashboard/month-model';
import { DashboardYearModel } from '../../models/dashboard/year-model';
import { DashboardLastFiveModel } from '../../models/dashboard/last-five-years-model';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class DashboardTransactionService {

    private apiUrl = `${environment.apiUrl}/transactions/dashboard`;
    private token = localStorage.getItem('authToken');
    constructor(private http: HttpClient) { }

    getDailyData(
        firmSelected: boolean = false,
        mealCardSelected: boolean = false,
        staffFilterSelected: boolean = false,
        firms?: string,
        mealCards?: string,
        staffs?: string,
    ): Observable<DashboardDailyModel> {
        const urlPath = `${this.apiUrl}`;
        const body = {
            "firm_ids": [
                firms,
            ],
            "meal_cards": [
                mealCards,
            ],
            "staff_ids": [
                staffs,
            ]
        };
        const headers = new HttpHeaders()
            .set('Authorization', `Bearer ${this.token}`);
        let params = new HttpParams()
            .set('date_filter', 'TODAY')
            .set('firm_id_selected', firmSelected)
            .set('is_it_meal_card', mealCardSelected)
            .set('staff_filter', staffFilterSelected);

        return this.http.post<DashboardDailyModel>(urlPath, body, { headers, params });
    }

    getYesyerdayData(
        firmSelected: boolean = false,
        mealCardSelected: boolean = false,
        staffFilterSelected: boolean = false,
        firms?: string,
        mealCards?: string,
        staffs?: string,
    ): Observable<DashboardDailyModel> {
        const urlPath = `${this.apiUrl}`;
        const body = {
            "firm_ids": [
                firms,
            ],
            "meal_cards": [
                mealCards,
            ],
            "staff_ids": [
                staffs,
            ]
        };
        const headers = new HttpHeaders()
            .set('Authorization', `Bearer ${this.token}`);
        let params = new HttpParams()
            .set('date_filter', 'YESTERDAY')
            .set('firm_id_selected', firmSelected)
            .set('is_it_meal_card', mealCardSelected)
            .set('staff_filter', staffFilterSelected);

        return this.http.post<DashboardDailyModel>(urlPath, body, { headers, params });
    }

    getWeekData(
        firmSelected: boolean = false,
        mealCardSelected?: string,
        staffFilterSelected: boolean = false,
        firms?: string,
        mealCards?: string,
        staffs?: string,
    ): Observable<DashboardWeekModel> {
        const urlPath = `${this.apiUrl}`;
        const body = {
            "firm_ids": [
                firms,
            ],
            "meal_cards": [
                mealCards,
            ],
            "staff_ids": [
                staffs,
            ]
        };
        const headers = new HttpHeaders()
            .set('Authorization', `Bearer ${this.token}`);
        const mealCardSelectedValue = mealCardSelected !== undefined ? mealCardSelected : 'false';

        let params = new HttpParams()
            .set('date_filter', 'THIS_WEEK')
            .set('firm_id_selected', firmSelected)
            .set('is_it_meal_card', mealCardSelectedValue)
            .set('staff_filter', staffFilterSelected);

        return this.http.post<DashboardWeekModel>(urlPath, body, { headers, params });
    }

    getMonthData(
        firmSelected: boolean = false,
        mealCardSelected: boolean = false,
        staffFilterSelected: boolean = false,
        firms?: string,
        mealCards?: string,
        staffs?: string,
    ): Observable<DashboardMonthModel> {
        const urlPath = `${this.apiUrl}`;
        const body = {
            "firm_ids": [
                firms,
            ],
            "meal_cards": [
                mealCards,
            ],
            "staff_ids": [
                staffs,
            ]
        };
        const headers = new HttpHeaders()
            .set('Authorization', `Bearer ${this.token}`);
        let params = new HttpParams()
            .set('date_filter', 'THIS_MONTH')
            .set('firm_id_selected', firmSelected)
            .set('is_it_meal_card', mealCardSelected)
            .set('staff_filter', staffFilterSelected);

        return this.http.post<DashboardMonthModel>(urlPath, body, { headers, params });
    }

    getYearData(
        firmSelected: boolean = false,
        mealCardSelected: boolean = false,
        staffFilterSelected: boolean = false,
        firms?: string,
        mealCards?: string,
        staffs?: string,
    ): Observable<DashboardYearModel> {
        const urlPath = `${this.apiUrl}`;
        const body = {
            "firm_ids": [
                firms,
            ],
            "meal_cards": [
                mealCards,
            ],
            "staff_ids": [
                staffs,
            ]
        };
        const headers = new HttpHeaders()
            .set('Authorization', `Bearer ${this.token}`);
        let params = new HttpParams()
            .set('date_filter', 'THIS_YEAR')
            .set('firm_id_selected', firmSelected)
            .set('is_it_meal_card', mealCardSelected)
            .set('staff_filter', staffFilterSelected);

        return this.http.post<DashboardYearModel>(urlPath, body, { headers, params });
    }

    getLastFiveYearData(
        firmSelected: boolean = false,
        mealCardSelected: boolean = false,
        staffFilterSelected: boolean = false,
        firms?: string,
        mealCards?: string,
        staffs?: string,
    ): Observable<DashboardLastFiveModel> {
        const urlPath = `${this.apiUrl}`;
        const body = {
            "firm_ids": [
                firms,
            ],
            "meal_cards": [
                mealCards,
            ],
            "staff_ids": [
                staffs,
            ]
        };
        const headers = new HttpHeaders()
            .set('Authorization', `Bearer ${this.token}`);
        let params = new HttpParams()
            .set('date_filter', 'LAST_5_YEARS')
            .set('firm_id_selected', firmSelected)
            .set('is_it_meal_card', mealCardSelected)
            .set('staff_filter', staffFilterSelected);

        return this.http.post<DashboardLastFiveModel>(urlPath, body, { headers, params });
    }

    getFilterData(
        firmSelected: boolean = false,
        mealCardSelected?: string,
        staffFilterSelected: boolean = false,
        firms?: string,
        mealCards?: string,
        staffs?: string,
        filterType?: string,
        filterMonth?:string,
        filterYear?:string,
    ): Observable<DashboardYearModel> {
        const urlPath = `${this.apiUrl}`;
        const body = {
            "firm_ids": [
                firms,
            ],
            "meal_cards": [
                mealCards,
            ],
            "staff_ids": [
                staffs,
            ]
        };
        const headers = new HttpHeaders()
            .set('Authorization', `Bearer ${this.token}`);
        let params = new HttpParams()
            .set('date_filter', filterType!)
            .set('month', filterMonth!)
            .set('year', filterYear!)
            .set('firm_id_selected', firmSelected)
            .set('is_it_meal_card', mealCardSelected!)
            .set('staff_filter', staffFilterSelected);

        return this.http.post<DashboardYearModel>(urlPath, body, { headers, params });
    }
}
