import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { Observable } from 'rxjs';
import { InvoicesTransActionsModel } from '../../models/transaction-model/transactions-invoices.model';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InvoicesTransActionsService {

  private apiUrl = `${environment.apiUrl}/invoices/list`;
  private token = localStorage.getItem('authToken');

  constructor(private http: HttpClient) { }

  allInvoices(
    page: string,
    limit: string,
    startDate: string,
    endDate: string,
    filterStatus?: string,
    firmSelected:boolean = false,
    firms?:[]
  ): Observable<InvoicesTransActionsModel> {
    const headers = new HttpHeaders()
      .set('Authorization', `Bearer ${this.token}`)
      .set('Content-Type', 'application/json');  
    const body = {
      "firm_ids": firms
    };

    let params = new HttpParams()
    .set('page', page)
    .set('limit', limit)
    .set('start_date', startDate)
    .set('end_date', endDate)
    .set('is_firm_select', firmSelected);

  if (filterStatus) {
    params = params.set('status', filterStatus);
  }

    return this.http.post<InvoicesTransActionsModel>(this.apiUrl, body, { headers,params });
  }
}
