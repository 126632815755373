export enum WorkingDaysEnum {
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY',
}

export function getEnumValues(enumObj: any): { key: string, value: string }[] {
  return Object.keys(enumObj).map(key => ({
    key,
    value: enumObj[key]
  }));
}