export const friends = [
  {
    id: 1,
    name: 'John Smith',
    img: 'assets/images/user-10.png',
    message: 'Just ideas for next time',
    time: '4m',
    badge: 3,
    messages: [
      {
        person: 'self',
        message: 'Hi'
      },
      {
        person: 'self',
        message: 'Hello'
      },
      {
        person: 'you',
        message: 'Hi, How are you'
      },
      {
        person: 'self',
        message: 'I am great! what about you?'
      },
      {
        person: 'you',
        message: 'Glad to know'
      },
      {
        person: 'you',
        message: 'When will you come?'
      }
    ]
  },
  {
    id: 2,
    name: 'Emma Johnson',
    img: 'assets/images/user-2.png',
    message: 'Omg, this is amazing',
    time: '4m',
    badge: 4,
    messages: [
      {
        person: 'you',
        message: 'Hi, How are you'
      },
      {
        person: 'self',
        message: 'Great, I am fine'
      },
      {
        person: 'you',
        message: 'Glad to know'
      },
      {
        person: 'self',
        message: "Iet's go for a dinner"
      }
    ]
  },
  {
    id: 3,
    name: 'Michael Williams',
    img: 'assets/images/user-3.png',
    message: 'You : perfect',
    time: '14m',
    badge: 2,
    messages: [
      {
        person: 'you',
        message: 'Hello....'
      },
      {
        person: 'you',
        message: 'HI'
      },
      {
        person: 'self',
        message: 'Are you free ?'
      },
      {
        person: 'self',
        message: "Iet's go for a dinner"
      },
      {
        person: 'you',
        message: 'No I am busy'
      }
    ]
  },
  {
    id: 4,
    name: 'Olivia Brown',
    img: 'assets/images/user-4.png',
    message: 'Woohoooo',
    time: '44m',
    messages: [
      {
        person: 'self',
        message: "Hey bro? what's up?"
      },
      {
        person: 'you',
        message: 'Great, I am fine'
      },
      {
        person: 'self',
        message: 'What are you doing?'
      },
      {
        person: 'you',
        message: "Iet's go for a dinner"
      },
      {
        person: 'you',
        message: 'No I am busy'
      }
    ]
  },
  {
    id: 5,
    name: 'Liam Jones',
    img: 'assets/images/user-5.png',
    message: 'Haha, oh man',
    time: '45m',
    messages: [
      {
        person: 'you',
        message: 'Who are you?'
      },
      {
        person: 'self',
        message: 'I am nobody? '
      },
      {
        person: 'you',
        message: 'What do you want?'
      },
      {
        person: 'self',
        message: 'You....  '
      },
      {
        person: 'you',
        message: 'No I am busy'
      }
    ]
  },
  {
    id: 6,
    name: 'Sophia Davis',
    img: 'assets/images/user-big-1.png',
    message: 'Just ideas for next time',
    time: '47m',
    messages: [
      {
        person: 'self',
        message: 'Why are you disturbing me?'
      },
      {
        person: 'you',
        message: 'Go for a travel '
      },
      {
        person: 'self',
        message: 'Please listen me... '
      },
      {
        person: 'you',
        message: 'No I am busy'
      }
    ]
  },
  {
    id: 7,
    name: 'William Wilson',
    img: 'assets/images/user-big-2.png',
    message: 'Haha, oh man',
    time: '45m',
    messages: [
      {
        person: 'you',
        message: 'Hi, How are you'
      },
      {
        person: 'self',
        message: 'Great, I am fine'
      },
      {
        person: 'you',
        message: 'Glad to know'
      },
      {
        person: 'self',
        message: "Iet's go for a dinner"
      },
      {
        person: 'you',
        message: 'No I am busy'
      }
    ]
  },
  {
    id: 8,
    name: 'Amelia Lee',
    img: 'assets/images/user-big-3.png',
    message: 'Hey',
    time: '49m',
    messages: [
      {
        person: 'self',
        message: "Hey bro? what's up?"
      },
      {
        person: 'you',
        message: 'Great, I am fine'
      },
      {
        person: 'self',
        message: 'What are you doing?'
      },
      {
        person: 'you',
        message: "Iet's go for a dinner"
      },
      {
        person: 'you',
        message: 'No I am busy'
      }
    ]
  },
  {
    id: 9,
    name: 'James Anderson',
    img: 'assets/images/user-big-4.png',
    message: 'Hey',
    time: '4h',
    messages: [
      {
        person: 'self',
        message: 'Will you Are you free ??'
      },
      {
        person: 'you',
        message: 'Great, I am fine'
      },
      {
        person: 'self',
        message: 'What are you doing?'
      },
      {
        person: 'you',
        message: "Iet's go for a dinner"
      },
      {
        person: 'self',
        message: 'No. I will go for fishing in pond.'
      },
      {
        person: 'you',
        message: 'No I am busy'
      }
    ]
  },
  {
    id: 10,
    name: 'Sophia Clark',
    img: 'assets/images/user-big-2.png',
    message: 'Hey',
    time: '5h',
    messages: [
      {
        person: 'you',
        message: 'Hi, How are you'
      },
      {
        person: 'self',
        message: 'Great, I am fine'
      },
      {
        person: 'you',
        message: 'Glad to know'
      },
      {
        person: 'self',
        message: "Iet's go for a dinner"
      },
      {
        person: 'you',
        message: 'No I am busy'
      }
    ]
  },
  {
    id: 11,
    name: 'Benjamin Parker',
    img: 'assets/images/user-3.png',
    message: 'Just ideas for next time',
    time: '10h',
    messages: [
      {
        person: 'self',
        message: "Hey bro? what's up?"
      },
      {
        person: 'you',
        message: 'Great, I am fine'
      },
      {
        person: 'self',
        message: 'What are you doing?'
      },
      {
        person: 'you',
        message: "Iet's go for a dinner"
      }
    ]
  },
  {
    id: 12,
    name: 'Emily Taylor',
    img: 'assets/images/user-4.png',
    message: 'I will be there',
    time: '12h',
    messages: [
      {
        person: 'you',
        message: 'Hi, How are you'
      },
      {
        person: 'self',
        message: 'Great, I am fine'
      },
      {
        person: 'you',
        message: 'Glad to know'
      },
      {
        person: 'self',
        message: "Iet's go for a dinner"
      },
      {
        person: 'you',
        message: 'No I am busy'
      }
    ]
  }
]
