<div class=" bg-n0 dark:bg-bg4 box overflow-y-auto max-h-[800px]">
  <div class="flex justify-between items-center bb-dashed pb-6 mb-6">
    <h4>Terminal Ekle</h4><i (click)="closeModal()" class="las cursor-pointer la-times"></i>
  </div>
  <form>
    <div class="grid grid-cols-2 gap-4 xxxl:gap-6">
      <div class="col-span-2">
        <label htmlFor="device-id" class="md:text-lg font-medium block mb-4">ID </label>
        <div class="bg-secondary1/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl relative">
          <input type="number" class="text-sm w-full px-6 py-2.5 md:py-3 bg-transparent rounded-3xl"
            placeholder="ER423422" id="device-id" required />
        </div>
      </div>

      <div class="col-span-2 md:col-span-1">
        <label htmlFor="device-name" class="md:text-lg font-medium block mb-4">Cihaz Adı </label>
        <div class="bg-secondary1/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl relative">
          <input type="text" class="text-sm w-full px-6 py-2.5 md:py-3 bg-transparent rounded-3xl"
            placeholder="Cihaz Adı" id="device-name" required />
        </div>
      </div>

      <div class="col-span-2 md:col-span-1">
        <label for="currency" class="mb-4 block font-medium md:text-lg"> Kullanım Bölgesi </label>
        <app-dropdown [options]="usageRegion" btnClassProps="lg:!py-3 rounded-3xl bg-secondary1/5"
          dropdownClassProps="w-full"></app-dropdown>
      </div>

      <div class="col-span-2">
        <label for="currency" class="mb-4 block font-medium md:text-lg">Şube Seçiniz </label>
        <app-dropdown [options]="braches" btnClassProps="lg:!py-3 rounded-3xl bg-secondary1/5"
          dropdownClassProps="w-full"></app-dropdown>
      </div>

      <div class="col-span-2 mt-4">
        <button class="btn flex w-full justify-center" type="submit">Onayla</button>
      </div>
    </div>
  </form>
</div>