import { Component } from '@angular/core';
import { ModalService } from 'ngx-modal-ease';

@Component({
  selector: 'delete-person-of-wallet-modal',
  standalone: true,
  imports: [],
  templateUrl: './delete-service-modal.component.html'
})
export class DeleteServiceModalComponent {
  constructor(private modalService:ModalService){}
  closeModal(){
    this.modalService.close('DeleteServiceModalComponent')
  }
}
