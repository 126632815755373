import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ProductSortableService {

    private apiUrl = `${environment.apiUrl}/category/`;
    private token = localStorage.getItem('authToken');
    constructor(private http: HttpClient) { }

    getProductSortable(categoriesId: string,items:string[]): Observable<any[]> {
        const urlPath = `${this.apiUrl}`;
        const headers = new HttpHeaders()
            .set('Authorization', `Bearer ${this.token}`);
            const body =  {
                "items": items
              }
        return this.http.patch<any[]>(urlPath + categoriesId + '/products-sortable',body ,{ headers });
    }

}
