import { Component } from '@angular/core'
import { DropdownComponent } from '../dropdown/dropdown.component'
import { ModalService } from 'ngx-modal-ease'

@Component({
  selector: 'app-regions-add-modal',
  standalone: true,
  imports: [DropdownComponent],
  templateUrl: './regions-add-modal.component.html'
})
export class RegionsAddModalComponent {
  constructor(private modalService:ModalService){}
  closeModal(){
    this.modalService.close('RegionsAddModalComponent')
  }
}
