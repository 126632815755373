import { CommonModule } from '@angular/common'
import { Component } from '@angular/core'
import { DropdownComponent } from '@component/shared/dropdown/dropdown.component'
import { OptionsHorizComponent } from '@component/shared/options-horiz/options-horiz.component'
import { TopBannerComponent } from '@component/shared/top-banner/top-banner.component'
import { NgApexchartsModule } from 'ng-apexcharts'
import { latestTransactions } from '@data/transactions/latestTransactions'
import { ModalService } from 'ngx-modal-ease'
import { TableService } from '@service/table.service'
import { FormsModule } from '@angular/forms';
import { ValorModalComponent } from '@component/shared/valor-modal/valor-modal.component'

@Component({
  selector: 'rp-app-valor-report',
  standalone: true,
  imports: [
    TopBannerComponent,
    FormsModule,
    OptionsHorizComponent,
    DropdownComponent,
    NgApexchartsModule,
    CommonModule
  ],
  templateUrl: './valor-report.component.html'
})
export class ValorReportComponent {
  transactions
  weeks: any[] = [];
  pages: number[] = []
  initialDate: Date = new Date();
  filters = ['Tümü', 'Başarılı', 'İptal Edildi', 'Devam Ediyor']
  currentFilter = this.filters[0]
  customerList = [
    'Test-1',
    'Test-2',
    'Test-3',
  ]
  events: { [key: string]: string[] } = {
    '2024-05-10': ['233.000,00₺'],
    '2024-05-15': ['145.021,25₺'],
    '2024-05-20': ['531.141,21₺']
  };

  constructor(private modalService: ModalService) {
    this.transactions = new TableService()
    this.onMonthChange({ target: { value: this.initialDate.toISOString().slice(0, 7) } });
    this.transactions.initialize(latestTransactions, 14)
  }

  ngOnInit() {
    this.pages = Array.from({ length: this.transactions.totalPages }, (_, i) => i + 1)
  }

  onMonthChange(event: any) {
    const selectedDate: string = event.target.value;
    const [year, monthNumber] = selectedDate.split('-');
    const date = new Date(parseInt(year), parseInt(monthNumber) - 1, 1);
    const firstDayOfWeek = date.getDay() === 0 ? 6 : date.getDay() - 1;
    const lastDay = new Date(parseInt(year), parseInt(monthNumber), 0).getDate();

    let dayCounter = 1;
    this.weeks = [];

    for (let i = 0; i < 6; i++) {
      const week: any[] = [];
      for (let j = 0; j < 7; j++) {
        if (i === 0 && j < firstDayOfWeek) {
          week.push(null);
        } else if (dayCounter <= lastDay) {
          week.push(`${year}-${monthNumber.padStart(2, '0')}-${dayCounter}`);
          dayCounter++;
        } else {
          week.push(null);
        }
      }
      this.weeks.push(week);
    }
  }

  getEventsForDay(day: string): string[] {
    return this.events[day] || [];
  }

  areAllItemsChecked(): boolean {
    return this.transactions.tableData.length > 1 && this.transactions.tableData.every((item) => item.isChecked)
  }

  previewValor() {
    this.modalService.open(ValorModalComponent, {
      modal: {
        enter: 'enter-going-down 0.3s ease-out',
        leave: 'fade-out 0.5s'
      },
      overlay: {
        leave: 'fade-out 0.5s'
      },
      data: {
        type: 'Angular modal library'
      }
    })
  }

}