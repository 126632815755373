import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoginService } from './login.service';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    private apiUrl = `${environment.apiUrl}/auth/user`;
    private token = localStorage.getItem('authToken');
    // private bToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjNkNDg5NDc0LTcxYzEtNDgzZC05ZDgxLTMxZDY3OTJmNTFjNCIsIm5hbWUiOiLFnmVub2wgxZ5lcmVmIiwicGhvbmUiOiIrOTA1MzQ4ODkzNTY1IiwiY29tcGFueV9pZCI6IjUyNmIxZWE1LTQyNmYtNDYzYy1hZWZlLTVhYjc1NWRkNDhkZCIsImZpcm1faWQiOiIzNTg1Y2FmMy01MjYxLTQ0ODktOWM5Yy1jZWM2ZTkxOGZmZjYiLCJyb2xlIjoiQVVUSE9SSVpFRCIsImJyYW5jaHMiOlsiNThiNGYzNjYtMDkxYi00ZGJiLThjYjAtOWQwMjA3MDIyMjVjIiwiMzU4NWNhZjMtNTI2MS00NDg5LTljOWMtY2VjNmU5MThmZmY2Il0sInVzZXJfdHlwZSI6IlBBTkVMX1VTRVIiLCJjbGllbnQiOiJXRUIiLCJleHBpcmVzIjoxNzE1OTU2MDcyLCJpYXQiOjE3MTU2ODYwNzIsImV4cCI6MTc0NzIyMjA3Mn0.SAYNIIokchecg5amUSySSUukhFqXBohF4SqlXr6yi5w"

    constructor(private http: HttpClient,private loginService: LoginService) { }

    getData(): Observable<any> {
        const headers = new HttpHeaders().set('Authorization', `Bearer ${this.token}`);
        return this.http.get<any>(this.apiUrl, { headers });
    }

    isLoggedIn(): boolean {
        return !!localStorage.getItem('authToken');
    }

    login(token: string) {
        localStorage.setItem('authToken', token);
    }
    logout() {
        localStorage.removeItem('authToken');
    }
}
