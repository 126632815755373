import { CommonModule } from '@angular/common'
import { Component, OnInit } from '@angular/core'
import { OptionsHorizComponent } from '@component/shared/options-horiz/options-horiz.component'
import { TopBannerComponent } from '@component/shared/top-banner/top-banner.component'
import { providersTabData } from '@data/payment/providers'
import { PasswordInputComponent } from '@component/shared/password-input/password-input.component'
import { DropdownComponent } from '@component/shared/dropdown/dropdown.component'
import { GeneralSettingsComponent } from '@component/shared/general-settings/general-settings.component'
import { WorkingHoursSettingsComponent } from '@component/shared/working-hours-settings/working-hours-settings.component'
import { ServiceAreaComponent } from '@component/shared/service-area/service area.component'
import { SectionSettingsComponent } from '@component/shared/sections-settings/sections-settings-component'
import { ShortCodeComponent } from '@component/shared/short-code/short-code-component'
import { PrinterSettingsComponent } from '@component/shared/printer-settings/printer-settings.component'
import { ApiEndPointComponent } from '@component/shared/api-endpoint/api-endpoint.component'
import { GetWorkingHoursService } from '@service/settings/get-working-hours-service'
import { GetWorkingHoursModel } from '../../../models/settings/get-working-house-response.model'

@Component({
  selector: 'app-payment-providers',
  standalone: true,
  imports: [
    TopBannerComponent,
    PrinterSettingsComponent,
    ShortCodeComponent,
    OptionsHorizComponent,
    SectionSettingsComponent,
    ServiceAreaComponent,
    GeneralSettingsComponent,
    WorkingHoursSettingsComponent,
    CommonModule,
    PasswordInputComponent,
    DropdownComponent,
    GeneralSettingsComponent,
    ApiEndPointComponent
  ],
  templateUrl: './payment-providers.component.html'
})
export class PaymentProviderseComponent {
  providers = providersTabData
  activeTab = this.providers[0].title
  chatShow = false

  setActiveTab(title: string) {
    this.activeTab = title
  }

  constructor(){}



}
