<div class="grid grid-cols-12 relative gap-4 xxl:gap-6 max-md:mt-3">
  <div id="chat-sidebar" [ngClass]="chatShow?'chatshow':'chathide'"
    class="max-md:box md:bg-transparent duration-500 max-md:w-[280px] max-md:max-h-[600px] max-md:overflow-y-auto max-md:rounded-xl max-md:absolute ltr:max-md:left-0 rtl:max-md:right-0 z-[3] max-md:bg-n0 max-md:dark:bg-bg4 max-md:top-0 md:col-span-5 xl:col-span-4 max-md:min-w-[300px]">
    <div class="md:box sticky top-20">
      <div class=" border-secondary1/20 mb-4 pb-4 lg:mb-6 lg:pb-6">
      </div>
      <ul class="flex flex-col gap-4 lg:gap-6  mb-6 pb-6">
        @for (item of tabs; track $index) {
        <li>
          <button (click)="setActiveTab(item.title)" class="provider-btn"
            [ngClass]="{ 'provider-active': item.title == activeTab }">
            <div>
              <p class="text-base xxl:text-lg font-medium">{{ item.title }}</p>
            </div>
            <span class="icon">
              <i [class]="item.icon"></i>
            </span>
          </button>
        </li>
        }
      </ul>
    </div>
  </div>
  <div class="col-span-12 md:col-span-7 xl:col-span-8 box xl:p-8">
    <div class=" border-secondary1/20 mb-4 pb-4 lg:mb-6 lg:pb-6">
      @for (item of tabs; track $index) {
      <div id="utility-tab" [ngClass]="{ 'tab-active': item.title == activeTab }"
        class="provider-tab grid grid-cols-12 gap-4 xxl:gap-6">
        @if (item.id == "1") {
        <app-devices-settings class="col-span-12 lg:col-span-12" />
        }@else if (item.id == "2") {
        <app-terminals class="col-span-12 lg:col-span-12"/>
        }
      </div>
      }
    </div>
  </div>
</div>