import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PersonelListModel } from '../../models/personel/personel-list-model';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class PersonelListService {

    private apiUrl = `${environment.apiUrl}/personnels`;
    private token = localStorage.getItem('authToken');
    constructor(private http: HttpClient) { }

    allPersonelList(): Observable<PersonelListModel[]> {
        const headers = new HttpHeaders()
            .set('Authorization', `Bearer ${this.token}`);
        const urlPath = `${this.apiUrl}`;
        return this.http.get<PersonelListModel[]>(urlPath, { headers });
    }
}
