<div class=" bg-n0 dark:bg-bg4 box overflow-y-auto max-h-[800px]" style="width: 85vh;">
  <div class="flex justify-end">
    <i (click)="closeModal()" class="las cursor-pointer la-times"></i>
  </div>
  <div class="">
    <div class="xxl:gap-6 p-4 box min-[1880px]:p-6 mb-4 xxl:mb-6"style="
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
">
      <img src="../../../../assets/icons/warning-icon.svg">
     <h3 style="text-align: center; font-size: 22px;" class="menu-title font-medium">İşlem için gerekli bilgiler eksik, <br>alanları tamamlayarak tekrar deneyiniz.</h3>
    </div>
  </div>
</div>