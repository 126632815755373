<div class=" bg-n0 dark:bg-bg4 box overflow-y-auto max-h-[800px]">
  <div class="flex justify-end items-center bb-dashed pb-6 mb-6">
    <i (click)="closeModal()" class="las cursor-pointer la-times"></i>
  </div>
  <div class="downloadOptions">
    <img src="../../../../assets/icons/warning-icon.svg" alt="">
    <h4 class="mt-4">Emin misiniz!</h4>
    <p class="mt-4" style="width: 70%; text-align: center;">
      Bölümler kısmında bulunan seçilmiş alan silinecektir.
      Silmek istediğinize emin misiniz.
    </p>
    <div class="mt-4 flex gap-4">
      <button (click)="closeModal()" class="btn-outline">Vazgeç</button>
      <button class="btn"> Onayla </button>
    </div>
  </div>
</div>