import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { OrderListModel } from '../../models/order-model/order-list.model';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class OrdersAllService {

    private apiUrl = `${environment.apiUrl}/orders`;
    private token = localStorage.getItem('authToken');
    constructor(private http: HttpClient) { }

    allOrders(
        page: string,
        limit: string,
        startDate: string,
        endDate: string,
        firmSelected: boolean = false,
        firms?: string[]
    ): Observable<OrderListModel> {
        const headers = new HttpHeaders()
            .set('Authorization', `Bearer ${this.token}`);
        const urlPath = `${this.apiUrl}?page=${page}&limit=${limit}&start_date=${startDate}&end_date=${endDate}&is_firm_select=${firmSelected}`;
        const body = {
            "firm_ids": firms
        };
        return this.http.post<OrderListModel>(urlPath, body, { headers });
    }

    allOrdersStatus(
        page: string,
        limit: string,
        startDate: string,
        endDate: string,
        status: string,
        firmSelected: boolean = false,
        firms?: []
    ): Observable<OrderListModel> {
        const headers = new HttpHeaders()
            .set('Authorization', `Bearer ${this.token}`);
        const urlPath = `${this.apiUrl}?page=${page}&limit=${limit}&start_date=${startDate}&is_firm_select=${firmSelected}&end_date=${endDate}&order_status=${status}`;
        const body = {
            "firm_ids": firms
        };
        return this.http.post<OrderListModel>(urlPath, body, { headers });
    }
}
