<app-top-banner title="Add Contact" />
<div class="box mb-4 xxxl:mb-6">
  <div class="mb-6 pb-6 bb-dashed flex justify-between items-center">
    <h4 class="h4">Add New Contact</h4>
    <app-options-horiz />
  </div>
  <form class="grid grid-cols-2 gap-4 xxxl:gap-6">
    <div class="col-span-2 md:col-span-1">
      <label for="name" class="mb-4 md:text-lg font-medium block"> Account Holder Name </label>
      <input type="text" class="w-full text-sm bg-secondary1/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-3 md:px-6 py-2 md:py-3" placeholder="Enter name" id="name" required />
    </div>
    <div class="col-span-2 md:col-span-1">
      <label for="account" class="mb-4 md:text-lg font-medium block"> Account Number </label>
      <input type="number" class="w-full text-sm bg-secondary1/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-3 md:px-6 py-2 md:py-3" placeholder="Enter Account" id="account" required />
    </div>

    <div class="col-span-2 md:col-span-1">
      <label for="number" class="md:text-lg font-medium block mb-4"> Phone Number </label>
      <input type="number" class="w-full text-sm bg-secondary1/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-3 md:px-6 py-2 md:py-3" placeholder="Enter Number" id="number" required />
    </div>
    <div class="col-span-2 md:col-span-1">
      <label for="currency" class="mb-4 md:text-lg font-medium block"> Currency </label>
      <app-dropdown [options]="currencies" btnClassProps="w-full lg:!py-3 bg-secondary1/5 rounded-3xl" dropdownClassProps="w-full"/>
    </div>
    <div class="col-span-2 md:col-span-1">
      <label for="rate" class="md:text-lg font-medium block mb-4"> Interest Rate </label>
      <input type="number" class="w-full text-sm bg-secondary1/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-3 md:px-6 py-2 md:py-3" placeholder="Enter Rate %" id="rate" required />
    </div>
    <div class="col-span-2 md:col-span-1">
      <label for="date" class="md:text-lg font-medium block mb-4"> Expiry Date </label>
      <div class="relative bg-secondary1/5 py-2.5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl">
        <p-calendar [(ngModel)]="date" styleClass="bg-white" name="date"  placeholder="Select Date" inputStyleClass="px-5 focus:outline-none !bg-secondary1/5 dark:!bg-bg3 w-full rounded-none focus:shadow-none active:shadow-none" panelStyleClass="bg-n0 dark:bg-bg3 sm:p-3 rounded-lg"></p-calendar>
        <i class="las la-calendar absolute ltr:right-4 rtl:left-4 top-1/2 -translate-y-1/2 cursor-pointer"></i>
      </div>
    </div>
    <div class="col-span-2 md:col-span-1">
      <label for="deposits" class="md:text-lg font-medium block mb-4"> Current Deposits </label>
      <input type="number" class="w-full text-sm bg-secondary1/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-3 md:px-6 py-2 md:py-3" placeholder="Enter Deposits" id="deposits" required />
    </div>
    <div class="col-span-2 flex gap-4 md:gap-6 mt-2">
      <button class="btn" type="submit">Create Account</button>
      <button class="btn-outline" type="reset">Cancel</button>
    </div>
  </form>
</div>
