<div class="grid grid-cols-12 gap-4 xxl:gap-6">
  <div class="box col-span-12">
    <div class="flex justify-between items-center gap-4 flex-wrap bb-dashed mb-4 pb-4 lg:mb-6 lg:pb-6">
      <h4 class="h4">Müşteriler Listesi</h4>
      <div class="flex items-center gap-4 flex-wrap grow sm:justify-end">
        <button (click)="createCustomerModal()" class="btn shrink-0 add-account-btn">Müşteri Oluştur</button>
      </div>
    </div>
    <div class="overflow-x-auto pb-4 lg:pb-6">
      <table class="w-full whitespace-nowrap" id="payment-account">
        <thead>
          <tr class="bg-secondary1/5 dark:bg-bg3">
            <th class="text-left p-5">
              <div class="searcHead">
                <p>İsim</p>
                <div
                  class="mt-2 bg-primary/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-3  py-2 relative">
                  <input [(ngModel)]="searchCustomerName" class="w-11/12 text-sm bg-transparent p-0 border-none"
                    type="text">
                  <span class="absolute eye-icon ltr:right-5 rtl:left-5 top-1/2 -translate-y-1/2">
                    <i class="las la-search"></i>
                  </span>
                </div>
              </div>
            </th>
            <th class="text-left p-5">
              <div class="searcHead">
                <p>İl</p>
                <div
                  class="mt-2 bg-primary/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-3  py-2 relative">
                  <input [(ngModel)]="searchCustomerCity" class="w-11/12 text-sm bg-transparent p-0 border-none"
                    type="text">
                  <span class="absolute eye-icon ltr:right-5 rtl:left-5 top-1/2 -translate-y-1/2">
                    <i class="las la-search"></i>
                  </span>
                </div>
              </div>
            </th>
            <th class="text-left p-5">
              <div class="searcHead">
                <p>İlçe</p>
                <div
                  class="mt-2 bg-primary/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-3  py-2 relative">
                  <input [(ngModel)]="searchCustomerDistrict" class="w-11/12 text-sm bg-transparent p-0 border-none"
                    type="text">
                  <span class="absolute eye-icon ltr:right-5 rtl:left-5 top-1/2 -translate-y-1/2">
                    <i class="las la-search"></i>
                  </span>
                </div>
              </div>
            </th>
            <th class="text-left p-5">
              <div class="searcHead">
                <p>Hesap</p>
                <div
                  class="mt-2 bg-primary/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-3  py-2 relative">
                  <input [(ngModel)]="searchCustomerAccount" class="w-11/12 text-sm bg-transparent p-0 border-none"
                    type="text">
                  <span class="absolute eye-icon ltr:right-5 rtl:left-5 top-1/2 -translate-y-1/2">
                    <i class="las la-search"></i>
                  </span>
                </div>
              </div>
            </th>
            <th class="text-left p-5">
              <div class="searcHead">
                <p>Hesap Etiketi</p>
                <div
                  class="mt-2 bg-primary/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-3  py-2 relative">
                  <input [(ngModel)]="searchCustomerAccountTag" class="w-11/12 text-sm bg-transparent p-0 border-none"
                    type="text">
                  <span class="absolute eye-icon ltr:right-5 rtl:left-5 top-1/2 -translate-y-1/2">
                    <i class="las la-search"></i>
                  </span>
                </div>
              </div>
            </th>
            <th class="text-left p-5">
              <div class="searcHead">
                <p>Hesap Özeti</p>
                <div
                  class="mt-2 bg-primary/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-3  py-2 relative">
                  <input [(ngModel)]="searchAccountStatement" class="w-11/12 text-sm bg-transparent p-0 border-none"
                    type="number">
                  <span class="absolute eye-icon ltr:right-5 rtl:left-5 top-1/2 -translate-y-1/2">
                    <i class="las la-search"></i>
                  </span>
                </div>
              </div>
            </th>
            <th class="flex !py-5 min-w-[130px]" style="justify-content: center;">
              <div class="flex items-center gap-1">İşlemler</div>
            </th>
          </tr>
        </thead>
        <tbody>
          @for (item of paymentAccounts.paginatedData; track item.id;let i = $index) {
          <tr *ngFor="let item of filteredTransactionsCombined; let i = index;"
            class="even:bg-secondary1/5 dark:even:bg-bg3">
            <td class="py-2 px-6">
              <div class="flex items-center gap-3">
                <div>
                  <p class="font-medium mb-1">{{ item.account }}</p>
                </div>
              </div>
            </td>
            <td class="py-2">
              <div>
                <p class="font-medium">{{ item.currency.short }}</p>
              </div>
            </td>
            <td class="py-2">
              <div>
                <p class="font-medium">{{ item.bank.name }}</p>
              </div>
            </td>
            <td class="py-2">
              <div>
                <p class="font-medium">{{ getLocale(item.balance) }}</p>
              </div>
            </td>
            <td>
              <p class="font-medium">
                {{item.expire}}
              </p>
            </td>
            <td>
              <span class="font-medium"> {{ item.account }} </span>
            </td>
            <td class="py-4">
              <div class="flex justify-center">
                <app-options-vertical-customer />
              </div>
            </td>
          </tr>
          }
        </tbody>
      </table>
    </div>
    <div class="flex col-span-12 gap-4 sm:justify-between justify-center items-center flex-wrap">
      <p>Toplam {{
        paymentAccounts.totalData }} sayfa içerisinde {{ paymentAccounts.startIndex + 1 }} sayfayı görmektesiniz.</p>
      <ul class="flex gap-2 md:gap-3 flex-wrap md:font-semibold items-center">
        <li>
          <button [disabled]="paymentAccounts.currentPage == 1" (click)="paymentAccounts.prevPage()"
            class="hover:bg-primary text-primary rtl:rotate-180 hover:text-n0 border md:w-10 duration-300 md:h-10 w-8 h-8 flex items-center rounded-full justify-center border-primary">
            <i class="las la-angle-left text-lg"></i>
          </button>
        </li>
        <li *ngFor="let page of pages">
          <button (click)="paymentAccounts.paginate(page)"
            [ngClass]="{ 'bg-primary !text-n0': paymentAccounts.currentPage == page }"
            class="hover:bg-primary bg-primary hover:text-n0 border md:w-10 duration-300 md:h-10 w-8 h-8 flex text-primary items-center rounded-full justify-center border-primary">
            {{ page }}
          </button>
        </li>
        <li>
          <button [disabled]="paymentAccounts.currentPage == paymentAccounts.totalPages"
            (click)="paymentAccounts.nextPage()"
            class="hover:bg-primary text-primary hover:text-n0 rtl:rotate-180 border md:w-10 duration-300 md:h-10 w-8 h-8 flex items-center rounded-full justify-center border-primary">
            <i class="las la-angle-right text-lg"></i>
          </button>
        </li>
      </ul>
    </div>
  </div>


</div>