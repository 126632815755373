
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FirmResponseModel } from '../../models/firm/firm-response-model';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class FirmDataService {

    private apiUrl = `${environment.apiUrl}/info`;
    private token = localStorage.getItem('authToken');
    constructor(private http: HttpClient) { }

    allFirmData(): Observable<FirmResponseModel> {
        const headers = new HttpHeaders()
            .set('Authorization', `Bearer ${this.token}`);
        const urlPath = `${this.apiUrl}`;
        return this.http.get<FirmResponseModel>(urlPath, { headers });
    }
}
