import { TransactionStatus } from '@data/dashboards/style2Transactions'

export const latestTransactions = [
  {
    id: 9,
    dueDate: "22.02.2024",
    orderCode: "356425323",
    speciealCode: "GDRFF2",
    title: "Yılmaz Gıda ve Un Mamülleri Marketler Zinciri",
    totalAmount: "145,525,000",
    remainingAmount: "123,000",
    collectedAmount: "525,000",
    status: TransactionStatus.Pending,
  },
  {
    id: 7,
    dueDate: "26.02.2024",
    orderCode: "312425323",
    speciealCode: "GDRFF3",
    title: "Efe Gıda ve Un Mamülleri Marketler Zinciri",
    totalAmount: "145,525,000",
    remainingAmount: "532,000",
    collectedAmount: "525,000",
    status: TransactionStatus.Successful,
  },

  {
    id: 7,
    dueDate: "24.02.2024",
    orderCode: "312425323",
    speciealCode: "GDRFF1",
    title: "Efe Gıda ve Un Mamülleri Marketler Zinciri",
    totalAmount: "123,315,000",
    remainingAmount: "341,000",
    collectedAmount: "525,000",
    status: TransactionStatus.Pending,
  },

  {
    id: 10,
    dueDate: "24.02.2024",
    orderCode: "426464223",
    speciealCode: "GSTDF5",
    title: "Deneme Lokanta",
    totalAmount: "124,525,000",
    remainingAmount: "325,000",
    collectedAmount: "425,000",
    status: TransactionStatus.Cancelled,
  },

  {
    id: 11,
    dueDate: "22.02.2024",
    orderCode: "1216464223",
    speciealCode: "BJKTDF5",
    title: "Yılmaz Gıda ve Un Mamülleri Marketler Zinciri",
    totalAmount: "544,525,000",
    remainingAmount: "415,000",
    collectedAmount: "525,000",
    status: TransactionStatus.Successful,
  },
]
