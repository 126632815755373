import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ProfileUpdateModel } from '../../models/profile/profile-update-request-model';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ProfileUpdateService {

    private apiUrl = `${environment.apiUrl}/profiles/`;
    private token = localStorage.getItem('authToken');

    constructor(private http: HttpClient) { }

    updateProfileSettings(profileId: string, settings: ProfileUpdateModel): Observable<ProfileUpdateModel> {
        const headers = new HttpHeaders()
            .set('Authorization', `Bearer ${this.token}`)
            .set('Content-Type', 'application/json');
        return this.http.put<ProfileUpdateModel>(`${this.apiUrl}${profileId}`, settings, { headers });
    }
}
