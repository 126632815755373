<div class=" bg-n0 dark:bg-bg4 box overflow-y-auto max-h-[800px]">
  <div class="flex justify-end">
    <i (click)="closeModal()" class="las cursor-pointer la-times"></i>
  </div>
  <div class="">
    <div class="box">
      <div class="flex justify-between items-center bb-dashed border-secondary1/20 pb-4 mb-4 lg:pb-6 lg:mb-6">
        <h4 class="h4">Müşteri Detayı</h4>
      </div>
      <div class="flex flex-wrap gap-4 lg:gap-6" style="
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: center;
  ">
        <div>
          <div href="#" class="flex justify-between items-center gap-2">
            <img src="../../../../assets/images/qr-code.svg" alt="">
          </div>
        </div>
        <div class="flex items-center gap-3 sm:gap-4 xxl:gap-6"
          style="display: flex; flex-direction: column; align-items: flex-start;">
          <div>
            <p class="text-lg font-medium">Kod:</p>
            <span class="text-xs">63423AGFH33</span>
          </div>
          <div>
            <p class="text-lg font-medium">Servis Alanı:</p>
            <span class="text-xs">Mutfak</span>
          </div>
          <div>
            <p class="text-lg font-medium">Bölüm Adı:</p>
            <span class="text-xs">Bar</span>
          </div>
          <div>
            <p class="text-lg font-medium">Servis Alanı:</p>
            <span class="text-xs">Masa 22</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>