import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class LoginService {
    private apiUrl = `${environment.apiUrl}/auth/login`;
    constructor(private http: HttpClient) { }

    sendPhoneNumber(phoneNumber: string): Observable<any> {
        const formattedPhoneNumber = `+90${phoneNumber}`;
        return this.http.post<any>(this.apiUrl, { mobile_phone: formattedPhoneNumber }).pipe(
            catchError(this.handleError)
        );
    }

    private handleError(error: HttpErrorResponse) {
        let errorMessage = 'Something bad happened; please try again later.';
        if (error.status === 404) {
            errorMessage = 'Kullanıcı bulunamadı';
        } else if (error.error instanceof ErrorEvent) {
            errorMessage = `An error occurred: ${error.error.message}`;
        } else {
            errorMessage = `Backend returned code ${error.status}, ` +
                `message was: ${error.error.message}`;
        }
        return throwError(() => new Error(errorMessage));
    }
}

