<div class="relative shrink-0">
  <div (click)="toggleOpen()" id="profile-btn" class="w-10 cursor-pointer md:w-12">
    <img src="assets/images/user.png" class="rounded-full" width="48" height="48" alt="profile img" />
  </div>
  <div id="profile" [ngClass]="isOpen?'show':'hide'"
    class="absolute top-full z-20 rounded-md bg-n0 shadow-[0px_6px_30px_0px_rgba(0,0,0,0.08)] duration-300 dark:bg-bg4 ltr:right-0 ltr:origin-top-right rtl:left-0 rtl:origin-top-left">
    <div class="flex flex-col items-center border-b p-3 text-center dark:border-n500 lg:p-4">
      <img src="assets/images/user.png" width="60" height="60" class="rounded-full" alt="profile img" />
      <h6 class="h6 mt-2 text-sm">{{firmData.full_name}}</h6>
      <span class="text-sm">+90 {{firmData.phone}}</span>
    </div>
    <ul class="flex w-[250px] flex-col p-4">
      <!-- <li>
        <a routerLink="/settings/profile"
          class="flex items-center gap-2 rounded-md p-2 duration-300 hover:bg-primary hover:text-n0">
          <span>
            <i class="las la-user mt-1 text-xl"></i>
          </span>
          Profile
        </a>
      </li>
      <li>
        <a routerLink="/private/chat"
          class="flex items-center gap-2 rounded-md p-2 duration-300 hover:bg-primary hover:text-n0">
          <span>
            <i class="las la-envelope mt-1 text-xl"></i>
          </span>
          Messages
        </a>
      </li>
      <li>
        <a routerLink="/support/help-center"
          class="flex items-center gap-2 rounded-md p-2 duration-300 hover:bg-primary hover:text-n0">
          <span>
            <i class="las la-life-ring mt-1 text-xl"></i>
          </span>
          Help
        </a>
      </li>
      <li>
        <a routerLink="/settings/security"
          class="flex items-center gap-2 rounded-md p-2 duration-300 hover:bg-primary hover:text-n0">
          <span>
            <i class="las la-cog mt-1 text-xl"></i>
          </span>
          Settings
        </a>
      </li> -->
      <li>
        <a (click)="logOut()" class="exitButton btn flex items-center gap-2 rounded-md p-2 duration-300">
          <span>
            <i class="las la-sign-out-alt mt-1 text-xl"></i>
          </span>
          Çıkış
        </a>
      </li>
    </ul>
  </div>
</div>