import { Component } from '@angular/core'
import { providersTabData } from '@data/payment/providers'
import { PasswordInputComponent } from '@component/shared/password-input/password-input.component'
import { DropdownComponent } from '@component/shared/dropdown/dropdown.component'
import { ModalService } from 'ngx-modal-ease'
import { TableService } from '@service/table.service'
import { paymentAccountData } from '@data/accounts/paymentAccount'
import { CommonModule } from '@angular/common'
import { OptionsVerticalBranchesComponent } from '../options-vertical-branches/options-vertical-branches.component'
import { AddBranchesModalComponent } from '../add-branches-modal/ add-branches-modal.component'
import { BaranchListService } from '@service/branches/bracnhs-list-service'
import { BranchListModel } from '../../../models/branches/bracnhs-list-model'

@Component({
  selector: 'app-branches-area',
  standalone: true,
  imports: [PasswordInputComponent, DropdownComponent, OptionsVerticalBranchesComponent, CommonModule],
  templateUrl: './branches-area-component.html'
})
export class BranchesAreaComponent {
  paymentAccounts
  providers = providersTabData
  activeTab = this.providers[0].title
  branchesData!:BranchListModel;

  constructor(private modalService: ModalService,private branchService:BaranchListService) {
    this.paymentAccounts = new TableService()
    this.paymentAccounts.initialize(paymentAccountData, 10)
  }

  branchesAdd() {
    this.modalService.open(AddBranchesModalComponent, {
      modal: {
        enter: 'enter-going-down 0.3s ease-out',
        leave: 'fade-out 0.5s'
      },
      overlay: {
        leave: 'fade-out 0.5s'
      },
      data: {
        type: 'Angular modal library'
      }
    })
  }

  ngOnInit(): void {
    this.branchService.allData().subscribe(
      response => {
        this.branchesData = response;
      },
      error => {
        console.error('Hata:', error);
      }
    );
  }
}
