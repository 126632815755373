import { Component } from '@angular/core'
import { providersTabData } from '@data/payment/providers'
import { PasswordInputComponent } from '@component/shared/password-input/password-input.component'
import { DropdownComponent } from '@component/shared/dropdown/dropdown.component'
import { TopBannerComponent } from '@component/shared/top-banner/top-banner.component'
import { CategoryListModel } from '../../../models/category/category-list.model'
import { CategoryListService } from '@service/category/category-list-service'
import { CommonModule } from '@angular/common'
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { AddProductService } from '@service/products/products-add-service'
import { AddProductModel } from '../../../models/products/add-product-model'
import { ProductSuccessAddModalComponent } from '@component/shared/product-success-add-modal/product-success-add-modal.component'
import { ModalService } from 'ngx-modal-ease'
import { FormBuilder, FormGroup,Validators } from '@angular/forms'
import { ReactiveFormsModule } from '@angular/forms';


@Component({
  selector: 'app-update-product',
  standalone: true,
  imports: [PasswordInputComponent,ReactiveFormsModule, DropdownComponent, TopBannerComponent, CommonModule, DropdownModule, MultiSelectModule],
  templateUrl: './update-product.component.html'
})
export class UpdateProductComponent {
  providers = providersTabData
  activeTab = this.providers[0].title
  productForm!: FormGroup;
  productCategory = [
    "Yemek",
    "İçecek"
  ]
  unit = [
    "1",
    "2",
    "10",
    "20"
  ]

  skuBarCode = [
    '323ASFDASF232',
    '323ASFDASF232-2',
    '323ASFDASF232-3',
  ]

  categoryList: CategoryListModel[] = [];
  addProdIItem!: AddProductModel;
  selectedCategories: string[] = [];
  selectedProdType: string[] = [];

  productTypeEnum = [
    {
      code: "PHYSICALLY",
      title: "Fiziksel",
    },
    {
      code: "VIRTUAL",
      title: "Sanal",
    },
    {
      code: "INFO",
      title: "Bilgi",
    },
    {
      code: "MD",
      title: "MD",
    },
    {
      code: "DSN",
      title: "DSN",
    },
  ];

  unitTypeEnum = [
    {
      code: "ADET",
      title: "Adet"
    },
    {
      code: "KG",
      title: "Kg"
    },
    {
      code: "GR",
      title: "Gr"
    },
    {
      code: "LT",
      title: "Lt"
    },
    {
      code: "MT",
      title: "Mt"
    },
    {
      code: "KOLI",
      title: "Koli"
    },
    {
      code: "PAKET",
      title: "Paket"
    },
  ];

  constructor(private categoryListService: CategoryListService, 
    private addProdService: AddProductService,
    private modalService: ModalService,
    private fb: FormBuilder) { }

  ngOnInit(): void {
    this.fetchCategories();
    this.productForm = this.fb.group({
      category: ['', Validators.required],
      productName: ['', Validators.required],
      productType: ['', Validators.required],
      unit: ['', Validators.required],
      productPrice: ['', Validators.required],
      vatRate: ['', Validators.required],
      productPrice2: ['', Validators.required],
      productPrice3: ['', Validators.required],
      purchasePrice: ['', Validators.required],
      productCode: ['', Validators.required],
      sku: ['', Validators.required],
      stock: ['', Validators.required],
      description: ['', Validators.required],
      criticStock: ['', Validators.required]
    });
  }

  onSelectChange(event: any) {
    const selectElement = event.target as HTMLSelectElement;
    const selectedOptions = Array.from(selectElement.selectedOptions).map(option => option.value);
    this.selectedProdType = selectedOptions;
  }

  onCategoryChange(event: any) {
    const selectedCodes = event.value.map((item: any) => item.id);
    this.selectedCategories = selectedCodes
  }

  fetchCategories(): void {
    this.categoryListService.allCategories().subscribe(
      (response: CategoryListModel[]) => {
        this.categoryList = response;
      },
      error => {
        console.error('Error fetching categories:', error);
      }
    );
  }


  onCancel() {
    this.productForm.reset();
  }


  onSubmit() {
    // console.log(this.productForm.value);
  }

  addProdTap():void{
   if(this.productForm.invalid){
    this.productForm.markAllAsTouched();
    return;
   }

   this.addProdService.addProduct(
    this.productForm.value.productCode,
    this.productForm.value.productName,
    this.productForm.value.sku,
    this.productForm.value.description,
    this.productForm.value.unit,
    this.productForm.value.productType,
    this.productForm.value.vatRate,
    this.productForm.value.purchasePrice,
    this.productForm.value.productPrice,
    this.productForm.value.productPrice2,
    this.productForm.value.productPrice3,
    this.productForm.value.criticStock,
    this.productForm.value.stock,
    false,
    "ALL",
    null,
    this.selectedCategories,
  ).subscribe(
    (response: AddProductModel) => {
      this.addProdIItem = response;
      console.log(this.addProdIItem);
      this.modalService.open(ProductSuccessAddModalComponent, {
        modal: {
          enter: 'enter-going-down 0.3s ease-out',
          leave: 'fade-out 0.5s'
        },
        overlay: {
          leave: 'fade-out 0.5s'
        },
        data: {
          type: 'Angular modal library'
        }
      });
      setTimeout(() => {
        this.modalService.close(); 
        this.productForm.reset(); 
      }, 3000);
    },
    error => {
      console.error('Error fetching categories:', error);
    }
  );
  }
}
