<div class="box xxl:p-8 xxxl:p-10">
  <div class="row" style="display: flex; flex-direction: row-reverse;">
    <button (click)="workingHoursAdd()" class="flex justify-end btn shrink-0 add-account-btn mb-4">Yeni Çalışma Saati
      Ekle</button>
  </div>
  <form class="xl:mt-8 gap-4 xxxxxl:gap-6">


    <div class="flex gap-6 hoursItem">
      <div class="workHeader" *ngFor="let dayKey of WorkingDaysEnumValues;let i = index">
        <label for="mondayOpen" class="md:text-lg font-medium block mb-4">
          {{ weekOfDays[i] || '' }}
        </label>
        <div class="workSection">
          <div *ngFor="let interval of workingHoursByDay[dayKey.key]?.time_intervals || []" class="flex gap-6 mb-4">
            <div class="flex-1">
              <div class="bg-primary/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-3 md:px-6 py-2 md:py-3 relative">
                <input type="time" disabled
                  [value]="interval.start || ''"
                  class="w-full text-sm bg-transparent p-0 border-none"
                  [name]="dayKey.key + 'Open'" [id]="dayKey.key + 'Open'" />
                <span class="absolute eye-icon ltr:right-5 rtl:left-5 top-1/2 -translate-y-1/2 cursor-pointer">
                  <i class="las la-clock"></i>
                </span>
              </div>
            </div>
      
            <div class="flex-1">
              <div class="bg-primary/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-3 md:px-6 py-2 md:py-3 relative">
                <input type="time" disabled
                  [value]="interval.end || ''"
                  class="w-full text-sm bg-transparent p-0 border-none"
                  [name]="dayKey.key + 'Close'" [id]="dayKey.key + 'Close'" />
                <span class="absolute eye-icon ltr:right-5 rtl:left-5 top-1/2 -translate-y-1/2 cursor-pointer">
                  <i class="las la-clock"></i>
                </span>
              </div>
            </div>
            <i 
            (click)="onDelete(dayKey.value)" 
            class="las la-trash-alt delete-icon">
          </i>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>