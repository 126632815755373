<div class="grid grid-cols-12 gap-4 xxl:gap-6">
  <div class="col-span-12 md:col-span-7 xxl:col-span-8">
    <div class="grid grid-cols-12 gap-4 xxl:gap-6 p-4 box min-[1880px]:p-6 mb-4 xxl:mb-6">
      <div
        class="col-span-12 cursor-pointer border border-dashed qt-modal-btn duration-300 border-transparent hover:border-primary lg:col-span-6 xxxl:col-span-3 box bg-primary/5 dark:bg-bg3 flex items-center gap-3 3xl:gap-6 xl:p-3 min-[1880px]:p-6">
        <div>
          <h5 class="text-base xxl:text-md xl:text-xl font-medium mb-1 ">Sipariş Kodu:</h5>
          <span class="text-xs xxl:text-sm"> {{orderDetailData.order_code.toString() || ''}} </span>
        </div>
      </div>
      <div
        class="col-span-12 cursor-pointer border border-dashed qt-modal-btn duration-300 border-transparent hover:border-primary lg:col-span-6 xxxl:col-span-3 box bg-primary/5 dark:bg-bg3 flex items-center gap-3 3xl:gap-6 xl:p-3 min-[1880px]:p-6">
        <div>
          <h5 class="text-base xxl:text-md xl:text-xl font-medium mb-1 ">Kullanıcı:</h5>
          <span class="text-xs xxl:text-sm"> {{orderDetailData.customer.name}} </span>
        </div>
      </div>
      <div
        class="col-span-12 cursor-pointer border border-dashed qt-modal-btn duration-300 border-transparent hover:border-primary lg:col-span-6 xxxl:col-span-3 box bg-primary/5 dark:bg-bg3 flex items-center gap-3 3xl:gap-6 xl:p-3 min-[1880px]:p-6">
        <div>
          <h5 class="text-base xxl:text-md xl:text-xl font-medium mb-1 ">Sipariş Durumu:</h5>
          @if(orderDetailData.status == 'SUCCESS'){
          <span class="text-xs xxl:text-sm"> Başarılı </span>
          }@else if(orderDetailData.status == "WAITING"){
          <span class="text-xs xxl:text-sm"> Bekliyor </span>
          }@else{
          <span class="text-xs xxl:text-sm"> Başarısız </span>
          }
        </div>
      </div>
      <div (click)="generatePdf()"
        class="download-button col-span-12 cursor-pointer qt-modal-btn duration-300 border-transparent hover:border-primary lg:col-span-6 xxxl:col-span-3 box bg-primary/5 dark:bg-bg3 flex items-center gap-3 3xl:gap-6 xl:p-3 min-[1880px]:p-6">
        <a>Dışarı Aktar </a>
        <i class="fa-solid fa-download"></i>
      </div>

    </div>

    <div class="grid grid-cols-12 gap-4 xxl:gap-6 p-4 box min-[1880px]:p-6 mb-4 xxl:mb-6">
      <div
        class="col-span-12 cursor-pointer border border-dashed qt-modal-btn duration-300 border-transparent hover:border-primary lg:col-span-6 xxxl:col-span-4 box bg-primary/5 dark:bg-bg3 flex items-center gap-4 4xl:gap-6 xl:p-3 min-[1880px]:p-6">
        <div>
          <h5 class="text-base xxl:text-md 4xl:text-xl font-medium mb-1 ">Özel Kodu:</h5>
          <span class="text-xs xxl:text-sm"> {{orderDetailData.special_code}} </span>
        </div>
      </div>
      <div
        class="col-span-12 cursor-pointer border border-dashed qt-modal-btn duration-300 border-transparent hover:border-primary lg:col-span-6 xxxl:col-span-4 box bg-primary/5 dark:bg-bg3 flex items-center gap-4 4xl:gap-6 xl:p-3 min-[1880px]:p-6">
        <div>
          <h5 class="text-base xxl:text-md 4xl:text-xl font-medium mb-1 ">Sipariş Tarihi:</h5>
          <span class="text-xs xxl:text-sm">{{orderDetailData.order_date}} </span>
        </div>
      </div>
      <div
        class="col-span-12 cursor-pointer border border-dashed qt-modal-btn duration-300 border-transparent hover:border-primary lg:col-span-6 xxxl:col-span-4 box bg-primary/5 dark:bg-bg3 flex items-center gap-4 4xl:gap-6 xl:p-3 min-[1880px]:p-6">
        <div>
          <h5 class="text-base xxl:text-md 4xl:text-xl font-medium mb-1 ">Ödeme Tipi:</h5>
          <span class="text-xs xxl:text-sm">{{orderDetailData.payment_method}} / {{orderDetailData.payment_type}} </span>
        </div>
      </div>
      <div
        class="col-span-12 cursor-pointer border border-dashed qt-modal-btn duration-300 border-transparent hover:border-primary lg:col-span-6 xxxl:col-span-4 box bg-primary/5 dark:bg-bg3 flex items-center gap-4 4xl:gap-6 xl:p-3 min-[1880px]:p-6">
        <div>
          <h5 class="text-base xxl:text-md 4xl:text-xl font-medium mb-1 ">Kısmi:</h5>
          @if(orderDetailData.is_partial == false){
          <span class="text-xs xxl:text-sm"> Hayır</span>
          }@else{
          <span class="text-xs xxl:text-sm">Evet </span>
          }
        </div>
      </div>
      <div
        class="col-span-12 cursor-pointer border border-dashed qt-modal-btn duration-300 border-transparent hover:border-primary lg:col-span-6 xxxl:col-span-4 box bg-primary/5 dark:bg-bg3 flex items-center gap-4 4xl:gap-6 xl:p-3 min-[1880px]:p-6">
        <div>
          <h5 class="text-base xxl:text-md 4xl:text-xl font-medium mb-1 ">Sipariş Kaynağı:</h5>
          <span class="text-xs xxl:text-sm">{{orderDetailData.payment_type}} </span>
        </div>
      </div>
      <div
        class="col-span-12 cursor-pointer border border-dashed qt-modal-btn duration-300 border-transparent hover:border-primary lg:col-span-6 xxxl:col-span-4 box bg-primary/5 dark:bg-bg3 flex items-center gap-4 4xl:gap-6 xl:p-3 min-[1880px]:p-6">
        <div>
          <h5 class="text-base xxl:text-md 4xl:text-xl font-medium mb-1 ">Taksit:</h5>
          <span class="text-xs xxl:text-sm">{{orderDetailData.installment}} </span>
        </div>
      </div>
      <div
        class="col-span-12 cursor-pointer border border-dashed qt-modal-btn duration-300 border-transparent hover:border-primary lg:col-span-6 xxxl:col-span-4 box bg-primary/5 dark:bg-bg3 flex items-center gap-4 4xl:gap-6 xl:p-3 min-[1880px]:p-6">
        <div>
          <h5 class="text-base xxl:text-md 4xl:text-xl font-medium mb-1 ">Belge Türü:</h5>
          <span class="text-xs xxl:text-sm">{{orderDetailData.customer.invoice_type}} </span>
        </div>
      </div>
      <div
        class="col-span-12 cursor-pointer border border-dashed qt-modal-btn duration-300 border-transparent hover:border-primary lg:col-span-6 xxxl:col-span-4 box bg-primary/5 dark:bg-bg3 flex items-center gap-4 4xl:gap-6 xl:p-3 min-[1880px]:p-6">
        <div>
          <h5 class="text-base xxl:text-md 4xl:text-xl font-medium mb-1 ">Ettn:</h5>
          <span class="text-xs xxl:text-sm">{{ orderDetailData.transactions?.[0]?.invoice_info?.ettn || 'Bulunamadı' }}</span>
        </div>
      </div>
    </div>

    <div class="box col-span-12 lg:col-span-6">
      <div class="flex flex-wrap gap-4 justify-between items-center bb-dashed mb-4 pb-4 lg:mb-6 ">
        <h4 class="h4">Ürünler</h4>
      </div>
      <div class="overflow-x-auto mb-4 lg:mb-6">
        <table class="w-full whitespace-nowrap">
          <thead>
            <tr class="bg-primary/5 dark:bg-bg3">
              <th class="text-start py-5 px-6 cursor-pointer min-w-[250px]">
                <div class="flex items-center gap-1">Ürün Adı
                </div>
              </th>
              <th class="text-start py-5 min-w-[100px]">Birim
              </th>
              <th class="text-start py-5 min-w-[130px]">Adet
              </th>
              <th class="text-start py-5 min-w-[130px]">Toplam Tutar
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              class="hover:bg-primary/5 dark:hover:bg-bg3 duration-500 border-b border-n30 dark:border-n500 first:border-t"
              *ngFor="let item of orderDetailData.products; let i = index">
              <td class="py-5 px-6">
                <div class="flex items-center gap-3">
                  <span class="font-medium">{{ item.title }}</span>
                </div>
              </td>
              <td class="py-5">
                <p class="font-medium">{{ item.unit }}</p>
              </td>
              <td class="py-5">
                <p class="font-medium">{{ item.quantity }}</p>
              </td>
              <td class="py-5">{{ item.grand_total }} ₺</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="box col-span-12 lg:col-span-6 mt-4">
      <div class="flex flex-wrap gap-4 justify-between items-center bb-dashed mb-4 pb-4 lg:mb-6 ">
        <h4 class="h4">İşlemler</h4>
      </div>
      <div class="overflow-x-auto mb-4 lg:mb-6">
        <table class="w-full whitespace-nowrap">
          <thead>
            <tr class="bg-primary/5 dark:bg-bg3">
              <th class="text-start py-5 px-6 cursor-pointer min-w-[250px]">
                <div class="flex items-center gap-1">Ödeme Tipi</div>
              </th>
              <th class="text-start py-5 min-w-[100px]">Tarih</th>

              <th class="text-start py-5 min-w-[130px]">Client IP</th>
              <th class="text-start py-5 min-w-[130px]">Toplam Tutar</th>
            </tr>
          </thead>
          <tbody>
            @for (item of orderDetailData.transactions; track item.id;let i = $index) {
            <tr
              class="hover:bg-primary/5 dark:hover:bg-bg3 duration-500 border-b border-n30 dark:border-n500 first:border-t">
              <td class="py-5 px-6">
                <div class="flex items-center gap-3">
                  <span class="font-medium"> {{ item.payment_type }} </span>
                </div>
              </td>
              <td class="py-5">
                <p class="font-medium">{{ item.document_date | date:'dd/MM/yyyy HH:mm'}}</p>
              </td>
              <td class="py-5">
                <p class="font-medium">{{ item.client_ip }}</p>
              </td>
              <td class="py-5">{{ item.total_amount }} ₺</td>
            </tr>
            }
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="col-span-12 md:col-span-5 xxl:col-span-4">

    <div class="box">
      <div class="flex justify-between items-center bb-dashed border-secondary1/20 pb-4 mb-4  lg:mb-6">
        <h4 class="h4">Müşteri Detayı</h4>
      </div>
      <ul class="flex flex-col gap-4 lg:gap-6">
        <li>
          <a  class="flex justify-between items-center gap-2">
            <div class="flex items-center gap-3 sm:gap-4 xxl:gap-6">
              <span
                class="circleAvatar border border-n30 dark:border-n500 bg-primary/5 w-10 h-10 flex items-center justify-center xxl:w-14 xxl:h-14 shrink-0 rounded-full">
                <img src="../../../../assets/icons/user.svg" alt="">
              </span>
              <div>
                <p class="text-md font-medium">Müşteri Adı:</p>
                <span class="text-xs"> {{ orderDetailData.customer.name }}</span>
              </div>
            </div>
          </a>
        </li>
        <li>
          <a  class="flex justify-between items-center gap-2">
            <div class="flex items-center gap-3 sm:gap-4 xxl:gap-6">
              <span
                class="circleAvatar border border-n30 dark:border-n500 bg-primary/5 w-10 h-10 flex items-center justify-center xxl:w-14 xxl:h-14 shrink-0 rounded-full">
                <img src="../../../../assets/icons/phone.svg" alt="">
              </span>
              <div>
                <p class="text-md font-medium">İletişim Bilgileri:</p>
                <span class="text-xs"> {{ orderDetailData.customer.phone }} <br>{{ orderDetailData.customer.email }}</span>
              </div>
            </div>
          </a>
        </li>
        <li>
          <a  class="flex justify-between items-center gap-2">
            <div class="flex items-center gap-3 sm:gap-4 xxl:gap-6">
              <span
                class="circleAvatar border border-n30 dark:border-n500 bg-primary/5 w-10 h-10 flex items-center justify-center xxl:w-14 xxl:h-14 shrink-0 rounded-full">
                <img src="../../../../assets/icons/customer-code.svg" alt="">
              </span>
              <div>
                <p class="text-md font-medium">Müşteri Kodu:</p>
                <span class="text-xs">{{ orderDetailData.customer.code }} </span>
              </div>
            </div>
          </a>
        </li>
        <li>
          <a  class="flex justify-between items-center gap-2">
            <div class="flex items-center gap-3 sm:gap-4 xxl:gap-6">
              <span
                class="circleAvatar border border-n30 dark:border-n500 bg-primary/5 w-10 h-10 flex items-center justify-center xxl:w-14 xxl:h-14 shrink-0 rounded-full">
                <img src="../../../../assets/icons/localization.svg" alt="">
              </span>
              <div>
                <p class="text-md font-medium">Vergi Dairesi:</p>
                <span class="text-xs">{{ orderDetailData.customer.tax_office }} </span>
              </div>
            </div>
          </a>
        </li>
      </ul>
    </div>

    <div class="box mt-4">
      <div class="flex justify-between items-center bb-dashed border-secondary1/20 pb-4 mb-4  lg:mb-6">
        <h4 class="h4">Fatura Detayı</h4>
      </div>
      <ul class="flex flex-col gap-4 lg:gap-6">
        <li>
          <div class="flex items-center gap-3 sm:gap-4 xxl:gap-6">
            <p class="text-md font-medium">Ara Toplam:</p>
            <p class="text-m">{{ orderDetailData.sub_total }} ₺</p>
          </div>
        </li>
        <li>
          <div class="flex items-center gap-3 sm:gap-4 xxl:gap-6">
            <p class="text-md font-medium">KDV Tutarı :</p>
            <p class="text-m">{{ orderDetailData.tax_amount }} ₺</p>
          </div>
        </li>
        <li>
          <div class="flex items-center gap-3 sm:gap-4 xxl:gap-6">
            <p class="text-md font-medium">İndirim:</p>
            <p class="text-m">{{ orderDetailData.discount_amount }} ₺</p>
          </div>
        </li>
        <li>
          <div class="flex items-center gap-3 sm:gap-4 xxl:gap-6">
            <p class="text-md font-medium">Genel Toplam:</p>
            <p class="text-m">{{ orderDetailData.grand_total }} ₺</p>
          </div>
        </li>
        <li>
          <div class="flex items-center gap-3 sm:gap-4 xxl:gap-6">
            <p class="text-md font-medium">Alacak Miktarı:</p>
            <p class="text-m">{{ orderDetailData.amount_due }} ₺</p>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>