import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ProfileUserModel } from '../../models/profile/profile-user.mode';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ProfileUserService {

    private apiUrl = `${environment.apiUrl}/info/users`;
    private token = localStorage.getItem('authToken');
    constructor(private http: HttpClient) { }

    allProfilesUser(): Observable<ProfileUserModel[]> {
        const headers = new HttpHeaders()
            .set('Authorization', `Bearer ${this.token}`,)
        return this.http.get<ProfileUserModel[]>(this.apiUrl,{headers});
    }

}
