<div class="box p-3 md:p-4 xl:p-6 grid grid-cols-12  items-center loginArea"
  style="padding: 0 !important; border-radius: 0 !important; background: transparent !important;">

  <form [formGroup]="phoneForm" id="loginForm" (ngSubmit)="submitForm()" class="col-span-12 lg:col-span-6">
    <img src="../../../../assets/images/logo-with-text.png" style="margin-left: 2rem; margin-bottom: 2rem;">
    <div class="dark:bg-bg3 lg:p-6" style="width: 100%;">
      <h3 class="h3 mb-2">Giriş Yap</h3>
      <p class="md:mb-6 md:pb-6 mb-4 pb-4 text-sm md:text-base">İnternet Şubemize Hoşgeldiniz.</p>
      <div class="mb-4 relative">
        <input formControlName="phoneNumber" type="tel"
          class="w-full pl-16 mb-1 text-sm bg-n0 dark:bg-bg4 border border-n30 dark:border-n500 rounded-3xl px-3 md:px-8 py-2 md:py-3 relative"
          placeholder="Telefon Numaranız" id="phoneNumber" maxlength="11" />
        <span class="circleFlag left-1 transform -translate-y-1/2 absolute top-1/2">
          <img src="../../../../assets/images/turkey-circle.png" width="25px" alt="Türk Bayrağı">
        </span>
      </div>
      <div *ngIf="phoneForm.get('phoneNumber')?.errors && phoneForm.get('phoneNumber')?.touched">
        <span class="text-secondary2 text-sm" *ngIf="phoneForm.get('phoneNumber')?.errors?.['required']">Telefon Zorunludur</span>
        <span class="text-secondary2 text-sm" *ngIf="phoneForm.get('phoneNumber')?.errors?.['minlength']">Telefon numarası 11 haneli olmalıdır</span>
        <span class="text-secondary2 text-sm" *ngIf="phoneForm.get('phoneNumber')?.errors?.['maxlength']">Telefon numarası 11 haneli olmalıdır</span>
        <span class="text-secondary2 text-sm" *ngIf="phoneForm.get('phoneNumber')?.errors?.['pattern']">Geçersiz Telefon Numarası</span>
      </div>
      <div class="mt-8 customButton">
        <button type="submit" class="btn px-5">Giriş Yap</button>
        <button class="app-page">Başvuru Yap</button>
      </div>
      <div class="copyLogin">
        <img src="../../../../assets/images/paycell.svg">
        <p>Pluspay Teknoloji Hizmetleri Anonim Şirketi, T.C. Merkez Bankası (TCMB)'nin iznine sahip Paycell Ödeme Kuruluşu'nun entegratörüdür.</p>
      </div>
    </div>
  </form>
  
  <div class="col-span-12 lg:col-span-6 imageLogos">
  </div>
</div>

<div class="messageStore">
  <div class="store-buttons">
    <div class="storeArea">
      <img class="logos" src="../../../../assets/images/logo-with-text-dark.png">
      <p>
        Mobil olarak<br>
        kullanmak için uygulamamızı
        indirin.
      </p>
    </div>
    <div class="storeArea">
      <a href="#" target="_blank" class="store-button apple">
        <img src="../../../../assets/images/app-store.png" alt="Download on the App Store">
      </a>
      <a href="#" target="_blank" class="store-button apple">
        <img src="../../../../assets/images/google-play.png" alt="Download on the App Store">
      </a>
    </div>

    <span>© 2024 PlusPay. Tüm Hakları Saklıdır.</span>
  </div>
</div>