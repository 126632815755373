export const providersTabData = [
  {
    id:'1',
    title: "Temel Ayarlar",
    icon: "las la-angle-right text-lg",
  },
  {
    id:'2',
    title: "Çalışma Saatlari",
    icon: "las la-angle-right text-lg",
  },
  {
    id:'3',
    title: "Servis Alanı",
    icon: "las la-angle-right text-lg",
  },
  {
    id:'4',
    title: "Bölümler",
    icon: "las la-angle-right text-lg",
  },
  {
    id:'5',
    title: "Kısa Kodlar",
    icon: "las la-angle-right text-lg",
  },
  {
    id:'6',
    title: "Yazıcı Ayarları",
    icon: "las la-angle-right text-lg",
  },
  {
    id:'7',
    title: "Api",
    icon: "las la-angle-right text-lg",
  },
];
