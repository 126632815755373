import { NgClass } from '@angular/common';
import { Component, HostListener, Input } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EditWalletModalComponent } from '@component/shared/edit-wallet-modal/edit-wallet-modal.component'
import { ModalService } from 'ngx-modal-ease'
import { DeleteProductModalComponent } from '../delete-product-modal/delete-product-modal.component';

@Component({
  selector: 'app-options-vertical-product',
  standalone: true,
  imports: [NgClass,RouterModule],
  templateUrl: './options-vertical-product.component.html'
})
export class OptionsVerticalProductComponent {
  isOpen = false

  @Input() selectedProdId!: any;

  toggleOpen(event:MouseEvent) {
    const allDropdowns = document.querySelectorAll('.dropdown-verti');
    allDropdowns.forEach(dropdown => {
        if (dropdown !== event.target) {
            dropdown.classList.remove('show');
            dropdown.classList.add('hide');
        }
    });
    this.isOpen = !this.isOpen
  }

  constructor(private modalService: ModalService){}

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
  const targetElement = event.target as Element;

    if (targetElement && !targetElement.closest('#dropdown-verti')) {
      this.isOpen = false;
    }
  }

  editWalletModal() {
    this.modalService.open(EditWalletModalComponent, {
      modal: {
        enter: 'enter-going-down 0.3s ease-out',
        leave: 'fade-out 0.5s'
      },
      overlay: {
        leave: 'fade-out 0.5s'
      },
      data: {
        type: 'Angular modal library'
      }
    })
  }

  deleteProdModal() {
    this.modalService.open(DeleteProductModalComponent, {
      modal: {
        enter: 'enter-going-down 0.3s ease-out',
        leave: 'fade-out 0.5s'
      },
      overlay: {
        leave: 'fade-out 0.5s'
      },
      data: {
        type: 'Angular modal library',
        product: this.selectedProdId
      }
    });
  }
}
