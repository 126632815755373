import { CommonModule } from '@angular/common'
import { ChangeDetectorRef, Component } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { DropdownComponent } from '@component/shared/dropdown/dropdown.component'
import { OptionsHorizComponent } from '@component/shared/options-horiz/options-horiz.component'
import { TopBannerComponent } from '@component/shared/top-banner/top-banner.component'
import { CalendarModule } from 'primeng/calendar';
import { recentPaymentData } from '@data/payments/recentPayments'
import { TableService } from '@service/table.service'
import { ActivatedRoute, NavigationEnd, Router, } from '@angular/router'
import { TransActionListData, TransactionListModel } from '../../../models/transaction-model/transactions-list.model'
import { TransactionAllService } from '@service/transaction/transactions-all.service'
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';

@Component({
  selector: 'app-collection-detail',
  standalone: true,
  imports: [DropdownComponent, CommonModule, TopBannerComponent, OptionsHorizComponent, CalendarModule, FormsModule],
  templateUrl: './collection-detail.component.html'
})

export class CollectionDetailComponent {
  date: Date | undefined
  recentPayments
  id!: string;

  ordersData = [
    {
      id: 1,
      title: 'Sipariş Kodu:',
      desc: '#AAS20240402112903',
      icon: 'las text-2xl xxl:text-3xl la-exchange-alt'
    },
    {
      id: 2,
      title: 'Kullanıcı:',
      desc: 'Bireysel Kullanıcı',
      icon: 'las text-2xl xxl:text-3xl la-qrcode'
    },
    {
      id: 3,
      title: 'Sipariş Durumu:',
      desc: 'Başarılı',
      icon: 'lab text-2xl xxl:text-3xl la-paypal'
    }
  ]
  actionsData = [
    {
      id: 1,
      title: 'Özel Kodu:',
      desc: '87823734TYEIE2',
      icon: 'las text-2xl xxl:text-3xl la-exchange-alt'
    },
    {
      id: 2,
      title: 'Sipariş Tarihi:',
      desc: '20.10.2024 11:33',
      icon: 'las text-2xl xxl:text-3xl la-qrcode'
    },
    {
      id: 3,
      title: 'Ödeme Tipi:',
      desc: 'Pos / Kredi Kartı',
      icon: 'lab text-2xl xxl:text-3xl la-paypal'
    },
    {
      id: 3,
      title: 'Kısmi:',
      desc: 'Hayır',
      icon: 'lab text-2xl xxl:text-3xl la-paypal'
    },
    {
      id: 3,
      title: 'Sipariş Kaynağı:',
      desc: 'Web',
      icon: 'lab text-2xl xxl:text-3xl la-paypal'
    },
    {
      id: 3,
      title: 'Taksit:',
      desc: '6',
      icon: 'lab text-2xl xxl:text-3xl la-paypal'
    },
  ]

  startDate: string | null = null;
  endDate: string | null = null;
  selectedBranch: [] = [];

  detailData!: any;
  transactionData!: TransactionListModel;

  filterResponse!: TransActionListData;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private transactionList: TransactionAllService
  ) {
    const navigation = this.router.getCurrentNavigation();
    if (navigation?.extras?.state) {
      const { startDate, endDate, brach } = navigation.extras.state as {
        startDate: string;
        endDate: string;
        brach: string;
      };

      const data = {
        startDate: startDate,
        endDate: endDate,
        selectedBranch: brach
      };

      localStorage.setItem('myAppData', JSON.stringify(data));
    }

    this.recentPayments = new TableService();
    this.recentPayments.initialize(recentPaymentData, 8);
  }

  formatter = new Intl.NumberFormat('tr-TR', {
    currency: 'TRY',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });

  formatAmount(amount: number | undefined): string {
    return this.formatter.format(amount ?? 0);
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.id = params['id'];
    });

    const storedData = localStorage.getItem('myAppData');
    if (storedData) {
      const data = JSON.parse(storedData);
      this.startDate = data.startDate;
      this.endDate = data.endDate;
      this.selectedBranch = data.selectedBranch;

      this.transactionList.allTransActions(
        "1",
        "20",
        this.startDate!,
        this.endDate!,
        false,
        this.selectedBranch!
      ).subscribe(
        response => {
          if (response.data && Array.isArray(response.data)) {
            const filteredData = response.data.find((item: any) => item.id === this.id);
            this.filterResponse = filteredData;
          } else {
            console.warn('response.data bir dizi değil veya mevcut değil.');
          }

          this.transactionData = response;
          this.cdr.detectChanges();
        },
        error => {
          console.error('Hata:', error);
        }
      );
    }
  }

  convertToTurkishCharacters(text: string) {
    return text.replace(/ğ/g, 'g')
      .replace(/Ğ/g, 'G')
      .replace(/ü/g, 'ü')
      .replace(/Ü/g, 'Ü')
      .replace(/ş/g, 's')
      .replace(/Ş/g, 'S')
      .replace(/ı/g, 'i')
      .replace(/İ/g, 'I')
      .replace(/ö/g, 'ö')
      .replace(/Ö/g, 'Ö')
      .replace(/ç/g, 'c')
      .replace(/Ç/g, 'C');
  }

  generatePdf() {
    const doc = new jsPDF();



    const orderCode = this.filterResponse.order.order_code;
    const orderSpecialCode = this.filterResponse.order.special_code;
    const orderDate = this.filterResponse.order.order_date;

    const customerName = this.filterResponse.customer.name;
    const customerCode = this.filterResponse.customer.code;
    const customerMail = this.filterResponse.customer.email;
    const customerPhone = this.filterResponse.customer.phone;
    const customerCity = this.filterResponse.customer.city;
    const customerDistrict = this.filterResponse.customer.district;
    const customerTaxNumber = this.filterResponse.customer.identity;
    const customerTaxOffice = this.filterResponse.customer.tax_office;
    const customerType = this.filterResponse.customer.account_type;

    const paymentType = this.filterResponse.payment_type;
    const paymentMethod = this.filterResponse.payment_method;
    const paymentStatus = this.filterResponse.transaction_status;
    const paymentSource = this.filterResponse.source;
    const paymentTotal = this.filterResponse.total_amount;

    const summaryTotal = this.filterResponse.total_amount;

    const logo = '../../../../assets/images/logo-with-text.png';
    doc.addImage(logo, 'PNG', 10, 10, 50, 20);

    const spaceBelowLogo = 10;
    const padding = 5;
    const borderRadius = 5;
    const lineHeight = 8;

    const orderDetailText = [
      `${this.convertToTurkishCharacters('Sipariş Kodu')}: ${orderCode}`,
      `${this.convertToTurkishCharacters('Sipariş Özel Kodu')}: ${orderSpecialCode}`,
      `${this.convertToTurkishCharacters('Sipariş Tarihi')}: ${orderDate}`
    ];

    const customerDetailText = [
      `${this.convertToTurkishCharacters('İsim')}: ${customerName}`,
      `${this.convertToTurkishCharacters('Kod')}: ${customerCode}`,
      `${this.convertToTurkishCharacters('E-Posta')}: ${customerMail}`,
      `${this.convertToTurkishCharacters('Telefon')}: ${customerPhone}`,
      `${this.convertToTurkishCharacters('Şehir')}: ${this.convertToTurkishCharacters(customerCity)}`,
      `${this.convertToTurkishCharacters('İlçe')}: ${this.convertToTurkishCharacters(customerDistrict)}`,
      `${this.convertToTurkishCharacters('Vergi Numarası')}: ${customerTaxNumber}`,
      `${this.convertToTurkishCharacters('Vergi Dairesi')}: ${this.convertToTurkishCharacters(customerTaxOffice)}`,
      `${this.convertToTurkishCharacters('Hesap Tipi')}: ${this.convertToTurkishCharacters(customerType)}`
    ];

    const paymentDetailText = [
      `${this.convertToTurkishCharacters('Ödeme Tipi')}: ${paymentType}`,
      `${this.convertToTurkishCharacters('Ödeme Methodu')}: ${paymentMethod}`,
      `${this.convertToTurkishCharacters('Durum')}: ${paymentStatus}`,
      `${this.convertToTurkishCharacters('Kaynak')}: ${paymentSource}`,
      `${this.convertToTurkishCharacters('Toplam Tutar')}: ${this.formatAmount(paymentTotal)}tl`,
    ];

    const summaryText = [
      `${this.convertToTurkishCharacters('Total')}: ${this.formatAmount(summaryTotal)}tl`,
    ];

    const calculateTextHeight = (textArray: string[]) => {
      return textArray.length * lineHeight + 2 * padding;
    };

    const orderDetailHeight = calculateTextHeight(orderDetailText) + 10;
    const customerDetailHeight = calculateTextHeight(customerDetailText) + 10;
    const paymentDetailHeight = calculateTextHeight(paymentDetailText) + 10;
    const summaryHeight = calculateTextHeight(summaryText) + 10;

    const orderDetailX = 10;
    const orderDetailY = 30 + spaceBelowLogo;

    const customerDetailX = 102;
    const customerDetailY = 30 + spaceBelowLogo;

    const paymentDetailX = 10;
    const paymentDetailY = orderDetailY + orderDetailHeight + spaceBelowLogo;

    const summaryX = 102;
    const summaryY = customerDetailY + customerDetailHeight + spaceBelowLogo;

    doc.setFillColor(255, 255, 255);
    doc.roundedRect(orderDetailX, orderDetailY, 80, orderDetailHeight, borderRadius, borderRadius, 'S');

    doc.setFontSize(14);
    doc.text(this.convertToTurkishCharacters('Sipariş Detay'), orderDetailX + padding, orderDetailY + padding + 10);

    doc.setFontSize(10);
    orderDetailText.forEach((text, index) => {
      doc.text(text, orderDetailX + padding, orderDetailY + padding + 20 + (index * lineHeight));
    });

    doc.setFillColor(255, 255, 255);
    doc.roundedRect(customerDetailX, customerDetailY, 80, customerDetailHeight, borderRadius, borderRadius, 'S');

    doc.setFontSize(14);
    doc.text(this.convertToTurkishCharacters('Müşteri Detay'), customerDetailX + padding, customerDetailY + padding + 10);

    doc.setFontSize(10);
    customerDetailText.forEach((text, index) => {
      doc.text(text, customerDetailX + padding, customerDetailY + padding + 20 + (index * lineHeight));
    });

    doc.setFillColor(255, 255, 255);
    doc.roundedRect(paymentDetailX, paymentDetailY, 80, paymentDetailHeight, borderRadius, borderRadius, 'S');

    doc.setFontSize(14);
    doc.text(this.convertToTurkishCharacters('Ödeme Detay'), paymentDetailX + padding, paymentDetailY + padding + 10);

    doc.setFontSize(10);
    paymentDetailText.forEach((text, index) => {
      doc.text(text, paymentDetailX + padding, paymentDetailY + padding + 20 + (index * lineHeight));
    });

    doc.setFillColor(255, 255, 255);
    doc.roundedRect(summaryX, summaryY, 80, summaryHeight, borderRadius, borderRadius, 'S');

    doc.setFontSize(14);
    doc.text(this.convertToTurkishCharacters('Özet'), summaryX + padding, summaryY + padding + 10);

    doc.setFontSize(10);
    summaryText.forEach((text, index) => {
      doc.text(text, summaryX + padding, summaryY + padding + 20 + (index * lineHeight));
    });

    doc.save(orderCode+'.pdf');
  }


}
