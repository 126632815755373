<div class="box col-span-12">
  <div class="flex justify-between items-center gap-4 flex-wrap bb-dashed mb-4 pb-4 lg:mb-6 lg:pb-6">
    <h4 class="h4">Ürün Listesi</h4>
    <div class="flex items-center gap-4 flex-wrap grow sm:justify-end">
      <div class="flex items-center gap-4 flex-wrap grow sm:justify-end">
        <button (click)="printTable()" class="btn shrink-0 add-account-btn">Dışarı
          Aktar
          <i class="fa-solid fa-download"></i>
        </button>
        <button (click)="navigateToDetailPage()" class="btn shrink-0 add-account-btn">Yeni Ekle</button>
      </div>
    </div>
  </div>
  <div class="overflow-x-auto mb-4 lg:mb-6">
    <table class="w-full whitespace-nowrap">
      <thead>
        <tr class="bg-secondary1/5 dark:bg-bg3">
          <th class="text-start py-5 px-6 min-w-[200px] cursor-pointer">
            <div class="flex items-center gap-1">Ürün </div>
          </th>
          <th class="text-start py-5 min-w-[100px]">
            <div class="flex items-center gap-1">Kdv / Fiyat
            </div>
          </th>
          <th class="text-start py-5 min-w-[100px] cursor-pointer">
            <div class="flex items-center gap-1">Birim/Stok
            </div>
          </th>
          <th class="text-start py-5 min-w-[130px]">
            <div class="flex items-center gap-1">Durum</div>
          </th>
          <th class="text-center p-5">İşlemler</th>
        </tr>
      </thead>
      <tbody>
        @for (item of displayedProducts; track item.id; let i = $index) {
        <tr class="even:bg-secondary1/5 dark:even:bg-bg3">
          <td class="py-4 px-6">
            <div class="flex items-center gap-3">
              <p class="font-medium mb-1">{{ item.title }}</p>
            </div>
          </td>
          <td class="py-4">
            <div>
              %{{ item.tax_rate }}
            </div> {{formatAmount(item.price_with_tax)}}₺
          </td>
          <td class="py-4 stockAlert">
            <div>
              <span> Uyarı: {{item.stock_alert}}</span>
            </div>
            <div>
              <span [ngClass]="{'text-red':item.stock < item.stock_alert}">
                Stok: {{item.stock}}
                <!-- <span [style.width]="limitStockWidth(item.stock) + '%'"
                    class="block h-1 rounded-sm bg-primary"></span> -->
              </span>
            </div>
          </td>
          <td class="py-4">
            <span
              [ngClass]="{ 'text-primary bg-primary/10': item.is_active == true, 'text-secondary2 bg-secondary2/20': item.is_active == false, 'text-secondary3 bg-secondary3/10': item.is_active == false }"
              class="block w-28 rounded-[30px] border border-n30 bg-primary/10 py-2 text-center text-xs text-primary dark:border-n500 dark:bg-bg3 xxl:w-36">
              @if(item.is_active == true){
              Aktif
              }@else {
              Pasif
              }
            </span>
          </td>
          <td class="py-4">
            <div class="flex justify-center">
              <app-options-vertical-product [selectedProdId]="item.id" />
            </div>
          </td>
        </tr>
        }
      </tbody>
    </table>
  </div>

  <div class="flex col-span-12 gap-4 sm:justify-between justify-center items-center flex-wrap">
    <ul class="flex gap-2 md:gap-3 flex-wrap md:font-semibold items-center">
      <li>
        <button [disabled]="currentPage === 1" (click)="changePage(currentPage - 1)"
          class="hover:bg-primary rtl:rotate-180 hover:text-n0 border md:w-10 duration-300 md:h-10 w-8 h-8 flex items-center rounded-full justify-center border-primary">
          <i class="las la-angle-left text-lg"></i>
        </button>
      </li>
      <li *ngFor="let page of pages">
        <button (click)="changePage(page)" [class.bg-primary]="currentPage === page"
          class="hover:bg-primary hover:text-n0 border md:w-10 duration-300 md:h-10 w-8 h-8 flex items-center rounded-full justify-center border-primary">
          {{ page }}
        </button>
      </li>
      <li>
        <button [disabled]="currentPage === totalPages" (click)="changePage(currentPage + 1)"
          class="hover:bg-primary hover:text-n0 rtl:rotate-180 border md:w-10 duration-300 md:h-10 w-8 h-8 flex items-center rounded-full justify-center border-primary">
          <i class="las la-angle-right text-lg"></i>
        </button>
      </li>
    </ul>
  </div>
</div>