<div ref="target" class="relative">
  <div [class]="btnClass" (click)="toggleOpen($event)">
    <div class="flex items-center gap-2 text-sm">
      {{ selected }}
    </div>
    <i class="las la-angle-down duration-300 text-sm" [ngClass]="isOpen?'rotate-180':''"></i>
  </div>
  <ul [ngClass]="isOpen ? 'show' : 'hide'" [class]="dropdownClass">
    <li *ngFor="let item of currentOptions" (click)="selectOption(item)" class="px-4 py-2 cursor-pointer text-sm rounded-md duration-300" [ngClass]="selected == item ? 'bg-primary/5' : ''">
      {{ item }}
    </li>
  </ul>
</div>
