import { Component } from '@angular/core'
import { providersTabData } from '@data/payment/providers'
import { PasswordInputComponent } from '@component/shared/password-input/password-input.component'
import { DropdownComponent } from '@component/shared/dropdown/dropdown.component'

@Component({
  selector: 'app-printer-settings',
  standalone: true,
  imports: [PasswordInputComponent,DropdownComponent],
  templateUrl: './printer-settings.component.html'
})
export class PrinterSettingsComponent {
  providers = providersTabData
  activeTab = this.providers[0].title
  posStationPrinter= [
    "Seçiniz -1",
    "Seçiniz -2"
  ]
  paymentModel=[
    "Seçiniz -1",
    "Seçiniz -2"
  ]
  printTemplate = [
    "Seçiniz -1",
    "Seçiniz -2"
  ]

  orderPoint= [
    "Seçiniz -1",
    "Seçiniz -2"
  ]

  orderStatus= [
    "Seçiniz -1",
    "Seçiniz -2"
  ]
  cashReceipt= [
    "Seçiniz -1",
    "Seçiniz -2"
  ]


}
