<div class=" bg-n0 dark:bg-bg4 box overflow-y-auto max-h-[800px]" style="width: 65vh;">
  <div class="flex justify-end">
    <i (click)="closeModal()" class="las cursor-pointer la-times"></i>
  </div>
  <div class="timer-container">
    <svg class="circle">
      <circle class="path" [attr.stroke-dasharray]="circumference" [attr.stroke-dashoffset]="offset" cx="50%" cy="50%"
        r="90"></circle>
    </svg>
    <div id="countdown-number">{{ countdown }}</div>
  </div>
  <p style="text-align: center;">Lütfen <b>+90 {{phoneNumber}}</b><br>
    Numarasına Gönderilen Kodu Giriniz.</p>
  <div class="otp-inputs mt-4">
    <input type="text" maxlength="1" [(ngModel)]="otp1" (keyup)="focusNext($event, 0)">
    <input type="text" maxlength="1" [(ngModel)]="otp2" (keyup)="focusNext($event, 1)">
    <input type="text" maxlength="1" [(ngModel)]="otp3" (keyup)="focusNext($event, 2)">
    <input type="text" maxlength="1" [(ngModel)]="otp4" (keyup)="focusNext($event, 3)">
    <input type="text" maxlength="1" [(ngModel)]="otp5" (keyup)="focusNext($event, 4)">
    <input type="text" maxlength="1" [(ngModel)]="otp6" (keyup)="focusNext($event, 5)">
  </div>
  <div *ngIf="errorMessage" class="error">
    <span class="text-secondary2"> {{ errorMessage }}</span>
  </div>
  <div class="col-span-2 md:col-span-1 mt-4">
    <button [disabled]="countdown == 0" (click)="submitForm()" class="btn flex w-full justify-center"
      type="submit">Doğrula</button>
  </div>
  <div class="col-span-2 md:col-span-1 mt-4">
    <button (click)="closeModal()" style="background-color: #17181A;" class="btn flex w-full justify-center"
      type="submit">Tekrar Gönder</button>
  </div>
</div>