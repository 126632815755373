import { Component } from '@angular/core'
import { DropdownComponent } from '../dropdown/dropdown.component'
import { ModalService } from 'ngx-modal-ease'
import { PersonelListService } from '@service/personel/personel-list.service';
import { PersonelListModel } from '../../../models/personel/personel-list-model';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { DashboardTransactionService } from '@service/dashboard/dashboard-transaction-service';
import { DashboardPaymentTypeService } from '@service/dashboard/payment-type-service';

@Component({
  selector: 'dashboard-filter-modal',
  standalone: true,
  imports: [DropdownComponent, CommonModule, ReactiveFormsModule],
  templateUrl: './dashboard-filter-modal.component.html'
})

export class DashboardFilterComponent {
  @Output() filterApplied = new EventEmitter<any>();

  selectedStaff!: string;
  selectedMonth!: string;
  currentYear: number;
  currentMonth: number;
  personelList!: PersonelListModel[];
  selectedType!: string;
  myForm!: FormGroup;
  selectType = [
    { id: 'MONTH', title: 'Aylık' },
    { id: 'YEAR', title: 'Yıllık' },
  ];
  months = [
    { id: "1", title: "Ocak" },
    { id: "2", title: "Şubat" },
    { id: "3", title: "Mart" },
    { id: "4", title: "Nisan" },
    { id: "5", title: "Mayıs" },
    { id: "6", title: "Haziran" },
    { id: "7", title: "Temmuz" },
    { id: "8", title: "Ağustos" },
    { id: "9", title: "Eylül" },
    { id: "10", title: "Ekim" },
    { id: "11", title: "Kasım" },
    { id: "12", title: "Aralık" }
  ];

  constructor(private getDashboardPaymentTypeService: DashboardPaymentTypeService, private getDashboardTransAcService: DashboardTransactionService, private modalService: ModalService, private getPersonelListService: PersonelListService, private fb: FormBuilder) {
    this.currentYear = new Date().getFullYear();
    this.currentMonth = new Date().getMonth() + 1;
  }

  closeSection() {
    this.modalService.close('DashboardFilterComponent')
  }

  closeModal(data: any) {
    this.modalService.close(data);
  }

  ngOnInit(): void {
    this.getPersonelListService.allPersonelList().subscribe(
      (response: PersonelListModel[]) => {
        this.personelList = response;
      },
      error => {
        console.error('Hata:', error);
      }
    );

    this.myForm = this.fb.group({
      staff: [null],
      dateRange: [null],
      month: [null],
      year: [null, Validators.required]
    });

    this.myForm.get('dateRange')?.valueChanges.subscribe(value => {
      if (value === 'YEARS') {
        this.myForm.get('year')?.setValidators(Validators.required);
      } else {
        this.myForm.get('year')?.clearValidators();
      }
      this.myForm.get('year')?.updateValueAndValidity();
    });
  }

  onStaffSelected(event: Event) {
    const target = event.target as HTMLSelectElement;
    this.selectedStaff = target.value;
  }

  onSelectType(event: Event) {
    const target = event.target as HTMLSelectElement;
    this.selectedType = target.value;
  }

  onMonthSelected(event: Event) {
    const target = event.target as HTMLSelectElement;
    this.selectedMonth = target.value;
  }

  onSubmit() {
    if (this.myForm.valid) {
      const formValue = this.myForm.value;
      const year = formValue['year'] ? formValue['year'] : this.currentYear;
      const month = this.selectedMonth ? this.selectedMonth : this.currentMonth.toString();

      const filterData = {
        staff: this.selectedStaff,
        type: this.selectedType,
        month: month,
        year: year
      };

      const transAcRequest = this.getDashboardTransAcService.getFilterData(
        false,
        'all',
        this.selectedStaff ? true : false,
        undefined,
        undefined,
        this.selectedStaff ? this.selectedStaff : undefined,
        this.selectedType ? this.selectedType : undefined,
        month,
        year
      );

      const paymentTypeRequest = this.getDashboardPaymentTypeService.getFilterData(
        false,
        false,
        this.selectedStaff ? true : false,
        undefined,
        undefined,
        this.selectedStaff ? this.selectedStaff : undefined,
        this.selectedType ? this.selectedType : undefined,
        month,
        year
      );

      const paymentTypeRequestWithFoodCard = this.getDashboardTransAcService.getFilterData(
        false,
        'all',
        this.selectedStaff ? true : false,
        undefined,
        undefined,
        this.selectedStaff ? this.selectedStaff : undefined,
        this.selectedType ? this.selectedType : undefined,
        month,
        year
      );



      Promise.all([
        transAcRequest.toPromise(),
        paymentTypeRequest.toPromise(),
        paymentTypeRequestWithFoodCard.toPromise()
      ]).then(([transAcResponse, paymentTypeResponse, paymentWithFoodCard]) => {
        const resultData = {
          transAcData: transAcResponse,
          paymentTypeData: paymentTypeResponse,
          paymentDataFoodCardType: paymentWithFoodCard,
          selectedStaff: this.selectedStaff,
          selectType: this.selectedType,
          selectMonth: this.selectedMonth,
          selectYear: this.myForm.value['year']
        };

        this.filterApplied.emit(resultData);

        this.closeModal(resultData);
      }).catch(error => {
        console.error('Hata:', error);
      });
    }
  }


}
