<div class=" bg-n0 dark:bg-bg4 box overflow-y-auto max-h-[800px] branchessAddModal">
  <div class="flex justify-between items-center bb-dashed pb-6 mb-6">
    <h4>Şube ekle</h4><i (click)="closeModal()" class="las cursor-pointer la-times"></i>
  </div>
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="mt-6 xl:mt-8 grid gap-4 xxxl:gap-6">
      <div class="col-span-2">
        <label for="branchesName" class="md:text-lg font-medium block mb-4">Şube Adı</label>
        <div class="bg-secondary1/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl relative">
          <input type="text" formControlName="branchesName" class="text-sm w-full px-6 py-2.5 md:py-3 bg-transparent rounded-3xl" placeholder="Şube Adı" id="branchesName" />
        </div>
        <div *ngIf="form.get('branchesName')?.invalid && (form.get('branchesName')?.dirty || form.get('branchesName')?.touched)" class="text-red-600 text-sm mt-1">
          <div *ngIf="form.get('branchesName')?.errors?.['required']">Şube Adı zorunludur.</div>
        </div>
      </div>

      <div class="col-span-2">
        <label for="fPhone" class="md:text-lg font-medium block mb-4">Telefon Numarası</label>
        <div class="bg-secondary1/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl relative">
          <input type="number" formControlName="fPhone" class="text-sm w-full px-6 py-2.5 md:py-3 bg-transparent rounded-3xl" placeholder="Telefon Numarası" id="fPhone" />
        </div>
        <div *ngIf="form.get('fPhone')?.invalid && (form.get('fPhone')?.dirty || form.get('fPhone')?.touched)" class="text-red-600 text-sm mt-1">
          <div *ngIf="form.get('fPhone')?.errors?.['required']">Telefon Numarası zorunludur.</div>
          <div *ngIf="form.get('fPhone')?.errors?.['pattern']">Geçerli bir telefon numarası giriniz.</div>
        </div>
      </div>

      <div class="col-span-2">
        <label for="fSelectedCity" class="md:text-lg font-medium block mb-4">Şehir Seçiniz</label>
        <select formControlName="fSelectedCity" (change)="onCitySelect($event)" class="rounded-3xl w-full !py-3 formDevicesSet w-full mb-1 text-sm bg-n0 dark:bg-bg4 border border-n30 dark:border-n500 rounded-3xl px-3 md:px-6 py-2 md:py-3">
          <option value="" disabled>Şehir seçiniz</option>
          <option *ngFor="let city of cities" [value]="city.id">{{ city.name }}</option>
        </select>
        <div *ngIf="form.get('fSelectedCity')?.invalid && (form.get('fSelectedCity')?.dirty || form.get('fSelectedCity')?.touched)" class="text-red-600 text-sm mt-1">
          <div *ngIf="form.get('fSelectedCity')?.errors?.['required']">Şehir seçilmesi zorunludur.</div>
        </div>
      </div>

      <div class="col-span-2">
        <label for="fSelectedDetailCity" class="md:text-lg font-medium block mb-4">Şehir Detayı Seçiniz</label>
        <select formControlName="fSelectedDetailCity" (click)="onCityDetailSelect($event)" class="rounded-3xl w-full !py-3 formDevicesSet w-full mb-1 text-sm bg-n0 dark:bg-bg4 border border-n30 dark:border-n500 rounded-3xl px-3 md:px-6 py-2 md:py-3">
          <option value="" disabled>Şehir Detayı seçiniz</option>
          <option *ngFor="let cityDet of citiesDetail" [value]="cityDet.id">{{ cityDet.name }}</option>
        </select>
        <div *ngIf="form.get('fSelectedDetailCity')?.invalid && (form.get('fSelectedDetailCity')?.dirty || form.get('fSelectedDetailCity')?.touched)" class="text-red-600 text-sm mt-1">
          <div *ngIf="form.get('fSelectedDetailCity')?.errors?.['required']">Şehir Detayı seçilmesi zorunludur.</div>
        </div>
      </div>

      <div class="col-span-2">
        <label for="fDetailAddress" class="md:text-lg font-medium block mb-4">Açık Adres</label>
        <div class="bg-secondary1/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl relative">
          <input type="text" formControlName="fDetailAddress" class="text-sm w-full px-6 py-2.5 md:py-3 bg-transparent rounded-3xl" placeholder="Açık Adres" id="fDetailAddress" />
        </div>
        <div *ngIf="form.get('fDetailAddress')?.invalid && (form.get('fDetailAddress')?.dirty || form.get('fDetailAddress')?.touched)" class="text-red-600 text-sm mt-1">
          <div *ngIf="form.get('fDetailAddress')?.errors?.['required']">Açık Adres zorunludur.</div>
        </div>
      </div>

      <div class="col-span-2">
        <label for="posIntegrator" class="md:text-lg font-medium block mb-4">POS Entegratörü</label>
        <div class="input-container bg-secondary1/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl relative">
          <input type="number" formControlName="posIntegrator" class="text-sm w-full px-6 py-2.5 md:py-3 bg-transparent rounded-3xl" placeholder="POS Entegratörü" id="posIntegrator" />
        </div>
        <div *ngIf="form.get('posIntegrator')?.invalid && (form.get('posIntegrator')?.dirty || form.get('posIntegrator')?.touched)" class="text-red-600 text-sm mt-1">
          <div *ngIf="form.get('posIntegrator')?.errors?.['required']">POS Entegratörü zorunludur.</div>
        </div>
      </div>

      <div *ngIf="warningForm ==true">
        <span style="color: red ;">Eksik alanları doldurunuz.</span>
      </div>

      <div class="col-span-2 mt-4">
        <button class="btn flex w-full justify-center" type="submit">Onayla</button>
      </div>
    </div>
  </form>

</div>