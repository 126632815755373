import { CommonModule } from '@angular/common'
import { Component } from '@angular/core'
import { DropdownComponent } from '@component/shared/dropdown/dropdown.component'
import { TopBannerComponent } from '@component/shared/top-banner/top-banner.component'
import { marketOverviewData } from '@data/trading/marketOverview'
import { TableService } from '@service/table.service'
import { Router } from '@angular/router';
import { OptionsVerticalProductComponent } from '@component/shared/options-vertical-product/options-vertical-product.component'
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import { CategoryListModel } from '../../../models/category/category-list.model'
import { CategoryListService } from '@service/category/category-list-service'
import { CdkDragDrop, DragDropModule, moveItemInArray } from '@angular/cdk/drag-drop';
import { FormGroup, Validators, FormBuilder } from '@angular/forms'
import { ReactiveFormsModule, FormsModule } from '@angular/forms'
import { ModalService } from 'ngx-modal-ease'
import { CategoryErrorModalComponent } from '@component/shared/category-error-modal/category-error-modal'
import { CategoryProductService } from '@service/products/category-product.service'
import { CategoriesProductModel, CategoriesProductsModel } from '../../../models/products/categories-product.model'
import { ProductSortableService } from '@service/products/products-sortable.service'
import { AddProductService } from '@service/products/products-add-service'
import { ProductDeleteService } from '@service/products/products-delete.service'
import { ProductSuccessDeleteModalComponent } from '@component/shared/product-success-delete-modal/product-success-delete-modal.component'
import { MultiSelectModule } from 'primeng/multiselect';
import { ProductDetailService } from '@service/products/product-detail-service'
import { ProductDetailModel } from '../../../models/products/product-detail.model'
import { AddProductModel } from '../../../models/products/add-product-model'
import { ProductSuccessAddModalComponent } from '@component/shared/product-success-add-modal/product-success-add-modal.component'
import { ProductUpdateService } from '@service/products/product-update.service'
import { DeleteCategoryModalComponent } from '@component/shared/delete-category-modal/delete-category-modal.component'

@Component({
  selector: 'td-app-style-02',
  standalone: true,
  imports: [TopBannerComponent, MultiSelectModule, FormsModule, ReactiveFormsModule, DragDropModule, CommonModule, OptionsVerticalProductComponent, DropdownComponent],
  templateUrl: './style-02.component.html'
})
export class TradingStyle02Component {

  marketOverview
  categoryList: CategoryListModel[] = [];
  displayedCategory: CategoryListModel[] = [];
  prodList: CategoriesProductModel[] = []
  pages: number[] = [];
  currentPage: number = 1;
  itemsPerPage: number = 12;
  totalPages: number = 1;
  prodDetail!: ProductDetailModel;

  categoryForm!: FormGroup;
  productForm!: FormGroup;
  productEditForm!: FormGroup;

  categoryStatus: boolean = false;
  draggingIndex: number | null = null;
  prodDraggingIndex: number | null = null;
  expandedIndex: number | null = null;
  openedIndex: number = -1;

  selectedCategoryProduct!: CategoriesProductsModel;

  isBoxVisible: boolean = false;

  productTypeEnum = [
    {
      code: "PHYSICALLY",
      title: "Fiziksel",
    },
    {
      code: "VIRTUAL",
      title: "Sanal",
    },
    {
      code: "INFO",
      title: "Bilgi",
    },
    {
      code: "MD",
      title: "MD",
    },
    {
      code: "DSN",
      title: "DSN",
    },
  ];
  unitTypeEnum = [
    {
      code: "ADET",
      title: "Adet"
    },
    {
      code: "KG",
      title: "Kg"
    },
    {
      code: "GR",
      title: "Gr"
    },
    {
      code: "LT",
      title: "Lt"
    },
    {
      code: "MT",
      title: "Mt"
    },
    {
      code: "KOLI",
      title: "Koli"
    },
    {
      code: "PAKET",
      title: "Paket"
    },
  ];
  selectedProdType: string[] = [];
  selectedCategories: string[] = [];
  addProdIItem!: AddProductModel;
  selectedProduct!: string;

  constructor(
    private router: Router,
    private categoryListService: CategoryListService,
    private fb: FormBuilder,
    private modalService: ModalService,
    private categoriesProdService: CategoryProductService,
    private prodSortableService: ProductSortableService,
    private productAddService: AddProductService,
    private deleteProductService: ProductDeleteService,
    private productDetailService: ProductDetailService,
    private updateProductService: ProductUpdateService,
  ) {
    this.marketOverview = new TableService()
    this.marketOverview.initialize(marketOverviewData, 10)

    this.categoryForm = this.fb.group({
      categoryName: ['', Validators.required],
      categoryCode: ['', Validators.required],
      isActive: [false]
    });

    this.productForm = this.fb.group({
      productName: ['', Validators.required],
      productPrice: ['', Validators.required],
      productTax: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.categoryListService.allCategories().subscribe(
      response => {
        this.categoryList = response;
        this.totalPages = Math.ceil(this.categoryList.length / this.itemsPerPage);
        this.pages = Array.from({ length: this.totalPages }, (_, i) => i + 1);
        this.updateDisplayedCategory();
      },
      error => {
        console.error('Error:', error);
      }
    );
    this.productEditForm = this.fb.group({
      category: ['', Validators.required],
      productName: ['', Validators.required],
      productType: ['', Validators.required],
      unit: ['', Validators.required],
      productPrice: ['', Validators.required],
      vatRate: ['', Validators.required],
      productPrice2: ['', Validators.required],
      productPrice3: ['', Validators.required],
      purchasePrice: ['', Validators.required],
      productCode: ['', Validators.required],
      sku: ['', Validators.required],
      stock: ['', Validators.required],
      description: ['', Validators.required],
      criticStock: ['', Validators.required]
    });
  }

  updateDisplayedCategory(): void {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    this.displayedCategory = this.categoryList.slice(startIndex, endIndex);
  }

  changePage(page: number): void {
    if (page >= 1 && page <= this.totalPages) {
      this.currentPage = page;
      this.updateDisplayedCategory();
    }
  }

  limitStockWidth(stock: number): string {
    return stock > 100 ? '100%' : stock + '%';
  }

  public printTable() {

    const doc = new jsPDF('l', 'mm', 'a4');

    const head = [
      [
        [this.convertToTurkishCharacters('Adı')],
        [this.convertToTurkishCharacters('Kodu')],
        [this.convertToTurkishCharacters('Durum')],
      ]]
    const data = this.categoryList.map((item: CategoryListModel) => [
      [item.name],
      [item.code],
      [item.is_active == true ? "Aktif" : "Pasif"]
    ]);


    autoTable(doc, {
      head: head,
      body: data,
      styles: {
        font: "Poppins",
        fontStyle: "normal",
      },
      bodyStyles: { fillColor: [200, 200, 200] },
    });

    doc.save('kategoriler-listesi.pdf');
  }

  convertToTurkishCharacters(text: string) {
    return text.replace(/ğ/g, 'g')
      .replace(/Ğ/g, 'G')
      .replace(/ü/g, 'u')
      .replace(/Ü/g, 'U')
      .replace(/ş/g, 's')
      .replace(/Ş/g, 'S')
      .replace(/ı/g, 'i')
      .replace(/İ/g, 'I')
      .replace(/ö/g, 'o')
      .replace(/Ö/g, 'O')
      .replace(/ç/g, 'c')
      .replace(/Ç/g, 'C');
  }

  toggleStatus(categoryId: string, itemStatus: boolean, itemName: string): void {
    const newItemStatus = !itemStatus;

    this.categoryListService.updateCategory(categoryId, !itemStatus, itemName).subscribe(
      response => {
        this.updateLocalCategoryStatus(categoryId, newItemStatus);
      },
      error => {
        console.error('Error updating profile', error);
      }
    );
  }

  updateLocalCategoryStatus(categoryId: string, newItemStatus: boolean): void {
    const category = this.categoryList.find(cat => cat.id === categoryId);
    if (category) {
      category.is_active = newItemStatus;
    }
  }

  drop(event: CdkDragDrop<CategoryListModel[]>) {
    moveItemInArray(this.displayedCategory, event.previousIndex, event.currentIndex);

    const idsToUpdate = this.displayedCategory.map(item => item.id);

    this.categoryListService.updateCategoryList(idsToUpdate).subscribe(
      () => {
      },
      (error) => {
        console.error('Kategori sırasını güncellemede hata oluştu:', error);
        moveItemInArray(this.displayedCategory, event.currentIndex, event.previousIndex);
      }
    );
  }

  dropProduct(categoryId: string, event: CdkDragDrop<CategoriesProductModel[]>) {
    moveItemInArray(this.selectedCategoryProduct.products, event.previousIndex, event.currentIndex);

    const idsToUpdate = this.selectedCategoryProduct.products.map(item => item.id);

    if (idsToUpdate.length === 0) {
      console.error('Güncelleme listesi boş olamaz.');
      return;
    }

    this.prodSortableService.getProductSortable(categoryId, idsToUpdate).subscribe(
      () => {
      },
      (error) => {
        console.error('Kategori sırasını güncellemede hata oluştu:', error);
        moveItemInArray(this.selectedCategoryProduct.products, event.currentIndex, event.previousIndex);
      }
    );
  }

  submitForm() {
    if (this.categoryForm.valid) {

      this.categoryListService.addCategory(
        this.categoryForm.value.categoryCode, this.categoryForm.value.categoryName
      ).subscribe(
        () => {
          const currentUrl = this.router.url;
          this.router.navigateByUrl('/refresh', { skipLocationChange: true }).then(() => {
            this.router.navigate([currentUrl]);
          });
        },
        (error) => {
          console.error('hata oluştu:', error);
        }
      );
    } else {
      this.modalService.open(CategoryErrorModalComponent, {
        modal: {
          enter: 'enter-going-down 0.3s ease-out',
          leave: 'fade-out 0.5s'
        },
        overlay: {
          leave: 'fade-out 0.5s'
        },
        data: {
          type: 'Angular modal library'
        }
      });
      setTimeout(() => {
        this.modalService.close();
      }, 2500);
    }
  }

  submitProductForm() {
    if (this.productForm.valid) {
      this.productAddService.addProduct(
        this.productForm.value.productName,
        this.productForm.value.productName,
        "",
        "",
        "ADET",
        "PHYSICALLY",
        this.productForm.value.productTax,
        null,
        this.productForm.value.productPrice,
        null,
        null,
        null,
        null,
        false,
        "ALL",
        null,
        [this.selectedCategoryProduct.id]
      ).subscribe(
        () => {
          const currentUrl = this.router.url;
          this.router.navigateByUrl('/refresh', { skipLocationChange: true }).then(() => {
            this.router.navigate([currentUrl]);
          });
        },
        (error) => {
          console.error('hata oluştu:', error);
        }
      );

    } else {
      this.modalService.open(CategoryErrorModalComponent, {
        modal: {
          enter: 'enter-going-down 0.3s ease-out',
          leave: 'fade-out 0.5s'
        },
        overlay: {
          leave: 'fade-out 0.5s'
        },
        data: {
          type: 'Angular modal library'
        }
      });
      setTimeout(() => {
        this.modalService.close();
      }, 2500);
    }
  }

  onToggleChange() {
    this.categoryStatus = !this.categoryStatus;
    this.categoryForm.patchValue({ isActive: this.categoryStatus });
  }

  onDragStart(event: any, index: number): void {
    this.draggingIndex = index;
  }

  onPordDragStart(event: any, index: number): void {
    this.prodDraggingIndex = index;
  }

  onDragEnd(event: any, index: number): void {
    this.draggingIndex = null;
  }

  onProdDragEnd(event: any, index: number): void {
    this.prodDraggingIndex = null;
  }

  toggleExpand(index: number): void {
    this.expandedIndex = this.expandedIndex === index ? null : index;
  }

  toggleItem(index: number) {
    if (this.openedIndex === index) {
      this.openedIndex = -1;
    } else {
      this.openedIndex = index;
    }
  }

  onTapCategoryProd(categoriesId: string) {
    this.categoriesProdService.getCategoryProduct(categoriesId).subscribe(
      response => {
        this.selectedCategoryProduct = response;
      },
      error => {
        console.error('Error:', error);
      }
    );
  }

  deleteProduct(prodId: string) {
    this.deleteProductService.deleteProduct(prodId).subscribe(
      response => {
        const data = response;
        this.modalService.open(ProductSuccessDeleteModalComponent, {
          modal: {
            enter: 'enter-going-down 0.3s ease-out',
            leave: 'fade-out 0.5s'
          },
          overlay: {
            leave: 'fade-out 0.5s'
          },
          data: {
            type: 'Angular modal library'
          }
        });
        setTimeout(() => {
          this.modalService.close();
        }, 2500);
      },
      error => {
        console.error('Error:', error);
      }
    );
  }

  toggleBox(prodId: string) {
    if (this.isBoxVisible) {
      setTimeout(() => {
        this.isBoxVisible = false;
      }, 300);
    } else {
      this.isBoxVisible = true;
      this.productDetailService.getProductDetail(prodId).subscribe(
        response => {
          this.prodDetail = response;
          this.selectedProduct = prodId;
        },
        error => {
          console.error('Error:', error);
        }
      );
    }
  }

  closeToggleBox() {
    setTimeout(() => {
      this.isBoxVisible = false;
    }, 300);
  }

  onSelectChange(event: any) {
    const selectElement = event.target as HTMLSelectElement;
    const selectedOptions = Array.from(selectElement.selectedOptions).map(option => option.value);
    this.selectedProdType = selectedOptions;
  }

  onCategoryChange(event: any) {
    const selectedCodes = event.value.map((item: any) => item.id);
    this.selectedCategories = selectedCodes
  }

  addProdTap(): void {
    if (this.productEditForm.invalid) {
      this.productEditForm.markAllAsTouched();
      return;
    }

    this.updateProductService.updateProduct(
      this.prodDetail.id,
      this.productEditForm.value.productName,
      this.productEditForm.value.sku,
      this.productEditForm.value.description,
      this.productEditForm.value.unit,
      this.productEditForm.value.productType,
      this.productEditForm.value.vatRate,
      this.productEditForm.value.purchasePrice,
      this.productEditForm.value.productPrice,
      this.productEditForm.value.productPrice2,
      this.productEditForm.value.productPrice3,
      this.productEditForm.value.criticStock,
      false,
      "ALL",
      true,
      this.selectedCategories,
    ).subscribe(
      (response: AddProductModel) => {
        this.addProdIItem = response;
        this.modalService.open(ProductSuccessAddModalComponent, {
          modal: {
            enter: 'enter-going-down 0.3s ease-out',
            leave: 'fade-out 0.5s'
          },
          overlay: {
            leave: 'fade-out 0.5s'
          },
          data: {
            type: 'Angular modal library'
          }
        });
        setTimeout(() => {
          this.modalService.close();
          this.productForm.reset();
          const currentUrl = this.router.url;
          this.router.navigateByUrl('/refresh', { skipLocationChange: true }).then(() => {
            this.router.navigate([currentUrl]);
          });
        }, 3000);

      },
      error => {
        console.error('Error fetching categories:', error);
      }
    );
  }

  deleteCategory(selecetedId:string) {
    this.modalService.open(DeleteCategoryModalComponent, {
      modal: {
        enter: 'enter-going-down 0.3s ease-out',
        leave: 'fade-out 0.5s'
      },
      overlay: {
        leave: 'fade-out 0.5s'
      },
      data: {
        type: 'Angular modal library',
        categoryId: selecetedId,
      }
    });
  }
}
