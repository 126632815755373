import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ProfileResponseModel } from '../../models/profile/profile.model';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ProfileService {

    private apiUrl = `${environment.apiUrl}/profiles`;
    private token = localStorage.getItem('authToken');
    constructor(private http: HttpClient) { }

    allProfiles(): Observable<ProfileResponseModel[]> {
        const headers = new HttpHeaders()
            .set('Authorization', `Bearer ${this.token}`,)
        return this.http.get<ProfileResponseModel[]>(this.apiUrl,{headers});
    }

}
