<label [for]="label" class="md:text-lg font-medium block mb-4"> {{ label }}</label>
<div class="bg-primary/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-3 md:px-6 py-2 md:py-3 relative">
  <input [type]="isVisible ? 'text' : 'password'" class="w-11/12 text-sm bg-transparent p-0 border-none" [placeholder]="label" [id]="label" />
  <span (click)="toggleVisibility()" class="absolute eye-icon ltr:right-5 rtl:left-5 top-1/2 -translate-y-1/2 cursor-pointer">
    @if (isVisible) {
    <i class="las la-eye"></i>
    } @else {
    <i class="las la-eye-slash"></i>
    }
  </span>
</div>
