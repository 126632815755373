import { CommonModule } from '@angular/common'
import { Component } from '@angular/core'
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms'
import { Router, RouterLink } from '@angular/router'
import { LoginService } from '@service/auth/login.service'
import { ModalService } from 'ngx-modal-ease'
import { PinModalComponent } from './components/pin-modal.component'

@Component({
  selector: 'app-sign-in',
  standalone: true,
  imports: [RouterLink, ReactiveFormsModule, CommonModule],
  templateUrl: './sign-in.component.html'
})
export class SignInComponent {
  passwordVisible = false
  phoneForm!: FormGroup;
  pin: string | null = null;
  transactionId!: string;
  branches!: any[];
  errorMessage!: string;
  otpCode!: string;
  toggleVisibility() {
    this.passwordVisible = !this.passwordVisible
  }

  constructor(private fb: FormBuilder, private router: Router, private modalService: ModalService, private loginService: LoginService) { }

  // submitForm(): void {
  //   if (this.phoneForm.valid) {
  //     this.router.navigateByUrl('/dashboards/style-01')
  //     console.log('Form gönderildi');
  //   }
  // }

  submitForm(): void {
    if (this.phoneForm.valid) {
      const phoneNumber = this.phoneForm.get('phoneNumber')?.value;
      this.loginService.sendPhoneNumber(phoneNumber).subscribe(
        (response) => {
          this.branches = response.branchs;
          this.modalService.open(PinModalComponent, {
            modal: {
              enter: 'enter-going-down 0.3s ease-out',
              leave: 'fade-out 0.5s'
            },
            overlay: {
              leave: 'fade-out 0.5s'
            },
            data: {
              type: 'Angular modal library',
              phoneNumber: phoneNumber,
              transactionId: response.transaction_id,
              branch_id: this.branches[0].id
            },
          }),
          this.pin = response.pin;
          this.errorMessage = response.message;
        },
        (error) => {
          this.errorMessage = error.message;
        }
      );
    }

  }

  ngOnInit(): void {
    this.phoneForm = this.fb.group({
      phoneNumber: ['', [Validators.required, Validators.pattern(/^\d{10}$/)]]
    });
  }
}
