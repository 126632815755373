import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class TransactionAllService {

    private apiUrl = `${environment.apiUrl}/transactions/all`;
    private token = localStorage.getItem('authToken');
    constructor(private http: HttpClient) { }

    allTransActions(
        page: string,
        limit: string,
        startDate: string,
        endDate: string,
        firmSelected: boolean = false,
        firms?: string[],
        staffFilter: boolean = false,
        staffs?: string[],
        paymentType?: string,
        paymentMethod?: string,
    ): Observable<any> {
        const headers = new HttpHeaders().set('Authorization', `Bearer ${this.token}`);
    
        let params = new HttpParams()
            .set('page', page)
            .set('limit', limit)
            .set('start_date', startDate)
            .set('end_date', endDate)
            .set('is_firm_select', firmSelected.toString())
            .set('staff_filter', staffFilter.toString());
    
        if (paymentType) {
            params = params.set('payment_type', paymentType);
        }
    
        if (paymentMethod) {
            params = params.set('payment_method', paymentMethod);
        }
    
        const body = {
            "staff_ids": staffs,
            "firm_ids": firms,
        };
    
        return this.http.post<any>(this.apiUrl, body, { headers, params });
    }
    

    allTransActionsStatus(page: string, limit: string, startDate: string, endDate: string, status: string): Observable<any> {
        const headers = new HttpHeaders()
            .set('Authorization', `Bearer ${this.token}`);
        const urlPath = `${this.apiUrl}?page=${page}&limit=${limit}&start_date=${startDate}&end_date=${endDate}&status=${status}`;
        const body = {
            "staff_ids": [],
            "firm_ids": [],
        };
        return this.http.post<any>(urlPath, body, { headers });
    }

    allPaymentTypeTransActions(page: string, limit: string, startDate: string, endDate: string, paymentType: string): Observable<any> {
        const headers = new HttpHeaders()
            .set('Authorization', `Bearer ${this.token}`);
        const urlPath = `${this.apiUrl}?page=${page}&limit=${limit}&start_date=${startDate}&end_date=${endDate}&payment_type=${paymentType}`;
        return this.http.get<any>(urlPath, { headers });
    }
}
