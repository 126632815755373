import { Component } from '@angular/core';
import { ModalService } from 'ngx-modal-ease';

@Component({
  selector: 'app-delete-regions-modal',
  standalone: true,
  imports: [],
  templateUrl: './delete-regions-modal.component.html'
})
export class DeleteRegionsModalComponent {
  constructor(private modalService:ModalService){}
  closeModal(){
    this.modalService.close('DeleteRegionsModalComponent')
  }
}
