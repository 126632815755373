import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DashboardPaymentTypeModel } from '../../models/dashboard/payment-type-model';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class DashboardPaymentTypeService {
    private apiUrl = `${environment.apiUrl}/transactions/dasboard-payment-type`;
    private token = localStorage.getItem('authToken');

    constructor(private http: HttpClient) { }

    getData(
        selectedDate: string,
        firmSelected: boolean = false,
        firms?: string,
        staffs?: string,
    ): Observable<DashboardPaymentTypeModel> {
        const urlPath = `${this.apiUrl}`;
        const body = {
            "staff_ids": [staffs],
            "firm_ids": [firms]
        };
        const headers = new HttpHeaders().set('Authorization', `Bearer ${this.token}`);
        let params = new HttpParams()
            .set('date_filter', selectedDate)
            .set('firm_id_selected', firmSelected.toString());

        return this.http.post<DashboardPaymentTypeModel>(urlPath, body, { headers, params });
    }

    getFilterData(
        firmSelected: boolean = false,
        mealCardSelected: boolean = false,
        staffFilterSelected: boolean = false,
        firms?: string,
        mealCards?: string,
        staffs?: string,
        filterType?: string,
        filterMonth?:string,
        filterYear?:string,
    ): Observable<DashboardPaymentTypeModel> {
        const urlPath = `${this.apiUrl}`;
        const body = {
            "firm_ids": [
                firms,
            ],
            "meal_cards": [
                mealCards,
            ],
            "staff_ids": [
                staffs,
            ]
        };
        const headers = new HttpHeaders()
            .set('Authorization', `Bearer ${this.token}`);
        let params = new HttpParams()
            .set('date_filter', filterType!)
            .set('month', filterMonth!)
            .set('year', filterYear!)
            .set('firm_id_selected', firmSelected)
            .set('is_it_meal_card', mealCardSelected)
            .set('staff_filter', staffFilterSelected);

        return this.http.post<DashboardPaymentTypeModel>(urlPath, body, { headers, params });
    }
}
