import { Component } from '@angular/core';
import { ModalService } from 'ngx-modal-ease';
import { DropdownComponent } from '../dropdown/dropdown.component';

@Component({
  selector: 'app-edit-wallet-modal',
  standalone: true,
  imports: [DropdownComponent],
  templateUrl: './edit-wallet-modal.component.html'
})
export class EditWalletModalComponent {
  walletType = ['Ön Ödemeli', 'Test', 'Test-1']
  constructor(private modalService:ModalService){}
  closeModal(){
    this.modalService.close('EditWalletModalComponent')
  }
}
