export const accountTabData = [
    {
      id:'1',
      title: "Şirket Bilgileri",
      icon: "las la-angle-right text-lg",
    },
    {
      id:'2',
      title: "Fatura Bilgileri",
      icon: "las la-angle-right text-lg",
    },
    {
      id:'3',
      title: "Abonelik Bilgileri",
      icon: "las la-angle-right text-lg",
    },
    {
      id:'4',
      title: "Bölgeler",
      icon: "las la-angle-right text-lg",
    },
    {
      id:'5',
      title: "Şubeler",
      icon: "las la-angle-right text-lg",
    },
];
  

export const profileUserData = [
  {
    id:'1',
    title: "Profiller",
    icon: "las la-angle-right text-lg",
  },
  {
    id:'2',
    title: "Kullanıcılar",
    icon: "las la-angle-right text-lg",
  },
];