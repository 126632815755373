export const InvoiceStatus = {
  paid: 'paid',
  rejected: 'rejected',
  unpaid: 'unpaid'
}

export const invoiceData = [
  {
    id: 9,
    dueDate: "22.02.2024",
    orderCode: "356425323",
    speciealCode: "GDRFF5",
    title: "Yılmaz Gıda ve Un Mamülleri Marketler Zinciri",
    totalAmount: 145525000,
    remainingAmount: 525000,
    collectedAmount: 525000,
    status: InvoiceStatus.paid,
  },
  
];
