import { Component } from '@angular/core';
import { ModalService } from 'ngx-modal-ease';
import { DropdownComponent } from '../dropdown/dropdown.component';

@Component({
  selector: 'app-add-card-modal',
  standalone: true,
  imports: [DropdownComponent],
  templateUrl: './add-person-modal.component.html'
})
export class AddPersonModalComponent {
  constructor(private modalService:ModalService){}
  chooseWallet = ['Plus Cüzdan', 'Plus Cüzdan-2', 'Plus Cüzdan-3']
  walletType = ['Ön Ödemeli', 'Kredili']
  cardType = ['Bonus', 'Bonus-1','Bonus-2']
  closeModal(){
    this.modalService.close('AddPersonModalComponent')
  }
}
