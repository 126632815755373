<div class="overflow-x-auto pb-4 lg:pb-6">
    <div class="row" style="display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;">
      <button (click)="codeAdd()" class="flex justify-end btn shrink-0 add-account-btn mb-4">Yeni Kod Ekle</button>
    </div>
    <table class="w-full whitespace-nowrap" id="payment-account">
      <thead>
        <tr class="bg-secondary1/5 dark:bg-bg3">
          <th class="text-start !py-5 px-6 min-w-[230px]">
            <div class="flex items-center gap-1">Kod</div>
          </th>
          <th class="text-start !py-5 px-6 min-w-[230px]">
            <div class="flex items-center gap-1">Kısa Kodlar</div>
          </th>
          <th class="text-start !py-5 px-6 min-w-[230px]">
            <div class="flex items-center gap-1">Bölüm</div>
          </th>
          <th class="text-start !py-5 px-6 min-w-[230px]">
            <div class="flex items-center gap-1 ">QR Kodu</div>
          </th>
          <th class="text-start !py-5 px-6 min-w-[230px]">
            <div class="flex justify-center ">İşlemler</div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of paymentAccounts.paginatedData; index as i" class="even:bg-secondary1/5 dark:even:bg-bg3">
          <td>
            <p class="font-medium">
              {{item.expire}}
            </p>
          </td>
          <td>
            <span class="font-medium"> {{ item.currency.long }} </span>
          </td>
          <td>
            <span class="font-medium"> {{ item.expire }} </span>
          </td>
          <td>
            <span class="font-medium"> {{ item.expire }} </span>
          </td>
          <td class="py-4 flex justify-center">
            <app-options-vertical-short-code />
          </td>
        </tr>
      </tbody>
    </table>
  </div>