import { CommonModule } from '@angular/common'
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { DropdownComponent } from '@component/shared/dropdown/dropdown.component'
import { OptionsVerticalComponent } from '@component/shared/options-vertical/options-vertical.component'
import { TopBannerComponent } from '@component/shared/top-banner/top-banner.component'
import { ModalService } from 'ngx-modal-ease'
import { TableService } from '@service/table.service'
import { transactionsData } from '@data/dashboards/style2Transactions'
import { AddCardModalComponent } from '@component/shared/add-card-modal/add-card-modal.component'
import { FormsModule } from '@angular/forms'
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import { CancelTransActionsService } from '@service/transaction/transactions-cancel.service'
import { Router } from '@angular/router'
import { TransactionCancelModel } from '../../../models/transaction-model/transactions-cancel.model'
import { DatePipe } from '@angular/common'
import { CalendarModule } from 'primeng/calendar';
import { LocaleConfig, NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { JsonPipe, registerLocaleData } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { provideNativeDateAdapter } from '@angular/material/core';
import { VERSION as MAT_VERSION, MatNativeDateModule } from '@angular/material/core';
import { IgxCalendarModule, IgxSnackbarModule } from "igniteui-angular";
import { LOCALE_ID } from '@angular/core';
import localeTrExtra from '@angular/common/locales/extra/tr';
import localeTr from '@angular/common/locales/tr';
import { ChangeDetectorRef } from '@angular/core';
import { OrdersAllService } from '@service/order/order.service';
import { OrderListDataModel, OrderListModel } from '../../../models/order-model/order-list.model';
import { BranchListData } from '../../../models/branches/bracnhs-list-model';
import { BaranchListService } from '@service/branches/bracnhs-list-service';
import { MultiSelectModule } from 'primeng/multiselect';
registerLocaleData(localeTr, 'tr-TR', localeTrExtra);

@Component({
  selector: 'tr-app-style-01',
  standalone: true,
  imports: [TopBannerComponent, FormsModule,MultiSelectModule, MatNativeDateModule, NgxDaterangepickerMd, IgxSnackbarModule, IgxCalendarModule, ReactiveFormsModule, JsonPipe, MatDatepicker, MatDatepickerModule, MatFormFieldModule, MatMomentDateModule, DropdownComponent, CalendarModule, OptionsVerticalComponent, CommonModule, DatePipe],
  templateUrl: './style-01.component.html',
  providers: [
    provideNativeDateAdapter(),
    { provide: LOCALE_ID, useValue: 'tr-TR' }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransactionStyle01Component implements OnInit {
  transactions
  searchDateTerm: string = '';
  paymentTypeTerm: string = '';
  paymentMethodTerm: string = '';
  customerTitleTerm: string = '';
  sipSpecialCodeTerm: string = '';
  orderCodeTerm: string = '';
  collectedTerm: string = '';

  pages: number[] = [];
  startPage: number = 1;
  endPage: number = 10;

  cancelTransactionData!: OrderListModel;
  selectedBranch!:[];
  branchList!: BranchListData[];

  validPages: number[] = [];
  showDatepicker: boolean = false;
  showSaveButton: boolean = false;
  selectedDateRange: { start: any, end: any } | null = null;

  locale: LocaleConfig = {
    applyLabel: 'Uygula',
    cancelLabel: 'İptal',
    format: 'DD.MM.YYYY',
    daysOfWeek: ['Pzt', 'Sal', 'Çar', 'Per', 'Cum', 'Cmt', 'Paz'],
    monthNames: ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık']
  };

  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl()
  });

  constructor(private ordersService: OrdersAllService,private branchesService: BaranchListService, private modalService: ModalService, private router: Router, private cdr: ChangeDetectorRef, private cancelTransactionService: CancelTransActionsService) {
    this.transactions = new TableService()
    this.transactions.initialize(transactionsData, 1)
  }

  areAllItemsChecked(): boolean {
    return this.transactions.tableData.length > 1 && this.transactions.tableData.every((item) => item.isChecked)
  }

  ngOnInit() {
      this.loadCancelData();
      this.branchesService.allData().subscribe(
        response => {
          this.branchList = response.data;
        },
        error => {
          console.error('Hata:', error);
        }
      );
  }

  loadCancelData() {
    const today = new Date();
    const formattedtoday = `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}`;
    this.ordersService.allOrdersStatus("1", "12", formattedtoday, formattedtoday,"CANCEL").subscribe(
      response => {
        this.cancelTransactionData = response;
        this.calculatePages();
        this.updateValidPages();
        this.cdr.detectChanges();
      },
      error => {
        console.error('Hata:', error);
      }
    );
  }

  loadPageData(pageNumber: number): void {
    const today = new Date();
    const formattedtoday = `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}`;
    if(this.selectedBranch){
      this.ordersService.allOrdersStatus(
        pageNumber.toString(),
        "12",
        formattedtoday,
        formattedtoday,
        "CANCEL",
        true,
        this.selectedBranch
      ).subscribe(
        response => {
          this.cancelTransactionData = response;
          this.transactions.initialize(this.cancelTransactionData.data, pageNumber);
          this.pages = Array.from({ length: this.cancelTransactionData.total_page }, (_, i) => i + 1);
        },
        error => {
          console.error('Error:', error);
        }
      );
    }else{
      this.ordersService.allOrdersStatus(
        pageNumber.toString(),
        "12",
        formattedtoday,
        formattedtoday,
        "CANCEL"
      ).subscribe(
        response => {
          this.cancelTransactionData = response;
          this.transactions.initialize(this.cancelTransactionData.data, pageNumber);
          this.pages = Array.from({ length: this.cancelTransactionData.total_page }, (_, i) => i + 1);
        },
        error => {
          console.error('Error:', error);
        }
      );
    }
  }

  changePage(pageNumber: number): void {
    this.loadPageData(pageNumber);
    this.transactions.currentPage = pageNumber;
  }

  openModal() {
    this.modalService.open(AddCardModalComponent, {
      modal: {
        enter: 'enter-going-down 0.3s ease-out',
        leave: 'fade-out 0.5s'
      },
      overlay: {
        leave: 'fade-out 0.5s'
      },
      data: {
        type: 'Angular modal library'
      }
    })
  }

  convertToTurkishCharacters(text: string) {
    return text.replace(/ğ/g, 'g')
      .replace(/Ğ/g, 'G')
      .replace(/ü/g, 'u')
      .replace(/Ü/g, 'U')
      .replace(/ş/g, 's')
      .replace(/Ş/g, 'S')
      .replace(/ı/g, 'i')
      .replace(/İ/g, 'I')
      .replace(/ö/g, 'o')
      .replace(/Ö/g, 'O')
      .replace(/ç/g, 'c')
      .replace(/Ç/g, 'C');
  }

  public printTable() {

    const doc = new jsPDF('l', 'mm', 'a4');

    const head = [
      [
        [this.convertToTurkishCharacters('İşlem Tarihi')],
        [this.convertToTurkishCharacters('Müşteri')],
        [this.convertToTurkishCharacters('Sip Özel Kodu')],
        [this.convertToTurkishCharacters('Sipariş Kodu')],
        [this.convertToTurkishCharacters('Toplam')]
      ]]
    const data = this.filteredTransactionsCombined.map((item: any) => [
      [item.order_date],
      [item.customer_name],
      [item.special_code],
      [item.order_code],
      [item.total_amount],
    ]);


    autoTable(doc, {
      head: head,
      body: data,
      styles: {
        font: "Poppins",
        fontStyle: "normal",
      },
      bodyStyles: { fillColor: [200, 200, 200] }
    });

    doc.save('iptal-iade.pdf');
  }

  onItemSelected(event: any): void {
    this.selectedBranch = event.target.value;
    this.loadCancelData();
    this.cdr.detectChanges();
  }

  get filteredTransactionsCombined() {
    const searchCustomerTitle = this.customerTitleTerm?.toLowerCase() || '';

    return this.cancelTransactionData.data.filter((item: any) => {
      const itemCustomerTitle = item.customer?.toLowerCase() || '';

      return (!this.searchDateTerm || item.created_at.includes(this.searchDateTerm)) &&
        (!searchCustomerTitle || itemCustomerTitle.includes(searchCustomerTitle)) &&
        (!this.sipSpecialCodeTerm || item.order.special_code.toString().includes(this.sipSpecialCodeTerm)) &&
        (!this.orderCodeTerm || item.order.order_code.toString().includes(this.orderCodeTerm)) &&
        (!this.collectedTerm || item.total_amount.toString().includes(this.collectedTerm));

    });
  }

  navigateToDetail(id: string) {
    this.router.navigate(['/order/detail', id]);
  }

  toggleCalendar() {
    this.showDatepicker = !this.showDatepicker;
    if (!this.showDatepicker) {
      this.showSaveButton = false;
    }
  }

  onRangeChange(event: any) {
    const start = new Date(event[0]);
    const endIndex = event.length - 1;
    const end = new Date(event[endIndex]);

    const formattedStart = `${start.getFullYear()}-${(start.getMonth() + 1).toString().padStart(2, '0')}-${start.getDate().toString().padStart(2, '0')}`; // YYYY-MM-DD
    const formattedEnd = `${end.getFullYear()}-${(end.getMonth() + 1).toString().padStart(2, '0')}-${end.getDate().toString().padStart(2, '0')}`; // YYYY-MM-DD

    this.selectedDateRange = {
      start: formattedStart,
      end: formattedEnd,
    };
    this.showSaveButton = true;
  }

  saveDateRange() {
    if (this.selectedDateRange) {
      this.showDatepicker = false;
      this.showSaveButton = false;

      if(this.selectedBranch){
        this.ordersService.allOrdersStatus("1", "12", this.selectedDateRange.start, this.selectedDateRange.end,"CANCEL",true,this.selectedBranch).subscribe(
          response => {
            this.cancelTransactionData = response;
            this.calculatePages();
            this.updateValidPages();
            this.cdr.detectChanges();
          },
          error => {
            console.error('Hata:', error);
          }
        );
      }else{
        this.ordersService.allOrdersStatus("1", "12", this.selectedDateRange.start, this.selectedDateRange.end,"CANCEL").subscribe(
          response => {
            this.cancelTransactionData = response;
            this.calculatePages();
            this.updateValidPages();
            this.cdr.detectChanges();
          },
          error => {
            console.error('Hata:', error);
          }
        );
      }
    } else {
      console.log('Tarih aralığı seçilmedi.');
    }
  }

  calculatePages() {
    const totalPages = Math.ceil(this.cancelTransactionData.total_count / 12);
    this.pages = Array.from({ length: totalPages }, (_, i) => i + 1);
  }

  updateValidPages() {
    const validPages: number[] = [];
    if (this.selectedDateRange) {
      const promises = this.pages.map(page =>
        this.cancelTransactionService.allCancelTransActions(page.toString(), "12", this.selectedDateRange?.start, this.selectedDateRange?.end).toPromise().then(
          response => {
            if (response && response.data.length > 0) {
              validPages.push(page);
            }
          }
        ).catch(error => {
          console.error('Hata:', error);
        })
      );
      Promise.all(promises).then(() => {
        this.validPages = validPages;
      });
    } else {
      const today = new Date();
      const formattedtoday = `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}`;
      const promises = this.pages.map(page =>
        this.cancelTransactionService.allCancelTransActions(page.toString(), "12", formattedtoday, formattedtoday).toPromise().then(
          response => {
            if (response && response.data.length > 0) {
              validPages.push(page);
            }
          }
        ).catch(error => {
          console.error('Hata:', error);
        })
      );
      Promise.all(promises).then(() => {
        this.validPages = validPages;
      });
    }

  }

  goToPage(page: number) {
    if (this.validPages.includes(page)) {
      if (this.selectedDateRange) {
        if(this.selectedBranch){
          this.ordersService.allOrdersStatus(page.toString(), "12", this.selectedDateRange.start, this.selectedDateRange.end,"CANCEL",true,this.selectedBranch).subscribe(
            response => {
              if (response && response.data.length > 0) {
                this.cancelTransactionData = response;
                this.transactions.paginate(page);
                this.cdr.detectChanges(); 
              } else {
                console.warn(`Page ${page} is empty and will not be displayed.`);
                this.updateValidPages();
              }
            },
            error => {
              console.error('Hata:', error);
            }
          );
        }else{
          this.ordersService.allOrdersStatus(page.toString(), "12", this.selectedDateRange.start, this.selectedDateRange.end,"CANCEL").subscribe(
            response => {
              if (response && response.data.length > 0) {
                this.cancelTransactionData = response;
                this.transactions.paginate(page);
                this.cdr.detectChanges(); 
              } else {
                console.warn(`Page ${page} is empty and will not be displayed.`);
                this.updateValidPages();
              }
            },
            error => {
              console.error('Hata:', error);
            }
          );
        }
      } else {
        if(this.selectedBranch){
          const today = new Date();
          const formattedtoday = `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}`;
          this.ordersService.allOrdersStatus(page.toString(), "12", formattedtoday, formattedtoday,"CANCEL",true,this.selectedBranch).subscribe(
            response => {
              if (response && response.data.length > 0) {
                this.cancelTransactionData = response;
                this.transactions.paginate(page);
                this.cdr.detectChanges(); 
              } else {
                console.warn(`Page ${page} is empty and will not be displayed.`);
                this.updateValidPages();
              }
            },
            error => {
              console.error('Hata:', error);
            }
          );
        }else{
          const today = new Date();
        const formattedtoday = `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}`;
        this.ordersService.allOrdersStatus(page.toString(), "12", formattedtoday, formattedtoday,"CANCEL").subscribe(
          response => {
            if (response && response.data.length > 0) {
              this.cancelTransactionData = response;
              this.transactions.paginate(page);
              this.cdr.detectChanges(); 
            } else {
              console.warn(`Page ${page} is empty and will not be displayed.`);
              this.updateValidPages();
            }
          },
          error => {
            console.error('Hata:', error);
          }
        );
        }
      }
    } else {
      console.warn(`Page ${page} is not valid and will not be displayed.`);
    }
  }
  

  updatePageRange() {
    this.endPage = Math.min(this.startPage + 9, this.pages.length);
  }
  
  nextPageGroup() {
    if (this.endPage < this.pages.length) {
      this.startPage = this.endPage + 1;
      this.endPage = Math.min(this.startPage + 9, this.pages.length);
      this.cdr.detectChanges();
    }
  }

  prevPageGroup() {
    if (this.startPage > 1) {
      this.endPage = this.startPage - 1;
      this.startPage = Math.max(this.endPage - 9, 1);
      this.cdr.detectChanges();
    }
  }

  onBranchChange(event: any) {
    const selectedCodes = event.value.map((item: any) => item.id);
    this.selectedBranch = selectedCodes
    this.loadCancelData();
    this.cdr.detectChanges();
  }
}
