
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GetWorkingHoursModel } from '../../models/settings/get-working-house-response.model';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class GetWorkingHoursService {

    private apiUrl = `${environment.apiUrl}/settings/get-working-hours`;
    private token = localStorage.getItem('authToken');
    constructor(private http: HttpClient) { }

    allHoursData(): Observable<GetWorkingHoursModel[]> {
        const headers = new HttpHeaders()
            .set('Authorization', `Bearer ${this.token}`);
        const urlPath = `${this.apiUrl}`;
        return this.http.get<GetWorkingHoursModel[]>(urlPath, { headers });
    }

    private addApiUrl = `${environment.apiUrl}/settings/add-working-hours`;
    addWorkHour(firmsId: string, day: string, startRange: string, endRange: string): Observable<any> {
        const headers = new HttpHeaders()
            .set('Authorization', `Bearer ${this.token}`);
        const body = {
            "firm_id": firmsId,
            "day_of_week": day,
            "time_intervals": [
                {
                    "start": startRange,
                    "end": endRange
                }
            ]
        };
        return this.http.post<any>(this.addApiUrl, body, { headers });
    }

    private deleteApiUrl = `${environment.apiUrl}/settings/delete-working-hours`;
    deleteWorkHour(firmId: string, day: string, start: string, end: string): Observable<any> {
        const headers = new HttpHeaders()
            .set('Authorization', `Bearer ${this.token}`);
        let params = new HttpParams()
            .set('firm_id', firmId)
            .set('day', day)
            .set('start', start)
            .set('end', end);
        return this.http.delete<any>(this.deleteApiUrl, { headers, params });
    }
}
