<div class=" bg-n0 dark:bg-bg4 box overflow-y-auto max-h-[800px]">
  <div class="flex justify-between items-center bb-dashed pb-6 mb-6">
    <h4>Ürün Sil</h4><i (click)="closeModal()" class="las cursor-pointer la-times"></i>
  </div>
  <div>
    <p>
      Bu ürünü silmek istediğinize emin misiniz ?
    </p>
  </div>
<div class="prodDelete">

  <div class="col-span-2 mt-4">
    <button (click)="closeModal()" class="btn flex w-full justify-center" type="submit">Kapat</button>
  </div>
  <div class="col-span-2 mt-4">
    <button (click)="deleteProduct()" class="deleteButton btn flex w-full justify-center" type="submit">Sil</button>
  </div>
</div>
</div>