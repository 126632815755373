import { TransactionStatus } from "@data/dashboards/style2Transactions";

export const marketOverviewData = [
  {
    id: 1,
    amount: 20,
    charge: 50.00,
    status: TransactionStatus.Successful,
    change: 3,
    title: 'Jacobs Monarch Filtre Kahve 500 gr ',
    process: 95
  },
  {
    id: 2,
    amount: 20,
    charge: 50.00,
    status: TransactionStatus.Cancelled,
    change: 25,
    title: 'Jacobs Monarch Filtre ',
    process: 50
  },
  {
    id: 3,
    amount: 20,
    charge: 50.00,
    status: TransactionStatus.Pending,
    change: -4,
    title: 'Jacobs Monarch Filtre Kahve 500 gr ',
    process: 25
  },
  {
    id: 4,
    amount: 20,
    charge: 50.00,
    status: TransactionStatus.Successful,
    change: -1,
    title: 'Jacobs Monarch Filtre Kahve 500 gr ',
    process: 45
  },
  
]
