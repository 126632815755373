<div class="grid grid-cols-12 gap-4 xxl:gap-6">

  <div class="col-span-12 lg:col-span-6 xxl:col-span-7 xxxl:col-span-6">

    <div class="flex justify-between border-gray-200 custom-select" style="margin-bottom: 1rem;" id="tabMenuItem">
      <select class="dashboardBranch" (change)="onBranchSelected($event)">
        <option *ngFor="let item of branchList" [value]="item.id">{{ item.title }}</option>
      </select>

      <div class="filter-container">
        <div (click)="openFilterModal()" class="filterSelect">
          <i class="fa-solid fa-filter fa-sm" style="color: white;"></i>
          <div *ngIf="filtersData != null" class="notification-badge">X</div>
        </div>
      </div>
    </div>

    <div *ngIf="filtersData == null" class="flex justify-between bg-white p-4 border-b border-gray-200"
      id="tabMenuItem">
      <button (click)="setFilter('today','TODAY')" [ngClass]="{'active': filter === 'today'}"
        class="tab-button">Bugün</button>
      <button (click)="setFilter('yesterday','YESTERDAY')" [ngClass]="{'active': filter === 'yesterday'}"
        class="tab-button">Dün</button>
      <button (click)="setFilter('lastWeek','THIS_WEEK')" [ngClass]="{'active': filter === 'lastWeek'}"
        class="tab-button">Son
        Hafta</button>
      <button (click)="setFilter('lastMounth','THIS_MONTH')" [ngClass]="{'active': filter === 'lastMounth'}"
        class="tab-button">Son
        Ay</button>
      <button (click)="setFilter('lastYear','THIS_YEAR')" [ngClass]="{'active': filter === 'lastYear'}"
        class="tab-button">Son
        Yıl</button>
    </div>

    <div *ngIf="filtersData != null" class="grid grid-cols-2 gap-4 xxl:gap-6 mt-4">
      <div *ngFor="let chart of statCharts; let i = index;"
        class="col-span-2 sm:col-span-1 lg:col-span-2 xxl:col-span-1 box p-3 md:p-4 xxxl:px-8 xxxl:py-6">
        <div class="flex items-center justify-between bb-dashed mb-4 pb-4 ">
          <div class="flex items-center gap-3">
            <h4 *ngIf="transDataCounter[i] == 'Başarılı Satış İşlemi' " class="h4 mb-3">
              {{filtersData.transAcData.SUCCESS.total_values.transaction_count || '0'}}
              Adet
            </h4>
            <h4 *ngIf="transDataCounter[i] == 'Toplam İptal Tutarı' " class="h4 mb-3">
              {{formatAmount(filtersData.transAcData.CANCEL.total_values.sum_total_amount)|| 'Veri Yok'}} ₺</h4>
            <h4 *ngIf="transDataCounter[i] == 'Brüt Satış Tutarı' " class="h4 mb-3">
              {{formatAmount(filtersData.transAcData.SUCCESS.total_values.sum_total_amount)|| 'Veri Yok'}} ₺</h4>
            <h4 *ngIf="transDataCounter[i] == 'Toplam KDV Tutarı' " class="h4 mb-3">
              {{formatAmount(filtersData.transAcData.SUCCESS.total_values.sum_tax_amount)|| 'Veri Yok'}} ₺</h4>
          </div>
        </div>
        <div class="flex items-center justify-between">
          <div>
            <p class="font-medium"> {{transDataCounter[i]}} </p>
          </div>
          <apx-chart [chart]="chart.chart" [stroke]="chart.stroke!" [dataLabels]="chart.dataLabels!"
            [markers]="chart.markers!" [colors]="chart.colors!" [xaxis]="chart.xaxis!" [yaxis]="chart.yaxis!"
            [tooltip]="chart.tooltip!" [fill]="chart.fill!" [responsive]="chart.responsive" [series]="chart.series"
            [legend]="chart.legend!" />
        </div>
      </div>
    </div>


    <div *ngIf="filter == 'today' && filtersData == null" class="grid grid-cols-2 gap-4 xxl:gap-6 mt-4">
      <div *ngFor="let chart of statCharts; let i = index;"
        class="col-span-2 sm:col-span-1 lg:col-span-2 xxl:col-span-1 box p-3 md:p-4 xxxl:px-8 xxxl:py-6">
        <div class="flex items-center justify-between bb-dashed mb-4 pb-4 ">
          <div class="flex items-center gap-3">
            <h4 *ngIf="transDataCounter[i] == 'Başarılı Satış İşlemi' " class="h4 mb-3">
              {{dailyData.SUCCESS.total_values.transaction_count || '0'}}
              Adet
            </h4>
            <h4 *ngIf="transDataCounter[i] == 'Toplam İptal Tutarı' " class="h4 mb-3">
              {{formatAmount(dailyData.CANCEL.total_values.sum_total_amount)|| 'Veri Yok'}} ₺</h4>
            <h4 *ngIf="transDataCounter[i] == 'Brüt Satış Tutarı' " class="h4 mb-3">
              {{formatAmount(dailyData.SUCCESS.total_values.sum_total_amount)|| 'Veri Yok'}} ₺</h4>
            <h4 *ngIf="transDataCounter[i] == 'Toplam KDV Tutarı' " class="h4 mb-3">
              {{formatAmount(dailyData.SUCCESS.total_values.sum_tax_amount)|| 'Veri Yok'}} ₺</h4>
          </div>
        </div>
        <div class="flex items-center justify-between">
          <div>
            <p class="font-medium"> {{transDataCounter[i]}} </p>
          </div>
          <apx-chart [chart]="chart.chart" [stroke]="chart.stroke!" [dataLabels]="chart.dataLabels!"
            [markers]="chart.markers!" [colors]="chart.colors!" [xaxis]="chart.xaxis!" [yaxis]="chart.yaxis!"
            [tooltip]="chart.tooltip!" [fill]="chart.fill!" [responsive]="chart.responsive" [series]="chart.series"
            [legend]="chart.legend!" />
        </div>
      </div>
    </div>

    <div *ngIf="filter == 'yesterday' && filtersData == null " class="grid grid-cols-2 gap-4 xxl:gap-6 mt-4">
      <div *ngFor="let chart of statCharts; let i = index;"
        class="col-span-2 sm:col-span-1 lg:col-span-2 xxl:col-span-1 box p-3 md:p-4 xxxl:px-8 xxxl:py-6">
        <div class="flex items-center justify-between bb-dashed mb-4 pb-4 ">
          <div class="flex items-center gap-3">
            <h4 *ngIf="transDataCounter[i] == 'Başarılı Satış İşlemi' " class="h4 mb-3">
              {{yesterdayDataList.SUCCESS.total_values.transaction_count || '0'}}
              Adet
            </h4>
            <h4 *ngIf="transDataCounter[i] == 'Toplam İptal Tutarı' " class="h4 mb-3">
              {{formatAmount(yesterdayDataList.CANCEL.total_values.sum_total_amount)|| 'Veri Yok'}} ₺</h4>
            <h4 *ngIf="transDataCounter[i] == 'Brüt Satış Tutarı' " class="h4 mb-3">
              {{formatAmount(yesterdayDataList.SUCCESS.total_values.sum_total_amount)|| 'Veri Yok'}} ₺</h4>
            <h4 *ngIf="transDataCounter[i] == 'Toplam KDV Tutarı' " class="h4 mb-3">
              {{formatAmount(yesterdayDataList.SUCCESS.total_values.sum_tax_amount)|| 'Veri Yok'}} ₺</h4>
          </div>
        </div>
        <div class="flex items-center justify-between">
          <div>
            <p class="font-medium"> {{transDataCounter[i]}} </p>
          </div>
          <apx-chart [chart]="chart.chart" [stroke]="chart.stroke!" [dataLabels]="chart.dataLabels!"
            [markers]="chart.markers!" [colors]="chart.colors!" [xaxis]="chart.xaxis!" [yaxis]="chart.yaxis!"
            [tooltip]="chart.tooltip!" [fill]="chart.fill!" [responsive]="chart.responsive" [series]="chart.series"
            [legend]="chart.legend!" />
        </div>
      </div>
    </div>

    <div *ngIf="filter == 'lastWeek' && filtersData == null " class="grid grid-cols-2 gap-4 xxl:gap-6 mt-4">
      <div *ngFor="let chart of statCharts; let i = index;"
        class="col-span-2 sm:col-span-1 lg:col-span-2 xxl:col-span-1 box p-3 md:p-4 xxxl:px-8 xxxl:py-6">
        <div class="flex items-center justify-between bb-dashed mb-4 pb-4 ">
          <div class="flex items-center gap-3">
            <h4 *ngIf="transDataCounter[i] == 'Başarılı Satış İşlemi' " class="h4 mb-3">
              {{weekDataList.SUCCESS.total_values.transaction_count || '0'}}
              Adet
            </h4>
            <h4 *ngIf="transDataCounter[i] == 'Toplam İptal Tutarı' " class="h4 mb-3">
              {{formatAmount(weekDataList.CANCEL.total_values.sum_total_amount)|| 'Veri Yok'}} ₺</h4>
            <h4 *ngIf="transDataCounter[i] == 'Brüt Satış Tutarı' " class="h4 mb-3">
              {{formatAmount(weekDataList.SUCCESS.total_values.sum_total_amount)|| 'Veri Yok'}} ₺</h4>
            <h4 *ngIf="transDataCounter[i] == 'Toplam KDV Tutarı' " class="h4 mb-3">
              {{formatAmount(weekDataList.SUCCESS.total_values.sum_tax_amount)|| 'Veri Yok'}} ₺</h4>
          </div>
        </div>
        <div class="flex items-center justify-between">
          <div>
            <p class="font-medium"> {{transDataCounter[i]}} </p>
          </div>
          <apx-chart [chart]="chart.chart" [stroke]="chart.stroke!" [dataLabels]="chart.dataLabels!"
            [markers]="chart.markers!" [colors]="chart.colors!" [xaxis]="chart.xaxis!" [yaxis]="chart.yaxis!"
            [tooltip]="chart.tooltip!" [fill]="chart.fill!" [responsive]="chart.responsive" [series]="chart.series"
            [legend]="chart.legend!" />
        </div>
      </div>
    </div>

    <div *ngIf="filter == 'lastMounth' && filtersData == null " class="grid grid-cols-2 gap-4 xxl:gap-6 mt-4">
      <div *ngFor="let chart of statCharts; let i = index;"
        class="col-span-2 sm:col-span-1 lg:col-span-2 xxl:col-span-1 box p-3 md:p-4 xxxl:px-8 xxxl:py-6">
        <div class="flex items-center justify-between bb-dashed mb-4 pb-4 ">
          <div class="flex items-center gap-3">
            <h4 *ngIf="transDataCounter[i] == 'Başarılı Satış İşlemi' " class="h4 mb-3">
              {{monthDataList.SUCCESS.total_values.transaction_count || '0'}}
              Adet
            </h4>
            <h4 *ngIf="transDataCounter[i] == 'Toplam İptal Tutarı' " class="h4 mb-3">
              {{formatAmount(monthDataList.CANCEL.total_values.sum_total_amount)|| 'Veri Yok'}} ₺</h4>
            <h4 *ngIf="transDataCounter[i] == 'Brüt Satış Tutarı' " class="h4 mb-3">
              {{formatAmount(monthDataList.SUCCESS.total_values.sum_total_amount)|| 'Veri Yok'}} ₺</h4>
            <h4 *ngIf="transDataCounter[i] == 'Toplam KDV Tutarı' " class="h4 mb-3">
              {{formatAmount(monthDataList.SUCCESS.total_values.sum_tax_amount)|| 'Veri Yok'}} ₺</h4>
          </div>
        </div>
        <div class="flex items-center justify-between">
          <div>
            <p class="font-medium"> {{transDataCounter[i]}} </p>
          </div>
          <apx-chart [chart]="chart.chart" [stroke]="chart.stroke!" [dataLabels]="chart.dataLabels!"
            [markers]="chart.markers!" [colors]="chart.colors!" [xaxis]="chart.xaxis!" [yaxis]="chart.yaxis!"
            [tooltip]="chart.tooltip!" [fill]="chart.fill!" [responsive]="chart.responsive" [series]="chart.series"
            [legend]="chart.legend!" />
        </div>
      </div>
    </div>

    <div *ngIf="filter == 'lastYear' && filtersData == null " class="grid grid-cols-2 gap-4 xxl:gap-6 mt-4">
      <div *ngFor="let chart of statCharts; let i = index;"
        class="col-span-2 sm:col-span-1 lg:col-span-2 xxl:col-span-1 box p-3 md:p-4 xxxl:px-8 xxxl:py-6">
        <div class="flex items-center justify-between bb-dashed mb-4 pb-4 ">
          <div class="flex items-center gap-3">
            <h4 *ngIf="transDataCounter[i] == 'Başarılı Satış İşlemi' " class="h4 mb-3">
              {{yearDataList.SUCCESS.total_values.transaction_count}}
              Adet
            </h4>
            <h4 *ngIf="transDataCounter[i] == 'Toplam İptal Tutarı' " class="h4 mb-3">{{
              formatAmount(yearDataList.CANCEL.total_values.sum_total_amount) || 'Veri Yok'}} ₺</h4>
            <h4 *ngIf="transDataCounter[i] == 'Brüt Satış Tutarı' " class="h4 mb-3">
              {{formatAmount(yearDataList.SUCCESS.total_values.sum_total_amount)|| 'Veri Yok'}} ₺</h4>
            <h4 *ngIf="transDataCounter[i] == 'Toplam KDV Tutarı' " class="h4 mb-3">
              {{formatAmount(yearDataList.SUCCESS.total_values.sum_tax_amount)|| 'Veri Yok'}} ₺</h4>
          </div>
        </div>
        <div class="flex items-center justify-between">
          <div>
            <p class="font-medium"> {{transDataCounter[i]}} </p>
          </div>
          <apx-chart [chart]="chart.chart" [stroke]="chart.stroke!" [dataLabels]="chart.dataLabels!"
            [markers]="chart.markers!" [colors]="chart.colors!" [xaxis]="chart.xaxis!" [yaxis]="chart.yaxis!"
            [tooltip]="chart.tooltip!" [fill]="chart.fill!" [responsive]="chart.responsive" [series]="chart.series"
            [legend]="chart.legend!" />
        </div>
      </div>
    </div>
  </div>

  <div class="col-span-12 lg:col-span-6 xxl:col-span-5 xxxl:col-span-6">
    <div class="flex justify-between border-gray-200 custom-select" id="tabMenuItem">
    </div>
    <div class="col-span-12 md:col-span-7 lg:col-span-8 box overflow-x-hidden" id="tolatSales">
      <div class="flex flex-wrap justify-between items-center gap-3 pb-4 lg:pb-6 bb-dashed">
        <div class="flex row">
          <div class="column">
            <h4 *ngIf="selectedDefStatus == 'Aylık'|| selectedDefStatus == null && filtersData == null" class="h4">
              {{formatAmount(totalAmountMonth) || 'Veri Yok'}} ₺</h4>

            <h4 *ngIf="selectedDefStatus == 'Yıllık' && filtersData == null" class="h4">{{formatAmount(totalAmountYear)
              || 'Veri Yok' }} ₺
            </h4>

            <h4 *ngIf="filtersData" class="h4">
              {{formatAmount(filtersData.transAcData.SUCCESS.total_values.sum_total_amount) || 'Veri Yok' }} ₺
            </h4>

            <span>Toplam Net Satış Tutarı</span>
          </div>
        </div>
        <div *ngIf="filtersData == null" class="flex items-center gap-2">
          <select (change)="onFilterDefSale($event)"
            class="rounded-3xl w-full !py-3 formDevicesSet w-full mb-1 text-sm bg-n0 dark:bg-bg4 border border-n30 dark:border-n500 rounded-3xl px-3 md:px-6 py-2 md:py-3 ng-pristine ng-invalid ng-touched">
            <option *ngFor="let item of definiteSalesFilter" [value]="item">
              {{item}}
            </option>
          </select>
        </div>
      </div>
      <apx-chart *ngIf="selectedDefStatus == 'Aylık'|| selectedDefStatus == null && filtersData == null"
        [series]="incomeChartOptions.series" [chart]="incomeChartOptions.chart" [xaxis]="incomeChartOptions.xaxis!"
        [stroke]="incomeChartOptions.stroke!" [colors]="incomeChartOptions.colors!"
        [legend]="incomeChartOptions.legend!" [yaxis]="incomeChartOptions.yaxis!" [fill]="incomeChartOptions.fill!"
        [grid]="incomeChartOptions.grid!" [responsive]="incomeChartOptions.responsive"></apx-chart>

      <apx-chart *ngIf="selectedDefStatus == 'Yıllık' && filtersData == null" [series]="incomeChartOptionsYears.series"
        [chart]="incomeChartOptionsYears.chart" [xaxis]="incomeChartOptionsYears.xaxis!"
        [stroke]="incomeChartOptionsYears.stroke!" [colors]="incomeChartOptionsYears.colors!"
        [legend]="incomeChartOptionsYears.legend!" [yaxis]="incomeChartOptionsYears.yaxis!"
        [fill]="incomeChartOptionsYears.fill!" [grid]="incomeChartOptionsYears.grid!"
        [responsive]="incomeChartOptionsYears.responsive"></apx-chart>

      <apx-chart *ngIf="filtersData" [series]="incomeChartOptionsFilters.series"
        [chart]="incomeChartOptionsFilters.chart" [xaxis]="incomeChartOptionsFilters.xaxis!"
        [stroke]="incomeChartOptionsFilters.stroke!" [colors]="incomeChartOptionsFilters.colors!"
        [legend]="incomeChartOptionsFilters.legend!" [yaxis]="incomeChartOptionsFilters.yaxis!"
        [fill]="incomeChartOptionsFilters.fill!" [grid]="incomeChartOptionsFilters.grid!"
        [responsive]="incomeChartOptionsFilters.responsive"></apx-chart>
    </div>
  </div>

  <div class="col-span-12 flex flex-col gap-4 md:col-span-6 lg:col-span-6 xxl:gap-6 box overflow-x-hidden">
    <div class=" mb-4 xxl:mb-6 overflow-x-hidden">
      <div class="flex flex-wrap justify-between items-center gap-3 pb-4 lg:pb-6 bb-dashed">
        <div class="flex row">
          <div class="column">
            <p class="font-medium">Genel Tahsilat</p>
          </div>
        </div>
        <div *ngIf="filtersData == null" class="flex items-center gap-2">
          <select (change)="onFilterDefPaymenyType($event)"
            class="rounded-3xl w-full !py-3 formDevicesSet w-full mb-1 text-sm bg-n0 dark:bg-bg4 border border-n30 dark:border-n500 rounded-3xl px-3 md:px-6 py-2 md:py-3 ng-pristine ng-invalid ng-touched">
            <option *ngFor="let item of definiteSalesFilter" [value]="item">
              {{item}}
            </option>
          </select>
        </div>
      </div>
      <div class="collection-card">
        <apx-chart *ngIf="selectedPaymentType == 'Yıllık' && filtersData == null" [series]="generalCollection.series"
          [chart]="generalCollection.chart" [fill]="generalCollection.fill!"
          [plotOptions]="generalCollection.plotOptions!" [legend]="generalCollection.legend!"
          [labels]="generalCollection.labels!" [dataLabels]="generalCollection.dataLabels!">
        </apx-chart>

        <apx-chart *ngIf="selectedPaymentType == 'Aylık'|| selectedPaymentType == null && filtersData == null"
          [series]="generalCollectionMonth.series" [chart]="generalCollectionMonth.chart"
          [fill]="generalCollectionMonth.fill!" [plotOptions]="generalCollectionMonth.plotOptions!"
          [legend]="generalCollectionMonth.legend!" [labels]="generalCollectionMonth.labels!"
          [dataLabels]="generalCollectionMonth.dataLabels!">
        </apx-chart>

        <apx-chart *ngIf="filtersData" [series]="generalCollectionFilter.series" [chart]="generalCollectionFilter.chart"
          [fill]="generalCollectionFilter.fill!" [plotOptions]="generalCollectionFilter.plotOptions!"
          [legend]="generalCollectionFilter.legend!" [labels]="generalCollectionFilter.labels!"
          [dataLabels]="generalCollectionFilter.dataLabels!">
        </apx-chart>

        <ng-template #noData>
          <p>Veri bulunmamaktadır.</p>
        </ng-template>

      </div>
    </div>
  </div>

  <div class="col-span-12 lg:col-span-6 box overflow-x-hidden" id="mealCardsArea">
    <div class="flex flex-wrap justify-between items-center gap-3 pb-4 lg:pb-6 mb-4 lg:mb-6 bb-dashed">
      <div class="flex row">
        <div class="column">
          <h4 *ngIf="filtersData == null" class="h4">
            {{formatAmount(mealCardWeekDataList.SUCCESS.total_values.sum_total_amount)}} ₺</h4>
          <h4 *ngIf="filtersData" class="h4">{{formatAmount(filterMealCardTotal)}} ₺</h4>
          <span>Yemek Kartları</span>
        </div>
      </div>
      <div class="flex items-center gap-2">
        <select *ngIf="filtersData == null" (change)="onFilterMealCard($event)"
          class="rounded-3xl w-full !py-3 formDevicesSet w-full mb-1 text-sm bg-n0 dark:bg-bg4 border border-n30 dark:border-n500 rounded-3xl px-3 md:px-6 py-2 md:py-3 ng-pristine ng-invalid ng-touched">
          <option *ngFor="let item of mealCardsList" [value]="item.key">
            {{item.value}}
          </option>
        </select>

        <select *ngIf="filtersData" (change)="onFiltersMealCard($event)"
          class="rounded-3xl w-full !py-3 formDevicesSet w-full mb-1 text-sm bg-n0 dark:bg-bg4 border border-n30 dark:border-n500 rounded-3xl px-3 md:px-6 py-2 md:py-3 ng-pristine ng-invalid ng-touched">
          <option *ngFor="let item of mealCardsList" [value]="item.key">
            {{item.value}}
          </option>
        </select>

      </div>
    </div>
    <apx-chart *ngIf="filtersData == null" [chart]="foodCardChart.chart" [stroke]="foodCardChart.stroke!"
      [dataLabels]="foodCardChart.dataLabels!" [markers]="foodCardChart.markers!" [colors]="foodCardChart.colors!"
      [xaxis]="foodCardChart.xaxis!" [yaxis]="foodCardChart.yaxis!" [tooltip]="foodCardChart.tooltip!"
      [fill]="foodCardChart.fill!" [responsive]="foodCardChart.responsive" [series]="foodCardChart.series"
      [plotOptions]="foodCardChart.plotOptions!" [legend]="foodCardChart.legend!" />

    <apx-chart *ngIf="filtersData" [chart]="foodCardChartFilter.chart" [stroke]="foodCardChartFilter.stroke!"
      [dataLabels]="foodCardChartFilter.dataLabels!" [markers]="foodCardChartFilter.markers!"
      [colors]="foodCardChartFilter.colors!" [yaxis]="foodCardChartFilter.yaxis!"
      [tooltip]="foodCardChartFilter.tooltip!" [fill]="foodCardChartFilter.fill!" [xaxis]="foodCardChartFilter.xaxis!"
      [responsive]="foodCardChartFilter.responsive" [series]="foodCardChartFilter.series"
      [plotOptions]="foodCardChartFilter.plotOptions!" [legend]="foodCardChartFilter.legend!" />

  </div>

  <div class="box col-span-12" id="collectionArea">
    <div class="flex flex-wrap gap-4 justify-between items-center bb-dashed mb-4 pb-4 ">
      <h4 class="h4">Tahsilatlar</h4>
      <div class="flex items-center max-lg:flex-wrap gap-4">

        <div class="rounded-[32px] bg-primary/5 border border-n30 dark:border-n500 flex invoice-btns">
          <!-- @for (item of allTransactions; track $index) {
          <button  class="font-medium text-xs px-4 sm:px-5 xxxl:px-6 py-3 capitalize"
            [ngClass]="{ 'invoice-active': item == currentFilter }">{{ item }}</button>
          } -->
        </div>
        <div class="flex items-center gap-3 whitespace-nowrap">
          <!-- <span>Filtrele : </span>
          <app-dropdown /> -->
        </div>
      </div>
    </div>
    <div class="overflow-x-auto mb-4 lg:mb-6">
      <table class="w-full whitespace-nowrap">
        <thead>
          <tr class="bg-secondary1/5 dark:bg-bg3">
            <th class="text-left p-5">Tahsilat Tipi</th>
            <th class="text-start py-5 min-w-[130px]">Toplam Başarılı Tutar/İşlem</th>
            <th class="text-start py-5 min-w-[130px]">
              <div class="flex items-center gap-1">Toplam Başarısız Tutar/İşlem</div>
            </th>
            <th class="text-center py-5 min-w-[130px]">Toplam Bekleyen Tutar/İşlem</th>
            <th class="text-center py-5 min-w-[130px]">Toplam İptal Tutar/İşlem</th>
            <th class="text-center py-5 min-w-[130px]">Genel Toplam</th>
            <th class="text-center py-5 min-w-[130px]">Toplam İşlem </th>
          </tr>
        </thead>
        <tbody *ngIf="filtersData == null">
          <tr *ngFor="let item of getTableData() let i = index;" [ngClass]="i % 2 === 0 ? 'even-row' : 'odd-row'">
            <td class="w-[15%]">
              <div class="bg-secondary1/5 dark:bg-bg3 py-5 px-3">
                {{ item.paymentType }}
              </div>
            </td>
            <td class="w-[15%]">
              <div class="bg-secondary1/5 dark:bg-bg3 py-5 px-3">
                {{ formatAmount(item.totalAmount)}}₺ / {{item.transactionCount}}
              </div>
            </td>
            <td class="w-[15%]">
              <div class="bg-secondary1/5 dark:bg-bg3 py-5 px-3">
                {{ formatAmount(item.failTotalAmount)}}₺ / {{item.failTransactionCount}}
              </div>
            </td>
            <td class="w-[15%]">
              <div class="bg-secondary1/5 dark:bg-bg3 py-5 px-3">
                {{ formatAmount(item.waitingTotalAmount)}}₺ / {{item.waitingTransactionCount}}
              </div>
            </td>
            <td class="w-[15%]">
              <div class="bg-secondary1/5 dark:bg-bg3 py-5 px-3">
                {{ formatAmount(item.cancelTotalAmount)}}₺ / {{item.cancelTransactionCount}}
              </div>
            </td>
            <td class="w-[15%]">
              <div class="bg-secondary1/5 dark:bg-bg3 py-5 px-3">
                {{ formatAmount(item.totalAmount)}}₺
              </div>
            </td>
            <td class="w-[15%]">
              <div class="bg-secondary1/5 dark:bg-bg3 py-5 px-3">
                {{ item.transactionCount}}
              </div>
            </td>
          </tr>
        </tbody>
        <tbody *ngIf="filtersData">
          <tr *ngFor="let item of getTableDataForFilter() let i = index;"
            [ngClass]="i % 2 === 0 ? 'even-row' : 'odd-row'">
            <td class="w-[15%]">
              <div class="bg-secondary1/5 dark:bg-bg3 py-5 px-3">
                {{ item.paymentType }}
              </div>
            </td>
            <td class="w-[15%]">
              <div class="bg-secondary1/5 dark:bg-bg3 py-5 px-3">
                {{ formatAmount(item.totalAmount)}}₺ / {{formatAmount(item.transactionCount)}}
              </div>
            </td>
            <td class="w-[15%]">
              <div class="bg-secondary1/5 dark:bg-bg3 py-5 px-3">
                {{ formatAmount(item.failTotalAmount)}}₺ / {{formatAmount(item.failTransactionCount)}}
              </div>
            </td>
            <td class="w-[15%]">
              <div class="bg-secondary1/5 dark:bg-bg3 py-5 px-3">
                {{ formatAmount(item.waitingTotalAmount)}}₺ / {{formatAmount(item.waitingTransactionCount)}}
              </div>
            </td>
            <td class="w-[15%]">
              <div class="bg-secondary1/5 dark:bg-bg3 py-5 px-3">
                {{ formatAmount(item.cancelTotalAmount)}}₺ / {{formatAmount(item.cancelTransactionCount)}}
              </div>
            </td>
            <td class="w-[15%]">
              <div class="bg-secondary1/5 dark:bg-bg3 py-5 px-3">
                {{ formatAmount(item.totalAmount)}}₺
              </div>
            </td>
            <td class="w-[15%]">
              <div class="bg-secondary1/5 dark:bg-bg3 py-5 px-3">
                {{ item.transactionCount}}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div *ngIf="this.allTransactionsData.payment_types == null && filtersData == null" class="content-not-found"
      style="margin-bottom: 2rem;">
      <img src="../../../../assets/images/content-not-found.png" alt="">
      <h4 style="margin-top: 1rem;">İçerik Bulunamadı</h4>
    </div>

    <div *ngIf="filter == 'today' && filtersData == null "
      class="col-span-12 lg:col-span-6 xxl:col-span-7 xxxl:col-span-6" id="totalDataTable">
      <div class="col-span-3 row leftSide">
        <div class="column">
          <p>Toplam Başarılı Tutar</p>
          <p class="subItem">Toplam Başarılı İşlem: {{dailyData.SUCCESS.total_values.transaction_count}} Adet</p>
        </div>
        <div class="totalSection">
          <p>{{formatAmount(dailyData.SUCCESS.total_values.sum_total_amount)}}₺</p>
        </div>
      </div>

      <div class="col-span-3 row leftSide" id="leftSide2">
        <div class="column">
          <p>Toplam Bekleyen Tutar</p>
          <p class="subItem">Toplam Bekleyen İşlem: {{dailyData.WAITING.total_values.transaction_count}} Adet</p>
        </div>
        <div class="totalSection">
          <p>{{formatAmount(dailyData.WAITING.total_values.sum_total_amount)}}₺</p>
        </div>
      </div>

      <div class="col-span-3 row leftSide" id="leftSide3">
        <div class="column">
          <p>Toplam İptal Tutar</p>
          <p class="subItem">Toplam Bekleyen İşlem: {{dailyData.CANCEL.total_values.transaction_count}} Adet</p>
        </div>
        <div class="totalSection">
          <p>{{formatAmount(dailyData.CANCEL.total_values.sum_total_amount)}}₺</p>
        </div>
      </div>

      <div class="col-span-3 row leftSide" id="leftSide4">
        <div class="column">
          <p>Toplam Başarısız Tutar</p>
          <p class="subItem">Toplam Bekleyen İşlem: {{dailyData.FAIL.total_values.transaction_count}} Adet</p>
        </div>
        <div class="totalSection">
          <p>{{formatAmount(dailyData.FAIL.total_values.sum_total_amount)}}₺</p>
        </div>
      </div>
    </div>

    <div *ngIf="filter == 'yesterday' && filtersData == null"
      class="col-span-12 lg:col-span-6 xxl:col-span-7 xxxl:col-span-6" id="totalDataTable">
      <div class="col-span-3 row leftSide">
        <div class="column">
          <p>Toplam Başarılı Tutar</p>
          <p class="subItem">Toplam Başarılı İşlem: {{yesterdayDataList.SUCCESS.total_values.transaction_count}} Adet
          </p>
        </div>
        <div class="totalSection">
          <p>{{formatAmount(yesterdayDataList.SUCCESS.total_values.sum_total_amount)}}₺</p>
        </div>
      </div>

      <div class="col-span-3 row leftSide" id="leftSide2">
        <div class="column">
          <p>Toplam Bekleyen Tutar</p>
          <p class="subItem">Toplam Bekleyen İşlem: {{yesterdayDataList.WAITING.total_values.transaction_count}} Adet
          </p>
        </div>
        <div class="totalSection">
          <p>{{formatAmount(yesterdayDataList.WAITING.total_values.sum_total_amount)}}₺</p>
        </div>
      </div>

      <div class="col-span-3 row leftSide" id="leftSide3">
        <div class="column">
          <p>Toplam İptal Tutar</p>
          <p class="subItem">Toplam İptal İşlem: {{yesterdayDataList.CANCEL.total_values.transaction_count}} Adet</p>
        </div>
        <div class="totalSection">
          <p>{{formatAmount(yesterdayDataList.CANCEL.total_values.sum_total_amount)}}₺</p>
        </div>
      </div>

      <div class="col-span-3 row leftSide" id="leftSide4">
        <div class="column">
          <p>Toplam Başarısız Tutar</p>
          <p class="subItem">Toplam Başarısız İşlem: {{yesterdayDataList.FAIL.total_values.transaction_count}} Adet</p>
        </div>
        <div class="totalSection">
          <p>{{formatAmount(yesterdayDataList.FAIL.total_values.sum_total_amount)}}₺</p>
        </div>
      </div>
    </div>

    <div *ngIf="filter == 'lastWeek' && filtersData == null"
      class="col-span-12 lg:col-span-6 xxl:col-span-7 xxxl:col-span-6" id="totalDataTable">
      <div class="col-span-3 row leftSide">
        <div class="column">
          <p>Toplam Başarılı Tutar</p>
          <p class="subItem">Toplam Başarılı İşlem: {{weekDataList.SUCCESS.total_values.transaction_count}} Adet</p>
        </div>
        <div class="totalSection">
          <p>{{formatAmount(weekDataList.SUCCESS.total_values.sum_total_amount)}}₺</p>
        </div>
      </div>

      <div class="col-span-3 row leftSide" id="leftSide2">
        <div class="column">
          <p>Toplam Bekleyen Tutar</p>
          <p class="subItem">Toplam Bekleyen İşlem: {{weekDataList.WAITING.total_values.transaction_count}} Adet</p>
        </div>
        <div class="totalSection">
          <p>{{formatAmount(weekDataList.WAITING.total_values.transaction_count)}}₺</p>
        </div>
      </div>

      <div class="col-span-3 row leftSide" id="leftSide3">
        <div class="column">
          <p>Toplam İptal Tutar</p>
          <p class="subItem">Toplam İptal İşlem: {{weekDataList.CANCEL.total_values.transaction_count}} Adet</p>
        </div>
        <div class="totalSection">
          <p>{{formatAmount(weekDataList.CANCEL.total_values.transaction_count)}}₺</p>
        </div>
      </div>

      <div class="col-span-3 row leftSide" id="leftSide4">
        <div class="column">
          <p>Toplam Başarısız Tutar</p>
          <p class="subItem">Toplam Başarısız İşlem: {{weekDataList.FAIL.total_values.transaction_count}} Adet</p>
        </div>
        <div class="totalSection">
          <p>{{formatAmount(weekDataList.FAIL.total_values.transaction_count)}}₺</p>
        </div>
      </div>
    </div>

    <div *ngIf="filter == 'lastMounth' && filtersData == null"
      class="col-span-12 lg:col-span-6 xxl:col-span-7 xxxl:col-span-6" id="totalDataTable">
      <div class="col-span-3 row leftSide">
        <div class="column">
          <p>Toplam Başarılı Tutar</p>
          <p class="subItem">Toplam Başarılı İşlem: {{monthDataList.SUCCESS.total_values.transaction_count}} Adet</p>
        </div>
        <div class="totalSection">
          <p>{{formatAmount(monthDataList.SUCCESS.total_values.sum_total_amount)}}₺</p>
        </div>
      </div>

      <div class="col-span-3 row leftSide" id="leftSide2">
        <div class="column">
          <p>Toplam Bekleyen Tutar</p>
          <p class="subItem">Toplam Bekleyen İşlem: {{monthDataList.WAITING.total_values.transaction_count}} Adet</p>
        </div>
        <div class="totalSection">
          <p>{{formatAmount(monthDataList.WAITING.total_values.transaction_count)}}₺</p>
        </div>
      </div>

      <div class="col-span-3 row leftSide" id="leftSide3">
        <div class="column">
          <p>Toplam İptal Tutar</p>
          <p class="subItem">Toplam İptal İşlem: {{monthDataList.CANCEL.total_values.transaction_count}} Adet</p>
        </div>
        <div class="totalSection">
          <p>{{formatAmount(monthDataList.CANCEL.total_values.transaction_count)}}₺</p>
        </div>
      </div>

      <div class="col-span-3 row leftSide" id="leftSide4">
        <div class="column">
          <p>Toplam Başarısız Tutar</p>
          <p class="subItem">Toplam Başarısız İşlem: {{monthDataList.FAIL.total_values.transaction_count}} Adet</p>
        </div>
        <div class="totalSection">
          <p>{{formatAmount(monthDataList.FAIL.total_values.transaction_count)}}₺</p>
        </div>
      </div>
    </div>

    <div *ngIf="filter == 'lastYear' && filtersData == null"
      class="col-span-12 lg:col-span-6 xxl:col-span-7 xxxl:col-span-6" id="totalDataTable">
      <div class="col-span-3 row leftSide">
        <div class="column">
          <p>Toplam Başarılı Tutar</p>
          <p class="subItem">Toplam Başarılı İşlem: {{yearDataList.SUCCESS.total_values.transaction_count}} Adet</p>
        </div>
        <div class="totalSection">
          <p>{{formatAmount(yearDataList.SUCCESS.total_values.sum_total_amount)}}₺</p>
        </div>
      </div>

      <div class="col-span-3 row leftSide" id="leftSide2">
        <div class="column">
          <p>Toplam Bekleyen Tutar</p>
          <p class="subItem">Toplam Bekleyen İşlem: {{yearDataList.WAITING.total_values.transaction_count}} Adet</p>
        </div>
        <div class="totalSection">
          <p>{{formatAmount(yearDataList.WAITING.total_values.sum_total_amount)}}₺</p>
        </div>
      </div>

      <div class="col-span-3 row leftSide" id="leftSide3">
        <div class="column">
          <p>Toplam İptal Tutar</p>
          <p class="subItem">Toplam İptal İşlem: {{yearDataList.CANCEL.total_values.transaction_count}} Adet</p>
        </div>
        <div class="totalSection">
          <p>{{formatAmount(yearDataList.CANCEL.total_values.sum_total_amount)}}₺</p>
        </div>
      </div>

      <div class="col-span-3 row leftSide" id="leftSide4">
        <div class="column">
          <p>Toplam Başarısız Tutar</p>
          <p class="subItem">Toplam Başarısız İşlem: {{yearDataList.FAIL.total_values.transaction_count}} Adet</p>
        </div>
        <div class="totalSection">
          <p>{{formatAmount(yearDataList.FAIL.total_values.sum_total_amount)}}₺</p>
        </div>
      </div>
    </div>

    <div *ngIf="filtersData" class="col-span-12 lg:col-span-6 xxl:col-span-7 xxxl:col-span-6" id="totalDataTable">
      <div class="col-span-3 row leftSide">
        <div class="column">
          <p>Toplam Başarılı Tutar</p>
          <p class="subItem">Toplam Başarılı İşlem: {{filtersData.transAcData.SUCCESS.total_values.transaction_count}}
            Adet</p>
        </div>
        <div class="totalSection">
          <p>{{formatAmount(filtersData.transAcData.SUCCESS.total_values.sum_total_amount)}}₺</p>
        </div>
      </div>

      <div class="col-span-3 row leftSide" id="leftSide2">
        <div class="column">
          <p>Toplam Bekleyen Tutar</p>
          <p class="subItem">Toplam Bekleyen İşlem: {{filtersData.transAcData.WAITING.total_values.transaction_count}}
            Adet</p>
        </div>
        <div class="totalSection">
          <p>{{formatAmount(filtersData.transAcData.WAITING.total_values.sum_total_amount)}}₺</p>
        </div>
      </div>

      <div class="col-span-3 row leftSide" id="leftSide3">
        <div class="column">
          <p>Toplam İptal Tutar</p>
          <p class="subItem">Toplam İptal İşlem: {{filtersData.transAcData.CANCEL.total_values.transaction_count}} Adet
          </p>
        </div>
        <div class="totalSection">
          <p>{{formatAmount(filtersData.transAcData.CANCEL.total_values.sum_total_amount)}}₺</p>
        </div>
      </div>

      <div class="col-span-3 row leftSide" id="leftSide4">
        <div class="column">
          <p>Toplam Başarısız Tutar</p>
          <p class="subItem">Toplam Başarısız İşlem: {{filtersData.transAcData.FAIL.total_values.transaction_count}}
            Adet</p>
        </div>
        <div class="totalSection">
          <p>{{formatAmount(filtersData.transAcData.FAIL.total_values.sum_total_amount)}}₺</p>
        </div>
      </div>
    </div>

  </div>

</div>