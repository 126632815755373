<div class="box xxl:p-8 xxxl:p-10">
    <p class="text-lg font-medium mb-4">Profile Resmi</p>
    <div class="flex flex-wrap gap-6 xxl:gap-10 items-center bb-dashed mb-6 pb-6"><img
        src="assets/images/placeholder.png" width="120" height="120" alt="img" class="rounded-xl">
      <div class="flex gap-4"><label for="photo-upload" class="btn"> Fotoğraf Yükle </label><input type="file"
          id="photo-upload" class="hidden"><button class="btn-outline">İptal</button></div>
    </div>
    <form class="mt-6 xl:mt-8 grid grid-cols-2 gap-4 xxxxxl:gap-6">
      <div class="col-span-2">
        <app-password-input label="Kimlik Numarası" />
      </div>
      <div class="col-span-2 md:col-span-1">
        <label for="fname" class="md:text-lg font-medium block mb-4"> İsim Soyisim </label>
        <input type="text" id="fname" placeholder="İsim Soyisim" required=""
          class="w-full text-sm bg-primary/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-3 md:px-6 py-2 md:py-3">
      </div>
      <div class="col-span-2 md:col-span-1">
        <label for="fphone" class="md:text-lg font-medium block mb-4">
          Telefon </label>
        <input type="text" placeholder="+90" id="fphone" required=""
          class="w-full text-sm bg-primary/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-3 md:px-6 py-2 md:py-3">
      </div>
      <div class="col-span-2 md:col-span-1">
        <label for="fphone" class="md:text-lg font-medium block mb-4">Mail
          Adresiniz </label>
        <input type="mail" id="fphone" placeholder="Mail Adresiniz" required=""
          class="w-full text-sm bg-primary/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-3 md:px-6 py-2 md:py-3">
      </div>
      <div class="col-span-2 md:col-span-1">
        <label for="lname" class="md:text-lg font-medium block mb-4">
          Kampanya Kodu </label>
        <input type="number" placeholder="Kampanya Kodu" id="fCampcode" required=""
          class="w-full text-sm bg-primary/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-3 md:px-6 py-2 md:py-3">
      </div>
      <div class="col-span-2 md:col-span-1">
        <label for="location" class="md:text-lg font-medium block mb-4"> Dil Ayarları </label>
        <app-dropdown [options]="languge" btnClassProps="rounded-3xl w-full !py-3" dropdownClassProps="w-full" />
      </div>
      <div class="col-span-2 md:col-span-1">
        <label for="lname" class="md:text-lg font-medium block mb-4"> İş
          Ortağı Kodu </label>
        <input type="number" placeholder="İş Ortağı Kodu" id="fBussinessCode" required=""
          class="w-full text-sm bg-primary/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-3 md:px-6 py-2 md:py-3">
      </div>
      <div class="col-span-2 md:col-span-1"><label for="fbCode" class="md:text-lg font-medium block mb-4">Marka
          Kodu</label><input type="number" id="fbCode" placeholder="Marka Kodu" required=""
          class="w-full text-sm bg-primary/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-3 md:px-6 py-2 md:py-3">
      </div>
      <div class="col-span-2 md:col-span-1">
        <label for="location" class="md:text-lg font-medium block mb-4"> Zaman Dilimi </label>
        <app-dropdown [options]="gmtType" btnClassProps="rounded-3xl w-full !py-3" dropdownClassProps="w-full" />
      </div>
      <app-password-input label="Şifre Belirle" />
      <app-password-input label="Şifre Tekrar" />
      <div class="col-span-2">
        <label for="location" class="md:text-lg font-medium block mb-4"> Para Birimi </label>
        <app-dropdown [options]="currencyType" btnClassProps="rounded-3xl w-full !py-3" dropdownClassProps="w-full" />
      </div>
      
      <div class="col-span-2">
        <div class="flex mt-6 xxl:mt-10 gap-4"><button class="btn px-5">Kaydet</button><button
            class="btn-outline px-5">İptal</button></div>
      </div>
    </form>
  </div>