export const terminalTabData = [
    {
      id:'1',
      title: "Cihazlar",
      icon: "las la-angle-right text-lg",
    },
    {
      id:'2',
      title: "Terminaller",
      icon: "las la-angle-right text-lg",
    },
  ];
  