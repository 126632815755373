<!-- { "translate-y-0 scale-100 opacity-100 my-10": open } -->
<div class="box max-h-[90vh] w-[310px] sm:w-[450px] md:w-[700px] overflow-y-auto">
  <div class="relative">
    <button (click)="closeModal()" class="ac-modal-close-btn absolute top-0 ltr:right-0 rtl:left-0">
      <i class="las la-times"></i>
    </button>
    <div class="bb-dashed mb-4 flex items-center justify-between pb-4 lg:mb-6 lg:pb-6">
      <h4 class="h4">Create Bank Account</h4>
    </div>
    <form>
      <p class="mb-4 text-lg font-medium">Profile Photo</p>
      <div class="mb-6 flex flex-wrap items-center gap-5 xl:gap-10">
        <img src="assets/images/placeholder.png" class="h-20 w-20 lg:h-auto lg:w-auto" alt="placeholder" />
        <div class="flex gap-4">
          <input type="file" name="photo" id="photo" class="hidden" />
          <label for="photo" class="btn"> Upload Photo </label>
          <button class="btn-outline">Cancel</button>
        </div>
      </div>
      <div class="mt-6 grid grid-cols-2 gap-4 xl:mt-8 xxxl:gap-6">
        <div class="col-span-2">
          <label for="name" class="mb-4 block font-medium md:text-lg"> Account Holder Name </label>
          <input type="text" class="w-full rounded-3xl border border-n30 bg-secondary1/5 px-6 py-2.5 dark:border-n500 dark:bg-bg3 md:py-3" placeholder="Enter Name" id="name" required />
        </div>
        <div class="col-span-2">
          <label for="number" class="mb-4 block font-medium md:text-lg"> Phone Number </label>
          <input type="number" class="w-full rounded-3xl border border-n30 bg-secondary1/5 px-6 py-2.5 dark:border-n500 dark:bg-bg3 md:py-3" placeholder="Enter Number" id="number" required />
        </div>
        <div class="col-span-2">
          <label for="expire" class="mb-4 block font-medium md:text-lg"> Expiry Date </label>
          <div class="relative bg-secondary1/5 py-3 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl">
            <p-calendar [(ngModel)]="date" styleClass="bg-white" name="date" placeholder="Select Date" inputStyleClass="px-5  focus:outline-none bg-secondary1/5 dark:bg-bg3 w-full rounded-none focus:shadow-none active:shadow-none" panelStyleClass="bg-n0 dark:bg-bg3 rounded-lg"></p-calendar>
            <i class="las la-calendar absolute ltr:right-4 rtl:left-4 top-1/2 -translate-y-1/2 cursor-pointer"></i>
          </div>
        </div>
        <div class="col-span-2">
          <label for="currency" class="mb-4 block font-medium md:text-lg"> Select Currency </label>
          <app-dropdown [options]="currencies" btnClassProps="lg:!py-3 rounded-3xl bg-secondary1/5" dropdownClassProps="w-full"></app-dropdown>
        </div>

        <div class="col-span-2 md:col-span-1">
          <label for="rate" class="mb-4 block font-medium md:text-lg"> Interest Rate </label>
          <input type="number" class="w-full rounded-3xl border border-n30 bg-secondary1/5 px-6 py-2.5 dark:border-n500 dark:bg-bg3 md:py-3" placeholder="Interest Rate %" id="rate" required />
        </div>
        <div class="col-span-2 md:col-span-1">
          <label for="amount" class="mb-4 block font-medium md:text-lg"> Amount </label>
          <input type="number" class="w-full rounded-3xl border border-n30 bg-secondary1/5 px-6 py-2.5 dark:border-n500 dark:bg-bg3 md:py-3" placeholder="Enter Amount" id="amount" required />
        </div>
        <div class="col-span-2">
          <label for="status" class="mb-4 block font-medium md:text-lg"> Select Status </label>
          <app-dropdown [options]="status" btnClassProps="lg:!py-3 rounded-3xl bg-secondary1/5" dropdownClassProps="w-full" />
        </div>
        <div class="col-span-2 mt-4">
          <button class="btn flex w-full justify-center" type="submit">Create Account</button>
        </div>
      </div>
    </form>
  </div>
</div>
