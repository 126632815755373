import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CityLocationModel } from '../../models/location/city-location.model';
import { CityDetailModel } from '../../models/location/city-detail-location.model';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class CityLocationService {

    private apiUrl = `${environment.apiUrl}/location`;
    private token = localStorage.getItem('authToken');
    constructor(private http: HttpClient) { }

    getData(): Observable<CityLocationModel[]> {
        const urlPath = `${this.apiUrl}`;
        const headers = new HttpHeaders()
            .set('Authorization', `Bearer ${this.token}`);
        return this.http.get<CityLocationModel[]>(urlPath, { headers });
    }

    private apiUrlDetail = `${environment.apiUrl}/location/`;
    getDataDetail(id: string): Observable<CityDetailModel[]> {
        const urlPath = `${this.apiUrlDetail}`;
        const headers = new HttpHeaders()
            .set('Authorization', `Bearer ${this.token}`);
        return this.http.get<CityDetailModel[]>(urlPath + id, { headers });
    }

}
