import { Component } from '@angular/core'
import { DropdownComponent } from '../dropdown/dropdown.component'
import { ModalService } from 'ngx-modal-ease'

@Component({
  selector: 'app-device-add-modal',
  standalone: true,
  imports: [DropdownComponent],
  templateUrl: './device-add-modal.component.html'
})
export class DeviceAddModalComponent {
  usageRegion= [
    "Test-1",
    "Test-2",
    "Test-3",
  ]

  braches=[
    "Test-1",
    "Test-2",
    "Test-3",
  ]
  constructor(private modalService:ModalService){}
  closeModal(){
    this.modalService.close('DeviceAddModalComponent')
  }
}
