import { Component, Input } from '@angular/core'
import { DropdownComponent } from '../dropdown/dropdown.component'
import { ModalService } from 'ngx-modal-ease'
import { CommonModule } from '@angular/common'
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { FirmResponseModel } from '../../../models/firm/firm-response-model';
import { GetWorkingHoursService } from '@service/settings/get-working-hours-service';

@Component({
  selector: 'app-add-working-hours',
  standalone: true,
  imports: [DropdownComponent, CommonModule, ReactiveFormsModule],
  templateUrl: './working-hours-modal.component.html'
})
export class WorkingHoursModalComponent {

  @Input() workingDays: any[] = [];
  @Input() firmsInfo!: FirmResponseModel;

  workDays = [
    { key: "MONDAY", value: 'Pazartesi' },
    { key: "TUESDAY", value: 'Salı' },
    { key: "WEDNESDAY", value: 'Çarşamba' },
    { key: "THURSDAY", value: 'Perşembe' },
    { key: "FRIDAY", value: 'Cuma' },
    { key: "SATURDAY", value: 'Cumartesi' },
    { key: "SUNDAY", value: 'Pazar' }
  ];

  form: FormGroup;
  constructor(
    private modalService: ModalService,
    private fb: FormBuilder,
    private getWorkingService: GetWorkingHoursService,
  ) {

    this.form = this.fb.group({
      fDays: ['', Validators.required],
      fOpenHours: ['', Validators.required],
      fCloseHours: ['', Validators.required]
    });
  }

  closeModal() {
    this.modalService.close('WorkingHoursModalComponent')
  }

  onSubmit() {
    if (this.form.valid) {
      this.getWorkingService.addWorkHour(
        this.firmsInfo.id,
        this.form.value['fDays'],
        this.form.value['fOpenHours'],
        this.form.value['fCloseHours'],
      ).subscribe(
        response => {
          this.closeModal();
          window.location.reload();
        },
        error => {
          console.error('Hata:', error);
        }
      );
    } else {
      console.error('Form geçersiz');
    }
  }

}
