import { CommonModule } from '@angular/common'
import { Component, OnInit } from '@angular/core'
import { OptionsHorizComponent } from '@component/shared/options-horiz/options-horiz.component'
import { TopBannerComponent } from '@component/shared/top-banner/top-banner.component'
import { PasswordInputComponent } from '@component/shared/password-input/password-input.component'
import { DropdownComponent } from '@component/shared/dropdown/dropdown.component'
import { ServiceAreaComponent } from '@component/shared/service-area/service area.component'
import { PrinterSettingsComponent } from '@component/shared/printer-settings/printer-settings.component'
import { ApiEndPointComponent } from '@component/shared/api-endpoint/api-endpoint.component'
import { accountTabData, profileUserData } from '@data/payment/account-tab'
import { CompanyInfoComponent } from '@component/shared/company-info/company-info.component'
import { BillingInfoComponent } from '@component/shared/billing-info/billing-info.component'
import { RegionsSettingsComponent } from '@component/shared/regions-settings/regions-settings-component'
import { BranchesAreaComponent } from '@component/shared/branches-area/branches-area-component'
import { OptionsVerticalProfilComponent } from '@component/shared/options-vertical-profil/options-vertical-profil.component'
import { ProfileService } from '@service/profile/profile.service'
import { ProfileResponseModel } from '../../models/profile/profile.model'
import { ModalService } from 'ngx-modal-ease'
import { CreateProfileModalComponent } from '@component/shared/create-profile-modal/create-profile-modal.component'
import { ReactiveFormsModule, FormsModule } from '@angular/forms'
import { FormGroup, FormBuilder,Validators } from '@angular/forms'
import { ProfileUserService } from '@service/profile/profile-user.service'
import { ProfileUserModel } from '../../models/profile/profile-user.mode'
import { ProfileUserSettingsService } from '@service/profile/profile-user-settings.service'
import { ProfileUserSettingsModel, SettingsItemModel } from '../../models/profile/profile-user-settings.model'
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormControl } from '@angular/forms'
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ProfileUpdateService } from '@service/profile/profile-update.service'
import { ProfileUpdateModel } from '../../models/profile/profile-update-request-model'

@Component({
    selector: 'app-profile-user',
    standalone: true,
    imports: [
        TopBannerComponent,
        CompanyInfoComponent,
        FormsModule,
        OptionsVerticalProfilComponent,
        PrinterSettingsComponent,
        OptionsHorizComponent,
        RegionsSettingsComponent,
        ServiceAreaComponent,
        CommonModule,
        MatCheckboxModule,
        PasswordInputComponent,
        DropdownComponent,
        ApiEndPointComponent,
        BillingInfoComponent,
        BranchesAreaComponent,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatSelectModule,
        MatButtonModule,
        MatSlideToggleModule,
    ],
    templateUrl: './profile-user.component.html'
})
export class ProfileUserComponent implements OnInit {
    tabs = profileUserData
    activeTab = this.tabs[0].title
    chatShow = false
    profileList: ProfileResponseModel[] = [];

    selectedOptions = new FormControl([]);
    options: any[] = [
        {
            id: 1,
            title: 'efe',
        },
        {
            id: 2,
            title: 'asdas',
        }
    ];

    posMenuAuthData: any;
    posMenuFuncData: any;
    posMenuAutomationData: any;
    posOrderData: any;
    parameterData: any;

    profileUserList: ProfileUserModel[] = [];
    profileUserSettingsList: ProfileUserSettingsModel[] = [];
    selectedTab: string = 'home';
    selectedFirmId!: string;
    selectedFirmName!: string;
    selectedValue: string = '';
    selectedSelectItem: any;

    selectedSelectItems: { [key: string]: any } = {};


    form!: FormGroup;
    checkboxName: string;
    checkboxNameAction: string;
    checkboxNameEdit: string;
    checkboxNameDelete: string;


    
    checkboxPosFunction: string;
    checkboxPosOrder: string;
    checkboxPosParameter: string;
    isChecked!: boolean;
    item!: SettingsItemModel;
    status: boolean = false;

    errorMessage: string | null = null;


    authorizationName = [
        'Satış Yap',
        'İade Al',
        'Cari Hesap',
        'Cüzdan+',
        'Siparişler',
        '',
        'Paycell Menü',
        'Raporlar',
        '',
        '',
        '',
        '',
        'İşyeri Menüsü',
        '',
        'Quickpoint Menü',
        'KFÖ Menü',
        'TaksiPOS Menü',
        'ParkPOS Menü',
    ];
    menuName = [
        "-",
        "-",
        "-",
        "-",
        "Açık Siparişler",
        "Kapalı Siparişler",
        "-",
        "Satış Raporları",
        "Tahsilat Rapoları",
        "Ürün Raporu",
        "Z Raporları",
        "X Raporları",
        "Gün Sonu Al",
        "Parametre Yükleme",
        "-",
        "-",
        "-",
        "-",
    ]
    posFunctionAuthorization = [
        "Yazdırma Sesi",
        "Başarılı Tahsilat Sesi ",
        "Başarısız Tahsilat Sesi ",
        "Ödeme Yapın Sesi ",
        "Fatura Gönderim Eposta ",
        "Fatura Gönderim SMS",
        "Satış Listesi Sonucu",
        "Sipariş Çağır",
        "Sipariş Çağır Hedefi ",
        "Ekran Koruma Süresi",
        "Çıkışta Pin Sor",
        "Çıkışta Gün Sonu Al",
    ]
    posAutomation = [
        "Taksitli İşlem Onayı ",
        "Kartlı İşlem Onay ",
        "Nakit İşlem Onay ",
        "Yemek Kartı Offline İşlem Onayı ",
        "Diğer Ödeme İşlem Onayı ",
        "Otomatik Fatura Havale / EFT",
        "Otomatik Fatura Açık Hesap",
        "Otomatik Fatura Online Ödeme",
    ]
    posOrdersTitle = [
        'Otomatik Sipariş Onayı',
        'Otomatik Hazırlandı Onayı',
        'Otomatik Yolda Onayı',
        'Otomatik Teslim Edildi Onayı'
    ]
    posParameterTitle = [
        "Tekrarlı Tutar Kontrolü Süresi",
        "Maksimum Fiş Tutarı",
        "Otomatik Nihai Tüketici Sınırı",
        "Otomatik TCKN Doldurma Sınırı",
        "Otomatik EPOSTA Doldurma Sınırı",
        "Otomatik GSM Doldurma Sınırı",
        "Fatura Bilgileri Zorunluluk Sınırı",
        "Kısım Satış Yetkisi",
        "Kısım Ekleme yetkisi",
        "KDV Oranları",
        "Varsayılan Sipariş Durumu",
        "Sipariş Listesi Versiyonu",
        "BÖL Kısa Yolu",
        "ÇARP Kısa Yolu",
    ]

    colors: string[] = ['Kırmızı', 'Mavi', 'Yeşil', 'Sarı', 'Mor', 'Turuncu'];
    selectedColors: string[] = [];

    setActiveTab(title: string) {
        this.activeTab = title
    }

    constructor(
        private profileService: ProfileService,
        private modalService: ModalService,
        private profileUserService: ProfileUserService,
        private fb: FormBuilder,
        private profileSettingsService: ProfileUserSettingsService,
        private settingPutService: ProfileUpdateService,
    ) {
        this.checkboxName = 'dynamicCheckbox0';
        this.checkboxNameAction = 'dynamicCheckboxAction0';
        this.checkboxNameEdit = 'dynamicCheckboxEdit0';
        this.checkboxNameDelete = 'dynamicCheckboxDelete0';
        this.checkboxPosFunction = 'dynamicCheckboxFunction0';
        this.checkboxPosOrder = 'dynamicCheckboxOrder0';
        this.checkboxPosParameter = 'dynamicCheckboxParameter0';
    }



    log(options: any, value: any) {
        this.isChecked = !this.isChecked;
    }


    selectTab(tab: string) {
        this.selectedTab = tab;
        this.getPermissionValue();
        this.getFunctionValue();
        this.getAutomationValue();
        this.getPosOrderValue();
        this.getParameterValue();
    }

    isCheckedChange(optionId: string, item: any): boolean {
        return item.model.value.includes(optionId);
    }

    toggleSwitch(itemCode: string, item: any, code: string) {
        const index = item.model.value.indexOf(itemCode);
        if (index === -1) {
            item.model.value.push(itemCode);
            const settings: ProfileUpdateModel = {
                name: this.selectedFirmName,
                is_active: true,
                items: this.profileUserSettingsList.map(e => ({
                    code: code,
                    value: item.model.value
                }))
            };
            this.settingPutService.updateProfileSettings(this.selectedFirmId, settings).subscribe(
                response => {
                },
                error => {
                    console.error('Error updating profile', error);
                }
            );
        } else {
            item.model.value.splice(index, 1);
            const settings: ProfileUpdateModel = {
                name: this.selectedFirmName,
                is_active: true,
                items: this.profileUserSettingsList.map(e => ({
                    code: code,
                    value: item.model.value
                }))
            };
            this.settingPutService.updateProfileSettings(this.selectedFirmId, settings).subscribe(
                response => {
                },
                error => {
                    console.error('Error updating profile', error);
                }
            );
        }
        this.log(item.code, item.model.value);
    }

    onItemSelected(item: any,event: any): void {
        this.selectedSelectItem = event.target.value;
        const selectedId = event.target.value;
        
        this.selectedSelectItems[item.code] = selectedId;

        const settings: ProfileUpdateModel = {
            name: this.selectedFirmName,
            is_active: true,
            items: [{
                code: item.code,
                value: selectedId
            }]
        };

        this.settingPutService.updateProfileSettings(this.selectedFirmId, settings).subscribe(
            response => {
                item.model.value = !item.model.value;
            },
            error => {
                console.error('Error updating profile', error);
            }
        );
    }

    toggleStatus(item: any, activeStatus: any): void {
        const settings: ProfileUpdateModel = {
            name: this.selectedFirmName,
            is_active: true,
            items: [{
                code: item.code,
                value: !activeStatus
            }]
        };

        this.settingPutService.updateProfileSettings(this.selectedFirmId, settings).subscribe(
            response => {
                item.model.value = !item.model.value;
            },
            error => {
                console.error('Error updating profile', error);
            }
        );
    }

    isCheckedStatus(value: any): boolean {
        return !!value;
    }

    ngOnInit(): void {
        this.profileService.allProfiles().subscribe(
            (response: ProfileResponseModel[]) => {
                this.profileList = response;
            },
            error => {
                console.error('Hata:', error);
            }
        );

        this.form = this.fb.group({
            [this.checkboxName]: false,
            [this.checkboxNameAction]: false,
            [this.checkboxNameEdit]: false,
            [this.checkboxNameDelete]: false,
            [this.checkboxPosFunction]: false,
            [this.checkboxPosOrder]: false,
            [this.checkboxPosParameter]: false,
        });

        this.profileUserService.allProfilesUser().subscribe(
            (response: ProfileUserModel[]) => {
                this.profileUserList = response;
            },
            error => {
                console.error('Hata:', error);
            }
        );

        this.form = this.fb.group({
            limitedValue: ['', [Validators.required, Validators.max(1000)]]
          });
        
    }

    addProfile() {
        this.modalService.open(CreateProfileModalComponent, {
            modal: {
                enter: 'enter-going-down 0.3s ease-out',
                leave: 'fade-out 0.5s'
            },
            overlay: {
                leave: 'fade-out 0.5s'
            },
            data: {
                type: 'Angular modal library'
            }
        })
    }

    addUser() { }

    getPermissionValue() {
        this.profileSettingsService.allProfilesUserSettings(this.selectedFirmId).subscribe(
            (response: ProfileUserSettingsModel[]) => {
                this.profileUserSettingsList = response;
                if (this.profileUserSettingsList && this.profileUserSettingsList.length > 0) {
                    this.posMenuAuthData = this.profileUserSettingsList[0].items;
                } else {
                    console.warn('Profil kullanıcı ayarları listesi boş veya tanımlı değil.');
                }
            },
            error => {
                console.error('Hata:', error);
            }
        );
    }

    getFunctionValue() {
        this.profileSettingsService.allProfilesUserSettings(this.selectedFirmId).subscribe(
            (response: ProfileUserSettingsModel[]) => {
                this.profileUserSettingsList = response;
                if (this.profileUserSettingsList && this.profileUserSettingsList.length > 0) {
                    this.posMenuFuncData = this.profileUserSettingsList[1].items;
                } else {
                    console.warn('Profil kullanıcı ayarları listesi boş veya tanımlı değil.');
                }
            },
            error => {
                console.error('Hata:', error);
            }
        );
    }

    getAutomationValue() {
        this.profileSettingsService.allProfilesUserSettings(this.selectedFirmId).subscribe(
            (response: ProfileUserSettingsModel[]) => {
                this.profileUserSettingsList = response;
                if (this.profileUserSettingsList && this.profileUserSettingsList.length > 0) {
                    this.posMenuAutomationData = this.profileUserSettingsList[2].items;
                } else {
                    console.warn('Profil kullanıcı ayarları listesi boş veya tanımlı değil.');
                }
            },
            error => {
                console.error('Hata:', error);
            }
        );
    }

    getPosOrderValue() {
        this.profileSettingsService.allProfilesUserSettings(this.selectedFirmId).subscribe(
            (response: ProfileUserSettingsModel[]) => {
                this.profileUserSettingsList = response;
                if (this.profileUserSettingsList && this.profileUserSettingsList.length > 0) {
                    this.posOrderData = this.profileUserSettingsList[3].items;
                } else {
                    console.warn('Profil kullanıcı ayarları listesi boş veya tanımlı değil.');
                }
            },
            error => {
                console.error('Hata:', error);
            }
        );
    }

    getParameterValue() {
        this.profileSettingsService.allProfilesUserSettings(this.selectedFirmId).subscribe(
            (response: ProfileUserSettingsModel[]) => {
                this.profileUserSettingsList = response;
                if (this.profileUserSettingsList && this.profileUserSettingsList.length > 0) {
                    this.parameterData = this.profileUserSettingsList[4].items;
                } else {
                    console.warn('Profil kullanıcı ayarları listesi boş veya tanımlı değil.');
                }
            },
            error => {
                console.error('Hata:', error);
            }
        );
    }

    changeCheckboxName(index: number) {
        this.checkboxName = `${index}`;
        this.form.addControl(this.checkboxName, this.fb.control(false));
    }

    changeCheckboxNameAction(index: number) {
        this.checkboxNameAction = `dynamicCheckboxAction0${index}`;
        this.form.addControl(this.checkboxNameAction, this.fb.control(false));
    }

    changeCheckboxNameEdit(index: number) {
        this.checkboxNameEdit = `dynamicCheckboxEdit0${index}`;
        this.form.addControl(this.checkboxNameEdit, this.fb.control(false));
    }

    changeCheckboxNameDelete(index: number) {
        this.checkboxNameDelete = `dynamicCheckboxDelete0${index}`;
        this.form.addControl(this.checkboxNameDelete, this.fb.control(false));
    }

    changeCheckboxPosFunction(index: number) {
        this.checkboxPosFunction = `dynamicCheckboxFunction0${index}`;
        this.form.addControl(this.checkboxPosFunction, this.fb.control(false));
    }

    changeCheckboxPosOrder(index: number) {
        this.checkboxPosOrder = `dynamicCheckboxOrder0${index}`;
        this.form.addControl(this.checkboxPosOrder, this.fb.control(false));
    }

    changeCheckboxPosParameter(index: number) {
        this.checkboxPosParameter = `dynamicCheckboxParameter0${index}`;
        this.form.addControl(this.checkboxPosParameter, this.fb.control(false));
    }

    selectedBossAuht(firmId: string, firmName: string) {
        this.selectedFirmId = firmId;
        this.selectedFirmName = firmName;
    }

    onSelectionChange(event: any) {
        this.selectedValue = event.target.value;
    }

    validateValue(itemCode: string) {
        const value = this.form.get('limitedValue')?.value;
    
        if (itemCode === 'PR_002') {
          if (value > 10000) {
            this.errorMessage = "Girilen Değer 10.000'den büyük olamaz.";
          } else {
            this.errorMessage = null;
            const settings: ProfileUpdateModel = {
            name: this.selectedFirmName,
            is_active: true,
            items: [{
                code: itemCode,
                value: value
            }]
            };
            this.settingPutService.updateProfileSettings(this.selectedFirmId, settings).subscribe(
            response => {
                console.log('Profile updated successfully', response);
            },
            error => {
                console.error('Error updating profile', error);
            }
             );
          }
        } else {
          if (value > 5000) {
            this.errorMessage = "Girilen Değer 5.000'den büyük olamaz.";
          } else {
            this.errorMessage = null;
            const settings: ProfileUpdateModel = {
                name: this.selectedFirmName,
                is_active: true,
                items: [{
                    code: itemCode,
                    value: value
                }]
                };
                this.settingPutService.updateProfileSettings(this.selectedFirmId, settings).subscribe(
                response => {
                    console.log('Profile updated successfully', response);
                },
                error => {
                    console.error('Error updating profile', error);
                }
                 );
          }
        }
      }


}
