import { NgClass } from '@angular/common';
import { Component, HostListener } from '@angular/core';
import { RouterLink } from '@angular/router';
import { LogOutService } from '@service/auth/logout.service';
import { Router } from '@angular/router';
import { OnInit } from '@angular/core';
import { FirmDataService } from '@service/firm/firm-service';
import { FirmResponseModel } from '../../../models/firm/firm-response-model';

@Component({
  selector: 'app-profile-dropdown',
  standalone: true,
  imports: [NgClass, RouterLink],
  templateUrl: './profile-dropdown.component.html'
})
export class ProfileDropdownComponent {
  isOpen = false
  data: any
  firmData!:FirmResponseModel;


  toggleOpen() {
    this.isOpen = !this.isOpen
  }

  constructor(private logoutService: LogOutService, private router: Router, private firmDataService: FirmDataService) { }

  @HostListener('document:click', ['$event'])

  onDocumentClick(event: MouseEvent): void {
    if (!document.querySelector('#profile')!.contains(event.target as Node) && !document.querySelector('#profile-btn')!.contains(event.target as Node)) {
      this.isOpen = false
    }
  }

  logOut(): void {
    localStorage.removeItem('authToken');
    this.logoutService.getData().subscribe(
      (response) => {
        this.data = response;
        this.router.navigateByUrl('/auth/sign-in')
      },
      (error) => {
        console.error('Hata:', error);
      }
    );
  }

  ngOnInit(): void {
    this.firmDataService.allFirmData().subscribe(
      response => {
        this.firmData = response;
        console.error(this.firmData);
      },
      error => {
        console.error('Hata:', error);
      }
    );
  }

}
