<div class="box col-span-12">
  <div class="flex justify-between items-center gap-4 flex-wrap bb-dashed mb-4 pb-4 lg:mb-6 lg:pb-6">
    <h4 class="h4">Kategoriler</h4>
    <div class="flex items-center gap-4 flex-wrap grow sm:justify-end">
      <div class="flex items-center gap-4 flex-wrap grow sm:justify-end">
        <button (click)="printTable()" class="btn shrink-0 add-account-btn">Dışarı
          Aktar
          <i class="fa-solid fa-download"></i>
        </button>
      </div>
    </div>
  </div>
  <div class="overflow-x-auto mb-4 lg:mb-6">
    <div class="flex">
      <div class="w-1/2 overflow-x-auto mb-4 lg:mb-6">
        <div class="accordion" cdkDropList (cdkDropListDropped)="drop($event)">
          <div *ngFor="let item of displayedCategory; let i = index" class="accordion-item"
            (click)="onTapCategoryProd(item.id)">
            <div class="accordion-header" (click)="toggleItem(i)" [cdkDragData]="item" cdkDrag
              (cdkDragStarted)="onDragStart($event, i)" (cdkDragEnded)="onDragEnd($event, i)"
              [ngClass]="{'dragging': draggingIndex === i}" class="even:bg-secondary1/5 dark:even:bg-bg3"
              (click)="toggleExpand(i)">
              <div class="row">
                <img class="cursor-move" src="../../../../assets/images/draggable-icon.svg">
                <div class="categoryTitle">
                  <p>{{item.name}}</p>
                </div>
                <div class="toggle-switch" [class.checked]="item.is_active"
                  (click)="toggleStatus(item.id, item.is_active, item.name); $event.stopPropagation()">
                  <div class="switch-handle"></div>
                </div>
                <div class="flex items-center gap-3">
                  <p class="font-medium">Kodu: {{ item.code }}</p>
                </div>
                <img class="rightArrow" src="../../../../assets/icons/right-arrow.svg">
                <i (click)="deleteCategory(item.id)" class="las la-trash-alt delete-icon"></i>
              </div>
            </div>

            <div class="accordion-content" *ngIf="openedIndex == i" cdkDropList
              (cdkDropListDropped)="dropProduct(item.id, $event)">
              <div *ngFor="let prodItem of selectedCategoryProduct.products" class="prodList" [cdkDragData]="prodItem"
                cdkDrag (cdkDragStarted)="onPordDragStart($event, i)" (cdkDragEnded)="onProdDragEnd($event, i)"
                [ngClass]="{'dragging': prodDraggingIndex === i}">
                <img class="cursor-move" src="../../../../assets/images/draggable-icon.svg">
                <div
                  class="bg-primary/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-3 md:px-6 py-2 md:py-3 relative">
                  <input type="text" class="w-11/12 text-sm bg-transparent p-0 border-none" readonly
                    placeholder="{{prodItem.title}}" />
                  <span class="absolute eye-icon ltr:right-5 rtl:left-5 top-1/2 -translate-y-1/2">
                    <img src="../../../../assets/icons/edit-pencil.svg">
                  </span>
                </div>
                <div
                  class="bg-primary/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-3 md:px-6 py-2 md:py-3 relative">
                  <input type="text" class="w-11/12 text-sm bg-transparent p-0 border-none"
                    placeholder=" {{prodItem.price}} ₺" />
                  <span class="absolute eye-icon ltr:right-5 rtl:left-5 top-1/2 -translate-y-1/2">
                    <img src="../../../../assets/icons/money-icon.svg">
                  </span>
                </div>
                <div
                  class="bg-primary/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-3 md:px-6 py-2 md:py-3 relative">
                  <input type="text" class="w-11/12 text-sm bg-transparent p-0 border-none"
                    placeholder="{{prodItem.tax_rate}} %" />
                </div>
                <img (click)="toggleBox(prodItem.id)" class="editProd" src="../../../../assets/icons/edit-pencil.svg">
                <img (click)="deleteProduct(prodItem.id)" class="editProd"
                  src="../../../../assets/icons/trash-icon.svg">
              </div>

              <form [formGroup]="productForm" (ngSubmit)="submitProductForm()">
                <div class="categoriesProdAdd">
                  <img class="cursor-move" src="../../../../assets/images/draggable-icon.svg">
                  <div
                    class="bg-primary/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-3 md:px-6 py-2 md:py-3 relative">
                    <input type="text" formControlName="productName"
                      class="w-11/12 text-sm bg-transparent p-0 border-none" placeholder="Ürün Oluştur" />
                    <span class="absolute eye-icon ltr:right-5 rtl:left-5 top-1/2 -translate-y-1/2">
                      <img src="../../../../assets/icons/edit-pencil.svg">
                    </span>
                  </div>
                  <div
                    class="bg-primary/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-3 md:px-6 py-2 md:py-3 relative">
                    <input type="text" formControlName="productPrice"
                      class="w-11/12 text-sm bg-transparent p-0 border-none" placeholder="0 ₺" />
                    <span class="absolute eye-icon ltr:right-5 rtl:left-5 top-1/2 -translate-y-1/2">
                      <img src="../../../../assets/icons/money-icon.svg">
                    </span>
                  </div>
                  <div
                    class="bg-primary/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-3 md:px-6 py-2 md:py-3 relative">
                    <input type="text" formControlName="productTax"
                      class="w-11/12 text-sm bg-transparent p-0 border-none" placeholder="0 %" />
                  </div>
                  <img class="editProd" src="../../../../assets/icons/edit-pencil.svg">
                  <img class="editProd" src="../../../../assets/icons/trash-icon.svg">
                </div>
                <button type="submit" class="mt-4 btn shrink-0 add-account-btn">Ürün Ekle</button>
              </form>
            </div>

          </div>
        </div>
        <form [formGroup]="categoryForm" (ngSubmit)="submitForm()" id="prodListForm">
          <div class="even:bg-secondary1/7 grid grid-cols-7 gap-4">

            <div class="col-span-1 py-4 px-6 cursor-move">
              <img src="../../../../assets/images/draggable-icon.svg">
            </div>

            <div class="col-span-2 categoryAddButton">
              <div
                class="bg-primary/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-3 md:px-6 py-2 md:py-3 relative">
                <input type="text" formControlName="categoryName" class="w-11/12 text-sm bg-transparent p-0 border-none"
                  placeholder="Kategori Oluştur" />
                <span class="absolute eye-icon ltr:right-5 rtl:left-5 top-1/2 -translate-y-1/2">
                  <img src="../../../../assets/icons/edit-pencil.svg">
                </span>
              </div>
            </div>

            <div class="col-span-1">
              <div class="col-span-1">
                <div class="toggle-switch" [class.checked]="categoryStatus" (click)="onToggleChange()">
                  <div class="switch-handle"></div>
                </div>
              </div>
            </div>

            <div class="col-span-2 categoryAddButton">
              <div
                class="bg-primary/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-3 md:px-6 py-2 md:py-3 relative">
                <input type="text" class="w-11/12 text-sm bg-transparent p-0 border-none" formControlName="categoryCode"
                  placeholder="Kod Yazınız" />
                <span class="absolute eye-icon ltr:right-5 rtl:left-5 top-1/2 -translate-y-1/2">
                  <img src="../../../../assets/icons/key-icon.svg">
                </span>
              </div>
            </div>

            <div class="col-span-1">
              <img class="rightArrow" src="../../../../assets/icons/right-arrow.svg">
            </div>

          </div>
          <button type="submit" class="mt-4 btn shrink-0 add-account-btn">Kategori Ekle</button>
        </form>
      </div>

      <div *ngIf="isBoxVisible == false" class="w-1/2 flex flex-col items-center mt-4" id="warnArea">
        <img src="../../../../assets/icons/warning-icon.svg" class="warnIcon mt-4" alt="Örnek İkon">
        <h2 class="warnHeader">Detaylı Bilgi</h2>
        <span class="warnTitle">Oluşturulan katagori hakkında detaylı
          <br>
          bilgileri kontrol edebilirsiniz.</span>
      </div>
    </div>

    <div class="container">
      <div class="side-box" [class.active]="isBoxVisible">
        <div class="dark-box xxl:p-8 xxxl:p-10">
          <form [formGroup]="productEditForm"
            class="mt-6 xl:mt-8 grid grid-cols-2 md:grid-cols-4 gap-4 xxxxl:gap-6 prodForm">
            <div class="col-span-2 md:col-span-1 category-prod">
              <label for="category" class="font-medium block mb-4">Ürün Kategorisi</label>
              <p-multiSelect
                class="shortAddProd bg-primary/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-3 md:px-6 py-2 md:py-3 relative"
                [options]="categoryList" formControlName="category"  (onChange)="onCategoryChange($event)"
                optionLabel="name" placeholder="Kategori Seçiniz." />
              <span *ngIf="productEditForm.get('category')!.invalid && productEditForm.get('category')!.touched"
                class="error-message">
                Kategori alanı zorunludur.
              </span>
            </div>

            <div class="col-span-2 md:col-span-1">
              <label for="productName" class="font-medium block mb-4">Ürün Adı</label>
              <div
                class="bg-primary/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-3 md:px-6 py-2 md:py-3 relative">
                <input type="text" id="productName" formControlName="productName"
                  class="w-11/12 text-sm bg-transparent p-0 border-none" *ngIf="prodDetail"
                  [placeholder]="prodDetail.title" />
              </div>
              <span *ngIf="productEditForm.get('productName')!.invalid && productEditForm.get('productName')!.touched"
                class="error-message">
                Ürün adı zorunludur.
              </span>
            </div>

            <div class="col-span-2 md:col-span-1">
              <label for="productType" class="font-medium block mb-4">Ürün Tipi</label>
              <select formControlName="productType" (change)="onSelectChange($event)">
                <option *ngFor="let item of productTypeEnum" [value]="item.code">{{ item.title }}</option>
              </select>
              <span *ngIf="productEditForm.get('productType')!.invalid && productEditForm.get('productType')!.touched"
                class="error-message">
                Ürün tipi zorunludur.
              </span>
            </div>

            <div class="col-span-2 md:col-span-1">
              <label for="unit" class="font-medium block mb-4">Birim</label>
              <select formControlName="unit" (change)="onSelectChange($event)">
                <option *ngFor="let item of unitTypeEnum" [value]="item.code">{{ item.title }}</option>
              </select>
              <span *ngIf="productEditForm.get('unit')!.invalid && productEditForm.get('unit')!.touched"
                class="error-message">
                Birim zorunludur.
              </span>
            </div>

            <div class="col-span-2 md:col-span-1">
              <label for="productPrice" class="font-medium block mb-4">Ürün Fiyatı (TL)</label>
              <div
                class="bg-primary/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-3 md:px-6 py-2 md:py-3 relative">
                <input type="text" id="productPrice" formControlName="productPrice"
                  class="w-11/12 text-sm bg-transparent p-0 border-none" *ngIf="prodDetail"
                  [placeholder]="prodDetail.price+'₺'" />
              </div>
              <span *ngIf="productEditForm.get('productPrice')!.invalid && productEditForm.get('productPrice')!.touched"
                class="error-message">
                Fiyat alanı zorunludur.
              </span>
            </div>

            <div class="col-span-2 md:col-span-1">
              <label for="vatRate" class="font-medium block mb-4">KDV Oranı (%)</label>
              <div
                class="bg-primary/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-3 md:px-6 py-2 md:py-3 relative">
                <input type="text" id="vatRate" class="w-11/12 text-sm bg-transparent p-0 border-none"
                  formControlName="vatRate" *ngIf="prodDetail" [placeholder]="prodDetail.tax_rate+'%'" />
              </div>
              <span *ngIf="productEditForm.get('vatRate')!.invalid && productEditForm.get('vatRate')!.touched"
                class="error-message">
                Kdv alanı zorunludur.
              </span>
            </div>

            <div class="col-span-2 md:col-span-1">
              <label for="productPrice2" class="font-medium block mb-4">Ürün Fiyat 2</label>
              <div
                class="bg-primary/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-3 md:px-6 py-2 md:py-3 relative">
                <input type="text" id="productPrice2" formControlName="productPrice2"
                  class="w-11/12 text-sm bg-transparent p-0 border-none" placeholder="0₺" />
              </div>
              <span
                *ngIf="productEditForm.get('productPrice2')!.invalid && productEditForm.get('productPrice2')!.touched"
                class="error-message">
                Ürün Fiyat 2 zorunludur.
              </span>
            </div>

            <div class="col-span-2 md:col-span-1">
              <label for="productPrice3" class="font-medium block mb-4">Ürün Fiyat 3</label>
              <div
                class="bg-primary/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-3 md:px-6 py-2 md:py-3 relative">
                <input type="text" id="productPrice3" formControlName="productPrice3"
                  class="w-11/12 text-sm bg-transparent p-0 border-none" placeholder="0₺" />
              </div>
              <span
                *ngIf="productEditForm.get('productPrice3')!.invalid && productEditForm.get('productPrice3')!.touched"
                class="error-message">
                Ürün Fiyat 3 zorunludur.
              </span>
            </div>

            <div class="col-span-2 md:col-span-1">
              <label for="purchasePrice" class="font-medium block mb-4">Satın Alma Fiyatı</label>
              <div
                class="bg-primary/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-3 md:px-6 py-2 md:py-3 relative">
                <input type="text" id="purchasePrice" formControlName="purchasePrice"
                  class="w-11/12 text-sm bg-transparent p-0 border-none" placeholder="0₺" />
              </div>
              <span
                *ngIf="productEditForm.get('purchasePrice')!.invalid && productEditForm.get('purchasePrice')!.touched"
                class="error-message">
                Satın Alma Fiyatı zorunludur.
              </span>
            </div>

            <div class="col-span-2 md:col-span-1">
              <label for="productCode" class="font-medium block mb-4">Ürün Kodu</label>
              <div
                class="bg-primary/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-3 md:px-6 py-2 md:py-3 relative">
                <input type="text" id="productCode" formControlName="productCode"
                  class="w-11/12 text-sm bg-transparent p-0 border-none" placeholder="Kod giriniz." />
              </div>
              <span *ngIf="productEditForm.get('productCode')!.invalid && productEditForm.get('productCode')!.touched"
                class="error-message">
                Ürün Kodu zorunludur.
              </span>
            </div>

            <div class="col-span-2 md:col-span-1">
              <label for="sku" class="font-medium block mb-4">SKU (Barkod)</label>
              <div
                class="bg-primary/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-3 md:px-6 py-2 md:py-3 relative">
                <input type="text" id="sku" class="w-11/12 text-sm bg-transparent p-0 border-none" formControlName="sku"
                  placeholder="Barkod giriniz..." />
              </div>
              <span *ngIf="productEditForm.get('sku')!.invalid && productEditForm.get('sku')!.touched"
                class="error-message">
                SKU (Barkod) zorunludur.
              </span>
            </div>

            <div class="col-span-2 md:col-span-1">
              <label for="stock" class="font-medium block mb-4">Stok Miktarı</label>
              <div
                class="bg-primary/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-3 md:px-6 py-2 md:py-3 relative">
                <input type="text" id="stock" class="w-11/12 text-sm bg-transparent p-0 border-none"
                  formControlName="stock" placeholder="Stok giriniz." />
              </div>
              <span *ngIf="productEditForm.get('stock')!.invalid && productEditForm.get('stock')!.touched"
                class="error-message">
                Stok Miktarı zorunludur.
              </span>
            </div>

            <div class="col-span-2">
              <label for="description" class="font-medium block mb-4">Ürün Açıklaması</label>
              <div
                class="bg-primary/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-3 md:px-6 py-2 md:py-3 relative">
                <textarea id="description" class="w-11/12 text-sm bg-transparent p-0 border-none"
                  formControlName="description" placeholder="Ürün Açıklaması"></textarea>
              </div>
              <span *ngIf="productEditForm.get('description')!.invalid && productEditForm.get('description')!.touched"
                class="error-message">
                Ürün Açıklaması zorunludur.
              </span>
            </div>

            <div class="col-span-2 md:col-span-1">
              <label for="criticStock" class="font-medium block mb-4">Kritik Stok Uyarı Seviyesi</label>
              <div
                class="bg-primary/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-3 md:px-6 py-2 md:py-3 relative">
                <input type="text" id="criticStock" formControlName="criticStock"
                  class="w-11/12 text-sm bg-transparent p-0 border-none" placeholder="Kritik stok giriniz." />
              </div>
              <span *ngIf="productEditForm.get('criticStock')!.invalid && productEditForm.get('criticStock')!.touched"
                class="error-message">
                Kritik Stok zorunludur.
              </span>
            </div>

            <div class="col-span-2">
              <div class="flex mt-6 xxl:mt-10 gap-4">
                <button type="submit" (click)="addProdTap()" class="btn px-5">Kaydet</button>
                <button type="button" (click)="closeToggleBox()" class="btn-outline px-5">İptal</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>



    <div class="flex col-span-12 gap-4 sm:justify-between justify-center items-center flex-wrap">
      <ul class="flex gap-2 md:gap-3 flex-wrap md:font-semibold items-center">
        <li>
          <button [disabled]="currentPage === 1" (click)="changePage(currentPage - 1)"
            class="hover:bg-primary rtl:rotate-180 hover:text-n0 border md:w-10 duration-300 md:h-10 w-8 h-8 flex items-center rounded-full justify-center border-primary">
            <i class="las la-angle-left text-lg"></i>
          </button>
        </li>
        <li *ngFor="let page of pages">
          <button (click)="changePage(page)" [class.bg-primary]="currentPage === page"
            class="hover:bg-primary hover:text-n0 border md:w-10 duration-300 md:h-10 w-8 h-8 flex items-center rounded-full justify-center border-primary">
            {{ page }}
          </button>
        </li>
        <li>
          <button [disabled]="currentPage === totalPages" (click)="changePage(currentPage + 1)"
            class="hover:bg-primary hover:text-n0 rtl:rotate-180 border md:w-10 duration-300 md:h-10 w-8 h-8 flex items-center rounded-full justify-center border-primary">
            <i class="las la-angle-right text-lg"></i>
          </button>
        </li>
      </ul>
    </div>

  </div>