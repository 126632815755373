import { Component } from '@angular/core'
import { DropdownComponent } from '../dropdown/dropdown.component'
import { ModalService } from 'ngx-modal-ease'
import { FormsModule } from '@angular/forms'
import { CommonModule } from '@angular/common'
import { CityLocationService } from '@service/location/city-location.service'
import { CityLocationModel } from '../../../models/location/city-location.model'
import { ReactiveFormsModule } from '@angular/forms'
import { CityDetailModel } from '../../../models/location/city-detail-location.model'
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BranchAddService } from '@service/branches/branches-add-service'

@Component({
  selector: 'app-add-branches-modal',
  standalone: true,
  imports: [DropdownComponent, FormsModule, ReactiveFormsModule, CommonModule],
  templateUrl: './add-branches-modal.component.html'
})
export class AddBranchesModalComponent {
  form!: FormGroup;
  cities!: CityLocationModel[];
  citiesDetail!: CityDetailModel[];

  selectedCity!: string;
  selectedDetailCity!: string;

  warningForm: boolean = false;

  constructor(
    private fb: FormBuilder,
    private modalService: ModalService,
    private cityService: CityLocationService,
    private addBranchSerivice: BranchAddService) { }

  closeModal() {
    this.modalService.close('AddBranchesModalComponent')
  }

  ngOnInit(): void {
    this.cityService.getData().subscribe(
      response => {
        this.cities = response;
      },
      error => {
        console.error('Hata:', error);
      }
    );
    this.form = this.fb.group({
      branchesName: ['', Validators.required],
      fPhone: ['', [Validators.required, Validators.pattern(/^\d+$/)]],
      fSelectedCity: ['', Validators.required],
      fSelectedDetailCity: ['', Validators.required],
      fDetailAddress: ['', Validators.required],
      posIntegrator: ['', Validators.required]
    });
  }

  onSubmit(): void {
    if (this.form.valid) {
      const branchesName = this.form.value['branchesName'];
      const fPhone = this.form.value['fPhone'];
      const fDetailAddress = this.form.value['fDetailAddress'];
      const fSelectedCity = parseInt(this.form.value['fSelectedCity']);
      const fSelectedDetailCity = parseInt(this.form.value['fSelectedDetailCity']);
      const posIntegrator = this.form.value['posIntegrator'];

      this.addBranchSerivice.addBranch(
        branchesName,
        fPhone.toString(),
        fDetailAddress,
        fSelectedCity,
        fSelectedDetailCity,
        true,
        posIntegrator
      ).subscribe(
        response => {
          this.closeModal();
          window.location.reload();
        },
        error => {
          console.error('Error:', error);
        }
      );

    } else {
      console.log('Form geçerli değil');
      this.warningForm = true;
    }
  }


  onCitySelect(event: Event): void {
    const selectElement = event.target as HTMLSelectElement;
    const cityId = selectElement.value;
    this.selectedDetailCity = cityId;
  }

  onCityDetailSelect(event: Event): void {
    this.cityService.getDataDetail(this.selectedDetailCity).subscribe(
      response => {
        this.citiesDetail = response;
      },
      error => {
        console.error('Hata:', error);
      }
    );
    const selectElement = event.target as HTMLSelectElement;
    const detailCityId = selectElement.value;
  }
}
