import { Component, Input } from '@angular/core'
import { DropdownComponent } from '../dropdown/dropdown.component'
import { ModalService } from 'ngx-modal-ease'
import { CommonModule } from '@angular/common'

@Component({
  selector: 'app-category-error-modal',
  standalone: true,
  imports: [DropdownComponent,CommonModule],
  templateUrl: './category-error-modal.html'
})
export class CategoryErrorModalComponent {

  constructor(private modalService:ModalService){}
  
  closeModal(){
    this.modalService.close('CategoryErrorModalComponent')
  }

  ngOnInit(): void { }

}
