import { CommonModule } from '@angular/common'
import { Component } from '@angular/core'
import { DropdownComponent } from '@component/shared/dropdown/dropdown.component'
import { OptionsHorizComponent } from '@component/shared/options-horiz/options-horiz.component'
import { TopBannerComponent } from '@component/shared/top-banner/top-banner.component'
import { NgApexchartsModule } from 'ng-apexcharts'
import { latestTransactions } from '@data/transactions/latestTransactions'
import { ModalService } from 'ngx-modal-ease'
import { TableService } from '@service/table.service'
import { FormsModule } from '@angular/forms';
import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';


@Component({
  selector: 'rp-app-service-bill-report',
  standalone: true,
  imports: [TopBannerComponent, FormsModule, OptionsHorizComponent, DropdownComponent, NgApexchartsModule, CommonModule],
  templateUrl: './service-bill-report.component.html'
})
export class ServiceBillReportComponent {
  transactions
  value1 = 1000
  customerList = [
    'Test-1',
    'Test-2',
    'Test-3',
  ]
  pages: number[] = []
  filters = ['Tümü', 'Başarılı', 'İptal Edildi', 'Devam Ediyor']
  currentFilter = this.filters[0]

  searchDate: number | undefined;
  searchBillNumber: number | undefined;
  searchTotalAmount: number | undefined;
  searchCustomerName: string = '';
  searchDesc: string = '';

  constructor(private modalService: ModalService) {
    this.transactions = new TableService()
    this.transactions.initialize(latestTransactions, 14)
  }

  areAllItemsChecked(): boolean {
    return this.transactions.tableData.length > 1 && this.transactions.tableData.every((item) => item.isChecked)
  }

  ngOnInit() {
    this.pages = Array.from({ length: this.transactions.totalPages }, (_, i) => i + 1)
  }

  get filteredTransactionsCombined() {
    const customerName = this.searchCustomerName.toLowerCase();
    const description = this.searchDesc.toLowerCase();

    return this.transactions.tableData.filter(item => {
      const itemCustomerName = item.title.toLowerCase();
      const itemDescription = item.title.toLowerCase();

      return (!this.searchDate || item.dueDate.includes(this.searchDate)) &&
        (!this.searchBillNumber || item.speciealCode.includes(this.searchBillNumber)) &&
        (!this.searchTotalAmount || item.collectedAmount.includes(this.searchTotalAmount)) &&
        (!customerName || itemCustomerName.includes(customerName)) &&
        (!description || itemDescription.includes(description));
    });
  }

  exportToExcel() {
    var table = document.getElementById("serviceBillListTable");
    var excel = new ExcelJS.Workbook();
    var sheet = excel.addWorksheet('Sheet1');

    var rows = table!.querySelectorAll('tr');

    rows.forEach(function (row) {
      var rowData: string[] = [];
      var cells = row.querySelectorAll('th, td');
      Array.from(cells).forEach(function (cell: Element) {
        rowData.push((cell as HTMLInputElement).innerText);
      });
      sheet.addRow(rowData);
    });

    excel.xlsx.writeBuffer().then(function (buffer) {
      var blob = new Blob([buffer], { type: 'application/octet-stream' });
      saveAs(blob, 'service_bill_list.xlsx');
    });
  }
}