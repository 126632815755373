import { Component, Inject, Input } from '@angular/core';
import { ModalService } from 'ngx-modal-ease';
import { DropdownComponent } from '../dropdown/dropdown.component';
import { ProductDeleteService } from '@service/products/products-delete.service';


@Component({
  selector: 'app-delete-product-modal',
  standalone: true,
  imports: [DropdownComponent],
  templateUrl: './delete-product-modal.component.html'
})
export class DeleteProductModalComponent {
  @Input() product!: number;
  walletType = ['Ön Ödemeli', 'Test', 'Test-1']

  constructor(private modalService: ModalService, private prodDeleteService:ProductDeleteService) { }

  closeModal() {
    this.modalService.close('DeleteProductModalComponent')
  }

  deleteProduct() {

    this.prodDeleteService.deleteProduct(this.product).subscribe(
      response => {
        window.location.reload();
      },
      error => {
        console.error('Error:', error);
      }
    );

  }
}
