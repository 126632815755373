import { Component, Input } from '@angular/core'
import { DropdownComponent } from '../dropdown/dropdown.component'
import { ModalService } from 'ngx-modal-ease'
import { CommonModule } from '@angular/common'

@Component({
  selector: 'app-product-success-add',
  standalone: true,
  imports: [DropdownComponent,CommonModule],
  templateUrl: './product-success-add-modal.component.html'
})
export class ProductSuccessAddModalComponent {

  constructor(private modalService:ModalService){}
  
  closeModal(){
    this.modalService.close('ProductSuccessAddModalComponent')
  }

  ngOnInit(): void { }

}
