<app-top-banner title="Deposit Details" />
<div class="flex flex-col gap-4 xxl:gap-6">
  <!-- Deposit Details -->
  <div class="box col-span-12 md:col-span-5 lg:col-span-4">
    <div class="bb-dashed border-secondary1/30 pb-4 mb-4 lg:mb-6 lg:pb-6 flex justify-between items-center">
      <h4 class="h4">Deposit Details</h4>
      <app-dropdown />
    </div>

    <div class="grid grid-cols-12 xxl:divide-x rtl:divide-x-reverse divide-secondary1/30 divide-dashed items-center gap-y-6">
      <div class="col-span-12 md:col-span-6 xxl:col-span-4 md:ltr:pr-6 xxxl:ltr:pr-10 md:rtl:pl-6 xxxl:rtl:pl-10">
        <div class="bg-secondary1 p-4 lg:px-6 lg:py-5 rounded-xl text-n0 relative overflow-hidden after:absolute after:rounded-full after:w-[300px] after:h-[300px] after:bg-[#FFC861] after:top-[50%] after:ltr:left-[55%] sm:ltr:after:left-[65%] after:rtl:right-[55%] sm:rtl:after:right-[65%]">
          <div class="flex justify-between items-start mb-4 md:mb-8 lg:mb-10 xxxl:mb-14">
            <div>
              <p class="text-xs mb-1">Current Balance</p>
              <h4 class="h4">80,700.00 USD</h4>
            </div>
            <img src="assets/images/visa-sm.png" width="45" height="16" alt="visa icon" />
          </div>
          <div class="flex justify-between items-end">
            <div>
              <img width="45" height="45" src="assets/images/mastercard.png" alt="card icon" />
              <p class="mb-1 mt-1 md:mt-3">Felecia Brown</p>
              <p class="text-xs">•••• •••• •••• 8854</p>
            </div>
            <p class="text-n700 relative z-[1]">12/27</p>
          </div>
        </div>
      </div>
      <div class="col-span-12 md:col-span-6 xxl:col-span-4 md:pl-6 xxl:px-6 xxxl:px-10">
        <ul class="flex flex-col gap-4">
          <li class="flex justify-between"><span>Card Type:</span> <span class="font-medium">Visa</span></li>
          <li class="flex justify-between"><span>Card Holder:</span> <span class="font-medium">Felecia Brown</span></li>
          <li class="flex justify-between"><span>Expires:</span> <span class="font-medium">12/27</span></li>
          <li class="flex justify-between"><span>Card Number:</span> <span class="font-medium">325 541 565 546</span></li>
          <li class="flex justify-between"><span>Total Balance:</span> <span class="font-medium">99,225.54 USD</span></li>
          <li class="flex justify-between"><span>Total Debt:</span> <span class="font-medium">9,545.22 USD</span></li>
        </ul>
      </div>
      <div class="col-span-12 md:col-span-6 xxl:col-span-4 xxl:ltr:pl-6 xxxl:ltr:pl-10 xxl:rtl:pr-6 xxxl:rtl:pr-10">
        <p class="text-lg font-medium mb-6">Active card</p>
        <div class="border border-primary border-dashed bg-primary/5 rounded-lg p-3 flex items-center gap-4 mb-6 lg:mb-8">
          <img src="assets/images/card-sm-1.png" width="60" height="40" alt="card" />
          <div>
            <p class="font-medium mb-1">John Snow - Metal</p>
            <span class="text-xs">**4291 - Exp: 12/26</span>
          </div>
        </div>
        <a href="#" class="text-primary font-semibold flex items-center gap-2 mb-6 lg:mb-8 bb-dashed pb-6"> More Card <i class="las la-arrow-right"></i> </a>
        <div class="flex gap-4 lg:gap-6">
          <a href="#" class="btn flex justify-center w-full lg:py-2.5"> Pay Debt </a>
          <a href="#" class="btn-outline flex justify-center w-full lg:py-2.5"> Cancel </a>
        </div>
      </div>
    </div>
  </div>
  <!-- Total Deposits -->
  <div class="box col-span-12 lg:col-span-6">
    <div class="flex flex-wrap gap-4 justify-between items-center bb-dashed mb-4 pb-4 lg:mb-6 lg:pb-6">
      <h4 class="h4">Total Deposits</h4>
      <div class="flex grow sm:justify-end items-center flex-wrap gap-4">
        <button (click)="addAccountModal()" class="btn shrink-0 total-deposit-btn">Add Deposit</button>
        <form class="bg-primary/5 datatable-search dark:bg-bg3 border border-n30 dark:border-n500 flex gap-3 rounded-[30px] focus-within:border-primary p-1 items-center justify-between min-w-[200px] xxl:max-w-[319px] w-full">
          <input type="text" placeholder="Search" class="bg-transparent text-sm ltr:pl-4 rtl:pr-4 py-1 w-full border-none" id="deposit-search" />
          <button class="bg-primary shrink-0 rounded-full w-7 h-7 lg:w-8 lg:h-8 flex justify-center items-center text-n0">
            <i class="las la-search text-lg"></i>
          </button>
        </form>
        <div class="flex items-center gap-3 whitespace-nowrap">
          <span>Sort By : </span>
          <app-dropdown />
        </div>
      </div>
    </div>
    <div class="overflow-x-auto pb-4 lg:pb-6">
      <table class="w-full whitespace-nowrap" id="deposit-table">
        <thead>
          <tr class="bg-secondary1/5 dark:bg-bg3">
            <th (click)="totalDeposits.sortData('title')" class="text-start !py-5 px-6 min-w-[230px] cursor-pointer">
              <div class="flex items-center gap-1">Title <i class="las la-exchange-alt rotate-90 text-base"></i></div>
            </th>
            <th (click)="totalDeposits.sortData('rate')" class="text-start !py-5 min-w-[100px] cursor-pointer">
              <div class="flex items-center gap-1">Rate <i class="las la-exchange-alt rotate-90 text-base"></i></div>
            </th>
            <th (click)="totalDeposits.sortData('balance')" class="text-start !py-5 min-w-[200px] cursor-pointer">
              <div class="flex items-center gap-1">Account Balance <i class="las la-exchange-alt rotate-90 text-base"></i></div>
            </th>
            <th (click)="totalDeposits.sortData('interest')" class="text-start !py-5 min-w-[200px] cursor-pointer">
              <div class="flex items-center gap-1">Account Interest <i class="las la-exchange-alt rotate-90 text-base"></i></div>
            </th>
            <th (click)="totalDeposits.sortData('expire')" class="text-start !py-5 min-w-[130px] cursor-pointer">
              <div class="flex items-center gap-1">Expiry Date <i class="las la-exchange-alt rotate-90 text-base"></i></div>
            </th>
            <th (click)="totalDeposits.sortData('status')" class="text-start !py-5 cursor-pointer min-w-[100px]">
              <div class="flex items-center gap-1">Status <i class="las la-exchange-alt rotate-90 text-base"></i></div>
            </th>
            <th class="text-center !py-5" >Action</th>
          </tr>
        </thead>
        <tbody>
          @for (item of totalDeposits.paginatedData; track item.id; let i = $index) {
          <tr class="hover:bg-primary/5 dark:hover:bg-bg3 border-b border-n30 dark:border-n500 first:border-t">
            <td class="py-2 px-6">
              <div class="flex items-center gap-3">
                <i class="las la-wallet text-primary"></i>
                <span class="font-medium">{{ item.title }}</span>
              </div>
            </td>
            <td class="py-2">
              <div>
                <p class="font-medium">{{ item.rate }}%</p>
                <span class="text-xs">Rate</span>
              </div>
            </td>
            <td class="py-2">
              <div>
                <p class="font-medium">{{ getLocale(item.balance) }}</p>
                <span class="text-xs">Account Balance</span>
              </div>
            </td>
            <td class="py-2">
              <div>
                <p class="font-medium">{{ getLocale(item.interest) }}</p>
                <span class="text-xs">Account Interest</span>
              </div>
            </td>
            <td>{{ item.expire }}</td>
            <td class="py-2">
              <span
                [ngClass]="{ 'text-primary bg-primary/10': item.status == 'active', 'text-secondary2 bg-secondary2/20': item.status == 'cancelled', 'text-secondary3 bg-secondary3/10': item.status == 'paused' }"
                class="block w-28 rounded-[30px] border border-n30 bg-primary/10 py-2 text-center text-xs text-primary dark:border-n500 dark:bg-bg3 xxl:w-36"
              >
                {{ item.status }}
              </span>
            </td>
            <td class="py-2">
              <div class="flex justify-center">
                <app-options-vertical></app-options-vertical>
              </div>
            </td>
          </tr>
          }
        </tbody>
      </table>
    </div>
    <div class="flex col-span-12 gap-4 sm:justify-between justify-center items-center flex-wrap">
      <p>Showing {{ totalDeposits.startIndex + 1 }} to {{ totalDeposits.endIndex + 1 }} of {{ totalDeposits.totalData }} entries</p>
      <ul class="flex gap-2 md:gap-3 flex-wrap md:font-semibold items-center">
        <li>
          <button [disabled]="totalDeposits.currentPage == 1" (click)="totalDeposits.prevPage()" class="hover:bg-primary text-primary rtl:rotate-180 hover:text-n0 border md:w-10 duration-300 md:h-10 w-8 h-8 flex items-center rounded-full justify-center border-primary">
            <i class="las la-angle-left text-lg"></i>
          </button>
        </li>
        <li *ngFor="let page of pages">
          <button (click)="totalDeposits.paginate(page)" [ngClass]="{ 'bg-primary !text-n0': totalDeposits.currentPage == page }" class="hover:bg-primary bg-primary hover:text-n0 border md:w-10 duration-300 md:h-10 w-8 h-8 flex text-primary items-center rounded-full justify-center border-primary">
            {{ page }}
          </button>
        </li>
        <li>
          <button [disabled]="totalDeposits.currentPage == totalDeposits.totalPages" (click)="totalDeposits.nextPage()" class="hover:bg-primary text-primary hover:text-n0 rtl:rotate-180 border md:w-10 duration-300 md:h-10 w-8 h-8 flex items-center rounded-full justify-center border-primary">
            <i class="las la-angle-right text-lg"></i>
          </button>
        </li>
      </ul>
    </div>
  </div>
</div>
