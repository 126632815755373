import { Component } from '@angular/core';
import { ModalService } from 'ngx-modal-ease';

@Component({
  selector: 'app-delete-terminal-modal',
  standalone: true,
  imports: [],
  templateUrl: './delete-terminal-modal.component.html'
})
export class DeleteTerminalModalComponent {
  constructor(private modalService:ModalService){}
  closeModal(){
    this.modalService.close('DeleteTerminalModalComponent')
  }
}
