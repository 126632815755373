<div class="box xxl:p-8 xxxl:p-10">
  <form class="mt-6 xl:mt-8 grid grid-cols-2 gap-4 xxxxxl:gap-6">

    <div class="col-span-2">
      <label for="fApiEndPoint" class="md:text-lg font-medium block mb-4">API Endpoint</label>
      <div
        class="bg-primary/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-3 md:px-6 py-2 md:py-3 relative">
        <input #apiEndpointInput type="text" readonly placeholder="https://api.pluspay.app/swagger/index.html"
          value="https://api.pluspay.app/swagger/index.html" class="w-11/12 text-sm bg-transparent p-0 border-none"
          name="fApiEndPoint" />
        <span class="absolute eye-icon ltr:right-5 rtl:left-5 top-1/2 -translate-y-1/2 cursor-pointer"
          (click)="copyToCEndPoint()">
          <i class="las la-copy"></i>
        </span>
      </div>
    </div>

    <div class="col-span-2">
      <label for="fApiEndPoint" class="md:text-lg font-medium block mb-4">API Anahtarı</label>
      <div
        class="bg-primary/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-3 md:px-6 py-2 md:py-3 relative">
        <input #apiKey type="text" readonly placeholder="32e093b8-810d-4dd4-9d52-c1656da073d4"
          value="32e093b8-810d-4dd4-9d52-c1656da073d4" class="w-11/12 text-sm bg-transparent p-0 border-none"
          name="fApiEndPoint" />
        <span class="absolute eye-icon ltr:right-5 rtl:left-5 top-1/2 -translate-y-1/2 cursor-pointer"
          (click)="copyToClipKey()">
          <i class="las la-copy"></i>
        </span>
      </div>
    </div>


    <div class="col-span-2">
      <label for="fApiEndPoint" class="md:text-lg font-medium block mb-4">Uygulama Anahtarı</label>
      <div
        class="bg-primary/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-3 md:px-6 py-2 md:py-3 relative">
        <input #apiAppKey type="text" readonly placeholder="32e093b8-810d-4dd4-9d52-c1656da073d4"
          value="32e093b8-810d-4dd4-9d52-c1656da073d4" class="w-11/12 text-sm bg-transparent p-0 border-none"
          name="fApiEndPoint" />
        <span class="absolute eye-icon ltr:right-5 rtl:left-5 top-1/2 -translate-y-1/2 cursor-pointer"
          (click)="copyToClipAppKey()">
          <i class="las la-copy"></i>
        </span>
      </div>
    </div>


    <div class="col-span-2">
      <label for="fApiEndPoint" class="md:text-lg font-medium block mb-4">Pos Anahtarı</label>
      <div
        class="bg-primary/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-3 md:px-6 py-2 md:py-3 relative">
        <input #appPosKey type="text" readonly placeholder="32e093b8-810d-4dd4-9d52-c1656da073d4"
          value="32e093b8-810d-4dd4-9d52-c1656da073d4" class="w-11/12 text-sm bg-transparent p-0 border-none"
          name="fApiEndPoint" />
        <span class="absolute eye-icon ltr:right-5 rtl:left-5 top-1/2 -translate-y-1/2 cursor-pointer"
          (click)="copyToClipPosKey()">
          <i class="las la-copy"></i>
        </span>
      </div>
    </div>

    <div class="col-span-2">
      <div class="flex mt-6 xxl:mt-10 gap-4"><button class="btn px-5">Kaydet</button><button
          class="btn-outline px-5">İptal</button></div>
    </div>
  </form>
</div>