import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { DropdownComponent } from '@component/shared/dropdown/dropdown.component';
import { OptionsVerticalComponent } from '@component/shared/options-vertical/options-vertical.component';
import { TopBannerComponent } from '@component/shared/top-banner/top-banner.component';
import { latestTransactions } from '@data/transactions/latestTransactions';
import { TableService } from '@service/table.service';
import { AddCardModalComponent } from '@component/shared/add-card-modal/add-card-modal.component'
import { ModalService } from 'ngx-modal-ease'
import { FormsModule } from '@angular/forms';
import { TransactionAllService } from '@service/transaction/transactions-all.service';
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import { LocaleConfig, NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { Router } from '@angular/router';
import { ChangeDetectorRef } from '@angular/core';
import { provideNativeDateAdapter } from '@angular/material/core';
import { VERSION as MAT_VERSION, MatNativeDateModule } from '@angular/material/core';
import { IgxCalendarModule, IgxSnackbarModule } from "igniteui-angular";
import { LOCALE_ID } from '@angular/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { JsonPipe, registerLocaleData } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import localeTrExtra from '@angular/common/locales/extra/tr';
import localeTr from '@angular/common/locales/tr';
import { TransactionListModel } from '../../../models/transaction-model/transactions-list.model';
import { BranchListData } from '../../../models/branches/bracnhs-list-model';
import { BaranchListService } from '@service/branches/bracnhs-list-service';
import { Store } from '@ngrx/store';
import { MultiSelectModule } from 'primeng/multiselect';
import { PersonelListService } from '@service/personel/personel-list.service';
import { PersonelListModel } from '../../../models/personel/personel-list-model';
registerLocaleData(localeTr, 'tr-TR', localeTrExtra);

@Component({
  selector: 'tr-app-style-02',
  standalone: true,
  providers: [
    provideNativeDateAdapter(),
    { provide: LOCALE_ID, useValue: 'tr-TR' }
  ],
  imports: [TopBannerComponent, NgxDaterangepickerMd, MultiSelectModule, FormsModule, MatMomentDateModule, MatNativeDateModule, IgxSnackbarModule, IgxCalendarModule, MatDatepicker, MatDatepickerModule, MatFormFieldModule, JsonPipe, DropdownComponent, OptionsVerticalComponent, CommonModule],
  templateUrl: './style-02.component.html'
})
export class TransactionStyle02Component {
  transactions
  filters = ['Tümü', 'Başarılı', 'İptal Edildi', 'Devam Ediyor']
  currentFilter = this.filters[0]
  searchTerm: string = '';
  searchPaymentTypeTerm: string = '';
  searchPaymentMethodTerm: string = '';
  searchCustomerTerm: string = '';
  searchCashierTerm: string = '';
  searchTerminalTerm: string = '';
  searchSipSpecialCodeTerm: string = '';
  searchOrderCodeTerm: string = '';
  searchTotalAmountTerm: string = '';
  transactionData!: TransactionListModel;

  paymentType = [
    'POS',
    'PAYCELL',
    'PAYE',
    'MULTINET',
    'METROPOL',
    'CASH',
    'ONLINE',
    'BANK_TRANSFER',
    'CIO_CARD',
    'FASTPAY',
    'TICKET',
    'SETCARD',
    'SODEXO',
    'GETIRPAY',
    'TOKENFLEX',
    'YEMEKMATIK',
    'CUZDANPLUS'
  ];

  pages: number[] = [];
  startPage: number = 1;
  endPage: number = 10;
  validPages: number[] = [];
  showDatepicker: boolean = false;
  showSaveButton: boolean = false;
  selectedDateRange: { start: any, end: any } | null = null;

  personelList!: PersonelListModel[];
  selectedStaff!: [];

  branchList!: BranchListData[];

  branches = [
    {
      id: 1,
      title: 'şube1',
    },
    {
      id: 2,
      title: 'şube2',
    },
    {
      id: 3,
      title: 'şube3',
    },
  ];
  selectedSelectItem: any;
  selectedPaymentType: string | null = '';

  selectedSelectItems: { [key: string]: any } = {};
  selectedBranch!: [];

  locale: LocaleConfig = {
    applyLabel: 'Uygula',
    cancelLabel: 'İptal',
    format: 'DD.MM.YYYY',
    daysOfWeek: ['Pzt', 'Sal', 'Çar', 'Per', 'Cum', 'Cmt', 'Paz'],
    monthNames: ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık']
  };

  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl()
  });

  constructor(
    private store: Store,
    private cdr: ChangeDetectorRef,
    private branchesService: BaranchListService,
    private modalService: ModalService,
    private router: Router,
    private getPersonelListService: PersonelListService,
    private transactionList: TransactionAllService
  ) {
    this.transactions = new TableService()
    this.transactions.initialize(latestTransactions, 1)
  }

  formatter = new Intl.NumberFormat('tr-TR', {
    currency: 'TRY',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });

  formatAmount(amount: number | undefined): string {
    return this.formatter.format(amount ?? 0);
  }

  areAllItemsChecked(): boolean {
    return this.transactions.tableData.length > 1 && this.transactions.tableData.every((item) => item.isChecked)
  }

  ngOnInit() {
    this.loadTransaction();

    console.log(localStorage.getItem('authToken'));

    this.branchesService.allData().subscribe(
      response => {
        this.branchList = response.data;
      },
      error => {
        console.error('Hata:', error);
      }
    );

    this.getPersonelListService.allPersonelList().subscribe(
      (response: PersonelListModel[]) => {
        this.personelList = response;
      },
      error => {
        console.error('Hata:', error);
      }
    );
  }

  loadTransaction() {
    const today = new Date();
    const formattedtoday = `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}`;
    this.transactionList.allTransActions("1", "20", formattedtoday, formattedtoday, true, this.selectedBranch).subscribe(
      response => {
        this.transactionData = response.data || [];
        this.calculatePages();
        this.updateValidPages();
      },
      error => {
        console.error('Hata:', error);
      }
    );
  }

  convertToTurkishCharacters(text: string) {
    return text.replace(/ğ/g, 'g')
      .replace(/Ğ/g, 'G')
      .replace(/ü/g, 'u')
      .replace(/Ü/g, 'U')
      .replace(/ş/g, 's')
      .replace(/Ş/g, 'S')
      .replace(/ı/g, 'i')
      .replace(/İ/g, 'I')
      .replace(/ö/g, 'o')
      .replace(/Ö/g, 'O')
      .replace(/ç/g, 'c')
      .replace(/Ç/g, 'C');
  }

  public printTable() {

    const doc = new jsPDF('l', 'mm', 'a4');

    const head = [
      [
        [this.convertToTurkishCharacters('İşlem Tarihi')],
        [this.convertToTurkishCharacters('Ödeme Tipi')],
        [this.convertToTurkishCharacters('Ödeme Metodu')],
        [this.convertToTurkishCharacters('Müşteri')],
        [this.convertToTurkishCharacters('Kasiyer')],
        [this.convertToTurkishCharacters('Terminal S.')],
        [this.convertToTurkishCharacters('Sip Özel Kodu')],
        [this.convertToTurkishCharacters('Sipariş Kodu')],
        [this.convertToTurkishCharacters('Toplam')],
        [this.convertToTurkishCharacters('Durum')],
      ]]
    const data = this.filteredTransactionsCombined.map((item: any) => [
      [item.created_at],
      [item.payment_type],
      [item.payment_method],
      [item.order.customer],
      [item.invoice_info.number],
      [item.transaction_id],
      [item.order.special_code],
      [item.order.order_code],
      [item.total_amount],
      [item.status],
    ]);


    autoTable(doc, {
      head: head,
      body: data,
      styles: {
        font: "Poppins",
        fontStyle: "normal",
      },
      bodyStyles: { fillColor: [200, 200, 200] }
    });

    doc.save('tahsilatlar.pdf');
  }

  // setFilter(filter: string) {
  //   this.currentFilter = filter
  //   if (filter == 'Tümü') {
  //     this.transactions.initialize(this.transactionData, 1)
  //     this.pages = Array.from({ length: this.transactions.totalPages }, (_, i) => i + 1)
  //   } else if (filter === 'Başarılı') {
  //     const result = this.transactionData.filter((item: any) => item.status === "SUCCESS");
  //     this.transactions.initialize(result, 1);
  //     this.transactions.paginate(1);
  //     this.pages = [1];
  //   } else if (filter === 'İptal Edildi') {
  //     const result = this.transactionData.filter((item: any) => item.status === "FAIL");
  //     this.transactions.initialize(result, 1);
  //     this.transactions.paginate(1);
  //     this.pages = [1];
  //   }
  //   else if (filter === 'Devam Ediyor') {
  //     const result = this.transactionData.filter((item: any) => item.status === "WAITING");
  //     this.transactions.initialize(result, 1);
  //     this.transactions.paginate(1);
  //     this.pages = [1];
  //   }
  //   else {
  //     const result = this.transactionData.filter((item: any) => item.status == filter)
  //     this.transactions.initialize(result)
  //     this.transactions.paginate(1)
  //     this.pages = [1]
  //   }
  // }

  openModal() {
    this.modalService.open(AddCardModalComponent, {
      modal: {
        enter: 'enter-going-down 0.3s ease-out',
        leave: 'fade-out 0.5s'
      },
      overlay: {
        leave: 'fade-out 0.5s'
      },
      data: {
        type: 'Angular modal library'
      }
    })
  }

  get filteredTransactionsCombined() {
    const customerTerm = this.searchCustomerTerm?.toLowerCase() || '';
    const oderCodeTerm = this.searchOrderCodeTerm?.toLowerCase() || '';
    const sipSpecailCodeTerm = this.searchSipSpecialCodeTerm?.toLowerCase() || '';
    const paymentTypeTerm = this.searchPaymentTypeTerm?.toLowerCase() || '';
    const paymentMethodTerm = this.searchPaymentMethodTerm?.toLowerCase() || '';
    const cashierTerm = this.searchCashierTerm?.toLowerCase() || '';
    const transactionIdTerm = this.searchTerminalTerm?.toLowerCase() || '';

    if (!this.transactionData || !this.transactionData.data) {
      return [];
    }

    return this.transactionData.data.filter(
      (item: any) => {
        const customerTitle = item.order.customer?.toLowerCase() || '';
        const orderCodeSearch = item.order.order_code?.toLowerCase() || '';
        const sipSearch = item.order.special_code?.toLowerCase() || '';
        const paymentType = item.payment_type?.toLowerCase() || '';
        const paymentMethod = item.payment_method?.toLowerCase() || '';
        const cashier = item.invoice_info.number?.toLowerCase() || '';
        const transactionId = item.transaction_id?.toLowerCase() || '';

        return (!this.searchTerm || item.created_at.includes(this.searchTerm)) &&
          (!oderCodeTerm || orderCodeSearch.includes(oderCodeTerm)) &&
          (!sipSpecailCodeTerm || sipSearch.includes(sipSpecailCodeTerm)) &&
          (!customerTerm || customerTitle.includes(customerTerm)) &&
          (!paymentTypeTerm || paymentType.includes(paymentTypeTerm)) &&
          (!paymentMethodTerm || paymentMethod.includes(paymentMethodTerm)) &&
          (!cashierTerm || cashier.includes(cashierTerm)) &&
          (!transactionIdTerm || transactionId.includes(transactionIdTerm)) &&
          (!this.searchTotalAmountTerm || item.total_amount.toString().includes(this.searchTotalAmountTerm));
      });
  }

  navigateToDetail(id: any) {
    const startDate = this.selectedDateRange?.start;
    const endDate = this.selectedDateRange?.end;
    const brach = this.selectedBranch
    this.router.navigate(['/collection-detail', id], {
      state: {
        startDate,
        endDate,
        brach
      }
    });
  }

  onItemSelected(event: any): void {
    this.selectedBranch = event.target.value;
    this.loadTransaction();
    this.cdr.detectChanges();
  }

  toggleCalendar() {
    this.showDatepicker = !this.showDatepicker;
    if (!this.showDatepicker) {
      this.showSaveButton = false;
    }
  }

  onRangeChange(event: any) {
    const start = new Date(event[0]);
    const endIndex = event.length - 1;
    const end = new Date(event[endIndex]);

    const formattedStart = `${start.getFullYear()}-${(start.getMonth() + 1).toString().padStart(2, '0')}-${start.getDate().toString().padStart(2, '0')}`; // YYYY-MM-DD
    const formattedEnd = `${end.getFullYear()}-${(end.getMonth() + 1).toString().padStart(2, '0')}-${end.getDate().toString().padStart(2, '0')}`; // YYYY-MM-DD

    this.selectedDateRange = {
      start: formattedStart,
      end: formattedEnd,
    };
    this.showSaveButton = true;
  }

  saveDateRange() {
    if (this.selectedDateRange) {
      this.showDatepicker = false;
      this.showSaveButton = false;

      // if (this.selectedBranch) {
      //   this.transactionList.allTransActions("1", "20", this.selectedDateRange.start, this.selectedDateRange.end, true, this.selectedBranch, this.selectedStaff ? true : false, this.selectedStaff ? this.selectedStaff : undefined,this.selectedPaymentType ? this.selectedPaymentType : undefined).subscribe(
      //     response => {
      //       this.transactionData = response;
      //       this.calculatePages();
      //       this.updateValidPages();
      //       this.cdr.detectChanges();
      //     },
      //     error => {
      //       console.error('Hata:', error);
      //     }
      //   );
      // } else {
      //   this.transactionList.allTransActions("1", "20", this.selectedDateRange.start, this.selectedDateRange.end, this.selectedBranch ? true : false, this.selectedBranch ? this.selectedBranch : undefined, this.selectedStaff ? true : false, this.selectedStaff ? this.selectedStaff : undefined,this.selectedPaymentType ? this.selectedPaymentType : undefined).subscribe(
      //     response => {
      //       this.transactionData = response;
      //       this.calculatePages();
      //       this.updateValidPages();
      //       this.cdr.detectChanges();
      //     },
      //     error => {
      //       console.error('Hata:', error);
      //     }
      //   );
      // }
      this.transactionList.allTransActions("1", "20", this.selectedDateRange.start, this.selectedDateRange.end, this.selectedBranch ? true : false, this.selectedBranch ? this.selectedBranch : undefined, this.selectedStaff ? true : false, this.selectedStaff ? this.selectedStaff : undefined, this.selectedPaymentType ? this.selectedPaymentType : undefined).subscribe(
        response => {
          this.transactionData = response;
          this.calculatePages();
          this.updateValidPages();
          this.cdr.detectChanges();
        },
        error => {
          console.error('Hata:', error);
        }
      );



    } else {
      console.log('Tarih aralığı seçilmedi.');
    }
  }

  calculatePages() {
    const totalPages = Math.ceil(this.transactionData.total_count / 20);
    this.pages = Array.from({ length: totalPages }, (_, i) => i + 1);
  }

  updateValidPages() {
    const validPages: number[] = [];
    if (this.selectedDateRange) {
      if (this.selectedBranch) {
        const promises = this.pages.map(page =>
          this.transactionList.allTransActions(page.toString(), "20", this.selectedDateRange?.start, this.selectedDateRange?.end, true, this.selectedBranch).toPromise().then(
            response => {
              if (response && response.data.length > 0) {
                validPages.push(page);
              }
            }
          ).catch(error => {
            console.error('Hata:', error);
          })
        );
        Promise.all(promises).then(() => {
          this.validPages = validPages;
        });
      } else {
        const promises = this.pages.map(page =>
          this.transactionList.allTransActions(page.toString(), "20", this.selectedDateRange?.start, this.selectedDateRange?.end).toPromise().then(
            response => {
              if (response && response.data.length > 0) {
                validPages.push(page);
              }
            }
          ).catch(error => {
            console.error('Hata:', error);
          })
        );
        Promise.all(promises).then(() => {
          this.validPages = validPages;
        });
      }
    } else {
      if (this.selectedBranch) {
        const today = new Date();
        const formattedtoday = `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}`;
        const promises = this.pages.map(page =>
          this.transactionList.allTransActions(page.toString(), "20", formattedtoday, formattedtoday, true, this.selectedBranch).toPromise().then(
            response => {
              if (response && response.data.length > 0) {
                validPages.push(page);
              }
            }
          ).catch(error => {
            console.error('Hata:', error);
          })
        );
        Promise.all(promises).then(() => {
          this.validPages = validPages;
        });
      } else {
        const today = new Date();
        const formattedtoday = `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}`;
        const promises = this.pages.map(page =>
          this.transactionList.allTransActions(page.toString(), "20", formattedtoday, formattedtoday).toPromise().then(
            response => {
              if (response && response.data.length > 0) {
                validPages.push(page);
              }
            }
          ).catch(error => {
            console.error('Hata:', error);
          })
        );
        Promise.all(promises).then(() => {
          this.validPages = validPages;
        });
      }

    }
  }

  goToPage(page: number) {
    if (this.validPages.includes(page)) {
      if (this.selectedDateRange) {
        if (this.selectedBranch) {
          this.transactionList.allTransActions(page.toString(), "20", this.selectedDateRange.start, this.selectedDateRange.end, true, this.selectedBranch).subscribe(
            response => {
              if (response && response.data.length > 0) {
                this.transactionData = response;
                this.transactions.paginate(page);
                this.cdr.detectChanges();
              } else {
                console.warn(`Page ${page} is empty and will not be displayed.`);
                this.updateValidPages();
              }
            },
            error => {
              console.error('Hata:', error);
            }
          );
        }
        this.transactionList.allTransActions(page.toString(), "20", this.selectedDateRange.start, this.selectedDateRange.end).subscribe(
          response => {
            if (response && response.data.length > 0) {
              this.transactionData = response;
              this.transactions.paginate(page);
              this.cdr.detectChanges();
            } else {
              console.warn(`Page ${page} is empty and will not be displayed.`);
              this.updateValidPages();
            }
          },
          error => {
            console.error('Hata:', error);
          }
        );
      } else {
        if (this.selectedBranch) {
          const today = new Date();
          const formattedtoday = `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}`;
          this.transactionList.allTransActions(page.toString(), "20", formattedtoday, formattedtoday, true, this.selectedBranch).subscribe(
            response => {
              if (response && response.data.length > 0) {
                this.transactionData = response;
                this.transactions.paginate(page);
                this.cdr.detectChanges();
              } else {
                console.warn(`Page ${page} is empty and will not be displayed.`);
                this.updateValidPages();
              }
            },
            error => {
              console.error('Hata:', error);
            }
          );
        }
        const today = new Date();
        const formattedtoday = `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}`;
        this.transactionList.allTransActions(page.toString(), "20", formattedtoday, formattedtoday).subscribe(
          response => {
            if (response && response.data.length > 0) {
              this.transactionData = response;
              this.transactions.paginate(page);
              this.cdr.detectChanges();
            } else {
              console.warn(`Page ${page} is empty and will not be displayed.`);
              this.updateValidPages();
            }
          },
          error => {
            console.error('Hata:', error);
          }
        );
      }
    } else {
      console.warn(`Page ${page} is not valid and will not be displayed.`);
    }
  }

  updatePageRange() {
    this.endPage = Math.min(this.startPage + 9, this.pages.length);
  }

  nextPageGroup() {
    if (this.endPage < this.pages.length) {
      this.startPage = this.endPage + 1;
      this.endPage = Math.min(this.startPage + 9, this.pages.length);
      this.cdr.detectChanges();
    }
  }

  prevPageGroup() {
    if (this.startPage > 1) {
      this.endPage = this.startPage - 1;
      this.startPage = Math.max(this.endPage - 9, 1);
      this.cdr.detectChanges();
    }
  }

  onBranchChange(event: any) {
    const selectedCodes = event.value.map((item: any) => item.id);
    this.selectedBranch = selectedCodes
    this.loadTransaction();
    this.cdr.detectChanges();
  }

  onStaffChange(event: any) {
    const selectedCodes = event.value.map((item: PersonelListModel) => item.id);
    this.selectedStaff = selectedCodes
    if (this.selectedDateRange) {
      const today = new Date();
      const formattedtoday = `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}`;
      this.transactionList.allTransActions("1", "20", this.selectedDateRange.start, this.selectedDateRange.end, true, this.selectedBranch, true, this.selectedStaff).subscribe(
        response => {
          this.transactionData = response.data || [];
          this.calculatePages();
          this.updateValidPages();
        },
        error => {
          console.error('Hata:', error);
        }
      );
    } else {
      const today = new Date();
      const formattedtoday = `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}`;
      this.transactionList.allTransActions("1", "20", formattedtoday, formattedtoday, true, this.selectedBranch, true, this.selectedStaff).subscribe(
        response => {
          this.transactionData = response.data || [];
          this.calculatePages();
          this.updateValidPages();
        },
        error => {
          console.error('Hata:', error);
        }
      );
    }
  }

  onPaymentType(event: any): void {
    this.selectedPaymentType = event.target.value;

    if (this.selectedPaymentType === 'all') {
      this.selectedPaymentType = null;
    }

    if (this.selectedDateRange) {
      this.transactionList.allTransActions("1", "20", this.selectedDateRange.start, this.selectedDateRange.end, true, this.selectedBranch, true, this.selectedStaff,this.selectedPaymentType ? this.selectedPaymentType : undefined).subscribe(
        response => {
          this.transactionData = response.data || [];
          this.calculatePages();
          this.updateValidPages();
        },
        error => {
          console.error('Hata:', error);
        }
      );
    } else {
      const today = new Date();
      const formattedtoday = `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}`;
      this.transactionList.allTransActions("1", "20", formattedtoday, formattedtoday, this.selectedBranch ?true :false, this.selectedBranch ? this.selectedBranch :undefined, this.selectedStaff ?true: false, this.selectedStaff ? this.selectedStaff: undefined, this.selectedPaymentType ? this.selectedPaymentType : undefined,).subscribe(
        response => {
          this.transactionData = response.data || [];
          this.calculatePages();
          this.updateValidPages();
        },
        error => {
          console.error('Hata:', error);
        }
      );
    }

  }

  clearFilter() {
    this.selectedPaymentType == null;
  }
}
