import { Routes } from '@angular/router'
import { DefaultLayoutComponent } from './components/layouts/default-layout/default-layout.component'
import { Style01Component } from './pages/dashboards/style-01/style-01.component'
import { Style02Component } from './pages/dashboards/style-02/style-02.component'
import { Style03Component } from './pages/dashboards/style-03/style-03.component'
import { Style04Component } from './pages/dashboards/style-04/style-04.component'
import { Style05Component } from './pages/dashboards/style-05/style-05.component'
import { BankAccountComponent } from './pages/accounts/bank-account/bank-account.component'
import { AccountOverviewComponent } from './pages/accounts/account-overview/account-overview.component'
import { AccountDetailsComponent } from './pages/accounts/account-details/account-details.component'
import { DepositDetailsComponent } from './pages/accounts/deposit-details/deposit-details.component'
import { CardOverviewComponent } from './pages/cards/card-overview/card-overview.component'
import { CardDetailsComponent } from './pages/cards/card-details/card-details.component'
import { TransactionStyle01Component } from './pages/transaction/style-01/style-01.component'
import { TransactionStyle02Component } from './pages/transaction/style-02/style-02.component'
import { TransactionStyle03Component } from './pages/transaction/style-03/style-03.component'
import { PaymentOverviewComponent } from './pages/payment/payment-overview/payment-overview.component'
import { CancellationRefundDetailComponent } from './pages/payment/cancellation-refund-detail/cancellation-refund-detail.component'
import { CollectionDetailComponent } from './pages/payment/collection-detail/collection-detail.component'
import { AddContactComponent } from './pages/private/add-contact/add-contact.component'
import { TransferOverviewComponent } from './pages/private/transfer-overview/transfer-overview.component'
import { MakeTransferComponent } from './pages/private/make-transfer/make-transfer.component'
import { ChatComponent } from './pages/private/chat/chat.component'
import { AddNewInvoiceComponent } from './pages/invoicing/add-new-invoice/add-new-invoice.component'
import { InvoicingStyle01Component } from './pages/invoicing/style-01/style-01.component'
import { InvoicingStyle02Component } from './pages/invoicing/style-02/style-02.component'
import { TradingStyle01Component } from './pages/trading/style-01/style-01.component'
import { ReportsStyle01Component } from './pages/reports/style-01/style-01.component'
import { ReportsStyle02Component } from './pages/reports/style-02/style-02.component'
import { ProfileComponent } from './pages/settings/profile/profile.component'
import { SecurityComponent } from './pages/settings/security/security.component'
import { SocialNetworkComponent } from './pages/settings/social-network/social-network.component'
import { NotificationComponent } from './pages/settings/notification/notification.component'
import { PaymentLimitsComponent } from './pages/settings/payment-limits/payment-limits.component'
import { HelpCenterComponent } from './pages/support/help-center/help-center.component'
import { PrivacyPolicyComponent } from './pages/support/privacy-policy/privacy-policy.component'
import { ContactUsComponent } from './pages/support/contact-us/contact-us.component'
import { SignUpComponent } from './pages/auth/sign-up/sign-up.component'
import { SignInComponent } from './pages/auth/sign-in/sign-in.component'
import { SignInQrcodeComponent } from './pages/auth/sign-in-qrcode/sign-in-qrcode.component'
import { AuthLayoutComponent } from './components/layouts/auth-layout/auth-layout.component'
import { ErrorComponent } from './pages/auth/error/error.component'
import { TradingStyle02Component } from '@pages/trading/style-02/style-02.component'
import { TradingStyle03Component } from '@pages/trading/style-03/style-03.component'
import { DocumentListComponent } from '@pages/transaction/document-list/document-list-component'
import { CollectionReportComponent } from '@pages/reports/collection-report/collection-report.component'
import { ProductSalesReportComponent } from '@pages/reports/product-sales-report/product-sales-report.component'
import { CommissionReportComponent } from '@pages/reports/commission-report/commission-report.component'
import { PaymentProviderseComponent } from '@pages/payment/payment-providers/payment-providers.component'
import { UpdateProductComponent } from '@pages/trading/product-update/update-product.component'
import { AccountsettingsComponent } from '@pages/payment/account-settings/ account-settings.component'
import { CustomerListviewComponent } from '@pages/customers/customer-list/customer-list.component'
import { ServiceBillReportComponent } from '@pages/reports/service-bill-report/service-bill-report.component'
import { CurrentServiceReportComponent } from '@pages/reports/current-service-report/current-service-report.component'
import { ValorReportComponent } from '@pages/reports/valor-report/valor-report.component'
import { TerminalSettingsComponent } from '@pages/settings/terminal-settings/terminal-settings.component'
import { AuthGuard } from '@service/auth-guard'
import { OrdersComponent } from '@pages/orders/order-list/orders.component'
import { OrderListDetailComponent } from '@pages/orders/order-list-detail/order-list-detail.component'
import { ProfileUserComponent } from '@pages/profile-user/profile-user.component'

export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'dashboards'
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboards',
        component: Style02Component,
        // children: [
        //   { path: 'style-01', component: Style01Component },
        //   { path: 'style-02', component: Style02Component },
        //   { path: 'style-03', component: Style03Component },
        //   { path: 'style-04', component: Style04Component },
        //   { path: 'style-05', component: Style05Component }
        // ]
      },
      {
        path: 'accounts',
        children: [
          { path: 'bank-account', component: BankAccountComponent },
          { path: 'account-overview', component: AccountOverviewComponent },
          { path: 'account-details', component: AccountDetailsComponent },
          { path: 'deposit-details', component: DepositDetailsComponent }
        ]
      },
      {
        path: 'cards',
        children: [
          { path: 'card-overview', component: CardOverviewComponent },
          { path: 'card-details', component: CardDetailsComponent }
        ]
      },
      {
        path: 'order',
        children: [
          { path: 'orders-list', component: OrdersComponent, canActivate: [AuthGuard] },
          { path: 'detail/:id', component: OrderListDetailComponent, canActivate: [AuthGuard] },
        ]
      },
      
      {
        path: 'collection',
        component: TransactionStyle02Component,
      },
      { path: 'collection-detail/:id', component: CollectionDetailComponent },

      { path: 'documents', component: DocumentListComponent },
      { path: 'cancellation-refund', component: TransactionStyle01Component },
      {
        path: 'payment',
        children: [
          { path: 'payment-overview/:id', component: PaymentOverviewComponent },
          { path: 'cancellation-refund-detail', component: CancellationRefundDetailComponent },
        ]
      },
      { path: 'general-settings', component: PaymentProviderseComponent },
      {
        path: 'private',
        children: [
          { path: 'add-contact', component: AddContactComponent },
          { path: 'transfer-overview', component: TransferOverviewComponent },
          { path: 'make-transfer', component: MakeTransferComponent },
          { path: 'chat', component: ChatComponent }
        ]
      },
      {
        path: 'invoicing',
        children: [
          { path: 'add-new', component: AddNewInvoiceComponent },
          { path: 'style-01', component: InvoicingStyle01Component },
          { path: 'style-02', component: InvoicingStyle02Component }
        ]
      },
      {
        path: 'product',
        children: [
          { path: 'list', component: TradingStyle01Component },
          { path: 'add', component: UpdateProductComponent },
          { path: 'style-03', component: TradingStyle03Component }
        ]
      },
      { path: 'category', component: TradingStyle02Component },
      {
        path: 'reports',
        children: [
          { path: 'collection-report', component: CollectionReportComponent },
          { path: 'product-sales-report', component: ProductSalesReportComponent },
          { path: 'commission-report', component: CommissionReportComponent },
          { path: 'valor-report', component: ValorReportComponent },
          { path: 'service-bill-report', component: ServiceBillReportComponent },
          { path: 'current-service-report', component: CurrentServiceReportComponent },
          { path: 'style-01', component: ReportsStyle01Component },
          { path: 'style-02', component: ReportsStyle02Component }
        ]
      },
      { path: 'account-settings', component: AccountsettingsComponent },
      { path: 'profile-user', component: ProfileUserComponent },
      { path: 'terminal-settings', component: TerminalSettingsComponent },
      { path: 'customer-list', component: CustomerListviewComponent },
      {
        path: 'settings',
        children: [
          { path: 'profile', component: ProfileComponent },
          { path: 'security', component: SecurityComponent },
          { path: 'social-network', component: SocialNetworkComponent },
          { path: 'notification', component: NotificationComponent },
          { path: 'payment-limits', component: PaymentLimitsComponent }
        ]
      },
      {
        path: 'support',
        children: [
          { path: 'help-center', component: HelpCenterComponent },
          { path: 'privacy-policy', component: PrivacyPolicyComponent },
          { path: 'contact-us', component: ContactUsComponent }
        ]
      }
    ]
  },
  {
    path: 'auth',
    component: AuthLayoutComponent,
    children: [
      { path: 'sign-up', component: SignUpComponent },
      { path: 'sign-in', component: SignInComponent },
      { path: 'signin-qrcode', component: SignInQrcodeComponent },
    ]
  },
  {
    path: 'not-found',
    pathMatch: 'full',
    component: ErrorComponent
  },
  {
    path: '**',
    redirectTo: 'not-found',
    pathMatch: 'full',
  }
]
