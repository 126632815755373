import { CommonModule } from '@angular/common'
import { Component } from '@angular/core'
import { AddCardModalComponent } from '@component/shared/add-card-modal/add-card-modal.component'
import { AddPersonModalComponent } from '@component/shared/add-person-modal/add-person-modal.component'
import { DropdownComponent } from '@component/shared/dropdown/dropdown.component'
import { EditWalletModalComponent } from '@component/shared/edit-wallet-modal/edit-wallet-modal.component'
import { OptionsHorizComponent } from '@component/shared/options-horiz/options-horiz.component'
import { OptionsVerticalWalletPersonListComponent } from '@component/shared/options-vertical-wallet-person-list/options-vertical-wallet-person-list.component'
import { TopBannerComponent } from '@component/shared/top-banner/top-banner.component'
import { paymentAccountData } from '@data/accounts/paymentAccount'
import { TableService } from '@service/table.service'
import { NgApexchartsModule } from 'ng-apexcharts'
import { ModalService } from 'ngx-modal-ease'

@Component({
  selector: 'app-make-transfer',
  standalone: true,
  imports: [TopBannerComponent, CommonModule, DropdownComponent, NgApexchartsModule, OptionsHorizComponent, OptionsVerticalWalletPersonListComponent],
  templateUrl: './make-transfer.component.html'
})
export class MakeTransferComponent {
  paymentAccounts
  pages: number[] = []

  constructor(private modalService: ModalService) {
    this.paymentAccounts = new TableService()
    this.paymentAccounts.initialize(paymentAccountData, 10)
  }

  ngOnInit() {
    this.pages = Array.from({ length: this.paymentAccounts.totalPages }, (_, i) => i + 1)
  }
  walletTypeFilter =['Cüzdan Tipi','Cüzdan Tip-2']
  getLocale(number: number) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'usd',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(number)
  }


  addAccountModal() {
    this.modalService.open(AddCardModalComponent, {
      modal: {
        enter: 'enter-going-down 0.3s ease-out',
        leave: 'fade-out 0.5s'
      },
      overlay: {
        leave: 'fade-out 0.5s'
      },
      data: {
        type: 'Angular modal library'
      }
    })
  }

  editWalletModal() {
    this.modalService.open(EditWalletModalComponent, {
      modal: {
        enter: 'enter-going-down 0.3s ease-out',
        leave: 'fade-out 0.5s'
      },
      overlay: {
        leave: 'fade-out 0.5s'
      },
      data: {
        type: 'Angular modal library'
      }
    })
  }

  addPersonModal() {
    this.modalService.open(AddPersonModalComponent, {
      modal: {
        enter: 'enter-going-down 0.3s ease-out',
        leave: 'fade-out 0.5s'
      },
      overlay: {
        leave: 'fade-out 0.5s'
      },
      data: {
        type: 'Angular modal library'
      }
    })
  }
}
