import { CommonModule } from '@angular/common'
import { Component } from '@angular/core'
import { AddCardModalComponent } from '@component/shared/add-card-modal/add-card-modal.component'
import { CreateWalletModalComponent } from '@component/shared/create-wallet-modal/create-wallet-modal.component'
import { DropdownComponent } from '@component/shared/dropdown/dropdown.component'
import { OptionsHorizComponent } from '@component/shared/options-horiz/options-horiz.component'
import { TopBannerComponent } from '@component/shared/top-banner/top-banner.component'
import { paymentAccountData } from '@data/accounts/paymentAccount'
import { ChartOptions } from '@pages/dashboards/style-01/style-01.component'
import { TableService } from '@service/table.service'
import { NgApexchartsModule } from 'ng-apexcharts'
import { ModalService } from 'ngx-modal-ease'
import { Router } from '@angular/router';
import { OptionsVerticalWalletComponent } from '@component/shared/options-vertical-wallet/options-vertical-wallet.component'
import { FormsModule } from '@angular/forms'

@Component({
  selector: 'app-transfer-overview',
  standalone: true,
  imports: [
    TopBannerComponent,
    FormsModule,
    CommonModule,
    DropdownComponent,
    NgApexchartsModule,
    OptionsHorizComponent,
    OptionsVerticalWalletComponent
  ],
  templateUrl: './transfer-overview.component.html'
})
export class TransferOverviewComponent {
  totalTransferChart!: ChartOptions
  paymentAccounts
  pages: number[] = []
  walletTypeFilter =['Cüzdan Tipi','Cüzdan Tip-2']
  searchWalletID: string = '';
  searchWalletName: string = '';
  searchWalletType: string = '';
  searchWalletAmount: string = '';
  searchWalletCreateDate: string = '';
  searchWalletUser: string = '';

  constructor(private modalService: ModalService,private router: Router) {
    this.paymentAccounts = new TableService()
    this.paymentAccounts.initialize(paymentAccountData, 1)
  }

  ngOnInit() {
    this.pages = Array.from({ length: this.paymentAccounts.totalPages }, (_, i) => i + 1)
  }

  getLocale(number: number) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'try',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(number)
  }

  addAccountModal() {
    this.modalService.open(AddCardModalComponent, {
      modal: {
        enter: 'enter-going-down 0.3s ease-out',
        leave: 'fade-out 0.5s'
      },
      overlay: {
        leave: 'fade-out 0.5s'
      },
      data: {
        type: 'Angular modal library'
      }
    })
  }

  createWalletModal() {
    this.modalService.open(CreateWalletModalComponent, {
      modal: {
        enter: 'enter-going-down 0.3s ease-out',
        leave: 'fade-out 0.5s'
      },
      overlay: {
        leave: 'fade-out 0.5s'
      },
      data: {
        type: 'Angular modal library'
      }
    })
  }

  get filteredTransactionsCombined() {
    const walletID = this.searchWalletID.toLowerCase();
    const walletName = this.searchWalletName.toLowerCase();
    const walletType = this.searchWalletType.toLowerCase();
    const walletUser = this.searchWalletUser.toLowerCase();

    return this.paymentAccounts.tableData.filter(item => {
      const itemWalletID = item.account.toLowerCase();
      const itemWalletName = item.currency.short.toLowerCase();
      const itemWalletType = item.bank.name.toLowerCase();
      const itemWalletUser = item.currency.long.toLowerCase();

      return (!walletID || itemWalletID.includes(walletID)) &&
             (!walletName || itemWalletName.includes(walletName))&&
             (!walletType || itemWalletType.includes(walletType))&&
             (!this.searchWalletAmount || item.balance.includes(this.searchWalletAmount))&&
             (!this.searchWalletCreateDate || item.expire.includes(this.searchWalletCreateDate))&&
             (!walletUser || itemWalletUser.includes(walletUser))
    });
  }
}
