import { Component } from '@angular/core'
import { providersTabData } from '@data/payment/providers'
import { PasswordInputComponent } from '@component/shared/password-input/password-input.component'
import { DropdownComponent } from '@component/shared/dropdown/dropdown.component'
import { ViewChild, ElementRef } from '@angular/core'

@Component({
  selector: 'app-api-endpoint',
  standalone: true,
  imports: [PasswordInputComponent, DropdownComponent],
  templateUrl: './api-endpoint.component.html'
})

export class ApiEndPointComponent {
  providers = providersTabData
  activeTab = this.providers[0].title

  @ViewChild('apiEndpointInput', { static: true }) apiEndpointInput!: ElementRef<HTMLInputElement>;
  @ViewChild('apiKey', { static: true }) apiKey!: ElementRef<HTMLInputElement>;
  @ViewChild('apiAppKey', { static: true }) apiAppKey!: ElementRef<HTMLInputElement>;
  @ViewChild('appPosKey', { static: true }) appPosKey!: ElementRef<HTMLInputElement>;

  copyToCEndPoint() {
    this.apiEndpointInput.nativeElement.select();
    document.execCommand('copy');
    alert('API EndPoint Kopyalandı');
  }

  copyToClipKey() {
    this.apiKey.nativeElement.select();
    document.execCommand('copy');
    alert('API Anahtarı Kopyalandı');
  }

  copyToClipAppKey() {
    this.apiAppKey.nativeElement.select();
    document.execCommand('copy');
    alert('Uygulama Anahtarı Kopyalandı');
  }

  copyToClipPosKey() {
    this.appPosKey.nativeElement.select();
    document.execCommand('copy');
    alert('Pos Anahtarı Kopyalandı');
  }
}


