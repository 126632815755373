import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ProductUpdateService {

    private apiUrl = `${environment.apiUrl}/products/`;
    private token = localStorage.getItem('authToken');

    constructor(private http: HttpClient) { }

    updateProduct(
        product_id:string,
        productTitle:string,
        productSku:string,
        productDescription:string,
        productUnit:string,
        productType:string,
        productTaxRate:number,
        productPurchasePrice:number,
        productPrice:number,
        productPrice1:number,
        productPrice2:number,
        productStockAlert:number,
        productSpecialCard:boolean,
        productPaymentGroup:string,
        productIsActive:boolean,
        productCategories: string[] | null,
        ): Observable<any> {
        const headers = new HttpHeaders()
            .set('Authorization', `Bearer ${this.token}`)
            .set('Content-Type', 'application/json');
            const body ={
                "title": productTitle,
                "sku": productSku,
                "description": productDescription,
                "unit": productUnit,
                "product_type": productType,
                "tax_rate": productTaxRate,
                "purchase_price": productPurchasePrice,
                "price": productPrice,
                "price_1": productPrice1,
                "price_2": productPrice2,
                "stock_alert": productStockAlert,
                "is_special_card": productSpecialCard,
                "payment_group": productPaymentGroup,
                "exemption_code": null,
                "is_active": productIsActive,
                "categories": productCategories
              }
        return this.http.put<any>(`${this.apiUrl}${product_id}`, body, { headers });
    }
}
