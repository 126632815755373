import { Component } from '@angular/core';
import { ModalService } from 'ngx-modal-ease';
import { DropdownComponent } from '../dropdown/dropdown.component';

@Component({
  selector: 'app-create-wallet-modal',
  standalone: true,
  imports: [DropdownComponent],
  templateUrl: './create-wallet-modal.component.html'
})
export class CreateWalletModalComponent {
  walletOfficial = ['Cüzdan Yetkili', 'Cüzdan Yetkili-2', 'Cüzdan Yetkili-3']
  walletType = ['Ön Ödemeli', 'Kredili']
  constructor(private modalService:ModalService){}
  closeModal(){
    this.modalService.close('CreateWalletModalComponent')
  }
}
