import { Component } from '@angular/core'
import { providersTabData } from '@data/payment/providers'
import { PasswordInputComponent } from '@component/shared/password-input/password-input.component'
import { DropdownComponent } from '@component/shared/dropdown/dropdown.component'
import { ModalService } from 'ngx-modal-ease'
import { TableService } from '@service/table.service'
import { paymentAccountData } from '@data/accounts/paymentAccount'
import { DocumentDetailModalComponent } from '../document-detail-modal/document-detail-modal.component'
import { CommonModule } from '@angular/common'
import { TerminalAddModalComponent } from '../terminal-add-modal/terminal-add-modal.component'
import { OptionsVerticalTerminalComponent } from '../options-vertical-terminal/options-vertical-terminal.component'
@Component({
  selector: 'app-terminals',
  standalone: true,
  imports: [
    PasswordInputComponent,
    DropdownComponent,
    CommonModule,
    OptionsVerticalTerminalComponent
  ],
  templateUrl: './terminals-component.html'
})
export class TerminalsComponent {
  paymentAccounts
  providers = providersTabData
  activeTab = this.providers[0].title
  constructor(private modalService: ModalService,) {
    this.paymentAccounts = new TableService()
    this.paymentAccounts.initialize(paymentAccountData, 10)
  }
  openModal() {
    this.modalService.open(DocumentDetailModalComponent, {
      modal: {
        enter: 'enter-going-down 0.3s ease-out',
        leave: 'fade-out 0.5s'
      },
      overlay: {
        leave: 'fade-out 0.5s'
      },
      data: {
        type: 'Angular modal library'
      }
    })
  }

  terminalAdd() {
    this.modalService.open(TerminalAddModalComponent, {
      modal: {
        enter: 'enter-going-down 0.3s ease-out',
        leave: 'fade-out 0.5s'
      },
      overlay: {
        leave: 'fade-out 0.5s'
      },
      data: {
        type: 'Angular modal library'
      }
    })
  }
}
