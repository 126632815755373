<app-top-banner title="Hizmet Fatura Raporları" />
<div class="box col-span-12">
    <div class="flex flex-wrap gap-4 justify-between items-center bb-dashed mb-4 pb-4 lg:mb-6 lg:pb-6"
        id="document-list-header">
        <div class="order-filters">
            <button class="font-medium text-xs px-4 sm:px-5 xxxl:px-6 py-3 capitalize invoice-active">Bugün <i
                    class="las la-calendar ml-2 cursor-pointer"></i></button>
            <button (click)="exportToExcel()" class="font-medium text-xs px-4 sm:px-5 xxxl:px-6 py-3 capitalize invoice-active"
                ng-reflect-ng-class="[object Object]">Dışarı Aktar</button>

        </div>
        <div class="flex items-center max-lg:flex-wrap gap-4" id="documentList">
            <div class="flex grow items-center gap-4 max-sm:flex-wrap sm:justify-end">
                <span style="width: 100%;">Fiyat Aralığı:</span>
                <h5 class="range whitespace-nowrap text-xl font-medium">
                    <span> 100.000₺ </span> /
                    <span class="range__value text-primary"> {{ value1.toFixed(3) }} ₺</span>
                </h5>
                <div class="w-full max-w-[203px] grow" dir="ltr">
                    <form class="range">
                        <div class="form-group range__slider">
                            <input [(ngModel)]="value1" name="limit" type="range" step="1" />
                        </div>
                    </form>
                </div>
            </div>
            <ng-container *ngIf="customerList.length > 0; else loadingOptions">
                <app-dropdown [options]="customerList" dropdownClassProps="document-dd"></app-dropdown>
            </ng-container>
            <ng-template #loadingOptions>
                <p>Seçenekler Yükleniyor...</p>
            </ng-template>

        </div>
    </div>
    <div class="overflow-x-auto mb-4 lg:mb-6">
        <table class="w-full whitespace-nowrap" id="serviceBillListTable">
            <thead>
                <tr class="bg-secondary1/5 dark:bg-bg3">
                    <th class="text-left p-5">
                        <div class="searcHead">
                            <p>İşlem Tarihi</p>
                            <div
                                class="mt-2 bg-primary/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-3  py-2 relative">
                                <input [(ngModel)]="searchDate" class="w-11/12 text-sm bg-transparent p-0 border-none"
                                    type="number">
                                <span class="absolute eye-icon ltr:right-5 rtl:left-5 top-1/2 -translate-y-1/2">
                                    <i class="las la-search"></i>
                                </span>
                            </div>
                        </div>
                    </th>
                    <th class="text-left p-5">
                        <div class="searcHead">
                            <p>Fatura Numarası</p>
                            <div
                                class="mt-2 bg-primary/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-3  py-2 relative">
                                <input [(ngModel)]="searchBillNumber"
                                    class="w-11/12 text-sm bg-transparent p-0 border-none" type="number">
                                <span class="absolute eye-icon ltr:right-5 rtl:left-5 top-1/2 -translate-y-1/2">
                                    <i class="las la-search"></i>
                                </span>
                            </div>
                        </div>
                    </th>
                    <th class="text-left p-5">
                        <div class="searcHead">
                            <p>Müşteri Adı</p>
                            <div
                                class="mt-2 bg-primary/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-3  py-2 relative">
                                <input [(ngModel)]="searchCustomerName"
                                    class="w-11/12 text-sm bg-transparent p-0 border-none" type="text">
                                <span class="absolute eye-icon ltr:right-5 rtl:left-5 top-1/2 -translate-y-1/2">
                                    <i class="las la-search"></i>
                                </span>
                            </div>
                        </div>
                    </th>
                    <th class="text-left p-5">
                        <div class="searcHead">
                            <p>Hizmet Açıklaması</p>
                            <div
                                class="mt-2 bg-primary/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-3  py-2 relative">
                                <input [(ngModel)]="searchDesc" class="w-11/12 text-sm bg-transparent p-0 border-none"
                                    type="text">
                                <span class="absolute eye-icon ltr:right-5 rtl:left-5 top-1/2 -translate-y-1/2">
                                    <i class="las la-search"></i>
                                </span>
                            </div>
                        </div>
                    </th>
                    <th class="text-left p-5">
                        <div class="searcHead">
                            <p>Toplam Bedel</p>
                            <div
                                class="mt-2 bg-primary/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-3  py-2 relative">
                                <input [(ngModel)]="searchTotalAmount"
                                    class="w-11/12 text-sm bg-transparent p-0 border-none" type="number">
                                <span class="absolute eye-icon ltr:right-5 rtl:left-5 top-1/2 -translate-y-1/2">
                                    <i class="las la-search"></i>
                                </span>
                            </div>
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody>
                @for (item of filteredTransactionsCombined; track item.id;let i = $index) {
                <tr>
                    <td class="w-[15%]">
                        <div class="bg-secondary1/5 dark:bg-bg3 py-5 px-3"> {{ item.dueDate }} </div>
                    </td>
                    <td class="w-[15%]">
                        <div class="bg-secondary1/5 dark:bg-bg3 py-5 px-3">{{ item.speciealCode }}</div>
                    </td>
                    <td class="w-[15%]">
                        <div class="bg-secondary1/5 dark:bg-bg3 py-5 px-3">{{ item.title }}</div>
                    </td>
                    <td class="w-[15%]">
                        <div class="bg-secondary1/5 dark:bg-bg3 py-5 px-3">{{ item.title }}</div>
                    </td>
                    <td class="w-[15%]">
                        <div class="bg-secondary1/5 dark:bg-bg3 py-5 px-3">{{ item.collectedAmount }}</div>
                    </td>
                </tr>
                }
            </tbody>
        </table>
    </div>

    <div class="flex col-span-12 gap-4 sm:justify-between justify-center items-center flex-wrap">
        <p>Toplam {{ transactions.totalData }} sayfa içerisinde {{ transactions.startIndex + 1 }}. sayfayı
            görmektesiniz.</p>
        <ul class="flex gap-2 md:gap-3 flex-wrap md:font-semibold items-center">
            <li>
                <button [disabled]="transactions.currentPage == 1" (click)="transactions.prevPage()"
                    class="hover:bg-primary text-primary rtl:rotate-180 hover:text-n0 border md:w-10 duration-300 md:h-10 w-8 h-8 flex items-center rounded-full justify-center border-primary">
                    <i class="las la-angle-left text-lg"></i>
                </button>
            </li>
            <li *ngFor="let page of pages">
                <button (click)="transactions.paginate(page)"
                    [ngClass]="{ 'bg-primary !text-n0': transactions.currentPage == page }"
                    class="hover:bg-primary bg-primary hover:text-n0 border md:w-10 duration-300 md:h-10 w-8 h-8 flex text-primary items-center rounded-full justify-center border-primary">
                    {{ page }}
                </button>
            </li>
            <li>
                <button [disabled]="transactions.currentPage == transactions.totalPages"
                    (click)="transactions.nextPage()"
                    class="hover:bg-primary text-primary hover:text-n0 rtl:rotate-180 border md:w-10 duration-300 md:h-10 w-8 h-8 flex items-center rounded-full justify-center border-primary">
                    <i class="las la-angle-right text-lg"></i>
                </button>
            </li>
        </ul>
    </div>
</div>