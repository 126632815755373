import { TransactionStatus } from '@data/dashboards/style2Transactions'

export const paymentAccountData = [
  {
    id: 3,
    account: 'ER423422',
    icon: 'assets/images/jp-sm.png',
    expire: '11/05/2028',
    currency: {
      long: 'Mehmet Soğukbulak',
      short: 'Pluscüzdan'
    },
    bank: {
      name: 'Ön Ödemeli',
      country: 'Mehmet Soğukbulak'
    },
    status: TransactionStatus.Pending,
    balance: "543200"
  },

  {
    id: 4,
    account: 'ER133422',
    icon: 'assets/images/jp-sm.png',
    expire: '12/05/2028',
    currency: {
      long: 'Efe Semih Demirtaş',
      short: 'Pluscüzdan'
    },
    bank: {
      name: 'Kredili',
      country: 'Efe Semih Demirtaş'
    },
    status: TransactionStatus.Pending,
    balance: "241400"
  },

  {
    id: 5,
    account: 'ER432417',
    icon: 'assets/images/jp-sm.png',
    expire: '13/05/2028',
    currency: {
      long: 'Yiğit Erdem',
      short: 'Pluscüzdan'
    },
    bank: {
      name: 'Ön Ödemeli',
      country:'Yiğit Erdem',
    },
    status: TransactionStatus.Pending,
    balance: "156425"
  },

]
