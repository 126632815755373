<div class="grid grid-cols-12 relative gap-4 xxl:gap-6 max-md:mt-3">
  <div id="chat-sidebar" [ngClass]="chatShow?'chatshow':'chathide'"
    class="max-md:box md:bg-transparent duration-500 max-md:w-[280px] max-md:max-h-[600px] max-md:overflow-y-auto max-md:rounded-xl max-md:absolute ltr:max-md:left-0 rtl:max-md:right-0 z-[3] max-md:bg-n0 max-md:dark:bg-bg4 max-md:top-0 md:col-span-5 xl:col-span-4 max-md:min-w-[300px]">
    <div class="md:box sticky top-20">
      <div class=" border-secondary1/20 mb-4 pb-4 lg:mb-6 lg:pb-6">
      </div>
      <ul class="flex flex-col gap-4 lg:gap-6  mb-6 pb-6">
        @for (item of tabs; track $index) {
        <li>
          <button (click)="setActiveTab(item.title)" class="provider-btn"
            [ngClass]="{ 'provider-active': item.title == activeTab }">
            <div>
              <p class="text-base xxl:text-lg font-medium">{{ item.title }}</p>
            </div>
            <span class="icon">
              <i [class]="item.icon"></i>
            </span>
          </button>
        </li>
        }
      </ul>
    </div>
  </div>

  <div class="col-span-12 md:col-span-7 xl:col-span-8 box xl:p-8">
    <div class=" border-secondary1/20 mb-4 pb-4 lg:mb-6 lg:pb-6">
      @for (item of tabs; track $index) {
      <div id="utility-tab" [ngClass]="{ 'tab-active': item.title == activeTab }"
        class="provider-tab grid grid-cols-12 gap-4 xxl:gap-6">
        @if (item.id == "1") {
        <div class="box col-span-12">
          <div class="flex flex-wrap gap-4 justify-between items-center bb-dashed mb-4 pb-4 lg:mb-6 lg:pb-6">
            <div class="order-filters profileAdd">
              <button (click)="addProfile()"
                class="font-medium text-xs px-4 sm:px-5 xxxl:px-6 py-3 capitalize invoice-active">Yeni Profil Ekle
                <i class="las la-arrow-circle-right ml-2 cursor-pointer"></i></button>
            </div>
          </div>

          <div class="tab-menu">
            <button (click)="selectTab('home')" [class.active]="selectedTab === 'home'">Profiller</button>
            <button [disabled]="selectedFirmId == null" (click)="selectTab('profile')"
              [class.active]="selectedTab === 'profile'">Pos+ Menü İzinleri</button>
            <button [disabled]="selectedFirmId == null" (click)="selectTab('settings')"
              [class.active]="selectedTab === 'settings'">Pos+ Fonksiyon Yetkileri</button>
            <button [disabled]="selectedFirmId == null" (click)="selectTab('posOtomasyon')"
              [class.active]="selectedTab === 'posOtomasyon'">Pos+ Otomasyon Ayarları</button>
            <button [disabled]="selectedFirmId == null" (click)="selectTab('posOrdersSettings')"
              [class.active]="selectedTab === 'posOrdersSettings'">Pos+ Sipariş Ayarları</button>
            <button [disabled]="selectedFirmId == null" (click)="selectTab('posParameterSettings')"
              [class.active]="selectedTab === 'posParameterSettings'">Pos+ Parametre Ayarları</button>
          </div>

          <div>

            <div *ngIf="selectedTab === 'home'" class="overflow-x-auto mb-4 lg:mb-6">
              <table class="w-full whitespace-nowrap">
                <thead>
                  <tr class="bg-secondary1/5 dark:bg-bg3">
                    <th class="text-start py-5 min-w-[130px]">
                      <div class="searcHead">
                        <p>-</p>
                      </div>
                    </th>
                    <th class="text-start py-5 min-w-[130px]">
                      <div class="searcHead">
                        <p>Profil Adı</p>
                      </div>
                    </th>
                    <th class="text-start py-5 min-w-[130px]">
                      <div class="searcHead">
                        <p>Oluşturma Tarihi</p>
                      </div>
                    </th>
                    <th class="text-start py-5 min-w-[130px]">
                      <div class="searcHead">
                        <p>Toplam Tutar</p>
                      </div>
                    </th>
                    <th class="text-start py-5 min-w-[130px]">
                      <div class="searcHead">
                        <p>Durumu</p>
                      </div>
                    </th>
                    <th class="text-start py-5 min-w-[130px]">
                      <div class="searcHead">
                        <p>Kullanıcı Sayısı</p>
                      </div>
                    </th>
                    <th class="text-center p-5">İşlemler</th>
                  </tr>
                </thead>

                <tbody *ngFor="let item of profileList; let i = index;">
                  @if(item.is_default == false){
                  <tr>
                    <td class="w-[15%]">
                      <div class="text-center bg-secondary1/5 dark:bg-bg3 py-5 px-3">
                        <input type="checkbox" [disabled]="selectedFirmName"
                          (click)="selectedBossAuht(item.id,item.title)" />
                      </div>
                    </td>
                    <td class="w-[15%]">
                      <div class="bg-secondary1/5 dark:bg-bg3 py-5 px-3">{{item.title}}</div>
                    </td>
                    <td class="w-[15%]">
                      <div class="bg-secondary1/5 dark:bg-bg3 py-5 px-3">{{item.created_at}}</div>
                    </td>
                    <td class="w-[15%]">
                      <div class="bg-secondary1/5 dark:bg-bg3 py-5 px-3">
                        ₺
                      </div>
                    </td>
                    <td class="w-[15%]">
                      @if(item.is_active == true){
                      <div class="bg-secondary1/5 dark:bg-bg3 py-5 px-3">Aktif</div>
                      }@else{
                      <div class="bg-secondary1/5 dark:bg-bg3 py-5 px-3">Pasif</div>
                      }
                    </td>
                    <td class="w-[15%]">
                      <div class="bg-secondary1/5 dark:bg-bg3 py-5 px-3"> Tanımsız</div>
                    </td>
                    <td class="w-[15%]">
                      <div class="bg-secondary1/5 dark:bg-bg3 py-5 px-3">
                        <app-options-vertical-profil />
                      </div>
                    </td>
                  </tr>
                  }

                </tbody>

              </table>
            </div>

            <div *ngIf="selectedTab === 'profile'" class="overflow-x-auto mb-4 lg:mb-6">

              <table class="w-full whitespace-nowrap">
                <thead class="profSetting">
                  <tr class="bg-secondary1/5 dark:bg-bg3">
                    <th class="text-start py-5 min-w-[130px]">
                      <div class="searcHead">
                        <p>Yetki Adı</p>
                      </div>
                    </th>
                    <th class="text-start py-5 min-w-[130px]">
                      <div class="searcHead">
                        <p>İşlem</p>
                      </div>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  <tr *ngFor="let item of posMenuAuthData; let i = index;" [formGroup]="form" class="possSettings">

                    <td class="w-[15%] bg-secondary1/5 dark:bg-bg3 py-5 px-3">
                      <div>{{item.title}}</div>
                    </td>

                    <td class="w-[100%] select-area bg-secondary1/5 dark:bg-bg3 py-5 px-3"
                      *ngIf="item.model.selection_type == 'MULTI_SELECT'">
                      <div *ngFor="let options of item.model.options">
                        <p>{{ options.title }}</p>
                        <div class="toggle-switch" [class.checked]="isCheckedChange(options.id, item)"
                          (click)="toggleSwitch(options.id, item, item.code)">
                          <div class="switch-handle"></div>
                        </div>
                      </div>
                    </td>

                    <td class="w-[15%]" *ngIf="item.model.selection_type == 'CHECKBOX'">
                      <p>efef</p>
                    </td>

                    <td class="w-[15%]" *ngIf="item.model.selection_type == 'SELECT'">
                      <div>
                        <mat-form-field>
                          <mat-select>
                            @for (topping of item.model.options; track topping) {
                            <mat-option [value]="topping.id">{{topping.title}}</mat-option>
                            }
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </td>

                    <td class="w-[15%]" *ngIf="item.model.selection_type == 'NUMBER'">
                      <div>
                        <input type="number" (click)="changeCheckboxName(i)" [id]="item.code"
                          [formControlName]="checkboxName">
                      </div>
                    </td>

                    <td class="w-[15%]" *ngIf="item.model.selection_type == 'DYNAMIC_SELECT'">
                      <p>efef</p>
                    </td>

                  </tr>

                </tbody>

              </table>

            </div>

            <div *ngIf="selectedTab === 'settings'" class="overflow-x-auto mb-4 lg:mb-6">
              <table class="w-full whitespace-nowrap">
                <thead class="profSetting">
                  <tr class="bg-secondary1/5 dark:bg-bg3">
                    <th class="text-start py-5 min-w-[130px]">
                      <div class="searcHead">
                        <p>Yetki Adı</p>
                      </div>
                    </th>
                    <th class="text-start py-5 min-w-[130px]">
                      <div class="searcHead">
                        <p>İşlem</p>
                      </div>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  <tr *ngFor="let item of posMenuFuncData; let i = index;" [formGroup]="form" class="possSettings">

                    <td class="w-[15%] bg-secondary1/5 dark:bg-bg3 py-5 px-3">
                      <div>{{item.title}}</div>
                    </td>

                    <td class="w-[100%] select-area bg-secondary1/5 dark:bg-bg3 py-5 px-3"
                      *ngIf="item.model.selection_type == 'MULTI_SELECT'">
                      <div *ngFor="let options of item.model.options">
                        <p>{{ options.title }}</p>
                        <div class="toggle-switch" [class.checked]="isCheckedChange(options.id, item)"
                          (click)="toggleSwitch(options.id, item, item.code)">
                          <div class="switch-handle"></div>
                        </div>
                      </div>
                    </td>

                    <td class="w-[15%] bg-secondary1/5 dark:bg-bg3 py-5 px-3"
                      *ngIf="item.model.selection_type == 'CHECKBOX'">
                      <div class="toggle-switch" [class.checked]="item.model.value"
                        (click)="toggleStatus(item, item.model.value)">
                        <div class="switch-handle"></div>
                      </div>
                    </td>

                    <td class="w-[15%] bg-secondary1/5 dark:bg-bg3 py-5 px-3"
                      *ngIf="item.model.selection_type == 'SELECT'">
                      <span>Aktif Değer: {{selectedSelectItems[item.code] ?? item.model.value}}</span>
                      <div>
                        <select
                          class="rounded-3xl w-full !py-3 formDevicesSet w-full mb-1 text-sm bg-n0 dark:bg-bg4 border border-n30 dark:border-n500 rounded-3xl px-3 md:px-6 py-2 md:py-3 ng-pristine ng-invalid ng-touched"
                          (change)="onItemSelected(item,$event)">
                          <option *ngFor="let item of item.model.options" value="{{item.id}}">
                            {{item.title}}
                          </option>
                        </select>
                      </div>
                    </td>

                    <td class="w-[15%]" *ngIf="item.model.selection_type == 'NUMBER'">
                      <div>
                        <input type="number" (click)="changeCheckboxName(i)" [id]="item.code"
                          [formControlName]="checkboxName">
                      </div>
                    </td>

                    <td class="w-[15%]" *ngIf="item.model.selection_type == 'DYNAMIC_SELECT'">
                      <p>efef</p>
                    </td>

                  </tr>

                </tbody>

              </table>
            </div>

            <div *ngIf="selectedTab === 'posOtomasyon'" class="overflow-x-auto mb-4 lg:mb-6">

              <table class="w-full whitespace-nowrap">
                <thead class="profSetting">
                  <tr class="bg-secondary1/5 dark:bg-bg3">
                    <th class="text-start py-5 min-w-[130px]">
                      <div class="searcHead">
                        <p>Yetki Adı</p>
                      </div>
                    </th>
                    <th class="text-start py-5 min-w-[130px]">İşlem</th>
                  </tr>
                </thead>

                <tbody>
                  <tr *ngFor="let item of posMenuAutomationData; let i = index;" [formGroup]="form"
                    class="possSettings">
                    <td class="w-[15%] bg-secondary1/5 dark:bg-bg3 py-5 px-3">
                      <div>{{item.title}}</div>
                    </td>

                    <td class="w-[100%] select-area bg-secondary1/5 dark:bg-bg3 py-5 px-3"
                      *ngIf="item.model.selection_type == 'MULTI_SELECT'">
                      <div *ngFor="let options of item.model.options">
                        <p>{{ options.title }}</p>
                        <div class="toggle-switch" [class.checked]="isCheckedChange(options.id, item)"
                          (click)="toggleSwitch(options.id, item, item.code)">
                          <div class="switch-handle"></div>
                        </div>
                      </div>
                    </td>

                    <td class="w-[15%] bg-secondary1/5 dark:bg-bg3 py-5 px-3"
                      *ngIf="item.model.selection_type == 'CHECKBOX'">
                      <div class="toggle-switch" [class.checked]="item.model.value"
                        (click)="toggleStatus(item, item.model.value)">
                        <div class="switch-handle"></div>
                      </div>
                    </td>

                    <td class="w-[15%]" *ngIf="item.model.selection_type == 'SELECT'">
                      <div>
                        <mat-form-field>
                          <mat-select>
                            @for (topping of item.model.options; track topping) {
                            <mat-option [value]="topping.id">{{topping.title}}</mat-option>
                            }
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </td>

                    <td class="w-[15%]" *ngIf="item.model.selection_type == 'NUMBER'">
                      <div>
                        <input type="number" (click)="changeCheckboxName(i)" [id]="item.code"
                          [formControlName]="checkboxName">
                      </div>
                    </td>

                    <td class="w-[15%]" *ngIf="item.model.selection_type == 'DYNAMIC_SELECT'">
                      <p>efef</p>
                    </td>

                  </tr>

                </tbody>

              </table>

            </div>

            <div *ngIf="selectedTab === 'posOrdersSettings'" class="overflow-x-auto mb-4 lg:mb-6">

              <table class="w-full whitespace-nowrap">
                <thead class="profSetting">
                  <tr class="bg-secondary1/5 dark:bg-bg3">
                    <th class="text-start py-5 min-w-[130px]">
                      <div class="searcHead">
                        <p>Yetki Adı</p>
                      </div>
                    </th>
                    <th class="text-start py-5 min-w-[130px]">İşlem</th>
                  </tr>
                </thead>

                <tbody>
                  <tr *ngFor="let item of posOrderData; let i = index;" [formGroup]="form" class="possSettings">


                    <td class="w-[15%] bg-secondary1/5 dark:bg-bg3 py-5 px-3">
                      <div>{{item.title}}</div>
                    </td>

                    <td class="w-[100%] select-area bg-secondary1/5 dark:bg-bg3 py-5 px-3"
                      *ngIf="item.model.selection_type == 'MULTI_SELECT'">
                      <div *ngFor="let options of item.model.options">
                        <p>{{ options.title }}</p>
                        <div class="toggle-switch" [class.checked]="isCheckedChange(options.id, item)"
                          (click)="toggleSwitch(options.id, item, item.code)">
                          <div class="switch-handle"></div>
                        </div>
                      </div>
                    </td>

                    <td class="w-[15%] bg-secondary1/5 dark:bg-bg3 py-5 px-3"
                      *ngIf="item.model.selection_type == 'CHECKBOX'">
                      <div class="toggle-switch" [class.checked]="item.model.value"
                        (click)="toggleStatus(item, item.model.value)">
                        <div class="switch-handle"></div>
                      </div>
                    </td>

                    <td class="w-[15%] bg-secondary1/5 dark:bg-bg3 py-5 px-3"
                      *ngIf="item.model.selection_type == 'SELECT'">
                      <span>Aktif Değer: {{selectedSelectItems[item.code] ?? item.model.value}}</span>
                      <div>
                        <select
                          class="rounded-3xl w-full !py-3 formDevicesSet w-full mb-1 text-sm bg-n0 dark:bg-bg4 border border-n30 dark:border-n500 rounded-3xl px-3 md:px-6 py-2 md:py-3 ng-pristine ng-invalid ng-touched"
                          (change)="onItemSelected(item,$event)">
                          <option *ngFor="let item of item.model.options" value="{{item.id}}">
                            {{item.title}}
                          </option>
                        </select>
                      </div>
                    </td>

                    <td class="w-[15%]" *ngIf="item.model.selection_type == 'NUMBER'">
                      <div>
                        <input type="number" (click)="changeCheckboxName(i)" [id]="item.code"
                          [formControlName]="checkboxName">
                      </div>
                    </td>

                    <td class="w-[15%]" *ngIf="item.model.selection_type == 'DYNAMIC_SELECT'">
                      <p>efef</p>
                    </td>

                  </tr>

                </tbody>

              </table>

            </div>

            <div *ngIf="selectedTab === 'posParameterSettings'" class="overflow-x-auto mb-4 lg:mb-6">

              <table class="w-full whitespace-nowrap">
                <thead class="profSetting">
                  <tr class="bg-secondary1/5 dark:bg-bg3">
                    <th class="text-start py-5 min-w-[130px]">
                      <div class="searcHead">
                        <p>Yetki Adı</p>
                      </div>
                    </th>
                    <th class="text-start py-5 min-w-[130px]">İşlem</th>
                  </tr>
                </thead>

                <tbody>
                  <tr *ngFor="let item of parameterData; let i = index;" [formGroup]="form" class="possSettings">

                    <td class="w-[15%] bg-secondary1/5 dark:bg-bg3 py-5 px-3">
                      <div>{{item.title}}</div>
                    </td>

                    <td class="w-[100%] select-area bg-secondary1/5 dark:bg-bg3 py-5 px-3"
                      *ngIf="item.model.selection_type == 'MULTI_SELECT'">
                      <div *ngFor="let options of item.model.options">
                        <p>{{ options.title }}</p>
                        <div class="toggle-switch" [class.checked]="isCheckedChange(options.id, item)"
                          (click)="toggleSwitch(options.id, item, item.code)">
                          <div class="switch-handle"></div>
                        </div>
                      </div>
                    </td>

                    <td class="w-[15%] bg-secondary1/5 dark:bg-bg3 py-5 px-3"
                      *ngIf="item.model.selection_type == 'CHECKBOX'">
                      <div class="toggle-switch" [class.checked]="item.model.value"
                        (click)="toggleStatus(item, item.model.value)">
                        <div class="switch-handle"></div>
                      </div>
                    </td>

                    <td class="w-[15%] bg-secondary1/5 dark:bg-bg3 py-5 px-3"
                      *ngIf="item.model.selection_type == 'SELECT'">
                      <span>Aktif Değer: {{selectedSelectItems[item.code] ?? item.model.value}}</span>
                      <div>
                        <select
                          class="rounded-3xl w-full !py-3 formDevicesSet w-full mb-1 text-sm bg-n0 dark:bg-bg4 border border-n30 dark:border-n500 rounded-3xl px-3 md:px-6 py-2 md:py-3 ng-pristine ng-invalid ng-touched"
                          (change)="onItemSelected(item,$event)">
                          <option *ngFor="let item of item.model.options" value="{{item.id}}">
                            {{item.title}}
                          </option>
                        </select>
                      </div>
                    </td>

                    <td class="w-[100%] bg-secondary1/5 dark:bg-bg3 py-5 px-3"
                      *ngIf="item.model.selection_type == 'NUMBER'">
                      <div class="column">
                        <div class="rangeArea">
                          <div>
                            <input type="number" (click)="changeCheckboxName(i)" [id]="item.code"
                              [formControlName]="'limitedValue'" placeholder="{{item.model.value}}" max="10000">
                          </div>
                          <div class="rangeSubmitButton">
                            <button (click)="validateValue(item.code)"
                              class="font-medium text-xs px-4 sm:px-5 xxxl:px-6 py-3 capitalize invoice-active">
                              Güncelle
                            </button>
                          </div>
                        </div>
                        <div *ngIf="errorMessage" class="text-red-500">
                          <span>{{ errorMessage }}</span>
                        </div>
                      </div>
                    </td>
                    <td class="w-[15%] bg-secondary1/5 dark:bg-bg3 py-5 px-3"
                      *ngIf="item.model.selection_type == 'DYNAMIC_SELECT'">
                      <p>efef</p>
                    </td>
                  </tr>

                </tbody>

              </table>

            </div>

          </div>



        </div>
        }@else if (item.id == "2") {

        <div class="box col-span-12">
          <div class="flex flex-wrap gap-4 justify-between items-center bb-dashed mb-4 pb-4 lg:mb-6 lg:pb-6">
            <div class="order-filters userAdd">
              <button (click)="addProfile()"
                class="font-medium text-xs px-4 sm:px-5 xxxl:px-6 py-3 capitalize invoice-active">Yeni Kullanıcı Ekle
                <i class="las la-arrow-circle-right ml-2 cursor-pointer"></i></button>
            </div>
          </div>
          <table class="w-full whitespace-nowrap">
            <thead>
              <tr class="bg-secondary1/5 dark:bg-bg3">
                <th class="text-start py-5 min-w-[130px]">
                  <div class="searcHead">
                    <p>Kullanıcı Adı</p>
                  </div>
                </th>
                <th class="text-start py-5 min-w-[130px]">
                  <div class="searcHead">
                    <p>Ekli Olduğu Profiller</p>
                  </div>
                </th>
                <th class="text-start py-5 min-w-[130px]">
                  <div class="searcHead">
                    <p>Durumu</p>
                  </div>
                </th>
                <th class="text-start py-5 min-w-[130px]">
                  <div class="searcHead">
                    <p>Kayıt Tarihi</p>
                  </div>
                </th>
                <th class="text-center p-5">İşlemler</th>
              </tr>
            </thead>

            <!-- <tbody *ngFor="let item of profileUserList; let i = index;">
              <tr>
                <td class="w-[15%]">
                  <div class="bg-secondary1/5 dark:bg-bg3 py-5 px-3">{{item.name}}</div>
                </td>
                <td class="w-[15%]">
                  <div class="bg-secondary1/5 dark:bg-bg3 py-5 px-3">{{item.}}</div>
                </td>
                <td class="w-[15%]">
                  <div class="bg-secondary1/5 dark:bg-bg3 py-5 px-3">
                    ₺
                  </div>
                </td>
                <td class="w-[15%]">
                  @if(item.is_active == true){
                  <div class="bg-secondary1/5 dark:bg-bg3 py-5 px-3">Aktif</div>
                  }@else{
                  <div class="bg-secondary1/5 dark:bg-bg3 py-5 px-3">Pasif</div>
                  }
                </td>
                <td class="w-[15%]">
                  <div class="bg-secondary1/5 dark:bg-bg3 py-5 px-3"> Tanımsız</div>
                </td>
                <td class="w-[15%]">
                  <div class="bg-secondary1/5 dark:bg-bg3 py-5 px-3">
                    <app-options-vertical-profil />
                  </div>
                </td>
              </tr>

            </tbody> -->

          </table>
        </div>

        }
      </div>
      }
    </div>
  </div>
</div>