<div class="box xxl:p-8 xxxl:p-10">
    <p class="text-lg font-medium mb-4">Şirket Logosu Ekle</p>
    <div class="flex flex-wrap gap-6 xxl:gap-10 items-center bb-dashed mb-6 pb-6"><img
        src="assets/images/placeholder.png" width="120" height="120" alt="img" class="rounded-xl">
      <div class="flex gap-4"><label for="photo-upload" class="btn"> Fotoğraf Yükle </label><input type="file"
          id="photo-upload" class="hidden"><button class="btn-outline">İptal</button></div>
    </div>
    <form class="mt-6 xl:mt-8 grid grid-cols-2 gap-4 xxxxxl:gap-6">
      <div class="col-span-2 md:col-span-1">
        <label for="fCompanyName" class="md:text-lg font-medium block mb-4"> Şirket Unvanı </label>
        <input type="text" id="fCompanyName" placeholder="{{firmData.company.name || 'Şirket Unvanı' }}" required=""
          class="w-full text-sm bg-primary/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-3 md:px-6 py-2 md:py-3">
      </div>
      <div class="col-span-2 md:col-span-1">
        <label for="fCompanyCeo" class="md:text-lg font-medium block mb-4">Şirket Sahibi </label>
        <input type="text" placeholder="Yılmaz Erden" id="fCompanyCeo" required=""
          class="w-full text-sm bg-primary/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-3 md:px-6 py-2 md:py-3">
      </div>
      <div class="col-span-2 md:col-span-1">
        <label for="fCeoMail" class="md:text-lg font-medium block mb-4">Mail
          Adresi </label>
        <input type="mail" id="fCeoMail" placeholder="yilmaz@plus.com.tr" required=""
          class="w-full text-sm bg-primary/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-3 md:px-6 py-2 md:py-3">
      </div>
      <div class="col-span-2 md:col-span-1">
        <label for="fPhoneCeo" class="md:text-lg font-medium block mb-4">Telefon </label>
        <input type="number" placeholder="{{firmData.phone || 'Telefon'}}" id="fPhoneCeo" required=""
          class="w-full text-sm bg-primary/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-3 md:px-6 py-2 md:py-3">
      </div>
      <div class="col-span-2 md:col-span-1">
        <label for="fCompanyPhone" class="md:text-lg font-medium block mb-4">İş Telefonu </label>
        <input type="number" placeholder="{{firmData.company.phone  || 'İş Telefonu' }}" id="fCompanyPhone" required=""
          class="w-full text-sm bg-primary/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-3 md:px-6 py-2 md:py-3">
      </div>
      <div class="col-span-2 md:col-span-1">
        <label for="location" class="md:text-lg font-medium block mb-4"> Şehir </label>
        <app-dropdown [options]="city" btnClassProps="rounded-3xl w-full !py-3" dropdownClassProps="w-full" />
      </div>
      <div class="col-span-2 md:col-span-1">
        <label for="location" class="md:text-lg font-medium block mb-4"> İlçe </label>
        <app-dropdown [options]="district" btnClassProps="rounded-3xl w-full !py-3" dropdownClassProps="w-full" />
      </div>

      <div class="col-span-2 md:col-span-1">
        <label for="fTaxArea" class="md:text-lg font-medium block mb-4">Vergi Dairesi</label>
        <input type="text" id="fTaxArea" placeholder="{{firmData.company.tax_address || 'Vergi Dairesi'}}" required
          class="w-full text-sm bg-primary/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-3 md:px-6 py-2 md:py-3">
      </div>

      <div class="col-span-2 md:col-span-1">
        <label for="fNeighbourhood" class="md:text-lg font-medium block mb-4">Mahalle</label>
        <input type="text" id="fNeighbourhood" placeholder="{{firmData.address.line || 'Mahalle'}}" required
          class="w-full text-sm bg-primary/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-3 md:px-6 py-2 md:py-3">
      </div>
      

      <div class="col-span-2 md:col-span-1">
        <label for="fAdress" class="md:text-lg font-medium block mb-4">Adres Bilgisi</label>
        <input type="text" id="fAdress" placeholder="{{firmData.address.city.name}} {{firmData.address.district.name || 'Adres Bilgisi' }}" required
          class="w-full text-sm bg-primary/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-3 md:px-6 py-2 md:py-3">
      </div>

      <div class="col-span-2 md:col-span-1">
        <label for="fTaxNum" class="md:text-lg font-medium block mb-4">Vergi Numarası</label>
        <input type="number" id="fTaxNum" placeholder="{{firmData.company.tax_number || 'Vergi Numarası'  }}" required
          class="w-full text-sm bg-primary/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-3 md:px-6 py-2 md:py-3">
      </div>

      <div class="col-span-2">
        <div class="flex mt-6 xxl:mt-10 gap-4"><button class="btn px-5">Kaydet</button><button
            class="btn-outline px-5">İptal</button></div>
      </div>
    </form>
  </div>