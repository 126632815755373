import { Component } from '@angular/core';
import { ModalService } from 'ngx-modal-ease';
import { DropdownComponent } from '../dropdown/dropdown.component';

@Component({
  selector: 'app-valor-modal',
  standalone: true,
  imports: [DropdownComponent],
  templateUrl: './valor-modal.component.html'
})
export class ValorModalComponent {
  constructor(private modalService:ModalService){}
  closeModal(){
    this.modalService.close('ValorModalComponent')
  }
}
