<div class="box xxl:p-8 xxxl:p-10">
  <h4 class="h4 bb-dashed pb-4">Ürün Ekle</h4>
  <form [formGroup]="productForm" class="mt-6 xl:mt-8 grid grid-cols-2 md:grid-cols-4 gap-4 xxxxl:gap-6 prodForm">
    <div class="col-span-2 md:col-span-1">
      <label for="category" class="md:text-lg font-medium block mb-4">Ürün Kategorisi</label>
      <p-multiSelect selectedItemsLabel="{0} kategori seçildi"
        class="bg-primary/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-3 md:px-6 py-2 md:py-3 relative"
        [options]="categoryList" formControlName="category" optionLabel="name" placeholder="Kategori Seçiniz."
        (onChange)="onCategoryChange($event)" />
        <span *ngIf="productForm.get('category')!.invalid && productForm.get('category')!.touched" class="error-message">
          Kategori seçimi zorunludur.
        </span>
    </div>
    <div class="col-span-2 md:col-span-1">
      <label for="productName" class="md:text-lg font-medium block mb-4">Ürün Adı</label>
      <div
        class="bg-primary/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-3 md:px-6 py-2 md:py-3 relative">
        <input type="text" id="productName" formControlName="productName"
          class="w-11/12 text-sm bg-transparent p-0 border-none" placeholder="Ürün Adı" />
      </div>
      <span *ngIf="productForm.get('productName')!.invalid && productForm.get('productName')!.touched" class="error-message">
        Ürün Adı zorunludur.
      </span>
    </div>
    <div class="col-span-2 md:col-span-1">
      <label for="productType" class="md:text-lg font-medium block mb-4">Ürün Tipi</label>
      <select formControlName="productType" (change)="onSelectChange($event)">
        <option *ngFor="let item of productTypeEnum" [value]="item.code">{{ item.title }}</option>
      </select>
      <span *ngIf="productForm.get('productType')!.invalid && productForm.get('productType')!.touched" class="error-message">
        Ürün Tipi zorunludur.
      </span>
    </div>
    <div class="col-span-2 md:col-span-1">
      <label for="unit" class="md:text-lg font-medium block mb-4">Birim</label>
      <select formControlName="unit" (change)="onSelectChange($event)">
        <option *ngFor="let item of unitTypeEnum" [value]="item.code">{{ item.title }}</option>
      </select>
      <span *ngIf="productForm.get('unit')!.invalid && productForm.get('unit')!.touched" class="error-message">
        Birim zorunludur.
      </span>
    </div>
    <div class="col-span-2 md:col-span-1">
      <label for="productPrice" class="md:text-lg font-medium block mb-4">Ürün Fiyatı (TL)</label>
      <div
        class="bg-primary/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-3 md:px-6 py-2 md:py-3 relative">
        <input type="text" id="productPrice" formControlName="productPrice"
          class="w-11/12 text-sm bg-transparent p-0 border-none" placeholder="200₺" />
      </div>
      <span *ngIf="productForm.get('productPrice')!.invalid && productForm.get('productPrice')!.touched" class="error-message">
        Ürün Fiyatı zorunludur.
      </span>
    </div>
    <div class="col-span-2 md:col-span-1">
      <label for="vatRate" class="md:text-lg font-medium block mb-4">KDV Oranı (%)</label>
      <div
        class="bg-primary/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-3 md:px-6 py-2 md:py-3 relative">
        <input type="text" id="vatRate" formControlName="vatRate" class="w-11/12 text-sm bg-transparent p-0 border-none"
          placeholder="%8" />
      </div>
      <span *ngIf="productForm.get('vatRate')!.invalid && productForm.get('vatRate')!.touched" class="error-message">
        KDV Oranı zorunludur.
      </span>
    </div>
    <div class="col-span-2 md:col-span-1">
      <label for="productPrice2" class="md:text-lg font-medium block mb-4">Ürün Fiyat 2</label>
      <div
        class="bg-primary/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-3 md:px-6 py-2 md:py-3 relative">
        <input type="text" id="productPrice2" formControlName="productPrice2"
          class="w-11/12 text-sm bg-transparent p-0 border-none" placeholder="200₺" />
      </div>
      <span *ngIf="productForm.get('productPrice2')!.invalid && productForm.get('productPrice2')!.touched" class="error-message">
        Ürün Fiyat 2 zorunludur.
      </span>
    </div>
    <div class="col-span-2 md:col-span-1">
      <label for="productPrice3" class="md:text-lg font-medium block mb-4">Ürün Fiyat 3</label>
      <div
        class="bg-primary/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-3 md:px-6 py-2 md:py-3 relative">
        <input type="text" id="productPrice3" formControlName="productPrice3"
          class="w-11/12 text-sm bg-transparent p-0 border-none" placeholder="200₺" />
      </div>
      <span *ngIf="productForm.get('productPrice3')!.invalid && productForm.get('productPrice3')!.touched" class="error-message">
        Ürün Fiyat 3 zorunludur.
      </span>
    </div>
    <div class="col-span-2 md:col-span-1">
      <label for="purchasePrice" class="md:text-lg font-medium block mb-4">Satın Alma Fiyatı</label>
      <div
        class="bg-primary/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-3 md:px-6 py-2 md:py-3 relative">
        <input type="text" id="purchasePrice" formControlName="purchasePrice"
          class="w-11/12 text-sm bg-transparent p-0 border-none" placeholder="200₺" />
      </div>
      <span *ngIf="productForm.get('purchasePrice')!.invalid && productForm.get('purchasePrice')!.touched" class="error-message">
        Satın Alma Fiyatı zorunludur.
      </span>
    </div>
    <div class="col-span-2 md:col-span-1">
      <label for="productCode" class="md:text-lg font-medium block mb-4">Ürün Kodu</label>
      <div
        class="bg-primary/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-3 md:px-6 py-2 md:py-3 relative">
        <input type="text" id="productCode" formControlName="productCode"
          class="w-11/12 text-sm bg-transparent p-0 border-none" placeholder="YTEUF83746832" />
      </div>
      <span *ngIf="productForm.get('productCode')!.invalid && productForm.get('productCode')!.touched" class="error-message">
        Ürün Kodu zorunludur.
      </span>
    </div>
    <div class="col-span-2 md:col-span-1">
      <label for="sku" class="md:text-lg font-medium block mb-4">SKU (Barkod)</label>
      <div
        class="bg-primary/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-3 md:px-6 py-2 md:py-3 relative">
        <input type="text" id="sku" formControlName="sku" class="w-11/12 text-sm bg-transparent p-0 border-none"
          placeholder="Barkod giriniz..." />
      </div>
      <span *ngIf="productForm.get('sku')!.invalid && productForm.get('sku')!.touched" class="error-message">
        SKU (Barkod) zorunludur.
      </span>
    </div>
    <div class="col-span-2 md:col-span-1">
      <label for="stock" class="md:text-lg font-medium block mb-4">Stok Miktarı</label>
      <div
        class="bg-primary/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-3 md:px-6 py-2 md:py-3 relative">
        <input type="text" id="stock" formControlName="stock" class="w-11/12 text-sm bg-transparent p-0 border-none"
          placeholder="123" />
      </div>
      <span *ngIf="productForm.get('stock')!.invalid && productForm.get('stock')!.touched" class="error-message">
        Stok Miktarı zorunludur.
      </span>
    </div>
    <div class="col-span-2">
      <label for="description" class="md:text-lg font-medium block mb-4">Ürün Açıklaması</label>
      <div
        class="bg-primary/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-3 md:px-6 py-2 md:py-3 relative">
        <textarea id="description" formControlName="description" class="w-11/12 text-sm bg-transparent p-0 border-none"
          placeholder="Ürün Açıklaması"></textarea>
      </div>
      <span *ngIf="productForm.get('description')!.invalid && productForm.get('description')!.touched" class="error-message">
        Ürün Açıklaması zorunludur.
      </span>
    </div>
    <div class="col-span-2 md:col-span-1">
      <label for="criticStock" class="md:text-lg font-medium block mb-4">Kritik Stok Uyarı Seviyesi</label>
      <div
        class="bg-primary/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-3 md:px-6 py-2 md:py-3 relative">
        <input type="text" id="criticStock" formControlName="criticStock"
          class="w-11/12 text-sm bg-transparent p-0 border-none" placeholder="10" />
      </div>
      <span *ngIf="productForm.get('criticStock')!.invalid && productForm.get('criticStock')!.touched" class="error-message">
        Kritik Stok Uyarı Seviyesi zorunludur.
      </span>
    </div>
    <div class="col-span-2">
      <div class="flex mt-6 xxl:mt-10 gap-4">
        <button type="submit" class="btn px-5" (click)="addProdTap()">Kaydet</button>
        <button type="button" class="btn-outline px-5" (click)="onCancel()">İptal</button>
      </div>
    </div>
  </form>
</div>