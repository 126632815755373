
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CategoryListModel } from '../../models/category/category-list.model';
import { CategoryAddModel } from '../../models/category/category-add.model';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class CategoryListService {

    private apiUrl = `${environment.apiUrl}/category/all`;
    private token = localStorage.getItem('authToken');
    constructor(private http: HttpClient) { }

    allCategories(): Observable<CategoryListModel[]> {
        const headers = new HttpHeaders()
            .set('Authorization', `Bearer ${this.token}`);
        const urlPath = `${this.apiUrl}`;
        return this.http.get<CategoryListModel[]>(urlPath, { headers });
    }

    private updateApi = `${environment.apiUrl}/category/`;
    updateCategory(categoryId: string, itemStatus:boolean,itemName:string): Observable<any[]> {
        const headers = new HttpHeaders().set('Authorization', `Bearer ${this.token}`);

        const body = {
            "name": itemName,
            "is_active": itemStatus
        }
        
        const urlPostPath = `${this.updateApi}`;
        return this.http.put<any>(urlPostPath + categoryId, body,{ headers });
    }

    private categoryListEditApi = `${environment.apiUrl}/category`;
    updateCategoryList(items:any[]): Observable<any> {
        const categoryEditApi = `${this.categoryListEditApi}`;
        const headers = new HttpHeaders().set('Authorization', `Bearer ${this.token}`);
        const body = {
            "items": items
          }; 
    
        return this.http.patch(categoryEditApi, body,{headers});
    }

    private categoryAddApi = `${environment.apiUrl}/category`;
    addCategory(categoryCode:string,categoryName:string): Observable<CategoryAddModel> {
        const categorAddUrl = `${this.categoryAddApi}`;
        const headers = new HttpHeaders().set('Authorization', `Bearer ${this.token}`);
        const body = {
            "code": categoryCode,
            "name": categoryName,
          }; 
    
        return this.http.post<CategoryAddModel>(categorAddUrl, body,{headers});
    }
}
