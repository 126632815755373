import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class InvoicesDetailService {

    private apiUrl = `${environment.apiUrl}/invoices/`;
    private token = localStorage.getItem('authToken');
    constructor(private http: HttpClient) { }

    allInvoicesData(transactionId:string): Observable<any> {
        const headers = new HttpHeaders()
            .set('Authorization', `Bearer ${this.token}`);
        const urlPath = `${this.apiUrl}`;
        return this.http.get<any>(urlPath +transactionId, { headers });
    }
}
