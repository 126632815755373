<div id="dropdown-verti" class="relative">
  <i class="las la-ellipsis-v text-2xl verti-option-btn cursor-pointer" (click)="toggleOpen($event)"></i>
  <ul [ngClass]="isOpen?'show':'hide'"
    class="dropdown-verti absolute z-[3] min-w-[122px] rounded-md bg-n0 p-3 shadow-[0px_6px_30px_0px_rgba(0,0,0,0.08)] duration-300 dark:bg-bg3 ltr:right-0  rtl:left-0 ">
    <li>
      <button (click)="editWalletModal()"
        class="block w-full text-start cursor-pointer rounded px-3 py-1 text-sm leading-normal duration-300 hover:bg-primary/10 dark:hover:bg-bg4">
        Düzenle </button>
    </li>
    <li>
      <button (click)="deleteProdModal()"
        class="block w-full text-start cursor-pointer rounded px-3 py-1 text-sm leading-normal duration-300 hover:bg-primary/10 dark:hover:bg-bg4">
        Sil </button>
    </li>
  </ul>
</div>