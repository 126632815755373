<div class=" bg-n0 dark:bg-bg4 box overflow-y-auto max-h-[800px]" style="width: 60vh !important;">
  <div class="flex justify-between items-center bb-dashed pb-6 mb-6">
    <h4></h4>
    <i (click)="closeSection()" class="las cursor-pointer la-times"></i>
  </div>
  <div class="">
    <form [formGroup]="myForm" (ngSubmit)="onSubmit()">
      <div class="grid grid-cols-2 gap-4 xxxl:gap-6">
        <div class="col-span-2">
          <label class="mb-4 block font-medium md:text-lg">Kasiyer Seç</label>
          <select class="dashboardBranch" formControlName="staff" (change)="onStaffSelected($event)">
            <option [value]="null">Kasiyer Seç</option>
            <option *ngFor="let item of personelList" [value]="item.id">{{ item.name }}</option>
          </select>
        </div>

        <div class="col-span-2">
          <label class="mb-4 block font-medium md:text-lg">Tarih Aralığı Seçiniz</label>
          <select class="dashboardBranch" formControlName="dateRange" (change)="onSelectType($event)">
            <option [value]="null">Seçiniz</option>
            <option *ngFor="let item of selectType" [value]="item.id">{{ item.title }}</option>
          </select>
        </div>

        <div *ngIf="myForm.get('dateRange')?.value === 'MONTH'" class="col-span-2">
          <select class="dashboardBranch" formControlName="month" (change)="onMonthSelected($event)">
            <option [value]="null">Seçiniz</option>
            <option *ngFor="let item of months" [value]="item.id">{{ item.title }}</option>
          </select>
        </div>

        <div *ngIf="myForm.get('dateRange')?.value === 'YEAR'" class="col-span-2">
          <input type="number" formControlName="year" required
            class="w-full text-sm bg-primary/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-3 md:px-6 py-2 md:py-3">
        </div>

        <div class="col-span-2 mt-4">
          <button class="btn flex w-full justify-center" type="submit">Kaydet</button>
        </div>

      </div>
    </form>


  </div>
</div>