import { Component } from '@angular/core';
import { ModalService } from 'ngx-modal-ease';
import { DropdownComponent } from '../dropdown/dropdown.component';

@Component({
  selector: 'app-add-create-profile-modal',
  standalone: true,
  imports: [DropdownComponent],
  templateUrl: './create-profile-modal.component.html'
})
export class CreateProfileModalComponent {
  constructor(private modalService: ModalService) { }
  closeModal() {
    this.modalService.close('CreateProfileModalComponent')
  }
}
