<div class="grid grid-cols-12 gap-4 xxl:gap-6">
  
  <div class="col-span-12 md:col-span-7 xxl:col-span-8">

    <div class="grid grid-cols-12 gap-4 xxl:gap-6 p-4 box  mb-4 xxl:mb-6">
      <div
        class="collectionArea col-span-12 border border-dashed qt-modal-btn duration-300 border-transparent hover:border-primary lg:col-span-6 xxxl:col-span-3 box bg-primary/5 dark:bg-bg3 flex items-center gap-3 3xl:gap-6 xl:p-3 ">
        <div>
          <h5 class="text-base xxl:text-md xl:text-xl font-medium mb-1">Sipariş Kodu:</h5><span
            class="text-xs xxl:text-sm"> {{filterResponse.order.order_code}} </span>
        </div>
      </div>
      <div
        class="collectionArea col-span-12 border border-dashed qt-modal-btn duration-300 border-transparent hover:border-primary lg:col-span-6 xxxl:col-span-3 box bg-primary/5 dark:bg-bg3 flex items-center gap-3 3xl:gap-6 xl:p-3 ">
        <div>
          <h5 class="text-base xxl:text-md xl:text-xl font-medium mb-1">Kullanıcı:</h5><span
            class="text-xs xxl:text-sm"> {{filterResponse.customer.account_type}}</span>
        </div>
      </div>
      <div
        class="collectionArea col-span-12 border border-dashed qt-modal-btn duration-300 border-transparent hover:border-primary lg:col-span-6 xxxl:col-span-3 box bg-primary/5 dark:bg-bg3 flex items-center gap-3 3xl:gap-6 xl:p-3 ">
        <div>
          <h5 class="text-base xxl:text-md xl:text-xl font-medium mb-1">Tahsilat Durumu:</h5>
          <span *ngIf="filterResponse.transaction_status == 'CANCEL' " class="text-xs xxl:text-sm"> İptal </span>
          <span *ngIf="filterResponse.transaction_status == 'WAITING' " class="text-xs xxl:text-sm"> Bekliyor </span>
          <span *ngIf="filterResponse.transaction_status == 'SUCCESS' " class="text-xs xxl:text-sm"> Başarılı </span>
        </div>
      </div>
      <div (click)="generatePdf()"
        class="download-button cursor-pointer col-span-12 qt-modal-btn duration-300 border-transparent hover:border-primary lg:col-span-6 xxxl:col-span-3 box bg-primary/5 dark:bg-bg3 flex items-center gap-3 3xl:gap-6 xl:p-3 ">
        <a>Dışa Aktar </a><i class="fa-solid fa-download"></i></div>
    </div>

    <div class="grid grid-cols-12 gap-4 xxl:gap-6 p-4 box  mb-4 xxl:mb-6">
      <div
        class="collectionArea col-span-12 border border-dashed qt-modal-btn duration-300 border-transparent hover:border-primary lg:col-span-6 xxxl:col-span-4 box bg-primary/5 dark:bg-bg3 flex items-center gap-4 4xl:gap-6 xl:p-3 ">
        <div>
          <h5 class="text-base xxl:text-lg 4xl:text-xl font-medium mb-1 xxl:mb-2">Ödeme Tarihi:</h5>
          <span class="text-xs xxl:text-sm">Bulunamadı </span>
        </div>
      </div>

      <div
        class="collectionArea col-span-12 border border-dashed qt-modal-btn duration-300 border-transparent hover:border-primary lg:col-span-6 xxxl:col-span-4 box bg-primary/5 dark:bg-bg3 flex items-center gap-4 4xl:gap-6 xl:p-3 ">
        <div>
          <h5 class="text-base xxl:text-lg 4xl:text-xl font-medium mb-1 xxl:mb-2">Özel Entegratör:</h5>
          <span class="text-xs xxl:text-sm">Bulunamadı </span>
        </div>
      </div>

      <div
        class="collectionArea col-span-12 border border-dashed qt-modal-btn duration-300 border-transparent hover:border-primary lg:col-span-6 xxxl:col-span-4 box bg-primary/5 dark:bg-bg3 flex items-center gap-4 4xl:gap-6 xl:p-3 ">
        <div>
          <h5 class="text-base xxl:text-lg 4xl:text-xl font-medium mb-1 xxl:mb-2">ETTN:</h5>
          <span class="text-xs xxl:text-sm">{{filterResponse.invoice_info.ettn || 'Bulunamadı'}} </span>
        </div>
      </div>

      <div
        class="collectionArea col-span-12 border border-dashed qt-modal-btn duration-300 border-transparent hover:border-primary lg:col-span-6 xxxl:col-span-4 box bg-primary/5 dark:bg-bg3 flex items-center gap-4 4xl:gap-6 xl:p-3 ">
        <div>
          <h5 class="text-base xxl:text-lg 4xl:text-xl font-medium mb-1 xxl:mb-2">Sipariş Kodu:</h5>
          <span class="text-xs xxl:text-sm">{{filterResponse.order.order_code || 'Bulunamadı'}} </span>
        </div>
      </div>

      <div
        class="collectionArea col-span-12 border border-dashed qt-modal-btn duration-300 border-transparent hover:border-primary lg:col-span-6 xxxl:col-span-4 box bg-primary/5 dark:bg-bg3 flex items-center gap-4 4xl:gap-6 xl:p-3 ">
        <div>
          <h5 class="text-base xxl:text-lg 4xl:text-xl font-medium mb-1 xxl:mb-2">Belge Türü:</h5>
          <span class="text-xs xxl:text-sm">{{filterResponse.invoice_info.type || 'Bulunamadı'}} </span>
        </div>
      </div>

      <div
        class="collectionArea col-span-12 border border-dashed qt-modal-btn duration-300 border-transparent hover:border-primary lg:col-span-6 xxxl:col-span-4 box bg-primary/5 dark:bg-bg3 flex items-center gap-4 4xl:gap-6 xl:p-3 ">
        <div>
          <h5 class="text-base xxl:text-lg 4xl:text-xl font-medium mb-1 xxl:mb-2">Belge Numarası:</h5>
          <span class="text-xs xxl:text-sm">{{filterResponse.invoice_info.number || 'Bulunamadı'}} </span>
        </div>
      </div>

      <div
        class="collectionArea col-span-12 border border-dashed qt-modal-btn duration-300 border-transparent hover:border-primary lg:col-span-6 xxxl:col-span-4 box bg-primary/5 dark:bg-bg3 flex items-center gap-4 4xl:gap-6 xl:p-3 ">
        <div>
          <h5 class="text-base xxl:text-lg 4xl:text-xl font-medium mb-1 xxl:mb-2">Sipariş Özel Kodu:</h5>
          <span class="text-xs xxl:text-sm">{{filterResponse.order.special_code || 'Bulunamadı'}} </span>
        </div>
      </div>

      <div
        class="collectionArea col-span-12 border border-dashed qt-modal-btn duration-300 border-transparent hover:border-primary lg:col-span-6 xxxl:col-span-4 box bg-primary/5 dark:bg-bg3 flex items-center gap-4 4xl:gap-6 xl:p-3 ">
        <div>
          <h5 class="text-base xxl:text-lg 4xl:text-xl font-medium mb-1 xxl:mb-2">Sipariş Kaynağı:</h5>
          <span class="text-xs xxl:text-sm">{{filterResponse.source || 'Bulunamadı'}} </span>
        </div>
      </div>

      <div
        class="collectionArea col-span-12 border border-dashed qt-modal-btn duration-300 border-transparent hover:border-primary lg:col-span-6 xxxl:col-span-4 box bg-primary/5 dark:bg-bg3 flex items-center gap-4 4xl:gap-6 xl:p-3 ">
        <div>
          <h5 class="text-base xxl:text-lg 4xl:text-xl font-medium mb-1 xxl:mb-2">Ödeme Tip & Metodu:</h5>
          @if(filterResponse.payment_type && filterResponse.payment_method){
          <span class="text-xs xxl:text-sm">{{filterResponse.payment_type}} / {{filterResponse.payment_method}} </span>
          }@else {
          <span class="text-xs xxl:text-sm">Bulunamadı</span>
          }
        </div>
      </div>

      <div
        class="collectionArea col-span-12 border border-dashed qt-modal-btn duration-300 border-transparent hover:border-primary lg:col-span-6 xxxl:col-span-4 box bg-primary/5 dark:bg-bg3 flex items-center gap-4 4xl:gap-6 xl:p-3 ">
        <div>
          <h5 class="text-base xxl:text-lg 4xl:text-xl font-medium mb-1 xxl:mb-2">KDV Tutarı:</h5>
          <span class="text-xs xxl:text-sm">Bulunamadı </span>
        </div>
      </div>

      <div
        class="collectionArea col-span-12 border border-dashed qt-modal-btn duration-300 border-transparent hover:border-primary lg:col-span-6 xxxl:col-span-4 box bg-primary/5 dark:bg-bg3 flex items-center gap-4 4xl:gap-6 xl:p-3 ">
        <div>
          <h5 class="text-base xxl:text-lg 4xl:text-xl font-medium mb-1 xxl:mb-2">Tutar:</h5>
          <span class="text-xs xxl:text-sm">{{formatAmount(filterResponse.total_amount)}} ₺</span>
        </div>
      </div>

      <div
        class="collectionArea col-span-12 border border-dashed qt-modal-btn duration-300 border-transparent hover:border-primary lg:col-span-6 xxxl:col-span-4 box bg-primary/5 dark:bg-bg3 flex items-center gap-4 4xl:gap-6 xl:p-3 ">
        <div>
          <h5 class="text-base xxl:text-lg 4xl:text-xl font-medium mb-1 xxl:mb-2">Genel Toplam:</h5>
          <span class="text-xs xxl:text-sm">{{formatAmount(filterResponse.total_amount)}} ₺</span>
        </div>
      </div>

    </div>

  </div>

  <div class="col-span-12 md:col-span-5 xxl:col-span-4">

    <div class="box">
      <div class="flex justify-between items-center bb-dashed border-secondary1/20 pb-4 mb-4 lg:pb-6 lg:mb-6">
        <h4 class="h4">Müşteri Detayı</h4>
      </div>
      <ul class="flex flex-col gap-4 lg:gap-6">
        <li>
          <a class="flex justify-between items-center gap-2">
            <div class="flex items-center gap-3 sm:gap-4 xxl:gap-6">
              <span
                class="circleAvatar border border-n30 dark:border-n500 bg-primary/5 w-10 h-10 flex items-center justify-center xxl:w-14 xxl:h-14 shrink-0 rounded-full">
                <img src="../../../../assets/icons/user.svg" alt="">
              </span>
              <div>
                <p class="text-lg font-medium">Müşteri Adı:</p>
                <span class="text-xs">{{filterResponse.customer.name}}</span>
              </div>
            </div>
          </a>
        </li>
        <li>
          <a class="flex justify-between items-center gap-2">
            <div class="flex items-center gap-3 sm:gap-4 xxl:gap-6">
              <span
                class="circleAvatar border border-n30 dark:border-n500 bg-primary/5 w-10 h-10 flex items-center justify-center xxl:w-14 xxl:h-14 shrink-0 rounded-full">
                <img src="../../../../assets/icons/phone.svg" alt="">
              </span>
              <div>
                <p class="text-lg font-medium">İletişim Bilgileri:</p>
                <span class="text-xs">{{filterResponse.customer.phone}} <br>{{filterResponse.customer.email}}</span>
              </div>
            </div>
          </a>
        </li>
        <li>
          <a class="flex justify-between items-center gap-2">
            <div class="flex items-center gap-3 sm:gap-4 xxl:gap-6">
              <span
                class="circleAvatar border border-n30 dark:border-n500 bg-primary/5 w-10 h-10 flex items-center justify-center xxl:w-14 xxl:h-14 shrink-0 rounded-full">
                <img src="../../../../assets/icons/customer-code.svg" alt="">
              </span>
              <div>
                <p class="text-lg font-medium">Müşteri Kodu:</p>
                <span class="text-xs">{{filterResponse.customer.code}}</span>
              </div>
            </div>
          </a>
        </li>
        <li>
          <a class="flex justify-between items-center gap-2">
            <div class="flex items-center gap-3 sm:gap-4 xxl:gap-6">
              <span
                class="circleAvatar border border-n30 dark:border-n500 bg-primary/5 w-10 h-10 flex items-center justify-center xxl:w-14 xxl:h-14 shrink-0 rounded-full">
                <img src="../../../../assets/icons/localization.svg" alt="">
              </span>
              <div>
                <p class="text-lg font-medium">Vergi Dairesi:</p>
                <span class="text-xs">{{filterResponse.customer.tax_office}}</span>
              </div>
            </div>
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>