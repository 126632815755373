import { Component, HostListener, Inject, OnInit, inject } from '@angular/core'
import { Router, RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router'
import { CommonModule, DOCUMENT, Location } from '@angular/common'
import { Observable } from 'rxjs'
import { Store } from '@ngrx/store'
import { ThemeCustomizerComponent } from '@component/shared/theme-customizer/theme-customizer.component'
import { NotificationDropdownComponent } from '@component/topbar/notification/notification.component'
import { ProfileDropdownComponent } from '@component/topbar/profile-dropdown/profile-dropdown.component'
import { LanguageComponent } from '@component/topbar/language/language.component'
import { LayoutState } from '@store/reducer'
import { sidebarData } from '@data/sidebar'
import { toggleDarkMode } from '@store/actions'
import { DialogModule } from 'primeng/dialog'
import { DropdownComponent } from '@component/shared/dropdown/dropdown.component'
import { CalendarModule } from 'primeng/calendar'
import { FormsModule, FormControl, ReactiveFormsModule } from '@angular/forms'

@Component({
  selector: 'app-default-layout',
  standalone: true,
  imports: [
    RouterOutlet,
    CommonModule,
    RouterLink,
    ReactiveFormsModule,
    RouterLinkActive,
    ThemeCustomizerComponent,
    NotificationDropdownComponent,
    LanguageComponent,
    ProfileDropdownComponent,
    DialogModule,
    DropdownComponent,
    CalendarModule,
    FormsModule
  ],
  templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent implements OnInit {
  private store = inject(Store)
  layout$: Observable<LayoutState>
  searchControl = new FormControl('');
  suggestions: string[] = [];
  private allSuggestions = [
    'Siparişler',
    'Tahsilatlar',
    'İptal İade',
    'Belge Listesi',
    'Ürün Listesi',
    'Ürün Ekle',
    'Kategoriler',
    'Ürün Satış Raporları',
    'Genel Ayarlar',
    'Hesap Ayarları',
    'Profiller ve Kullanıcılar',
  ];
  visible = false
  isSidebarOpen = false
  date: Date | undefined
  currencies = ['USD', 'GBP', 'YEN', 'JPN']
  status = ['active', 'inactive']
  colorMode = ''
  sidebarData = sidebarData
  activeMenu = sidebarData[0].name

  constructor(private location: Location, private router: Router) {
    this.layout$ = this.store.select('layout')
    this.searchControl.valueChanges.subscribe(value => {
      this.suggestions = this.filterSuggestions(value!);
    });
  }



  private filterSuggestions(query: string): string[] {
    if (!query) {
      return [];
    }
    return this.allSuggestions.filter(item =>
      item.toLowerCase().includes(query.toLowerCase())
    );
  }

  selectSuggestion(suggestion: string) {
    if (suggestion === 'Siparişler') {
      this.router.navigate(['/order/orders-list']);
    } else if (suggestion === 'Tahsilatlar') {
      this.router.navigate(['/collection']);
    } else if (suggestion === 'İptal İade') {
      this.router.navigate(['/cancellation-refund']);
    } else if (suggestion === 'Belge Listesi') {
      this.router.navigate(['/documents']);
    } else if (suggestion === 'Ürün Listesi') {
      this.router.navigate(['/product/list']);
    } else if (suggestion === 'Ürün Ekle') {
      this.router.navigate(['/product/add']);
    } else if (suggestion === 'Kategoriler') {
      this.router.navigate(['/category']);
    } else if (suggestion === 'Ürün Satış Raporları') {
      this.router.navigate(['/reports/product-sales-report']);
    } else if (suggestion === 'Genel Ayarlar') {
      this.router.navigate(['/general-settings']);
    } else if (suggestion === 'Hesap Ayarları') {
      this.router.navigate(['/account-settings']);
    } else if (suggestion === 'Profiller ve Kullanıcılar') {
      this.router.navigate(['//profile-user']);
    }
    this.searchControl.setValue('');
  }

  setActiveMenu(name: string) {
    this.activeMenu == name ? (this.activeMenu = '') : (this.activeMenu = name)
  }

  goToDashboard(name: string) {
    this.router.navigate(['/dashboards']);
    this.activeMenu == name ? (this.activeMenu = '') : (this.activeMenu = name)
  }

  toggleSidebar() {
    this.isSidebarOpen = !this.isSidebarOpen
  }

  toggleDarkMode() {
    this.store.dispatch(toggleDarkMode({ newTheme: this.colorMode == 'light' ? 'dark' : 'light' }))
  }

  onModalClick(event: MouseEvent) {
    event.stopPropagation()
  }

  getCurrentYear() {
    return new Date().getFullYear()
  }

  ngOnInit(): void {
    // Initial check when the component is initialized
    this.layout$.subscribe((theme) => {
      this.colorMode = theme.theme
    })
    this.handleWindowResize()
    sidebarData.map(({ name, submenus }) => submenus.map(({ url }) => (url == this.location.path() ? (this.activeMenu = name) : '')))
  }

  ngOnDestroy(): void {
    // Clean up by removing event listener when the component is destroyed
    window.removeEventListener('resize', this.handleWindowResize)
  }

  @HostListener('window:resize', ['$event'])
  handleWindowResize(event?: Event) {
    // Check window width and set isSidebarOpen accordingly
    this.isSidebarOpen = window.innerWidth > 1200
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    // Check if the click event target is not within the sidebar
    if (window.innerWidth < 1200) {
      if (!document.querySelector('.sidebar')!.contains(event.target as Node) && !document.querySelector('#sidebar-toggle-btn')!.contains(event.target as Node)) {
        this.isSidebarOpen = false // Close the sidebar
      }
    }
  }
  clickRoute() {
    if (window.innerWidth < 1200) {
      this.isSidebarOpen = false
    }
  }
}
