<app-top-banner title="Belge Listesi" />

<div class="relative inline-block w-full lg:w-auto">
    <article *ngIf="showDatepicker" class="calendar-wrapper">
        <igx-calendar selection="range" (selected)="onRangeChange($event)">
        </igx-calendar>
    </article>
</div>

<div class="box col-span-12">
    <div class="flex flex-wrap gap-4 justify-between items-center bb-dashed mb-4 pb-4 lg:mb-6 lg:pb-6"
        id="document-list-header">
        <div class="order-filters">
            <button *ngIf="showSaveButton == false" (click)="toggleCalendar()"
                class="font-medium text-xs px-4 sm:px-5 xxxl:px-6 py-3 capitalize invoice-active">
                @if(selectedDateRange?.start && selectedDateRange?.end){
                {{selectedDateRange?.start}}/{{selectedDateRange?.end}}
                }@else{Bugün}
                <i class="las la-calendar ml-2 cursor-pointer"></i>
            </button>

            <button *ngIf="showSaveButton" (click)="saveDateRange()"
                class="font-medium text-xs px-4 sm:px-5 xxxl:px-6 py-3 capitalize invoice-active">
                Kaydet
            </button>
            <button (click)="printTable()"
                class="font-medium text-xs px-4 sm:px-5 xxxl:px-6 py-3 capitalize invoice-active"
                ng-reflect-ng-class="[object Object]">Dışarı Aktar
                <i class="fa-solid fa-download"></i>
            </button>

        </div>
        <div class="chooseFirm">
            <label>Şube: </label>
            <!-- <select (change)="onItemSelected($event)">
                <option *ngFor="let item of branchList" [value]="item.id">{{ item.title }}</option>
            </select> -->
            <p-multiSelect selectedItemsLabel="{0} şube seçildi"
            class="bg-primary/5 dark:bg-bg3 border border-n30 dark:border-n500 px-3 md:px-6 py-2 md:py-3 relative"
            [options]="branchList" formControlName="category" optionLabel="title" placeholder="Şube seçiniz"
            (onChange)="onBranchChange($event)" />
        </div>
        <div class="flex items-center max-lg:flex-wrap gap-4" id="documentList">

            <div class="rounded-[5] bg-primary/5 border border-n30 dark:border-n500 flex invoice-btns filters-orders">
                @for (item of filters; track $index) {
                <button (click)="setFilter(item)" class="font-medium text-xs px-4 sm:px-5 xxxl:px-6 py-3 capitalize"
                    [ngClass]="{ 'invoice-active': item == currentFilter }">{{ item }}</button>
                }
            </div>
        </div>
    </div>

    <div class="overflow-x-auto mb-4 lg:mb-6">
        <table class="w-full whitespace-nowrap" id="documenListTable">
            <thead>
                <tr class="bg-secondary1/5 dark:bg-bg3">
                    <th class="text-left p-5">
                        <div class="searcHead">
                            <p>Tarih</p>
                            <div
                                class="mt-2 bg-primary/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-3  py-2 relative">
                                <input [(ngModel)]="searchDate" class="w-11/12 text-sm bg-transparent p-0 border-none"
                                    type="text">
                                <span class="absolute eye-icon ltr:right-5 rtl:left-5 top-1/2 -translate-y-1/2">
                                    <i class="las la-search"></i>
                                </span>
                            </div>
                        </div>
                    </th>
                    <th class="text-left p-5">
                        <div class="searcHead">
                            <p>Şube</p>
                            <div
                                class="mt-2 bg-primary/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-3  py-2 relative">
                                <input [(ngModel)]="searchBranche"
                                    class="w-11/12 text-sm bg-transparent p-0 border-none" type="text">
                                <span class="absolute eye-icon ltr:right-5 rtl:left-5 top-1/2 -translate-y-1/2">
                                    <i class="las la-search"></i>
                                </span>
                            </div>
                        </div>
                    </th>
                    <th class="text-left p-5">
                        <div class="searcHead">
                            <p>Ettn</p>
                            <div
                                class="mt-2 bg-primary/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-3  py-2 relative">
                                <input [(ngModel)]="searchEttn" class="w-11/12 text-sm bg-transparent p-0 border-none"
                                    type="number">
                                <span class="absolute eye-icon ltr:right-5 rtl:left-5 top-1/2 -translate-y-1/2">
                                    <i class="las la-search"></i>
                                </span>
                            </div>
                        </div>
                    </th>
                    <th class="text-left p-5">
                        <div class="searcHead">
                            <p>B. Seri No</p>
                            <div
                                class="mt-2 bg-primary/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-3  py-2 relative">
                                <input [(ngModel)]="searchSeriesNo"
                                    class="w-11/12 text-sm bg-transparent p-0 border-none" type="text">
                                <span class="absolute eye-icon ltr:right-5 rtl:left-5 top-1/2 -translate-y-1/2">
                                    <i class="las la-search"></i>
                                </span>
                            </div>
                        </div>
                    </th>
                    <th class="text-left p-5">
                        <div class="searcHead">
                            <p>Görevli</p>
                            <div
                                class="mt-2 bg-primary/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-3  py-2 relative">
                                <input [(ngModel)]="searchCasier" class="w-11/12 text-sm bg-transparent p-0 border-none"
                                    type="text">
                                <span class="absolute eye-icon ltr:right-5 rtl:left-5 top-1/2 -translate-y-1/2">
                                    <i class="las la-search"></i>
                                </span>
                            </div>
                        </div>
                    </th>
                    <th class="text-left p-5">
                        <div class="searcHead">
                            <p>Müşteri</p>
                            <div
                                class="mt-2 bg-primary/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-3  py-2 relative">
                                <input [(ngModel)]="searchCustomer"
                                    class="w-11/12 text-sm bg-transparent p-0 border-none" type="text">
                                <span class="absolute eye-icon ltr:right-5 rtl:left-5 top-1/2 -translate-y-1/2">
                                    <i class="las la-search"></i>
                                </span>
                            </div>
                        </div>
                    </th>
                    <th class="text-left p-5">
                        <div class="searcHead">
                            <p>Belge Tipi</p>
                            <div
                                class="mt-2 bg-primary/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-3  py-2 relative">
                                <input [(ngModel)]="searchDocumentType"
                                    class="w-11/12 text-sm bg-transparent p-0 border-none" type="text">
                                <span class="absolute eye-icon ltr:right-5 rtl:left-5 top-1/2 -translate-y-1/2">
                                    <i class="las la-search"></i>
                                </span>
                            </div>
                        </div>
                    </th>

                    <th class="text-center p-5">Durum</th>
                    <th class="text-center p-5">Ödeme Durumu</th>
                    <th class="text-left p-5">
                        <div class="searcHead">
                            <p>T.Ü. Adedi</p>
                            <div
                                class="mt-2 bg-primary/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-3  py-2 relative">
                                <input [(ngModel)]="searchTUquantity"
                                    class="w-11/12 text-sm bg-transparent p-0 border-none" type="number">
                                <span class="absolute eye-icon ltr:right-5 rtl:left-5 top-1/2 -translate-y-1/2">
                                    <i class="las la-search"></i>
                                </span>
                            </div>
                        </div>
                    </th>
                    <th class="text-left p-5">
                        <div class="searcHead">
                            <p>T. Tutar</p>
                            <div
                                class="mt-2 bg-primary/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-3  py-2 relative">
                                <input [(ngModel)]="searchCollectedAmount"
                                    class="w-11/12 text-sm bg-transparent p-0 border-none" type="number">
                                <span class="absolute eye-icon ltr:right-5 rtl:left-5 top-1/2 -translate-y-1/2">
                                    <i class="las la-search"></i>
                                </span>
                            </div>
                        </div>
                    </th>
                    <th class="text-left p-5">
                        <div class="searcHead">
                            <p>KDV Tutar</p>
                            <div
                                class="mt-2 bg-primary/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-3  py-2 relative">
                                <input [(ngModel)]="searchTaxAmount"
                                    class="w-11/12 text-sm bg-transparent p-0 border-none" type="number">
                                <span class="absolute eye-icon ltr:right-5 rtl:left-5 top-1/2 -translate-y-1/2">
                                    <i class="las la-search"></i>
                                </span>
                            </div>
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of filteredTransactionsCombined; let i = index;" (click)="openModal(item)" [ngClass]="i % 2 === 0 ? 'even-row' : 'odd-row'">
                    <td class="w-[15%]">
                        <div class="bg-secondary1/5 dark:bg-bg3 py-5 px-3"> {{ item.document_date|
                            date:'dd/MM/yyyyhh:mm'}} </div>
                    </td>
                    <td class="w-[15%]">
                        <div class="bg-secondary1/5 dark:bg-bg3 py-5 px-3">{{item.info}}</div>
                    </td>
                    @if(item.invoice_info.ettn){
                    <td class="w-[15%]">
                        <div class="bg-secondary1/5 dark:bg-bg3 py-5 px-3">{{ item.invoice_info.ettn }}</div>
                    </td>
                    }@else {
                    <td class="w-[15%]">
                        <div class="bg-secondary1/5 dark:bg-bg3 py-5 px-3">Bulunamadı</div>
                    </td>
                    }
                    <td class="w-[15%]">
                        <div class="bg-secondary1/5 dark:bg-bg3 py-5 px-3">{{item.invoice_info.document_number}}</div>
                    </td>
                    <td class="w-[15%]">
                        <div class="bg-secondary1/5 dark:bg-bg3 py-5 px-3">
                            {{item.staff.name ?? '-'}}
                        </div>
                    </td>
                    <td class="w-[15%]">
                        <div class="bg-secondary1/5 dark:bg-bg3 py-5 px-3">
                            {{ item.order.customer }}
                        </div>
                    </td>
                    <td class="w-[15%]">
                        <div class="bg-secondary1/5 dark:bg-bg3 py-5 px-3">
                            {{ item.invoice_info.document_type }}
                        </div>
                    </td>
                    <td>
                        <div class="bg-secondary1/5 dark:bg-bg3 px-3 py-4">
                            @if(item.transaction_document_status == 'SUCCESS'){
                            <span
                                class="text-primary bg-primary/10 block w-28 rounded-[30px] border border-n30 bg-primary/10 py-2 text-center text-xs text-primary dark:border-n500 dark:bg-bg3 xxl:w-36">
                                Başarılı
                            </span>
                            }@else if(item.transaction_document_status == 'FAIL'){
                            <span
                                class="text-secondary2 bg-secondary2/20 block w-28 rounded-[30px] border border-n30 bg-primary/10 py-2 text-center text-xs text-primary dark:border-n500 dark:bg-bg3 xxl:w-36">
                                Başarısız
                            </span>
                            }@else {
                            <span
                                class="text-secondary2 bg-secondary2/20 block w-28 rounded-[30px] border border-n30 bg-primary/10 py-2 text-center text-xs text-primary dark:border-n500 dark:bg-bg3 xxl:w-36">
                                Başarısız
                            </span>
                            }
                        </div>
                    </td>
                    <td>
                        <div class="bg-secondary1/5 dark:bg-bg3 px-3 py-4">
                            @if(item.transaction_status == 'SUCCESS'){
                            <span
                                class="text-primary bg-primary/10 block w-28 rounded-[30px] border border-n30 bg-primary/10 py-2 text-center text-xs text-primary dark:border-n500 dark:bg-bg3 xxl:w-36">
                                Başarılı
                            </span>
                            }@else if(item.transaction_status == 'FAIL'){
                            <span
                                class="text-secondary2 bg-secondary2/20 block w-28 rounded-[30px] border border-n30 bg-primary/10 py-2 text-center text-xs text-primary dark:border-n500 dark:bg-bg3 xxl:w-36">
                                Başarısız
                            </span>
                            }@else {
                            <span
                                class="text-secondary2 bg-secondary2/20 block w-28 rounded-[30px] border border-n30 bg-primary/10 py-2 text-center text-xs text-primary dark:border-n500 dark:bg-bg3 xxl:w-36">
                                Başarısız
                            </span>
                            }
                        </div>
                    </td>
                    <td class="w-[15%]">
                        <div class="bg-secondary1/5 dark:bg-bg3 py-5 px-3"> Adet</div>
                    </td>
                    <td class="w-[15%]">
                        @if(item.total_amount == null){
                        <div class="bg-secondary1/5 dark:bg-bg3 py-5 px-3">0 ₺
                        </div>
                        }@else {
                        <div class="bg-secondary1/5 dark:bg-bg3 py-5 px-3">{{ item.total_amount }} ₺
                        </div>
                        }
                    </td>
                    <td class="w-[15%]">
                        <div class="bg-secondary1/5 dark:bg-bg3 py-5 px-3"> KDV
                        </div>
                    </td>

                </tr>
            </tbody>
        </table>
    </div>

    <div *ngIf="invoicesList.data.length == 0" class="content-not-found">
        <img src="../../../../assets/images/content-not-found.png" alt="">
        <h4 style="margin-top: 1rem;">İçerik Bulunamadı</h4>
    </div>

    <div *ngIf="pages.length > 1"
        class="flex col-span-12 gap-4 sm:justify-between justify-center items-center flex-wrap">
        <ul class="flex gap-2 md:gap-3 flex-wrap md:font-semibold items-center">
            <li>
                <button [disabled]="startPage === 1" (click)="prevPageGroup()"
                    class="hover:bg-primary rtl:rotate-180 hover:text-n0 border md:w-10 duration-300 md:h-10 w-8 h-8 flex items-center rounded-full justify-center border-primary">
                    <i class="las la-angle-left text-lg"></i>
                </button>
            </li>
            <li *ngFor="let page of pages.slice(startPage - 1, endPage)">
                <button (click)="goToPage(page)" [class.bg-primary]="transactions.currentPage === page"
                    class="hover:bg-primary hover:text-n0 border md:w-10 duration-300 md:h-10 w-8 h-8 flex items-center rounded-full justify-center border-primary">
                    {{ page }}
                </button>
            </li>
            <li>
                <button [disabled]="endPage === pages.length" (click)="nextPageGroup()"
                    class="hover:bg-primary hover:text-n0 rtl:rotate-180 border md:w-10 duration-300 md:h-10 w-8 h-8 flex items-center rounded-full justify-center border-primary">
                    <i class="las la-angle-right text-lg"></i>
                </button>
            </li>
        </ul>
    </div>

</div>