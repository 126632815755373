import { Component } from '@angular/core'
import { DropdownComponent } from '../dropdown/dropdown.component'
import { ModalService } from 'ngx-modal-ease'

@Component({
  selector: 'app-terminal-add-modal',
  standalone: true,
  imports: [DropdownComponent],
  templateUrl: './terminal-add-modal.component.html'
})
export class TerminalAddModalComponent {
  usageRegion= [
    "Test-1",
    "Test-2",
    "Test-3",
  ]

  braches=[
    "Test-1",
    "Test-2",
    "Test-3",
  ]
  constructor(private modalService:ModalService){}
  closeModal(){
    this.modalService.close('TerminalAddModalComponent')
  }
}
