<div class=" bg-n0 dark:bg-bg4 box overflow-y-auto max-h-[800px]">
  <div class="flex justify-between items-center bb-dashed pb-6 mb-6">
    <h4>Kişi Düzenle</h4><i (click)="closeModal()" class="las cursor-pointer la-times"></i>
  </div>
  <form>
    <div class="mt-6 xl:mt-8 grid grid-cols-2 gap-4 xxxl:gap-6">
      <div class="col-span-2">
        <label htmlFor="card-number" class="md:text-lg font-medium block mb-4"> Cüzdan ID </label>
        <div class="bg-secondary1/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl relative">
          <input type="number" class="text-sm w-full px-6 py-2.5 md:py-3 bg-transparent rounded-3xl"
            placeholder="ER423422" id="card-number" required />
        </div>
      </div>
      <div class="col-span-2 md:col-span-1">
        <label htmlFor="month" class="md:text-lg font-medium block mb-4"> Kişi Bilgileri </label>
        <input type="text"
          class="w-full text-sm bg-secondary1/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-6 py-2.5 md:py-3"
          placeholder="Efe Semih Demirtaş" id="name" required />
      </div>
      <div class="col-span-2 md:col-span-1">
        <label for="location" class="md:text-lg font-medium block mb-4"> Cüzdan Tipi </label>
        <app-dropdown [options]="walletType" btnClassProps="rounded-3xl w-full !py-3" dropdownClassProps="w-full" />
      </div>
      <div class="col-span-2 md:col-span-1">
        <label htmlFor="month" class="md:text-lg font-medium block mb-4"> Bakiye </label>
        <input type="number"
          class="w-full text-sm bg-secondary1/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-6 py-2.5 md:py-3"
          placeholder="2.431" id="month" required />
      </div>
      <div class="col-span-2 md:col-span-1">
        <label htmlFor="year" class="md:text-lg font-medium block mb-4"> Günlük Kullanım Adedi </label>
        <input type="number"
          class="w-full text-sm bg-secondary1/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-6 py-2.5 md:py-3"
          placeholder="5.564₺" id="year" required />
      </div>
      <div class="col-span-2 md:col-span-1">
        <label htmlFor="year" class="md:text-lg font-medium block mb-4"> Günlük Limiti </label>
        <input type="number"
          class="w-full text-sm bg-secondary1/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-6 py-2.5 md:py-3"
          placeholder="0₺" id="daylimit" required />
      </div>
      <div class="col-span-2 md:col-span-1">
        <label htmlFor="year" class="md:text-lg font-medium block mb-4"> Haftalık Limit</label>
        <input type="number"
          class="w-full text-sm bg-secondary1/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-6 py-2.5 md:py-3"
          placeholder="0₺" id="weeklimit" required />
      </div>
      <div class="col-span-2">
        <label htmlFor="year" class="md:text-lg font-medium block mb-4"> Aylık Limit </label>
        <input type="number"
          class="w-full text-sm bg-secondary1/5 dark:bg-bg3 border border-n30 dark:border-n500 rounded-3xl px-6 py-2.5 md:py-3"
          placeholder="0₺" id="moonlimit" required />
      </div>
      <div class="col-span-2 md:col-span-1">
        <label for="location" class="md:text-lg font-medium block mb-4">Cüzdan Seç </label>
        <app-dropdown [options]="chooseWallet" btnClassProps="rounded-3xl w-full !py-3" dropdownClassProps="w-full" />
      </div>
      <div class="col-span-2 md:col-span-1">
        <label for="location" class="md:text-lg font-medium block mb-4">Kart Tipi</label>
        <app-dropdown [options]="cardType" btnClassProps="rounded-3xl w-full !py-3" dropdownClassProps="w-full" />
      </div>
      <div class="col-span-2 mt-4">
        <button class="btn flex w-full justify-center" type="submit">Onayla</button>
      </div>
    </div>
  </form>
</div>