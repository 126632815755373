import { NgClass } from '@angular/common';
import { Component, HostListener, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { EditWalletModalComponent } from '@component/shared/edit-wallet-modal/edit-wallet-modal.component'
import { ModalService } from 'ngx-modal-ease'
import { QrCodeDetailModalComponent } from '../qr-code-detail-modal/qr-code-detail-modal.component';
import { DeleteShortCodeModalComponent } from '../delete-short-code-modal/delete-short-code-modal.component';
@Component({
  selector: 'app-options-vertical-short-code',
  standalone: true,
  imports: [NgClass, RouterModule],
  templateUrl: './options-vertical-short-code.component.html'
})
export class OptionsVerticalShortCodeComponent {
  isOpen = false
  @Input()
  toggleOpen(event: MouseEvent) {
    const allDropdowns = document.querySelectorAll('.dropdown-verti'); // Find all dropdowns
    allDropdowns.forEach(dropdown => {
      if (dropdown !== event.target) {
        dropdown.classList.remove('show');
        dropdown.classList.add('hide');
      }
    });
    this.isOpen = !this.isOpen
  }

  constructor(private modalService: ModalService) { }


  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    const targetElement = event.target as Element;

    // Check if event.target exists and if the click event target is not within the dropdown or the dropdown button
    if (targetElement && !targetElement.closest('#dropdown-verti')) {
      this.isOpen = false; // Close the dropdown
    }
  }

  deleteShortCode() {
    this.modalService.open(DeleteShortCodeModalComponent, {
      modal: {
        enter: 'enter-going-down 0.3s ease-out',
        leave: 'fade-out 0.5s'
      },
      overlay: {
        leave: 'fade-out 0.5s'
      },
      data: {
        type: 'Angular modal library'
      }
    })
  }

  qrCodeDetail() {
    this.modalService.open(QrCodeDetailModalComponent, {
      modal: {
        enter: 'enter-going-down 0.3s ease-out',
        leave: 'fade-out 0.5s'
      },
      overlay: {
        leave: 'fade-out 0.5s'
      },
      data: {
        type: 'Angular modal library'
      }
    })
  }
}
