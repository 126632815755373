interface SubmenuItem {
  title: string;
  url: string;
}

interface SidebarItem {
  id: number;
  name: string;
  icon: string;
  submenus: SubmenuItem[];
  url?: string;
}
export const sidebarData: SidebarItem[] = [
  {
    id: 4,
    name: 'İşlemler',
    icon: 'las la-exchange-alt',
    submenus: [
      { title: 'Siparişler', url: '/order/orders-list' },
      { title: 'Tahsilatlar', url: '/collection' },
      { title: 'İptal İade', url: '/cancellation-refund' },
      { title: 'Belge Listesi', url: '/documents' },
    ]
  },
  // {
  //   id: 9,
  //   name: 'Cüzdan',
  //   icon: 'las la-wallet',
  //   submenus: [
  //     { title: 'Cüzdan Listesi', url: '/private/transfer-overview' },
  //     { title: 'Cüzdan Detay', url: '/private/make-transfer' },
  //   ]
  // },
  {
    id: 7,
    name: 'Ürünler',
    icon: 'las la-chart-bar',
    submenus: [
      { title: 'Ürün Listesi', url: '/product/list' },
      { title: 'Ürün Ekle', url: '/product/add' },
      { title: 'Kategoriler', url: '/category' },
      // { title: 'Style 03', url: '/trading/style-03' }
    ]
  },
  {
    id: 8,
    name: 'Raporlar',
    icon: 'las la-chart-pie',
    submenus: [
      { title: 'Ürün Satış Raporları', url: '/reports/product-sales-report' },
      // { title: 'Tahsilat Raporları', url: '/reports/collection-report' },
      // { title: 'Komisyon Raporları', url: '/reports/commission-report' },
      // { title: 'Valor Raporları', url: '/reports/valor-report' },
      // { title: 'Hizmet Fatura Raporları', url: 'reports/service-bill-report' },
      // { title: 'Cari Hizmet Raporu', url: 'reports/current-service-report' },
      // { title: 'Style 01', url: '/reports/style-01' },
      // { title: 'Style 02', url: '/reports/style-02' }
    ]
  },
  {
    id: 9,
    name: 'Ayarlar',
    icon: 'las la-cog',
    submenus: [
      { title: 'Genel Ayarlar', url: '/general-settings' },
      { title: 'Hesap Ayarları', url: '/account-settings' },
      { title: 'Profiller & Kullanıcılar ', url: '/profile-user' },
      // { title: 'Terminal Ayarları', url: '/terminal-settings' },
      // { title: 'Profile', url: '/settings/profile' },
      // { title: 'Security', url: '/settings/security' },
      // { title: 'Social Network', url: '/settings/social-network' },
      // { title: 'Notification', url: '/settings/notification' },
      // { title: 'Payment Limits', url: '/settings/payment-limits' }
    ]
  },
  // {
  //   id: 10,
  //   name: 'Müşteriler',
  //   icon: 'las la-user-circle',
  //   submenus: [
  //     { title: 'Sign Up', url: '/auth/sign-up' },
  //     { title: 'Müşteriler Listesi', url: '/customer-list' },
  //     { title: 'Sign In', url: '/auth/sign-in' },
  //     { title: 'Sign In QR Code', url: '/auth/signin-qrcode' },
  //   ]
  // }
]
