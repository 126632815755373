import { Component } from '@angular/core';
import { ModalService } from 'ngx-modal-ease';
import { DropdownComponent } from '../dropdown/dropdown.component';

@Component({
  selector: 'app-edit-wallet-modal',
  standalone: true,
  imports: [DropdownComponent],
  templateUrl: './edit-wallet-person-modal.component.html'
})
export class EditWalletPersonModalComponent {
  walletType = ['Ön Ödemeli', 'Test', 'Test-1']
  cardType = ['Bonus', 'Bonus-1', 'Bonus-2']
  chooseWallet = ['Plus Cüzdan', 'Plus Cüzdan -1', 'Plus Cüzdan-2']
  constructor(private modalService:ModalService){}
  closeModal(){
    this.modalService.close('EditWalletPersonModalComponent')
  }
}
