import { Component } from '@angular/core';
import { ModalService } from 'ngx-modal-ease';
import { DropdownComponent } from '../dropdown/dropdown.component';

@Component({
  selector: 'app-edit-customer-modal',
  standalone: true,
  imports: [DropdownComponent],
  templateUrl: './edit-customer-modal.component.html'
})
export class EditCustomerModalComponent {
  city = ['İstanbul', 'Ankara', 'İzmir']
  district = ['Ümraniye', 'Ataşehir','Kadıköy']
  hood = ['Test-1', 'Test-2','Test-3']
  constructor(private modalService:ModalService){}
  closeModal(){
    this.modalService.close('EditCustomerModalComponent')
  }
}
