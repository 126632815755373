import { CommonModule } from '@angular/common'
import { Component } from '@angular/core'
import { OnInit } from '@angular/core'
import { CreateBankAccountModalComponent } from '@component/shared/create-bank-account-modal/create-bank-account-modal.component'
import { DropdownComponent } from '@component/shared/dropdown/dropdown.component'
import { OptionsHorizComponent } from '@component/shared/options-horiz/options-horiz.component'
import { OptionsVerticalComponent } from '@component/shared/options-vertical/options-vertical.component'
import { TopBannerComponent } from '@component/shared/top-banner/top-banner.component'
import { recentPaymentData } from '@data/payments/recentPayments'
import { ChartOptions } from '@pages/dashboards/style-01/style-01.component'
import { TableService } from '@service/table.service'
import { NgApexchartsModule } from 'ng-apexcharts'
import { ModalService } from 'ngx-modal-ease'
import { ActivatedRoute } from '@angular/router'
import { OrderDetailService } from '@service/order/order.detail.service'
import { OrderDetailModel } from '../../../models/order-model/order-detail.model'
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';

@Component({
  selector: 'app-order-list-detail',
  standalone: true,
  imports: [TopBannerComponent, DropdownComponent, OptionsVerticalComponent, OptionsHorizComponent, CommonModule, NgApexchartsModule],
  templateUrl: './order-list-detail.component.html'
})
export class OrderListDetailComponent implements OnInit {

  overviewChartOptions!: ChartOptions
  recentPayments
  pages: number[] = []
  orderDetailData!: OrderDetailModel;
  id!: string;

  constructor(private modalService: ModalService, private route: ActivatedRoute, private orderDetailService: OrderDetailService) {
    this.recentPayments = new TableService()
    this.recentPayments.initialize(recentPaymentData, 8)
  }

  formatter = new Intl.NumberFormat('tr-TR', {
    currency: 'TRY',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });

  formatAmount(amount: number | undefined): string {
    return this.formatter.format(amount ?? 0);
  }

  ngOnInit() {
    this.pages = Array.from({ length: this.recentPayments.totalPages }, (_, i) => i + 1)

    this.route.params.subscribe(params => {
      this.id = params['id'];
    });

    this.orderDetailService.orderDetail(
      this.id,
    ).subscribe(
      response => {
        this.orderDetailData = response;
      },
      error => {
        console.error('Hata:', error);
      }
    );

  }

  openModal() {
    this.modalService.open(CreateBankAccountModalComponent, {
      modal: {
        enter: 'enter-going-down 0.3s ease-out',
        leave: 'fade-out 0.5s'
      },
      overlay: {
        leave: 'fade-out 0.5s'
      },
      data: {
        type: 'Angular modal library'
      }
    })
  }

  convertToTurkishCharacters(text: string) {
    return text.replace(/ğ/g, 'g')
      .replace(/Ğ/g, 'G')
      .replace(/ü/g, 'ü')
      .replace(/Ü/g, 'Ü')
      .replace(/ş/g, 's')
      .replace(/Ş/g, 'S')
      .replace(/ı/g, 'i')
      .replace(/İ/g, 'I')
      .replace(/ö/g, 'ö')
      .replace(/Ö/g, 'Ö')
      .replace(/ç/g, 'c')
      .replace(/Ç/g, 'C');
  }

  generatePdf() {
    const doc = new jsPDF();



    const orderCode = this.orderDetailData.order_code;
    const orderSpecialCode = this.orderDetailData.special_code;
    const orderDate = this.orderDetailData.order_date;

    const customerName = this.orderDetailData.customer.name;
    const customerCode = this.orderDetailData.customer.code;
    const customerMail = this.orderDetailData.customer.email;
    const customerPhone = this.orderDetailData.customer.phone;
    const customerCity = this.orderDetailData.customer.city;
    const customerDistrict = this.orderDetailData.customer.district;
    const customerTaxNumber = this.orderDetailData.customer.identity;
    const customerTaxOffice = this.orderDetailData.customer.tax_office;
    const customerType = this.orderDetailData.customer.account_type;

    const paymentType = this.orderDetailData.payment_type;
    const paymentMethod = this.orderDetailData.payment_method;
    const paymentStatus = this.orderDetailData.status;
    const paymentSource = this.orderDetailData.source;
    const paymentPartial = this.orderDetailData.is_partial;
    const paymentTotal = this.orderDetailData.total_amount;
    const paymentDiscount = this.orderDetailData.discount_amount;
    const paymentSubTotal = this.orderDetailData.sub_total;
    const paymentKdv = this.orderDetailData.tax_amount;

    const summaryTotal = this.orderDetailData.total_amount;
    const summaryTotalPaid = this.orderDetailData.total_paid;
    const summaryAmountDue = this.orderDetailData.amount_due;

    const logo = '../../../../assets/images/logo-with-text.png';
    doc.addImage(logo, 'PNG', 10, 10, 50, 20);

    const spaceBelowLogo = 10;
    const padding = 5;
    const borderRadius = 5;
    const lineHeight = 8;

    const orderDetailText = [
      `${this.convertToTurkishCharacters('Sipariş Kodu')}: ${orderCode}`,
      `${this.convertToTurkishCharacters('Sipariş Özel Kodu')}: ${orderSpecialCode}`,
      `${this.convertToTurkishCharacters('Sipariş Tarihi')}: ${orderDate}`
    ];

    const customerDetailText = [
      `${this.convertToTurkishCharacters('İsim')}: ${customerName}`,
      `${this.convertToTurkishCharacters('Kod')}: ${customerCode}`,
      `${this.convertToTurkishCharacters('E-Posta')}: ${customerMail}`,
      `${this.convertToTurkishCharacters('Telefon')}: ${customerPhone}`,
      `${this.convertToTurkishCharacters('Şehir')}: ${this.convertToTurkishCharacters(customerCity)}`,
      `${this.convertToTurkishCharacters('İlçe')}: ${this.convertToTurkishCharacters(customerDistrict)}`,
      `${this.convertToTurkishCharacters('Vergi Numarası')}: ${customerTaxNumber}`,
      `${this.convertToTurkishCharacters('Vergi Dairesi')}: ${this.convertToTurkishCharacters(customerTaxOffice)}`,
      `${this.convertToTurkishCharacters('Hesap Tipi')}: ${this.convertToTurkishCharacters(customerType)}`
    ];

    const paymentDetailText = [
      `${this.convertToTurkishCharacters('Ödeme Tipi')}: ${paymentType}`,
      `${this.convertToTurkishCharacters('Ödeme Methodu')}: ${paymentMethod}`,
      `${this.convertToTurkishCharacters('Durum')}: ${paymentStatus == 'SUCCESS' ? this.convertToTurkishCharacters('Başarılı') : paymentStatus == 'CANCEL' ? this.convertToTurkishCharacters('İptal') : paymentStatus == 'WAITING' ? this.convertToTurkishCharacters('Bekliyor'): this.convertToTurkishCharacters('Başarısız')}`,
      `${this.convertToTurkishCharacters('Kaynak')}: ${paymentSource}`,
      `${this.convertToTurkishCharacters('Parçalı')}: ${paymentPartial == false ? this.convertToTurkishCharacters('Hayır') : 'Evet'}`,
      `${this.convertToTurkishCharacters('Toplam Tutar')}: ${this.formatAmount(paymentTotal)}tl`,
      `${this.convertToTurkishCharacters('İndirim Tutarı')}: ${this.formatAmount(paymentDiscount)}tl`,
      `${this.convertToTurkishCharacters('Ara Toplam')}: ${this.formatAmount(paymentSubTotal)}tl`,
      `${this.convertToTurkishCharacters('KDV Tutarı')}: ${this.formatAmount(paymentKdv)}tl`
    ];

    const summaryText = [
      `${this.convertToTurkishCharacters('Total')}: ${this.formatAmount(summaryTotal)}tl`,
      `${this.convertToTurkishCharacters('Toplam Ödenen')}: ${this.formatAmount(summaryTotalPaid)}tl`,
      `${this.convertToTurkishCharacters('Ödenecek tutar')}: ${this.formatAmount(summaryAmountDue)}tl`
    ];

    const calculateTextHeight = (textArray: string[]) => {
      return textArray.length * lineHeight + 2 * padding;
    };

    const orderDetailHeight = calculateTextHeight(orderDetailText) + 10;
    const customerDetailHeight = calculateTextHeight(customerDetailText) + 10;
    const paymentDetailHeight = calculateTextHeight(paymentDetailText) + 10;
    const summaryHeight = calculateTextHeight(summaryText) + 10;

    const orderDetailX = 10;
    const orderDetailY = 30 + spaceBelowLogo;

    const customerDetailX = 102;
    const customerDetailY = 30 + spaceBelowLogo;

    const paymentDetailX = 10;
    const paymentDetailY = orderDetailY + orderDetailHeight + spaceBelowLogo;

    const summaryX = 102;
    const summaryY = customerDetailY + customerDetailHeight + spaceBelowLogo;

    doc.setFillColor(255, 255, 255);
    doc.roundedRect(orderDetailX, orderDetailY, 80, orderDetailHeight, borderRadius, borderRadius, 'S');

    doc.setFontSize(14);
    doc.text(this.convertToTurkishCharacters('Sipariş Detay'), orderDetailX + padding, orderDetailY + padding + 10);

    doc.setFontSize(10);
    orderDetailText.forEach((text, index) => {
      doc.text(text, orderDetailX + padding, orderDetailY + padding + 20 + (index * lineHeight));
    });

    doc.setFillColor(255, 255, 255);
    doc.roundedRect(customerDetailX, customerDetailY, 80, customerDetailHeight, borderRadius, borderRadius, 'S');

    doc.setFontSize(14);
    doc.text(this.convertToTurkishCharacters('Müşteri Detay'), customerDetailX + padding, customerDetailY + padding + 10);

    doc.setFontSize(10);
    customerDetailText.forEach((text, index) => {
      doc.text(text, customerDetailX + padding, customerDetailY + padding + 20 + (index * lineHeight));
    });

    doc.setFillColor(255, 255, 255);
    doc.roundedRect(paymentDetailX, paymentDetailY, 80, paymentDetailHeight, borderRadius, borderRadius, 'S');

    doc.setFontSize(14);
    doc.text(this.convertToTurkishCharacters('Ödeme Detay'), paymentDetailX + padding, paymentDetailY + padding + 10);

    doc.setFontSize(10);
    paymentDetailText.forEach((text, index) => {
      doc.text(text, paymentDetailX + padding, paymentDetailY + padding + 20 + (index * lineHeight));
    });

    doc.setFillColor(255, 255, 255);
    doc.roundedRect(summaryX, summaryY, 80, summaryHeight, borderRadius, borderRadius, 'S');

    doc.setFontSize(14);
    doc.text(this.convertToTurkishCharacters('Özet'), summaryX + padding, summaryY + padding + 10);

    doc.setFontSize(10);
    summaryText.forEach((text, index) => {
      doc.text(text, summaryX + padding, summaryY + padding + 20 + (index * lineHeight));
    });

    const columns = [this.convertToTurkishCharacters('Ürün Adı'), this.convertToTurkishCharacters('Ürün Fiyatı ve Adeti')];
    const rows = this.orderDetailData.products.map(product => [
      product.title,
      `${this.formatAmount(product.price)}tl - ${product.quantity}`
  ]);

    autoTable(doc, {
      head: [columns],
      body: rows,
      headStyles: {
        fillColor: '#1cc4e0'  
      },
      startY: summaryY + summaryHeight + spaceBelowLogo
    });

    doc.save(orderCode+'.pdf');
  }


}
