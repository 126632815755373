import { Component } from '@angular/core'
import { providersTabData } from '@data/payment/providers'
import { PasswordInputComponent } from '@component/shared/password-input/password-input.component'
import { DropdownComponent } from '@component/shared/dropdown/dropdown.component'
import { FirmResponseModel } from '../../../models/firm/firm-response-model'
import { FirmDataService } from '@service/firm/firm-service'

@Component({
  selector: 'app-billing-info',
  standalone: true,
  imports: [PasswordInputComponent,DropdownComponent],
  templateUrl: './billing-info.component.html'
})
export class BillingInfoComponent {
  providers = providersTabData
  activeTab = this.providers[0].title
  city = [
    "İstanbul",
    "Ankara",
    "İzmir"
  ]
  district = [
    'Ataşehir',
    'Ümraniye',
    'Kadıköy'
  ]

  firmData!:FirmResponseModel;

  constructor(private firmDataService: FirmDataService){}

  ngOnInit(): void {

    this.firmDataService.allFirmData().subscribe(
      response => {
        this.firmData = response;
      },
      error => {
        console.error('Hata:', error);
      }
    );
  }
}
