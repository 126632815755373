
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CategoryAddModel } from '../../models/category/category-add.model';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class BranchAddService {
    private apiUrl = `${environment.apiUrl}/branchs`;
    private token = localStorage.getItem('authToken');
    constructor(private http: HttpClient) { }
    addBranch(
        title: string,
        phone: string,
        address: string,
        cityId: number,
        districtId: number,
        isActive: boolean,
        posIntegrator: string
    ): Observable<CategoryAddModel> {
        const categorAddUrl = `${this.apiUrl}`;
        const headers = new HttpHeaders().set('Authorization', `Bearer ${this.token}`);
        const body = {
            "title": title.toString(),
            "phone": phone.toString(),
            "address": address.toString(),
            "city_id": cityId,
            "district_id": districtId,
            "is_active": isActive,
            "brand_id": null,
            "lat": null,
            "long": null,
            "pos_integrator": posIntegrator,
            "region_id": null
        };

        return this.http.post<CategoryAddModel>(categorAddUrl, body, { headers });
    }
}
