<div class="relative">
  <button (click)="toggleOpen()" id="notification-btn" class="relative h-10 w-10 rounded-full border border-n30 bg-primary/5 dark:border-n500 dark:bg-bg3 md:h-12 md:w-12">
    <i class="las la-bell text-2xl"></i>
    <span class="absolute -right-1 -top-1 flex h-5 w-5 items-center justify-center rounded-full bg-primary text-xs text-n0"> 2 </span>
  </button>
  <div id="notification" [ngClass]="isOpen?'show':'hide'" class="absolute top-full z-20 origin-[60%_0] rounded-md bg-n0 shadow-[0px_6px_30px_0px_rgba(0,0,0,0.08)] duration-300 dark:bg-bg4 ltr:-right-[110px] sm:ltr:right-0 sm:ltr:origin-top-right rtl:-left-[120px] sm:rtl:left-0 sm:rtl:origin-top-left">
    <div class="flex items-center justify-between border-b p-3 dark:border-n500 lg:px-4">
      <h4 class="h4">Bildirimler</h4>
      <a href="#" class="text-sm text-primary"> Tümünü Gör </a>
    </div>
    <ul class="flex w-[300px] flex-col p-4">
      <div class="flex cursor-pointer gap-2 rounded-md p-2 duration-300 hover:bg-primary/10">
        <img src="assets/icons/warning-icon.svg" width="44" height="40" class="shrink-0 rounded-full" alt="img" />
        <div class="text-sm">
          <div class="flex gap-1">
            <span class="font-medium">Bildirim</span>
          </div>
          <span class="text-xs text-n100 dark:text-n50">1 saat önce</span>
        </div>
      </div>
      <div class="flex cursor-pointer gap-2 rounded-md p-2 duration-300 hover:bg-primary/10">
        <img src="assets/icons/warning-icon.svg" width="44" height="40" class="shrink-0 rounded-full" alt="img" />
        <div class="text-sm">
          <div class="flex gap-1">
            <span class="font-medium">Bildirim</span>
          </div>
          <span class="text-xs text-n100 dark:text-n50">2 saaat önce</span>
        </div>
      </div>
    </ul>
  </div>
</div>
