<div class="box xxl:p-8 xxxl:p-10">

    <form class="mt-6 xl:mt-8 grid grid-cols-2 gap-4 xxxxxl:gap-6">
    
 
      <div class="col-span-2 md:col-span-1">
        <label for="location" class="md:text-lg font-medium block mb-4">Pos İstasyonu Yazıcısı </label>
        <app-dropdown [options]="posStationPrinter" btnClassProps="rounded-3xl w-full !py-3" dropdownClassProps="w-full" />
      </div>
       
      <div class="col-span-2 md:col-span-1">
        <label for="location" class="md:text-lg font-medium block mb-4"> Ödeme Modeli</label>
        <app-dropdown [options]="paymentModel" btnClassProps="rounded-3xl w-full !py-3" dropdownClassProps="w-full" />
      </div>

      <div class="col-span-2 md:col-span-1">
        <label for="location" class="md:text-lg font-medium block mb-4">Yazdırma Şablonu</label>
        <app-dropdown [options]="printTemplate" btnClassProps="rounded-3xl w-full !py-3" dropdownClassProps="w-full" />
      </div>
       
      <div class="col-span-2 md:col-span-1">
        <label for="location" class="md:text-lg font-medium block mb-4"> Sipariş Noktası</label>
        <app-dropdown [options]="orderPoint" btnClassProps="rounded-3xl w-full !py-3" dropdownClassProps="w-full" />
      </div>

      <div class="col-span-2 md:col-span-1">
        <label for="location" class="md:text-lg font-medium block mb-4">Sipariş Durumu </label>
        <app-dropdown [options]="orderStatus" btnClassProps="rounded-3xl w-full !py-3" dropdownClassProps="w-full" />
      </div>
       
      <div class="col-span-2 md:col-span-1">
        <label for="location" class="md:text-lg font-medium block mb-4"> Kasa Fişi</label>
        <app-dropdown [options]="cashReceipt" btnClassProps="rounded-3xl w-full !py-3" dropdownClassProps="w-full" />
      </div>
      
      <div class="col-span-2">
        <div class="flex mt-6 xxl:mt-10 gap-4"><button class="btn px-5">Kaydet</button><button
            class="btn-outline px-5">İptal</button></div>
      </div>
    </form>
  </div>