<div class=" bg-n0 dark:bg-bg4 box overflow-y-auto max-h-[800px]" style="width: 100vh;">
  <div class="flex justify-end">
    <i (click)="closeModal()" class="las cursor-pointer la-times"></i>
  </div>
  <div class="">
    <div class="box document-head">
      <div class="column" style="width: 60%;">
        @if(invoDetailData.ettn){
        <p>ETTN #{{invoDetailData.ettn}}</p>
        }@else {
        <p>ETTN #Bulunamadı</p>
        }
        @if(invoDetailData.transaction_status == "WAITING"){
        <p class="selected-status  pt-4">Sipariş Durumu: Bekliyor</p>
        }@else if(invoDetailData.transaction_status == "FAIL"){
          <p class="selected-status pt-4" style="background: red !important;">Sipariş Durumu: Başarısız</p>
        }@else{
        <p class="selected-status pt-4">Sipariş Durumu: Başarılı</p>
        }
      </div>
      <button ng-reflect-ng-class="[object Object]"
        class="font-medium text-xs px-4 sm:px-5 xxxl:px-6 py-3 capitalize invoice-active documentDownload">Dışarı Aktar
        <i class="fa-solid fa-download"></i>  
      </button>
    </div>

    <div class="grid grid-cols-12 gap-4 xxl:gap-6 p-4 box min-[1880px]:p-6 mb-4 xxl:mb-6">

      <div
        class="col-span-12 cursor-pointer border border-dashed qt-modal-btn duration-300 border-transparent hover:border-primary lg:col-span-6 xxxl:col-span-4 box bg-primary/5 dark:bg-bg3 flex items-center gap-4 4xl:gap-6 xl:p-3 min-[1880px]:p-6">
        <div>
          <h5 class="text-base xxl:text-lg 4xl:text-xl font-medium mb-1 xxl:mb-2">Sipariş Tarihi:</h5>
          <span class="text-xs xxl:text-sm"> {{ invoDetailData.document_date | date:'dd/MM/yyyy  hh:mm' }} </span>
        </div>
      </div>


      <div
        class="col-span-12 cursor-pointer border border-dashed qt-modal-btn duration-300 border-transparent hover:border-primary lg:col-span-6 xxxl:col-span-4 box bg-primary/5 dark:bg-bg3 flex items-center gap-4 4xl:gap-6 xl:p-3 min-[1880px]:p-6">
        <div>
          <h5 class="text-base xxl:text-lg 4xl:text-xl font-medium mb-1 xxl:mb-2">Belge Tipi:</h5>
          <span class="text-xs xxl:text-sm"> {{ invoDetailData.document_type }} </span>
        </div>
      </div>


      <div
        class="col-span-12 cursor-pointer border border-dashed qt-modal-btn duration-300 border-transparent hover:border-primary lg:col-span-6 xxxl:col-span-4 box bg-primary/5 dark:bg-bg3 flex items-center gap-4 4xl:gap-6 xl:p-3 min-[1880px]:p-6">
        <div>
          <h5 class="text-base xxl:text-lg 4xl:text-xl font-medium mb-1 xxl:mb-2">Şube:</h5>
          <span class="text-xs xxl:text-sm"> {{invoDetailData.company_info.name}} </span>
        </div>
      </div>

      <div
        class="col-span-12 cursor-pointer border border-dashed qt-modal-btn duration-300 border-transparent hover:border-primary lg:col-span-6 xxxl:col-span-4 box bg-primary/5 dark:bg-bg3 flex items-center gap-4 4xl:gap-6 xl:p-3 min-[1880px]:p-6">
        <div>
          <h5 class="text-base xxl:text-lg 4xl:text-xl font-medium mb-1 xxl:mb-2">Görevli:</h5>
          <span class="text-xs xxl:text-sm"> Görevli</span>
        </div>
      </div>

      <div
        class="col-span-12 cursor-pointer border border-dashed qt-modal-btn duration-300 border-transparent hover:border-primary lg:col-span-6 xxxl:col-span-4 box bg-primary/5 dark:bg-bg3 flex items-center gap-4 4xl:gap-6 xl:p-3 min-[1880px]:p-6">
        <div>
          <h5 class="text-base xxl:text-lg 4xl:text-xl font-medium mb-1 xxl:mb-2">B. Seri No:</h5>
          <span class="text-xs xxl:text-sm"> {{ invoDetailData.body.invoice.number }} </span>
        </div>
      </div>
    </div>

    <div class="box">
      <div class="flex justify-between items-center bb-dashed border-secondary1/20 pb-4 mb-4 lg:pb-6 lg:mb-6">
        <h4 class="h4">Müşteri Detayı</h4>
      </div>
      <ul class="flex flex-wrap gap-4 lg:gap-6">
        <li class="w-full md:w-1/2">
          <div href="#" class="flex justify-between items-center gap-2">
            <div class="flex items-center gap-3 sm:gap-4 xxl:gap-6">
              <span
                class="circleAvatar border border-n30 dark:border-n500 bg-primary/5 w-10 h-10 flex items-center justify-center xxl:w-14 xxl:h-14 shrink-0 rounded-full">
                <img src="../../../../assets/icons/user.svg" alt="">
              </span>
              <div>
                <p class="text-lg font-medium">Müşteri Adı:</p>
                <span class="text-xs">{{invoDetailData.body.customer.name}}</span>
              </div>
            </div>
          </div>
        </li>
        <li>
          <div class="flex justify-between items-center gap-2">
            <div class="flex items-center gap-3 sm:gap-4 xxl:gap-6">
              <span
                class="circleAvatar border border-n30 dark:border-n500 bg-primary/5 w-10 h-10 flex items-center justify-center xxl:w-14 xxl:h-14 shrink-0 rounded-full">
                <img src="../../../../assets/icons/phone.svg" alt="">
              </span>
              <div>
                <p class="text-lg font-medium">Firma:</p>
                <span class="text-xs">{{invoDetailData.company_info.name || ''}}</span>
              </div>
            </div>
          </div>
        </li>
        <li class="w-full md:w-1/2">
          <div class="flex justify-between items-center gap-2">
            <div class="flex items-center gap-3 sm:gap-4 xxl:gap-6">
              <span
                class="circleAvatar border border-n30 dark:border-n500 bg-primary/5 w-10 h-10 flex items-center justify-center xxl:w-14 xxl:h-14 shrink-0 rounded-full">
                <img src="../../../../assets/icons/customer-code.svg" alt="">
              </span>
              <div>
                <p class="text-lg font-medium">Entegre ID:</p>
                <span class="text-xs">{{invoDetailData.integrator.gateway}}</span>
              </div>
            </div>
          </div>
        </li>
        <li>
          <div class="flex justify-between items-center gap-2">
            <div class="flex items-center gap-3 sm:gap-4 xxl:gap-6">
              <span
                class="circleAvatar border border-n30 dark:border-n500 bg-primary/5 w-10 h-10 flex items-center justify-center xxl:w-14 xxl:h-14 shrink-0 rounded-full">
                <img src="../../../../assets/icons/localization.svg" alt="">
              </span>
              <div>
                <p class="text-lg font-medium">Şube:</p>
                <span class="text-xs">""</span>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>

    <div class="box mt-4" id="documentFooter">
      <div class="flex justify-between items-center bb-dashed border-secondary1/20 pb-4 mb-4 lg:pb-6 lg:mb-6">
        <h4 class="h4">Fatura Detayı</h4>
      </div>
      <ul class="flex flex-wrap gap-4 lg:gap-6">
        <li class="w-full md:w-1/2">
          <div class="flex items-center gap-3 sm:gap-4">
            <p class="text-m font-medium">Ara Toplam:</p>
            <p class="text-sm">{{invoDetailData.total_amount}} ₺</p>
          </div>
        </li>
        <li>
          <div class="flex items-center gap-3 sm:gap-4">
            <p class="text-m font-medium">T. Tutar:</p>
            <p class="text-sm">{{invoDetailData.total_amount}} ₺</p>
          </div>
        </li>
        <li class="w-full md:w-1/2">
          <div class="flex items-center gap-3 sm:gap-4">
            <p class="text-m font-medium">T.Ü Adedi:</p>
            <p class="text-sm">{{invoDetailData.product_count}} Adet</p>
          </div>
        </li>
        <li>
          <div class="flex items-center gap-3 sm:gap-4">
            <p class="text-m font-medium">Ödeme:</p>
            <p class="text-sm">{{invoDetailData.payment_method}}</p>
          </div>
        </li>
        <li class="w-full md:w-1/2">
          <div class="flex items-center gap-3 sm:gap-4">
            <p class="text-m font-medium">Ödeme Türü:</p>
            <p class="text-sm">{{invoDetailData.payment_type}}</p>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>