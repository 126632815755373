<div class="grid grid-cols-12 gap-4 xxl:gap-6">
  <div class="box col-span-12">
    <div class="flex justify-between items-center gap-4 flex-wrap bb-dashed mb-4 pb-4 lg:mb-6 lg:pb-6">
      <h4 class="h4">Plus Cüzdan Kişi Listesi</h4>
      <div class="flex items-center gap-4 flex-wrap grow sm:justify-end">
        <div class="flex items-center gap-3 whitespace-nowrap">
          <span>Filtre :</span>
          <app-dropdown [options]="walletTypeFilter" btnClassProps="!py-3 rounded-3xl bg-secondary1/5"
            dropdownClassProps="w-full"></app-dropdown>
        </div>
        <button class="btn shrink-0 add-account-btn">Bugün <i class="las la-calendar ml-2 cursor-pointer"></i></button>
        <button (click)="addAccountModal()" class="btn shrink-0 add-account-btn">Dışarı Aktar</button>
        <button (click)="addPersonModal()" class="btn shrink-0 add-account-btn">Kişi Ekle</button>
        <form
          class="bg-primary/5 datatable-search dark:bg-bg3 border border-n30 dark:border-n500 flex gap-3 rounded-[30px] focus-within:border-primary p-1 items-center justify-between min-w-[200px] xxl:max-w-[319px] w-full">
          <input type="text" placeholder="Search"
            class="bg-transparent text-sm ltr:pl-4 rtl:pr-4 py-1 w-full border-none" id="payment-account-search" />
          <button
            class="bg-primary shrink-0 rounded-full w-7 h-7 lg:w-8 lg:h-8 flex justify-center items-center text-n0">
            <i class="las la-search text-lg"></i>
          </button>
        </form>

      </div>
    </div>
    <div class="overflow-x-auto pb-4 lg:pb-6">
      <table class="w-full whitespace-nowrap" id="payment-account">
        <thead>
          <tr class="bg-secondary1/5 dark:bg-bg3">
            <th class="text-start !py-5 px-6 min-w-[230px]">
              <div class="flex items-center gap-1">Kişi ID</div>
            </th>
            <th (click)="paymentAccounts.sortData('account')" class="text-start !py-5 min-w-[130px] cursor-pointer">
              <div class="flex items-center gap-1">Kişi Bilgisi <i class="las la-exchange-alt rotate-90 text-base"></i>
              </div>
            </th>
            <th (click)="paymentAccounts.sortData('account')" class="text-start !py-5 min-w-[200px] cursor-pointer">
              <div class="flex items-center gap-1">Cüzdan Tipi <i class="las la-exchange-alt rotate-90 text-base"></i>
              </div>
            </th>
            <th (click)="paymentAccounts.sortData('account')" class="text-start !py-5 min-w-[140px] cursor-pointer">
              <div class="flex items-center gap-1">Oluşturma Tarihi <i
                  class="las la-exchange-alt rotate-90 text-base"></i></div>
            </th>
            <th (click)="paymentAccounts.sortData('account')" class="text-start !py-5 min-w-[130px] cursor-pointer">
              <div class="flex items-center gap-1">Fiyatı <i class="las la-exchange-alt rotate-90 text-base"></i></div>
            </th>
            <th class="flex !py-5 min-w-[130px]" style="justify-content: center;">
              <div class="flex items-center gap-1">İşlem</div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of paymentAccounts.paginatedData; index as i"
            class="even:bg-secondary1/5 dark:even:bg-bg3">
            <td class="py-2 px-6">
              <div class="flex items-center gap-3">
                <div>
                  <p class="font-medium mb-1">{{ item.account }}</p>
                </div>
              </div>
            </td>
            <td class="py-2">
              <div>
                <p class="font-medium">{{ item.currency.short }}</p>
              </div>
            </td>
            <td class="py-2">
              <div>
                <p class="font-medium">{{ item.bank.name }}</p>
              </div>
            </td>
            <td>11/05/2027</td>
            <td>
              <span class="font-medium"> {{ getLocale(item.balance) }} </span>
            </td>
            <td class="py-4">
              <div class="flex justify-center">
                <app-options-vertical-wallet-person-list></app-options-vertical-wallet-person-list>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="flex col-span-12 gap-4 sm:justify-between justify-center items-center flex-wrap">
      <p>Showing {{ paymentAccounts.startIndex + 1 }} to {{ paymentAccounts.endIndex + 1 }} of {{
        paymentAccounts.totalData }} entries</p>
      <ul class="flex gap-2 md:gap-3 flex-wrap md:font-semibold items-center">
        <li>
          <button [disabled]="paymentAccounts.currentPage == 1" (click)="paymentAccounts.prevPage()"
            class="hover:bg-primary text-primary rtl:rotate-180 hover:text-n0 border md:w-10 duration-300 md:h-10 w-8 h-8 flex items-center rounded-full justify-center border-primary">
            <i class="las la-angle-left text-lg"></i>
          </button>
        </li>
        <li *ngFor="let page of pages">
          <button (click)="paymentAccounts.paginate(page)"
            [ngClass]="{ 'bg-primary !text-n0': paymentAccounts.currentPage == page }"
            class="hover:bg-primary bg-primary hover:text-n0 border md:w-10 duration-300 md:h-10 w-8 h-8 flex text-primary items-center rounded-full justify-center border-primary">
            {{ page }}
          </button>
        </li>
        <li>
          <button [disabled]="paymentAccounts.currentPage == paymentAccounts.totalPages"
            (click)="paymentAccounts.nextPage()"
            class="hover:bg-primary text-primary hover:text-n0 rtl:rotate-180 border md:w-10 duration-300 md:h-10 w-8 h-8 flex items-center rounded-full justify-center border-primary">
            <i class="las la-angle-right text-lg"></i>
          </button>
        </li>
      </ul>
    </div>
  </div>


</div>