import { Component } from '@angular/core';
import { ModalService } from 'ngx-modal-ease';

@Component({
  selector: 'delete-section-modal',
  standalone: true,
  imports: [],
  templateUrl: './delete-section-modal.component.html'
})
export class DeleteSectionModalComponent {
  constructor(private modalService:ModalService){}
  closeModal(){
    this.modalService.close('DeleteSectionModalComponent')
  }
}
