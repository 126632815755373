import { Component } from '@angular/core'
import { DropdownComponent } from '../dropdown/dropdown.component'
import { ModalService } from 'ngx-modal-ease'

@Component({
  selector: 'app-add-short-code-modal',
  standalone: true,
  imports: [DropdownComponent],
  templateUrl: './add-short-code-modal.component.html'
})
export class AddShortCodeModalComponent {
  actionsData= [
    {
      id: 1,
      title: 'B.Seri No:',
      desc: '87823734TYEIE2',
    },
    {
      id: 2,
      title: 'Sipariş Tarihi:',
      desc: '20.10.2024 11:33',
    },
    {
      id: 3,
      title: 'Belge Tipi:',
      desc: 'Earşiv',
    },
    {
      id: 4,
      title: 'Şube:',
      desc: 'Özyeğin Üniversitesi / #99d2e8f9-4cde-40fb-b5a1-b26beff82905',
    },
    {
      id: 5,
      title: 'Kasiyer:',
      desc: 'Emre Yılmaz',
    },
  ]
  currencies = ['USD', 'GBP', 'YEN', 'JPN']
  statusList=['Active','Inactive']
  constructor(private modalService:ModalService){}
  closeModal(){
    this.modalService.close('AddShortCodeModalComponent')
  }
}
