import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class LogOutService {

    private apiUrl = `${environment.apiUrl}/auth/logout`;
    private token = localStorage.getItem('authToken');

    constructor(private http: HttpClient) { }

    getData(): Observable<any> {
        const headers = new HttpHeaders().set('Authorization', `Bearer ${this.token}`);
        return this.http.get<any>(this.apiUrl,{headers});
    }
}
