<div id="dropdown-horiz" class="relative">
  <i class="las la-ellipsis-h text-2xl horiz-option-btn cursor-pointer" (click)="toggleOpen($event)"></i>
  <ul [ngClass]="isOpen ? 'show' : 'hide'" class="dropdown-horiz absolute top-full z-[3] min-w-[122px] rounded-md bg-n0 p-3 shadow-[0px_6px_30px_0px_rgba(0,0,0,0.08)] duration-300 dark:bg-bg3 ltr:right-0 ltr:origin-top-right rtl:left-0 rtl:origin-top-left">
    <li>
      <span class="block cursor-pointer rounded px-3 py-1 text-sm leading-normal duration-300 hover:bg-primary/10 dark:hover:bg-bg4"> Edit </span>
    </li>
    <li>
      <span class="block cursor-pointer rounded px-3 py-1 text-sm leading-normal duration-300 hover:bg-primary/10 dark:hover:bg-bg4"> Print </span>
    </li>
    <li>
      <span class="block cursor-pointer rounded px-3 py-1 text-sm leading-normal duration-300 hover:bg-primary/10 dark:hover:bg-bg4"> Share </span>
    </li>
  </ul>
</div>
