import { Component, Input } from '@angular/core'
import { ModalService } from 'ngx-modal-ease'
import { FormsModule } from '@angular/forms';
import { VerificationService } from '@service/auth/verification.service';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { LoginService } from '@service/auth/login.service';
import { UserService } from '@service/auth/user.service';
@Component({
  selector: 'app-add-pin-modal',
  standalone: true,
  imports: [FormsModule, CommonModule],
  templateUrl: './pin-modal.component.html'
})
export class PinModalComponent {
  otp1!: string;
  otp2!: string;
  otp3!: string;
  otp4!: string;
  otp5!: string;
  otp6!: string;

  errorMessage!: string;
  countdown: number = 60;
  interval: any;
  radius: number = 90;
  circumference: number = 2 * Math.PI * this.radius;
  offset: number = this.circumference;

  @Input() phoneNumber!: string;
  @Input() transactionId!: string;
  @Input() branch_id!: string;

  constructor(private router: Router, private userService: UserService, private modalService: ModalService, private verifyService: VerificationService) { }
  closeModal() {
    this.modalService.close('PinModalComponent')
  }

  focusNext(event: KeyboardEvent, currentIndex: number) {
    const inputs = document.querySelectorAll('.otp-inputs input');
    if (event.key >= '0' && event.key <= '9') {
      if (currentIndex < inputs.length - 1) {
        (inputs[currentIndex + 1] as HTMLElement).focus();
      }
    } else if (event.key === 'Backspace') {
      if (currentIndex > 0) {
        (inputs[currentIndex - 1] as HTMLElement).focus();
      }
    }
  }

  ngOnInit(): void {
    this.startTimer();
  }

  startTimer() {
    this.interval = setInterval(() => {
      this.countdown--;

      if (this.countdown <= 0) {
        clearInterval(this.interval);
      }

      this.offset = this.circumference - (this.circumference / 60) * (60 - this.countdown);
    }, 1000);
  }

  submitForm(): void {
    const otpCode = `${this.otp1}${this.otp2}${this.otp3}${this.otp4}${this.otp5}${this.otp6}`;

    this.verifyService.verifyOtp(this.transactionId, this.branch_id, otpCode).subscribe(
      (response) => {
        this.closeModal();
        this.errorMessage = response.message;
        localStorage.setItem('authToken', response.token);

        this.userService.login(response.token);
        this.router.navigateByUrl('/dashboards/style-02')
      },
      (error) => {
        this.errorMessage = error.message;
      }
    );
  }
}
