import { CommonModule } from '@angular/common'
import { CUSTOM_ELEMENTS_SCHEMA, Component, OnInit } from '@angular/core'
import { DropdownComponent } from '@component/shared/dropdown/dropdown.component'
import { TopBannerComponent } from '@component/shared/top-banner/top-banner.component'
import { ChartOptions } from '../style-01/style-01.component'
import { NgApexchartsModule } from 'ng-apexcharts'
import { OptionsVerticalComponent } from '@component/shared/options-vertical/options-vertical.component'
import { ChangeDetectorRef } from '@angular/core'
import { TableService } from '@service/table.service'
import { DashboardTransactionService } from '@service/dashboard/dashboard-transaction-service'
import { DashboardDailyModel } from '../../../models/dashboard/daily-model'
import { DashboardWeekModel } from '../../../models/dashboard/week-model'
import { DashboardMonthModel } from '../../../models/dashboard/month-model'
import { DashboardYearModel } from '../../../models/dashboard/year-model'
import { DashboardLastFiveModel } from '../../../models/dashboard/last-five-years-model'
import { DashboardPaymentTypeService } from '@service/dashboard/payment-type-service'
import { DashboardPaymentTypeModel, PaymentTypes } from '../../../models/dashboard/payment-type-model'
import { BranchListData } from '../../../models/branches/bracnhs-list-model'
import { BaranchListService } from '@service/branches/bracnhs-list-service'
import { PersonelListService } from '@service/personel/personel-list.service'
import { PersonelListModel } from '../../../models/personel/personel-list-model'
import { FormsModule } from '@angular/forms'
import { ModalService } from 'ngx-modal-ease'
import { AddAccountModalComponent } from '@component/shared/add-account-modal/add-account-modal.component'
import { DashboardFilterComponent } from '@component/shared/dashboard-filter-modal/dashboard-filter-modal.component'

@Component({
  selector: 'app-style-02',
  standalone: true,
  imports: [
    TopBannerComponent,
    FormsModule,
    DropdownComponent,
    CommonModule,
    NgApexchartsModule,
    OptionsVerticalComponent
  ],
  templateUrl: './style-02.component.html',
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class Style02Component implements OnInit {
  statCharts: any[] = [];
  stats = [
    {
      id: 1,
      title: 'USD',
      amount: 72245,
      percent: 28.3,
      color: 'text-primary',
      arrow: 'las la-arrow-up text-lg'
    },
    {
      id: 2,
      title: 'EUR',
      amount: 72245,
      percent: 45.3,
      color: 'text-primary',
      arrow: 'las la-arrow-up text-lg'
    },
    {
      id: 3,
      title: 'GBP',
      amount: 72245,
      percent: -12.3,
      color: 'text-secondary2',
      arrow: 'las la-arrow-down text-lg'
    },
    {
      id: 4,
      title: 'JPY',
      amount: 72245,
      percent: 10.3,
      color: 'text-secondary3',
      arrow: 'las la-arrows-alt-v text-lg'
    }
  ]
  incomeChartOptions: ChartOptions = {
    series: [0],
    xaxis: {
      type: 'category',
      categories: [],
    },
    chart: {
      height: 350,
      width: '100%',
      type: 'line',
      toolbar: {
        show: false
      }
    }
  };

  incomeChartOptionsYears: ChartOptions = {
    series: [0],
    xaxis: {
      type: 'category',
      categories: [],
    },
    chart: {
      height: 350,
      width: '100%',
      type: 'line',
      toolbar: {
        show: false
      }
    }
  };

  incomeChartOptionsFilters: ChartOptions = {
    series: [0],
    xaxis: {
      type: 'category',
      categories: [],
    },
    chart: {
      height: 350,
      width: '100%',
      type: 'line',
      toolbar: {
        show: false
      }
    }
  };

  generalCollection: ChartOptions = {
    series: [0],
    chart: {
      type: 'donut',
      height: 350,
      width: '100%'
    },
    fill: {
      colors: ['#0E58D5', '#20C3E0', '#96ABCE', '#FF5733', '#F7DC6F', '#58D68D', '#F39C12', '#D5DBDB', '#C39BD3', '#E67E22', '#16A085', '#E74C3C']
    },
    stroke: {
      lineCap: 'round'
    },
    plotOptions: {
      pie: {
        donut: {
          size: '85px',
          labels: {
            show: true,
            value: {
              fontSize: '24px',
              offsetY: 2
            },
            total: {
              show: false,
              label: '99,800.35',
              fontWeight: 600,
              fontSize: '26px',
              formatter: () => 'Total Balance'
            }
          }
        }
      }
    },
    legend: {
      position: 'right',
      itemMargin: {
        vertical: 0,
        horizontal: 0
      },
      horizontalAlign: 'center',
      markers: {
        width: 20,
        height: 7
      }
    },
    dataLabels: {
      enabled: false
    },
    labels: []
  };
  generalCollectionMonth: ChartOptions = {
    series: [0],
    chart: {
      type: 'donut',
      height: 350,
      width: '100%'
    },
    fill: {
      colors: ['#0E58D5', '#20C3E0', '#96ABCE', '#FF5733', '#F7DC6F', '#58D68D', '#F39C12', '#D5DBDB', '#C39BD3', '#E67E22', '#16A085', '#E74C3C']
    },
    stroke: {
      lineCap: 'round'
    },
    plotOptions: {
      pie: {
        donut: {
          size: '85px',
          labels: {
            show: true,
            value: {
              fontSize: '24px',
              offsetY: 2
            },
            total: {
              show: false,
              label: '99,800.35',
              fontWeight: 600,
              fontSize: '26px',
              formatter: () => 'Total Balance'
            }
          }
        }
      }
    },
    legend: {
      position: 'right',
      itemMargin: {
        vertical: 0,
        horizontal: 0
      },
      horizontalAlign: 'center',
      markers: {
        width: 20,
        height: 7
      }
    },
    dataLabels: {
      enabled: false
    },
    labels: []
  };
  generalCollectionFilter: ChartOptions = {
    series: [0],
    chart: {
      type: 'donut',
      height: 350,
      width: '100%'
    },
    fill: {
      colors: ['#0E58D5', '#20C3E0', '#96ABCE', '#FF5733', '#F7DC6F', '#58D68D', '#F39C12', '#D5DBDB', '#C39BD3', '#E67E22', '#16A085', '#E74C3C']
    },
    stroke: {
      lineCap: 'round'
    },
    plotOptions: {
      pie: {
        donut: {
          size: '85px',
          labels: {
            show: true,
            value: {
              fontSize: '24px',
              offsetY: 2
            },
            total: {
              show: false,
              label: '99,800.35',
              fontWeight: 600,
              fontSize: '26px',
              formatter: () => 'Total Balance'
            }
          }
        }
      }
    },
    legend: {
      position: 'right',
      itemMargin: {
        vertical: 0,
        horizontal: 0
      },
      horizontalAlign: 'center',
      markers: {
        width: 20,
        height: 7
      }
    },
    dataLabels: {
      enabled: false
    },
    labels: []
  };

  foodCardChart: ChartOptions = {
    series: [],
    chart: {
      height: 0,
      width: '100%',
      type: 'line',
      toolbar: {
        show: false
      }
    }
  }

  foodCardChartFilter: ChartOptions = {
    series: [],
    chart: {
      height: 0,
      width: '100%',
      type: 'line',
      toolbar: {
        show: false
      }
    }
  }

  definiteSalesFilter = ['Aylık', 'Yıllık'];
  mealCardsList = [
    {
      key: 'CIO_CARD',
      value: 'CIOCard',
    },
    {
      key: 'METROPOL',
      value: 'Metropol',
    },
    {
      key: 'MULTINET',
      value: 'Multinet',
    },
    {
      key: 'PAYE',
      value: 'Paye',
    },
    {
      key: 'SETCARD',
      value: 'Setcard',
    },
    {
      key: 'SODEXO',
      value: 'Sodexo',
    },
    {
      key: 'TICKET',
      value: 'Ticket',
    },
    {
      key: 'TOKENFLEX',
      value: 'Tokenflex',
    },
    {
      key: 'YEMEKMATIK',
      value: 'Yemekmatik',
    },
  ]


  filteredStats: any[] = [];
  filter: string = 'today';

  filters = ['Tümü', 'Başarılı', 'İptal Edildi', 'Devam Ediyor']
  currentFilter = this.filters[0]
  statisticsData = [
    {
      title: 'İşlem Adedi',
      amount: '619,00 ₺',
    },
    {
      title: 'Toplam İptal Tutarı',
      amount: '54,00 ₺',
    },
    {
      title: 'Brüt Satış Tutarı',
      amount: '50,00 ₺',
    },
    {
      title: 'Toplam KDV Tutarı',
      amount: '23,00 ₺',
    }
  ]
  monthTranslations: { [key: string]: string } = {
    'January': 'Ocak',
    'February': 'Şubat',
    'March': 'Mart',
    'April': 'Nisan',
    'May': 'Mayıs',
    'June': 'Haziran',
    'July': 'Temmuz',
    'August': 'Ağustos',
    'September': 'Eylül',
    'October': 'Ekim',
    'November': 'Kasım',
    'December': 'Aralık'
  };
  transDataCounter = ['Başarılı Satış İşlemi', 'Toplam İptal Tutarı', 'Brüt Satış Tutarı', 'Toplam KDV Tutarı']
  cards: string[] = ['Visa', 'Mastercard', 'Paypal']
  currencies: string[] = ['$', '€', '£', '¥']
  activeIndex = 1
  pages: number[] = [1]

  totalAmountMonth: number = 0;
  totalAmountYear: number = 0;

  currentMonthSales!: number;
  salesData: number[] = [];
  allTransactionsData!: DashboardPaymentTypeModel;
  allPaymentTypeTransactionsData: any;
  private _searchVal = ''

  dailyData!: DashboardDailyModel;
  yesterdayDataList!: DashboardDailyModel;
  weekDataList!: DashboardWeekModel;
  monthDataList!: DashboardMonthModel;
  yearDataList!: DashboardYearModel;
  fiveYearDataList!: DashboardLastFiveModel;

  paymentTypeList!: DashboardPaymentTypeModel;
  paymentTypes: string[] = [];
  series: number[] = [];

  paymentTypesMonth: string[] = [];
  seriesMonth: number[] = [];
  noDataMessage: string = '';

  mealCardWeekDataList!: DashboardWeekModel;
  paymentTypesListArea: any[] = [];
  tabSelectedDate!: string;

  filtersData: any | null = null;
  filterResulStaff!: string;
  filterMealCardTotal!: number;

  formatter = new Intl.NumberFormat('tr-TR', {
    currency: 'TRY',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });

  formatAmount(amount: number | undefined): string {
    return this.formatter.format(amount ?? 0);
  }

  formatStaticAmount(amount: string): number {
    return parseFloat(amount.replace(/,/g, ''));
  }

  selectedDefStatus!: string;
  selectedPaymentType!: string;
  selectedMealCard!: string;

  branchList!: BranchListData[];
  personelList!: PersonelListModel[];
  selectedBranch!: string;
  selectedStaff!: string;

  constructor(
    private cdRef: ChangeDetectorRef,
    private getDashboardTransAcService: DashboardTransactionService,
    private branchesService: BaranchListService,
    private getDashboardPaymentTypeService: DashboardPaymentTypeService,
    private getPersonelListService: PersonelListService,
    private modalService: ModalService,
  ) {
    // this.allTransactionsData = new TableService()
    // this.allTransactionsData.initialize(this.allTransactionsData, 12)
  }

  get searchVal(): string {
    return this._searchVal
  }

  set searchVal(value: string) {
    this._searchVal = value
    this.cdRef.detectChanges()
  }

  loadStats() {
    this.applyFilter();
  }

  applyFilter() {
    if (this.filter === 'today') {
      this.filteredStats = this.stats.filter(item => item.amount < 200);
    } else if (this.filter === 'yesterday') {
      this.filteredStats = this.stats.filter(item => item.amount >= 200 && item.amount < 300);
    } else if (this.filter === 'lastWeek') {
      this.filteredStats = this.stats.filter(item => item.amount >= 300);
    }
  }

  setFilter(filter: string, date: string) {
    this.filter = filter;
    this.tabSelectedDate = date,
      this.applyFilter();
    if (this.selectedBranch) {
      this.getDashboardPaymentTypeService.getData(date, true, this.selectedBranch).subscribe(
        (response: DashboardPaymentTypeModel) => {
          this.allTransactionsData = response;
        },
        error => {
          console.error('Hata:', error);
        }
      );
    } else {
      this.getDashboardPaymentTypeService.getData(date).subscribe(
        (response: DashboardPaymentTypeModel) => {
          this.allTransactionsData = response;
        },
        error => {
          console.error('Hata:', error);
        }
      );
    }
    this.cdRef.detectChanges();
  }

  getLocale(number: number) {
    return number.toLocaleString()
  }

  onInputChange(event: Event) {
    const inputElement = event.target as HTMLInputElement
    this.searchVal = inputElement.value
    this.cdRef.detectChanges()
  }

  ngOnInit() {
    this.getData();
    this.getPersonelList();
    this.yesterdayData();
    this.weekData();
    this.monthData();
    this.yearData();
    this.lastFiveYearData();
    this.getPaymentMethodYears();
    this.getPaymentMethodMonth();
    this.getPaymentMethodDaily();

    this.mealCardWeekData();

    this.loadStats();
    this.applyFilter();
    const startDate = new Date(new Date().getFullYear(), 0, 1);
    const endDate = new Date();
    this.statCharts = [
      {
        chart: {
          height: 100,
          width: 150,
          type: 'area',
          sparkline: {
            enabled: true
          },
          toolbar: {
            show: false
          },
          animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 800
          }
        },
        grid: {
          show: false
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth',
          width: 1
        },
        series: [
          {
            name: 'Series 1',
            data: [40, 42, 35, 38, 52, 17, 15, 19, 29, 35, 30, 40, 35, 45, 35, 29, 38, 51, 56, 48, 53, 57, 69, 65, 53, 39, 53, 38, 52, 51, 49, 50, 36, 63, 90, 72, 75, 89, 96, 72, 91, 83, 71, 61, 52, 45, 49]
          }
        ],
        tooltip: {
          enabled: false
        },
        colors: ['#27CC6A'],
        fill: {
          colors: ['#27CC6A'],
          opacity: 1,
          type: 'gradient',
          gradient: {
            shade: 'dark',
            type: 'vertical',
            shadeIntensity: 0.3,
            gradientToColors: undefined,
            inverseColors: false,
            opacityFrom: 1,
            opacityTo: 0.1,
            colorStops: []
          }
        },
        xaxis: {
          tooltip: {
            enabled: false
          },
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          labels: {
            show: false
          }
        },
        yaxis: {
          tooltip: {
            enabled: false
          },
          labels: {
            show: false
          }
        }
      },
      {
        chart: {
          height: 100,
          width: 150,
          type: 'area',
          sparkline: {
            enabled: true
          },
          toolbar: {
            show: false
          },
          animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 800
          }
        },
        grid: {
          show: false
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth',
          width: 1
        },
        series: [
          {
            name: 'Series 1',
            data: [40, 42, 35, 38, 52, 17, 15, 19, 29, 35, 30, 40, 35, 45, 35, 29, 38, 51, 56, 48, 53, 57, 69, 65, 53, 39, 53, 38, 52, 51, 49, 50, 36, 63, 90, 72, 75, 89, 96, 72, 91, 83, 71, 61, 52, 45, 49]
          }
        ],
        tooltip: {
          enabled: false
        },
        colors: ['#EE4444'],
        fill: {
          colors: ['#EE4444'],
          opacity: 1,
          type: 'gradient',
          gradient: {
            shade: 'dark',
            type: 'vertical',
            shadeIntensity: 0.3,
            gradientToColors: undefined,
            inverseColors: false,
            opacityFrom: 1,
            opacityTo: 0.1,
            colorStops: []
          }
        },
        xaxis: {
          tooltip: {
            enabled: false
          },
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          labels: {
            show: false
          }
        },
        yaxis: {
          tooltip: {
            enabled: false
          },
          labels: {
            show: false
          }
        }
      },
      {
        chart: {
          height: 100,
          width: 150,
          type: 'area',
          sparkline: {
            enabled: true
          },
          toolbar: {
            show: false
          },
          animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 800
          }
        },
        grid: {
          show: false
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth',
          width: 1
        },
        series: [
          {
            name: 'Series 1',
            data: [40, 42, 35, 38, 52, 17, 15, 19, 29, 35, 30, 40, 35, 45, 35, 29, 38, 51, 56, 48, 53, 57, 69, 65, 53, 39, 53, 38, 52, 51, 49, 50, 36, 63, 90, 72, 75, 89, 96, 72, 91, 83, 71, 61, 52, 45, 49]
          }
        ],
        tooltip: {
          enabled: false
        },
        colors: ['#27CC6A'],
        fill: {
          colors: ['#27CC6A'],
          opacity: 1,
          type: 'gradient',
          gradient: {
            shade: 'dark',
            type: 'vertical',
            shadeIntensity: 0.3,
            gradientToColors: undefined,
            inverseColors: false,
            opacityFrom: 1,
            opacityTo: 0.1,
            colorStops: []
          }
        },
        xaxis: {
          tooltip: {
            enabled: false
          },
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          labels: {
            show: false
          }
        },
        yaxis: {
          tooltip: {
            enabled: false
          },
          labels: {
            show: false
          }
        }
      },
      {
        chart: {
          height: 100,
          width: 150,
          type: 'area',
          sparkline: {
            enabled: true
          },
          toolbar: {
            show: false
          },
          animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 800
          }
        },
        grid: {
          show: false
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth',
          width: 1
        },
        series: [
          {
            name: 'Series 1',
            data: [40, 42, 35, 38, 52, 17, 15, 19, 29, 35, 30, 40, 35, 45, 35, 29, 38, 51, 56, 48, 53, 57, 69, 65, 53, 39, 53, 38, 52, 51, 49, 50, 36, 63, 90, 72, 75, 89, 96, 72, 91, 83, 71, 61, 52, 45, 49]
          }
        ],
        tooltip: {
          enabled: false
        },
        colors: ['#FDAF36'],
        fill: {
          colors: ['#FDAF36'],
          opacity: 1,
          type: 'gradient',
          gradient: {
            shade: 'dark',
            type: 'vertical',
            shadeIntensity: 0.3,
            gradientToColors: undefined,
            inverseColors: false,
            opacityFrom: 1,
            opacityTo: 0.1,
            colorStops: []
          }
        },
        xaxis: {
          tooltip: {
            enabled: false
          },
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          labels: {
            show: false
          }
        },
        yaxis: {
          tooltip: {
            enabled: false
          },
          labels: {
            show: false
          }
        }
      },
    ]

    const pastMonths = this.getCurrentYearMonths();


    this.pages = Array(Math.ceil(this.statisticsData.length / 6))
      .fill(0)
      .map((_, i) => i + 1)

    this.branchesService.allData().subscribe(
      response => {
        this.branchList = response.data;
      },
      error => {
        console.error('Hata:', error);
      }
    );
  }

  getPersonelList() {
    this.getPersonelListService.allPersonelList().subscribe(
      (response: PersonelListModel[]) => {
        this.personelList = response;
      },
      error => {
        console.error('Hata:', error);
      }
    );
  }

  getPaymentMethodYears() {
    this.getDashboardPaymentTypeService.getData('THIS_YEAR').subscribe(
      (response: DashboardPaymentTypeModel) => {
        const paymentTypes = response.payment_types as PaymentTypes;

        this.paymentTypes = Object.keys(paymentTypes);

        this.series = this.paymentTypes.map(type => {
          const paymentType = paymentTypes[type as keyof PaymentTypes];
          return paymentType.summary.sum_total_amount;
        });

        this.generalCollection = {
          series: this.series,
          labels: this.paymentTypes,
          chart: {
            type: 'donut',
            height: 350,
            width: '100%'
          }
        };
      },
      error => {
        console.error('Hata:', error);
      }
    );
  }

  getPaymentMethodMonth() {
    this.getDashboardPaymentTypeService.getData('THIS_MONTH').subscribe(
      (response: DashboardPaymentTypeModel) => {
        const paymentTypesMonth = response.payment_types as PaymentTypes;

        this.paymentTypesMonth = Object.keys(paymentTypesMonth);

        this.seriesMonth = this.paymentTypesMonth.map(type => {
          const paymentTypeMonth = paymentTypesMonth[type as keyof PaymentTypes];
          return paymentTypeMonth.summary.sum_total_amount;
        });

        this.generalCollectionMonth = {
          series: this.seriesMonth,
          labels: this.paymentTypesMonth,
          chart: {
            type: 'donut',
            height: 350,
            width: '100%'
          }
        };
      },
      error => {
        console.error('Hata:', error);
      }
    );
  }

  getPaymentMethodDaily() {
    this.getDashboardPaymentTypeService.getData('TODAY').subscribe(
      (response: DashboardPaymentTypeModel) => {
        this.allTransactionsData = response;
      },
      error => {
        console.error('Hata:', error);
      }
    );
  }

  getTableData() {
    const paymentTypes = this.allTransactionsData?.payment_types || {};

    const tableData = Object.keys(paymentTypes).map(key => {
      const item = paymentTypes[key as keyof PaymentTypes];
      const transactions = item.transactions || [];

      const successTransaction = transactions.find((tx: any) => 'SUCCESS' in tx.status);
      const failTransaction = transactions.find((tx: any) => 'FAIL' in tx.status);
      const waitingTransaction = transactions.find((tx: any) => 'WAITING' in tx.status);
      const cancelTransaction = transactions.find((tx: any) => 'CANCEL' in tx.status);

      const getSuccessData = (status: any) => status.SUCCESS ? status.SUCCESS : { amount: 0, tax_amount: 0, transaction_count: 0, total_amount: 0 };
      const getFailData = (status: any) => status.FAIL ? status.FAIL : { amount: 0, tax_amount: 0, transaction_count: 0, total_amount: 0 };
      const getWaitingData = (status: any) => status.WAITING ? status.WAITING : { amount: 0, tax_amount: 0, transaction_count: 0, total_amount: 0 };
      const getCancelData = (status: any) => status.CANCEL ? status.CANCEL : { amount: 0, tax_amount: 0, transaction_count: 0, total_amount: 0 };

      return {
        paymentType: key,
        transactions: transactions.map((tx: any) => tx.payment_method).join(', '),
        amount: successTransaction ? getSuccessData(successTransaction.status).amount : 0,
        taxAmount: successTransaction ? getSuccessData(successTransaction.status).tax_amount : 0,
        summary: item.summary,

        transactionCount: successTransaction ? getSuccessData(successTransaction.status).transaction_count : 0,
        totalAmount: successTransaction ? getSuccessData(successTransaction.status).total_amount : 0,

        failTransactionCount: failTransaction ? getFailData(failTransaction.status).transaction_count : 0,
        failTotalAmount: failTransaction ? getFailData(failTransaction.status).total_amount : 0,

        waitingTransactionCount: waitingTransaction ? getWaitingData(waitingTransaction.status).transaction_count : 0,
        waitingTotalAmount: waitingTransaction ? getWaitingData(waitingTransaction.status).total_amount : 0,

        cancelTransactionCount: cancelTransaction ? getCancelData(cancelTransaction.status).transaction_count : 0,
        cancelTotalAmount: cancelTransaction ? getCancelData(cancelTransaction.status).total_amount : 0,
      };
    });

    return tableData;
  }


  getTableDataForFilter() {
    const paymentTypes = this.filtersData.paymentTypeData.payment_types || {};

    const tableData = Object.keys(paymentTypes).map(key => {
      const item = paymentTypes[key as keyof PaymentTypes];
      const transactions = item.transactions || [];
      const successTransaction = transactions.find((tx: any) => tx.status.SUCCESS);
      const failTransaction = transactions.find((tx: any) => tx.status.FAIL);
      const waitingTransaction = transactions.find((tx: any) => tx.status.WAITING);
      const cancelTransaction = transactions.find((tx: any) => tx.status.CANCEL);

      return {
        paymentType: key,
        transactions: transactions.map((tx: any) => tx.payment_method).join(', '),
        amount: successTransaction ? successTransaction.status.SUCCESS.amount : 0,
        taxAmount: successTransaction ? successTransaction.status.SUCCESS.tax_amount : 0,
        summary: item.summary,


        transactionCount: successTransaction ? successTransaction.status.SUCCESS.transaction_count : 0,
        totalAmount: successTransaction ? successTransaction.status.SUCCESS.total_amount : 0,

        failTransactionCount: failTransaction ? failTransaction.status.FAIL.transaction_count : 0,
        failTotalAmount: failTransaction ? failTransaction.status.FAIL.total_amount : 0,

        waitingTransactionCount: waitingTransaction ? waitingTransaction.status.WAITING.transaction_count : 0,
        waitingTotalAmount: waitingTransaction ? waitingTransaction.status.WAITING.total_amount : 0,

        cancelTransactionCount: cancelTransaction ? cancelTransaction.status.CANCEL.transaction_count : 0,
        cancelTotalAmount: cancelTransaction ? cancelTransaction.status.CANCEL.total_amount : 0,
      };
    });

    return tableData;
  }

  extractSumAmounts(paymentTypes: any): { [key: string]: number } {
    const amounts: { [key: string]: number } = {};

    for (const type in paymentTypes) {
      if (paymentTypes.hasOwnProperty(type)) {
        const summary = paymentTypes[type].summary;
        if (summary && summary.sum_amount !== undefined) {
          amounts[type] = summary.sum_amount;
        }
      }
    }

    return amounts;
  }

  getData() {
    this.getDashboardTransAcService.getDailyData().subscribe(
      response => {
        this.dailyData = response;

        this.cdRef.detectChanges();
      },
      error => {
        console.error('Hata:', error);
      }
    );
  }

  yesterdayData() {
    this.applyFilter();
    this.getDashboardTransAcService.getYesyerdayData().subscribe(
      response => {
        this.yesterdayDataList = response;
        this.cdRef.detectChanges();
      },
      error => {
        console.error('Hata:', error);
      }
    );
  }

  weekData() {
    this.applyFilter();
    this.getDashboardTransAcService.getWeekData().subscribe(
      response => {
        this.weekDataList = response;
        this.cdRef.detectChanges();
      },
      error => {
        console.error('Hata:', error);
      }
    );
  }

  monthData() {
    this.applyFilter();
    this.getDashboardTransAcService.getMonthData().subscribe(
      response => {
        this.monthDataList = response;
        this.totalAmountMonth = response.SUCCESS.total_values.sum_total_amount
        this.cdRef.detectChanges();
      },
      error => {
        console.error('Hata:', error);
      }
    );
  }

  yearData() {
    this.applyFilter();
    this.getDashboardTransAcService.getYearData().subscribe(
      response => {
        this.yearDataList = response;
        this.totalAmountYear = response.SUCCESS.total_values.sum_total_amount
        this.incomeChartOptions = {
          series: [
            {
              name: 'Tutar',
              type: 'line',
              data: [
                this.formatStaticAmount(response.SUCCESS.grafic_values[0].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[1].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[2].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[3].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[4].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[5].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[6].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[7].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[8].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[9].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[10].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[11].transactions.sum_total_amount.toString()),
              ]
            }
          ],
          xaxis: {
            type: 'category',
            categories: [
              'Ocak',
              'Şubat',
              'Mart',
              'Nisan',
              'Mayıs',
              'Haziran',
              'Temmuz',
              'Ağustos',
              'Eylül',
              'Ekim',
              'Kasım',
              'Aralık'
            ],
          },
          chart: {
            height: 350,
            width: '100%',
            type: 'line',
            toolbar: {
              show: false
            }
          }
        };

        this.cdRef.detectChanges();
      },
      error => {
        console.error('Hata:', error);
      }
    );
  }

  lastFiveYearData() {
    this.getDashboardTransAcService.getLastFiveYearData().subscribe(
      response => {
        this.fiveYearDataList = response;
        this.incomeChartOptionsYears = {
          series: [
            {
              name: 'Tutar',
              type: 'line',
              data: [
                this.formatStaticAmount(response.SUCCESS.grafic_values[0].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[1].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[2].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[3].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[4].transactions.sum_total_amount.toString()),
              ]
            }
          ],
          xaxis: {
            type: 'category',
            categories: [
              response.SUCCESS.grafic_values[0].year,
              response.SUCCESS.grafic_values[1].year,
              response.SUCCESS.grafic_values[2].year,
              response.SUCCESS.grafic_values[3].year,
              response.SUCCESS.grafic_values[4].year
            ],
          },
          chart: {
            height: 350,
            width: '100%',
            type: 'line',
            toolbar: {
              show: false
            }
          }
        };
        this.cdRef.detectChanges();
      },
      error => {
        console.error('Hata:', error);
      }
    );
  }

  mealCardWeekData() {
    this.getDashboardTransAcService.getWeekData(false, 'all',).subscribe(
      response => {
        this.mealCardWeekDataList = response;
        this.foodCardChart = {
          series: [
            {
              name: 'Toplam Satış',
              type: 'column',
              data: [
                this.mealCardWeekDataList.SUCCESS.grafic_values[0].transactions.sum_total_amount,
                this.mealCardWeekDataList.SUCCESS.grafic_values[1].transactions.sum_total_amount,
                this.mealCardWeekDataList.SUCCESS.grafic_values[2].transactions.sum_total_amount,
                this.mealCardWeekDataList.SUCCESS.grafic_values[3].transactions.sum_total_amount,
                this.mealCardWeekDataList.SUCCESS.grafic_values[4].transactions.sum_total_amount,
                this.mealCardWeekDataList.SUCCESS.grafic_values[5].transactions.sum_total_amount,
                this.mealCardWeekDataList.SUCCESS.grafic_values[6].transactions.sum_total_amount,
              ]
            }
          ],
          chart: {
            width: '100%',
            height: 350,
            type: 'line',
            toolbar: {
              show: false
            },
            animations: {
              enabled: true,
              easing: 'easeinout',
              speed: 800
            }
          },
          plotOptions: {
            bar: {
              columnWidth: window.innerWidth < 768 ? '10%' : '40%'
            }
          },
          stroke: {
            width: [0, 2]
          },
          legend: {
            position: 'bottom',
            fontSize: '14px',
            fontFamily: 'Helvetica, Arial',
            fontWeight: 400,
            labels: {
              colors: '#333',
              useSeriesColors: false
            },
            markers: {
              width: 12,
              height: 12,
              radius: 0
            },
            itemMargin: {
              horizontal: 5,
              vertical: 8
            },
            onItemClick: {
              toggleDataSeries: true
            },
            onItemHover: {
              highlightDataSeries: true
            }
          },
          fill: {
            opacity: 1,
            colors: ['#20C3E0', '#20B757']
          },
          dataLabels: {
            enabled: false
          },
          xaxis: {
            type: 'category',
            categories: [
              'Pazartesi',
              'Salı',
              'Çarşamba',
              'Perşembe',
              'Cuma',
              'Cumartesi',
              'Pazar'
            ],
          },
          responsive: [
            {
              breakpoint: 1500,
              options: {
                chart: {
                  height: 300
                }
              }
            },
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 350
                }
              }
            },
            {
              breakpoint: 570,
              options: {
                chart: {
                  height: 250
                }
              }
            }
          ],
          grid: {
            padding: {
              left: -20
            }
          }
        };
        this.cdRef.detectChanges()
      },
      error => {
        console.error('Hata:', error);
      }
    );
  }

  getCurrentYearMonths() {
    const now = new Date();
    const months = [];
    for (let month = 0; month <= now.getMonth(); month++) {
      const startDate = new Date(now.getFullYear(), month, 1);
      const endDate = new Date(now.getFullYear(), month + 1, 0);
      months.push({ startDate: this.formatDate(startDate), endDate: this.formatDate(endDate) });
    }
    return months;
  }

  formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  getCurrentMonthSales() {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();

    if (this.salesData && this.salesData.length > currentMonth) {
      this.currentMonthSales = this.salesData[currentMonth];
    }
  }

  onFilterDefSale(event: any): void {
    this.selectedDefStatus = event.target.value;
  }

  onFilterDefPaymenyType(event: any): void {
    this.selectedPaymentType = event.target.value;
  }

  onFilterMealCard(event: any): void {
    this.selectedMealCard = event.target.value;
    this.getDashboardTransAcService.getWeekData(
      false,
      'true',
      false,
      undefined,
      this.selectedMealCard
    ).subscribe(
      response => {
        this.mealCardWeekDataList = response;
        this.foodCardChart = {
          series: [
            {
              name: 'Toplam Satış',
              type: 'column',
              data: [
                this.mealCardWeekDataList.SUCCESS.grafic_values[0].transactions.sum_total_amount,
                this.mealCardWeekDataList.SUCCESS.grafic_values[1].transactions.sum_total_amount,
                this.mealCardWeekDataList.SUCCESS.grafic_values[2].transactions.sum_total_amount,
                this.mealCardWeekDataList.SUCCESS.grafic_values[3].transactions.sum_total_amount,
                this.mealCardWeekDataList.SUCCESS.grafic_values[4].transactions.sum_total_amount,
                this.mealCardWeekDataList.SUCCESS.grafic_values[5].transactions.sum_total_amount,
                this.mealCardWeekDataList.SUCCESS.grafic_values[6].transactions.sum_total_amount,
              ]
            }
          ],
          chart: {
            width: '100%',
            height: 350,
            type: 'line',
            toolbar: {
              show: false
            },
            animations: {
              enabled: true,
              easing: 'easeinout',
              speed: 800
            }
          },
          plotOptions: {
            bar: {
              columnWidth: window.innerWidth < 768 ? '10%' : '40%'
            }
          },
          stroke: {
            width: [0, 2]
          },
          legend: {
            position: 'bottom',
            fontSize: '14px',
            fontFamily: 'Helvetica, Arial',
            fontWeight: 400,
            labels: {
              colors: '#333',
              useSeriesColors: false
            },
            markers: {
              width: 12,
              height: 12,
              radius: 0
            },
            itemMargin: {
              horizontal: 5,
              vertical: 8
            },
            onItemClick: {
              toggleDataSeries: true
            },
            onItemHover: {
              highlightDataSeries: true
            }
          },
          fill: {
            opacity: 1,
            colors: ['#20C3E0', '#20B757']
          },
          dataLabels: {
            enabled: false
          },
          xaxis: {
            type: 'category',
            categories: [
              'Pazartesi',
              'Salı',
              'Çarşamba',
              'Perşembe',
              'Cuma',
              'Cumartesi',
              'Pazar'
            ],
          },
          responsive: [
            {
              breakpoint: 1500,
              options: {
                chart: {
                  height: 300
                }
              }
            },
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 350
                }
              }
            },
            {
              breakpoint: 570,
              options: {
                chart: {
                  height: 250
                }
              }
            }
          ],
          grid: {
            padding: {
              left: -20
            }
          }
        };
        this.cdRef.detectChanges()
      },
      error => {
        console.error('Hata:', error);
      }
    );
  }

  onFiltersMealCard(event: any): void {
    this.selectedMealCard = event.target.value;
    const currentMonth = new Date().getMonth() + 1;
    const currentYear = new Date().getFullYear();
    this.getDashboardTransAcService.getFilterData(
      false,
      'true',
      true,
      undefined,
      this.selectedMealCard,
      this.filterResulStaff,
      this.filtersData.selectType,
      this.filtersData.selectMonth == undefined ? currentMonth : this.filtersData.selectMonth,
      this.filtersData.selectYear == undefined ? currentYear : this.filtersData.selectYear,
    ).subscribe(
      response => {

        const graficValueFoodCard = response.SUCCESS.grafic_values;
        const hasDayFoodCard = graficValueFoodCard.some((value: any) => value.day !== undefined);
        const hasMonthCard = graficValueFoodCard.some((value: any) => value.month !== undefined);
        this.foodCardChartFilter = {
          series: [
            {
              name: 'Toplam Satış',
              type: 'column',
              data: graficValueFoodCard.map((value: any) =>
                this.formatStaticAmount(value.transactions.sum_total_amount.toString())
              )
            }
          ],
          chart: {
            width: '100%',
            height: 350,
            type: 'line',
            toolbar: {
              show: false
            },
            animations: {
              enabled: true,
              easing: 'easeinout',
              speed: 800
            }
          },
          plotOptions: {
            bar: {
              columnWidth: window.innerWidth < 768 ? '10%' : '40%'
            }
          },
          stroke: {
            width: [0, 2]
          },
          legend: {
            position: 'bottom',
            fontSize: '14px',
            fontFamily: 'Helvetica, Arial',
            fontWeight: 400,
            labels: {
              colors: '#333',
              useSeriesColors: false
            },
            markers: {
              width: 12,
              height: 12,
              radius: 0
            },
            itemMargin: {
              horizontal: 5,
              vertical: 8
            },
            onItemClick: {
              toggleDataSeries: true
            },
            onItemHover: {
              highlightDataSeries: true
            }
          },
          fill: {
            opacity: 1,
            colors: ['#20C3E0', '#20B757']
          },
          dataLabels: {
            enabled: false
          },
          xaxis: {
            type: 'category',
            categories: graficValueFoodCard.map((value: any) => {
              if (hasDayFoodCard) {
                return value.day;
              } else if (hasMonthCard && value.month) {
                return this.monthTranslations[value.month] || value.month;
              } else {
                return 'Bilinmeyen';
              }
            }),
          },
          responsive: [
            {
              breakpoint: 1500,
              options: {
                chart: {
                  height: 300
                }
              }
            },
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 350
                }
              }
            },
            {
              breakpoint: 570,
              options: {
                chart: {
                  height: 250
                }
              }
            }
          ],
          grid: {
            padding: {
              left: -20
            }
          }
        };

        this.cdRef.detectChanges()
      },
      error => {
        console.error('Hata:', error);
      }
    );
  }

  onBranchSelected(event: any): void {
    this.selectedBranch = event.target.value;

    this.getDashboardTransAcService.getLastFiveYearData(
      true,
      false,
      false,
      this.selectedBranch
    ).subscribe(
      response => {
        this.fiveYearDataList = response;
        this.incomeChartOptionsYears = {
          series: [
            {
              name: 'Tutar',
              type: 'line',
              data: [
                this.formatStaticAmount(response.SUCCESS.grafic_values[0].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[1].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[2].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[3].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[4].transactions.sum_total_amount.toString()),
              ]
            }
          ],
          xaxis: {
            type: 'category',
            categories: [
              response.SUCCESS.grafic_values[0].year,
              response.SUCCESS.grafic_values[1].year,
              response.SUCCESS.grafic_values[2].year,
              response.SUCCESS.grafic_values[3].year,
              response.SUCCESS.grafic_values[4].year
            ],
          },
          chart: {
            height: 350,
            width: '100%',
            type: 'line',
            toolbar: {
              show: false
            }
          }
        };
        this.cdRef.detectChanges();
      },
      error => {
        console.error('Hata:', error);
      }
    );

    this.getDashboardTransAcService.getYearData(
      true,
      false,
      false,
      this.selectedBranch,
    ).subscribe(
      response => {
        this.totalAmountYear = response.SUCCESS.total_values.sum_total_amount
        this.yearDataList = response;
        this.incomeChartOptions = {
          series: [
            {
              name: 'Tutar',
              type: 'line',
              data: [
                this.formatStaticAmount(response.SUCCESS.grafic_values[0].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[1].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[2].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[3].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[4].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[5].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[6].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[7].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[8].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[9].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[10].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[11].transactions.sum_total_amount.toString()),
              ]
            }
          ],
          xaxis: {
            type: 'category',
            categories: [
              'Ocak',
              'Şubat',
              'Mart',
              'Nisan',
              'Mayıs',
              'Haziran',
              'Temmuz',
              'Ağustos',
              'Eylül',
              'Ekim',
              'Kasım',
              'Aralık'
            ],
          },
          chart: {
            height: 350,
            width: '100%',
            type: 'line',
            toolbar: {
              show: false
            }
          }
        };

        this.cdRef.detectChanges();
      },
      error => {
        console.error('Hata:', error);
      }
    );

    this.getDashboardTransAcService.getMonthData(
      true,
      false,
      false,
      this.selectedBranch,
    ).subscribe(
      response => {
        this.monthDataList = response;
        this.totalAmountMonth = response.SUCCESS.total_values.sum_total_amount
        this.incomeChartOptions = {
          series: [
            {
              name: 'Tutar',
              type: 'line',
              data: [
                this.formatStaticAmount(response.SUCCESS.grafic_values[0].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[1].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[2].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[3].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[4].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[5].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[6].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[7].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[8].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[9].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[10].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[11].transactions.sum_total_amount.toString()),
              ]
            }
          ],
          xaxis: {
            type: 'category',
            categories: [
              'Ocak',
              'Şubat',
              'Mart',
              'Nisan',
              'Mayıs',
              'Haziran',
              'Temmuz',
              'Ağustos',
              'Eylül',
              'Ekim',
              'Kasım',
              'Aralık'
            ],
          },
          chart: {
            height: 350,
            width: '100%',
            type: 'line',
            toolbar: {
              show: false
            }
          }
        };

        this.cdRef.detectChanges();
      },
      error => {
        console.error('Hata:', error);
      }
    );

    this.getDashboardTransAcService.getWeekData(
      true,
      undefined,
      false,
      this.selectedBranch,
    ).subscribe(
      response => {
        this.weekDataList = response;
        this.incomeChartOptions = {
          series: [
            {
              name: 'Tutar',
              type: 'line',
              data: [
                this.formatStaticAmount(response.SUCCESS.grafic_values[0].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[1].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[2].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[3].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[4].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[5].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[6].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[7].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[8].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[9].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[10].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[11].transactions.sum_total_amount.toString()),
              ]
            }
          ],
          xaxis: {
            type: 'category',
            categories: [
              'Ocak',
              'Şubat',
              'Mart',
              'Nisan',
              'Mayıs',
              'Haziran',
              'Temmuz',
              'Ağustos',
              'Eylül',
              'Ekim',
              'Kasım',
              'Aralık'
            ],
          },
          chart: {
            height: 350,
            width: '100%',
            type: 'line',
            toolbar: {
              show: false
            }
          }
        };

        this.cdRef.detectChanges();
      },
      error => {
        console.error('Hata:', error);
      }
    );

    this.getDashboardTransAcService.getYesyerdayData(
      true,
      undefined,
      false,
      this.selectedBranch,
    ).subscribe(
      response => {
        this.yesterdayDataList = response;
        this.incomeChartOptions = {
          series: [
            {
              name: 'Tutar',
              type: 'line',
              data: [
                this.formatStaticAmount(response.SUCCESS.grafic_values[0].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[1].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[2].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[3].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[4].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[5].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[6].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[7].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[8].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[9].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[10].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[11].transactions.sum_total_amount.toString()),
              ]
            }
          ],
          xaxis: {
            type: 'category',
            categories: [
              'Ocak',
              'Şubat',
              'Mart',
              'Nisan',
              'Mayıs',
              'Haziran',
              'Temmuz',
              'Ağustos',
              'Eylül',
              'Ekim',
              'Kasım',
              'Aralık'
            ],
          },
          chart: {
            height: 350,
            width: '100%',
            type: 'line',
            toolbar: {
              show: false
            }
          }
        };

        this.cdRef.detectChanges();
      },
      error => {
        console.error('Hata:', error);
      }
    );

    this.getDashboardTransAcService.getDailyData(
      true,
      undefined,
      false,
      this.selectedBranch,
    ).subscribe(
      response => {
        this.dailyData = response;
        this.incomeChartOptions = {
          series: [
            {
              name: 'Tutar',
              type: 'line',
              data: [
                this.formatStaticAmount(response.SUCCESS.grafic_values[0].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[1].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[2].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[3].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[4].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[5].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[6].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[7].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[8].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[9].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[10].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[11].transactions.sum_total_amount.toString()),
              ]
            }
          ],
          xaxis: {
            type: 'category',
            categories: [
              'Ocak',
              'Şubat',
              'Mart',
              'Nisan',
              'Mayıs',
              'Haziran',
              'Temmuz',
              'Ağustos',
              'Eylül',
              'Ekim',
              'Kasım',
              'Aralık'
            ],
          },
          chart: {
            height: 350,
            width: '100%',
            type: 'line',
            toolbar: {
              show: false
            }
          }
        };

        this.cdRef.detectChanges();
      },
      error => {
        console.error('Hata:', error);
      }
    );

    this.getDashboardPaymentTypeService.getData('THIS_MONTH', true, this.selectedBranch).subscribe(
      (response: DashboardPaymentTypeModel) => {
        const paymentTypesMonth = response.payment_types as PaymentTypes;

        this.paymentTypesMonth = Object.keys(paymentTypesMonth);

        this.seriesMonth = this.paymentTypesMonth.map(type => {
          const paymentTypeMonth = paymentTypesMonth[type as keyof PaymentTypes];
          return paymentTypeMonth.summary.sum_total_amount;
        });

        this.generalCollectionMonth = {
          series: this.seriesMonth,
          labels: this.paymentTypesMonth,
          chart: {
            type: 'donut',
            height: 350,
            width: '100%'
          }
        };
        this.cdRef.detectChanges();
      },
      error => {
        console.error('Hata:', error);
      }
    );

    this.getDashboardPaymentTypeService.getData('THIS_YEAR', true, this.selectedBranch).subscribe(
      (response: DashboardPaymentTypeModel) => {
        const paymentTypes = response.payment_types as PaymentTypes;

        this.paymentTypes = Object.keys(paymentTypes);

        this.series = this.paymentTypes.map(type => {
          const paymentType = paymentTypes[type as keyof PaymentTypes];
          return paymentType.summary.sum_total_amount;
        });

        if (response.payment_types == null) {
          this.generalCollection = {
            series: this.series,
            labels: this.paymentTypes,
            chart: {
              type: 'donut',
              height: 0,
              width: '0%'
            }
          };
        }

        this.generalCollection = {
          series: this.series,
          labels: this.paymentTypes,
          chart: {
            type: 'donut',
            height: 350,
            width: '100%'
          }
        };
        this.cdRef.detectChanges();
      },
      error => {
        console.error('Hata:', error);
      }
    );

    this.getDashboardPaymentTypeService.getData(this.tabSelectedDate, true, this.selectedBranch).subscribe(
      (response: DashboardPaymentTypeModel) => {
        this.allTransactionsData = response;
      },
      error => {
        console.error('Hata:', error);
      }
    );
  }

  onStaffSelected(filterData: any): void {
    this.selectedStaff = filterData.staff;

    this.getDashboardTransAcService.getLastFiveYearData(
      false,
      false,
      true,
      undefined,
      undefined,
      this.selectedStaff,
    ).subscribe(
      response => {
        this.fiveYearDataList = response;
        this.incomeChartOptionsYears = {
          series: [
            {
              name: 'Tutar',
              type: 'line',
              data: [
                this.formatStaticAmount(response.SUCCESS.grafic_values[0].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[1].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[2].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[3].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[4].transactions.sum_total_amount.toString()),
              ]
            }
          ],
          xaxis: {
            type: 'category',
            categories: [
              response.SUCCESS.grafic_values[0].year,
              response.SUCCESS.grafic_values[1].year,
              response.SUCCESS.grafic_values[2].year,
              response.SUCCESS.grafic_values[3].year,
              response.SUCCESS.grafic_values[4].year
            ],
          },
          chart: {
            height: 350,
            width: '100%',
            type: 'line',
            toolbar: {
              show: false
            }
          }
        };
        this.cdRef.detectChanges();
      },
      error => {
        console.error('Hata:', error);
      }
    );

    this.getDashboardTransAcService.getYearData(
      false,
      false,
      true,
      undefined,
      undefined,
      this.selectedStaff
    ).subscribe(
      response => {
        this.totalAmountYear = response.SUCCESS.total_values.sum_total_amount
        this.yearDataList = response;
        this.incomeChartOptions = {
          series: [
            {
              name: 'Tutar',
              type: 'line',
              data: [
                this.formatStaticAmount(response.SUCCESS.grafic_values[0].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[1].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[2].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[3].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[4].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[5].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[6].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[7].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[8].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[9].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[10].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[11].transactions.sum_total_amount.toString()),
              ]
            }
          ],
          xaxis: {
            type: 'category',
            categories: [
              'Ocak',
              'Şubat',
              'Mart',
              'Nisan',
              'Mayıs',
              'Haziran',
              'Temmuz',
              'Ağustos',
              'Eylül',
              'Ekim',
              'Kasım',
              'Aralık'
            ],
          },
          chart: {
            height: 350,
            width: '100%',
            type: 'line',
            toolbar: {
              show: false
            }
          }
        };

        this.cdRef.detectChanges();
      },
      error => {
        console.error('Hata:', error);
      }
    );

    this.getDashboardTransAcService.getMonthData(
      false,
      false,
      true,
      undefined,
      undefined,
      this.selectedStaff
    ).subscribe(
      response => {
        this.monthDataList = response;
        this.totalAmountMonth = response.SUCCESS.total_values.sum_total_amount
        this.incomeChartOptions = {
          series: [
            {
              name: 'Tutar',
              type: 'line',
              data: [
                this.formatStaticAmount(response.SUCCESS.grafic_values[0].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[1].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[2].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[3].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[4].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[5].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[6].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[7].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[8].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[9].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[10].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[11].transactions.sum_total_amount.toString()),
              ]
            }
          ],
          xaxis: {
            type: 'category',
            categories: [
              'Ocak',
              'Şubat',
              'Mart',
              'Nisan',
              'Mayıs',
              'Haziran',
              'Temmuz',
              'Ağustos',
              'Eylül',
              'Ekim',
              'Kasım',
              'Aralık'
            ],
          },
          chart: {
            height: 350,
            width: '100%',
            type: 'line',
            toolbar: {
              show: false
            }
          }
        };

        this.cdRef.detectChanges();
      },
      error => {
        console.error('Hata:', error);
      }
    );

    this.getDashboardTransAcService.getWeekData(
      false,
      undefined,
      true,
      undefined,
      undefined,
      this.selectedStaff
    ).subscribe(
      response => {
        this.weekDataList = response;
        this.incomeChartOptions = {
          series: [
            {
              name: 'Tutar',
              type: 'line',
              data: [
                this.formatStaticAmount(response.SUCCESS.grafic_values[0].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[1].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[2].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[3].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[4].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[5].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[6].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[7].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[8].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[9].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[10].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[11].transactions.sum_total_amount.toString()),
              ]
            }
          ],
          xaxis: {
            type: 'category',
            categories: [
              'Ocak',
              'Şubat',
              'Mart',
              'Nisan',
              'Mayıs',
              'Haziran',
              'Temmuz',
              'Ağustos',
              'Eylül',
              'Ekim',
              'Kasım',
              'Aralık'
            ],
          },
          chart: {
            height: 350,
            width: '100%',
            type: 'line',
            toolbar: {
              show: false
            }
          }
        };

        this.cdRef.detectChanges();
      },
      error => {
        console.error('Hata:', error);
      }
    );

    this.getDashboardTransAcService.getYesyerdayData(
      false,
      undefined,
      true,
      undefined,
      undefined,
      this.selectedStaff,
    ).subscribe(
      response => {
        this.yesterdayDataList = response;
        this.incomeChartOptions = {
          series: [
            {
              name: 'Tutar',
              type: 'line',
              data: [
                this.formatStaticAmount(response.SUCCESS.grafic_values[0].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[1].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[2].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[3].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[4].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[5].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[6].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[7].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[8].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[9].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[10].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[11].transactions.sum_total_amount.toString()),
              ]
            }
          ],
          xaxis: {
            type: 'category',
            categories: [
              'Ocak',
              'Şubat',
              'Mart',
              'Nisan',
              'Mayıs',
              'Haziran',
              'Temmuz',
              'Ağustos',
              'Eylül',
              'Ekim',
              'Kasım',
              'Aralık'
            ],
          },
          chart: {
            height: 350,
            width: '100%',
            type: 'line',
            toolbar: {
              show: false
            }
          }
        };

        this.cdRef.detectChanges();
      },
      error => {
        console.error('Hata:', error);
      }
    );

    this.getDashboardTransAcService.getDailyData(
      false,
      undefined,
      true,
      undefined,
      undefined,
      this.selectedStaff
    ).subscribe(
      response => {
        console.warn(response);
        this.dailyData = response;
        this.incomeChartOptions = {
          series: [
            {
              name: 'Tutar',
              type: 'line',
              data: [
                this.formatStaticAmount(response.SUCCESS.grafic_values[0].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[1].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[2].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[3].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[4].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[5].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[6].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[7].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[8].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[9].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[10].transactions.sum_total_amount.toString()),
                this.formatStaticAmount(response.SUCCESS.grafic_values[11].transactions.sum_total_amount.toString()),
              ]
            }
          ],
          xaxis: {
            type: 'category',
            categories: [
              'Ocak',
              'Şubat',
              'Mart',
              'Nisan',
              'Mayıs',
              'Haziran',
              'Temmuz',
              'Ağustos',
              'Eylül',
              'Ekim',
              'Kasım',
              'Aralık'
            ],
          },
          chart: {
            height: 350,
            width: '100%',
            type: 'line',
            toolbar: {
              show: false
            }
          }
        };

        this.cdRef.detectChanges();
      },
      error => {
        console.error('Hata:', error);
      }
    );

    this.getDashboardPaymentTypeService.getData('THIS_MONTH', true, this.selectedBranch).subscribe(
      (response: DashboardPaymentTypeModel) => {
        const paymentTypesMonth = response.payment_types as PaymentTypes;

        this.paymentTypesMonth = Object.keys(paymentTypesMonth);

        this.seriesMonth = this.paymentTypesMonth.map(type => {
          const paymentTypeMonth = paymentTypesMonth[type as keyof PaymentTypes];
          return paymentTypeMonth.summary.sum_total_amount;
        });

        this.generalCollectionMonth = {
          series: this.seriesMonth,
          labels: this.paymentTypesMonth,
          chart: {
            type: 'donut',
            height: 350,
            width: '100%'
          }
        };
        this.cdRef.detectChanges();
      },
      error => {
        console.error('Hata:', error);
      }
    );

    this.getDashboardPaymentTypeService.getData('THIS_YEAR', true, this.selectedBranch).subscribe(
      (response: DashboardPaymentTypeModel) => {
        const paymentTypes = response.payment_types as PaymentTypes;

        this.paymentTypes = Object.keys(paymentTypes);

        this.series = this.paymentTypes.map(type => {
          const paymentType = paymentTypes[type as keyof PaymentTypes];
          return paymentType.summary.sum_total_amount;
        });

        if (response.payment_types == null) {
          this.generalCollection = {
            series: this.series,
            labels: this.paymentTypes,
            chart: {
              type: 'donut',
              height: 0,
              width: '0%'
            }
          };
        }

        this.generalCollection = {
          series: this.series,
          labels: this.paymentTypes,
          chart: {
            type: 'donut',
            height: 350,
            width: '100%'
          }
        };
        this.cdRef.detectChanges();
      },
      error => {
        console.error('Hata:', error);
      }
    );

    this.getDashboardPaymentTypeService.getData(this.tabSelectedDate, true, this.selectedBranch).subscribe(
      (response: DashboardPaymentTypeModel) => {
        this.allTransactionsData = response;
      },
      error => {
        console.error('Hata:', error);
      }
    );
  }

  openFilterModal() {
    if (this.filtersData == null) {
      this.modalService.open(DashboardFilterComponent, {
        modal: {
          enter: 'enter-going-down 0.3s ease-out',
          leave: 'fade-out 0.5s'
        },
        overlay: {
          leave: 'fade-out 0.5s'
        },
        data: {
          type: 'Angular modal library'
        }
      }).subscribe(result => {
        this.filtersData = result;
        this.filterResulStaff = result.selectedStaff;

        const graficValues = result.transAcData.SUCCESS.grafic_values;
        const hasDay = graficValues.some((value: any) => value.day !== undefined);
        const hasMonth = graficValues.some((value: any) => value.month !== undefined);
        this.incomeChartOptionsFilters = {
          series: [
            {
              name: 'Tutar',
              type: 'line',
              data: graficValues.map((value: any) =>
                this.formatStaticAmount(value.transactions.sum_total_amount.toString())
              )
            }
          ],
          xaxis: {
            type: 'category',
            categories: graficValues.map((value: any) => {
              if (hasDay) {
                return value.day;
              } else if (hasMonth && value.month) {
                return this.monthTranslations[value.month] || value.month;
              } else {
                return 'Bilinmeyen';
              }
            }),
          },
          chart: {
            height: 350,
            width: '100%',
            type: 'line',
            toolbar: {
              show: false
            }
          }
        };

        const paymentTypesMonth = result.paymentTypeData.payment_types as PaymentTypes;
        this.paymentTypesMonth = Object.keys(paymentTypesMonth);
        this.seriesMonth = this.paymentTypesMonth.map(type => {
          const paymentTypeMonth = paymentTypesMonth[type as keyof PaymentTypes];
          return paymentTypeMonth.summary.sum_total_amount;
        });

        this.generalCollectionFilter = {
          series: this.seriesMonth,
          labels: this.paymentTypesMonth,
          chart: {
            type: 'donut',
            height: 350,
            width: '100%'
          }
        };

        const graficValueFoodCard = result.paymentDataFoodCardType.SUCCESS.grafic_values;

        const hasDayFoodCard = graficValueFoodCard.some((value: any) => value.day !== undefined);
        const hasMonthCard = graficValueFoodCard.some((value: any) => value.month !== undefined);
        this.foodCardChartFilter = {
          series: [
            {
              name: 'Toplam Satış',
              type: 'column',
              data: graficValueFoodCard.map((value: any) =>
                this.formatStaticAmount(value.transactions.sum_total_amount.toString())
              )
            }
          ],
          chart: {
            width: '100%',
            height: 350,
            type: 'line',
            toolbar: {
              show: false
            },
            animations: {
              enabled: true,
              easing: 'easeinout',
              speed: 800
            }
          },
          plotOptions: {
            bar: {
              columnWidth: window.innerWidth < 768 ? '10%' : '40%'
            }
          },
          stroke: {
            width: [0, 2]
          },
          legend: {
            position: 'bottom',
            fontSize: '14px',
            fontFamily: 'Helvetica, Arial',
            fontWeight: 400,
            labels: {
              colors: '#333',
              useSeriesColors: false
            },
            markers: {
              width: 12,
              height: 12,
              radius: 0
            },
            itemMargin: {
              horizontal: 5,
              vertical: 8
            },
            onItemClick: {
              toggleDataSeries: true
            },
            onItemHover: {
              highlightDataSeries: true
            }
          },
          fill: {
            opacity: 1,
            colors: ['#20C3E0', '#20B757']
          },
          dataLabels: {
            enabled: false
          },
          xaxis: {
            type: 'category',
            categories: graficValueFoodCard.map((value: any) => {
              if (hasDayFoodCard) {
                return value.day;
              } else if (hasMonthCard && value.month) {
                return this.monthTranslations[value.month] || value.month;
              } else {
                return 'Bilinmeyen';
              }
            }),
          },
          responsive: [
            {
              breakpoint: 1500,
              options: {
                chart: {
                  height: 300
                }
              }
            },
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 350
                }
              }
            },
            {
              breakpoint: 570,
              options: {
                chart: {
                  height: 250
                }
              }
            }
          ],
          grid: {
            padding: {
              left: -20
            }
          }
        };

        this.filterMealCardTotal = result.paymentDataFoodCardType.SUCCESS.total_values.sum_total_amount;

        this.cdRef.detectChanges();
      });
    } else {
      this.clearFiltersData();
    }
  }


  formatStaticAmounts(amount: string): string {
    return amount;
  }

  clearFiltersData() {
    this.filtersData = null;
  }
}