<div class="grid grid-cols-12 gap-4 xxl:gap-6">
  <div class="col-span-12 md:col-span-7 xxl:col-span-8">
    <div class="grid grid-cols-12 gap-4 xxl:gap-6 p-4 box min-[1880px]:p-6 mb-4 xxl:mb-6">
      @for (item of ordersData; track $index) {
      <div
        class="col-span-12 cursor-pointer border border-dashed qt-modal-btn duration-300 border-transparent hover:border-primary lg:col-span-6 xxxl:col-span-3 box bg-primary/5 dark:bg-bg3 flex items-center gap-3 3xl:gap-6 xl:p-3 min-[1880px]:p-6">
        <div>
          <h5 class="text-base xxl:text-lg xl:text-xl font-medium mb-1 xxl:mb-2">{{ item.title }}</h5>
          <span class="text-xs xxl:text-sm"> {{ item.desc }} </span>
        </div>
      </div>
      }
      <div
        class="download-button col-span-12 cursor-pointer border border-dashed qt-modal-btn duration-300 border-transparent hover:border-primary lg:col-span-6 xxxl:col-span-3 box bg-primary/5 dark:bg-bg3 flex items-center gap-3 3xl:gap-6 xl:p-3 min-[1880px]:p-6">
        <a href="">Dışarı Aktar </a>
        <img src="../../../../assets/icons/download.svg" style="color: black;" alt="">
      </div>

    </div>

    <div class="grid grid-cols-12 gap-4 xxl:gap-6 p-4 box min-[1880px]:p-6 mb-4 xxl:mb-6">
      @for (item of actionsData; track $index) {
      <div
        class="col-span-12 cursor-pointer border border-dashed qt-modal-btn duration-300 border-transparent hover:border-primary lg:col-span-6 xxxl:col-span-4 box bg-primary/5 dark:bg-bg3 flex items-center gap-4 4xl:gap-6 xl:p-3 min-[1880px]:p-6">
        <div>
          <h5 class="text-base xxl:text-lg 4xl:text-xl font-medium mb-1 xxl:mb-2">{{ item.title }}</h5>
          <span class="text-xs xxl:text-sm"> {{ item.desc }} </span>
        </div>
      </div>
      }
    </div>

    <div class="box col-span-12 lg:col-span-6">
      <div class="flex flex-wrap gap-4 justify-between items-center bb-dashed mb-4 pb-4 lg:mb-6 lg:pb-6">
        <h4 class="h4">Ürünler</h4>
      </div>
      <div class="overflow-x-auto mb-4 lg:mb-6">
        <table class="w-full whitespace-nowrap">
          <thead>
            <tr class="bg-primary/5 dark:bg-bg3">
              <th (click)="recentPayments.sortData('title')" class="text-start py-5 px-6 cursor-pointer min-w-[250px]">
                <div class="flex items-center gap-1">Ürün Adı <i class="las la-exchange-alt rotate-90 text-base"></i>
                </div>
              </th>
              <th (click)="recentPayments.sortData('invoice')" class="text-start py-5 min-w-[100px]">Birim <i
                  class="las la-exchange-alt rotate-90 text-base"></i></th>

              <th (click)="recentPayments.sortData('date')" class="text-start py-5 min-w-[130px]">Adet <i
                  class="las la-exchange-alt rotate-90 text-base"></i></th>
              <th (click)="recentPayments.sortData('time')" class="text-start py-5 min-w-[130px]">Toplam Tutar <i
                  class="las la-exchange-alt rotate-90 text-base"></i></th>
            </tr>
          </thead>
          <tbody>
            @for (item of recentPayments.paginatedData; track item.id;let i = $index) {
            <tr
              class="hover:bg-primary/5 dark:hover:bg-bg3 duration-500 border-b border-n30 dark:border-n500 first:border-t">
              <td class="py-5 px-6">
                <div class="flex items-center gap-3">
                  <span class="font-medium"> {{ item.title }} </span>
                </div>
              </td>
              <td class="py-5">
                <p class="font-medium">{{ item.medium }}</p>
              </td>
              <td class="py-5">
                <p class="font-medium">{{ item.invoice }}</p>
              </td>
              <td class="py-5">$ {{ item.money.toLocaleString() }}</td>
            </tr>
            }
          </tbody>
        </table>
      </div>
    </div>

    <div class="box col-span-12 lg:col-span-6 mt-4">
      <div class="flex flex-wrap gap-4 justify-between items-center bb-dashed mb-4 pb-4 lg:mb-6 lg:pb-6">
        <h4 class="h4">İşlemler</h4>
      </div>
      <div class="overflow-x-auto mb-4 lg:mb-6">
        <table class="w-full whitespace-nowrap">
          <thead>
            <tr class="bg-primary/5 dark:bg-bg3">
              <th (click)="recentPayments.sortData('title')" class="text-start py-5 px-6 cursor-pointer min-w-[250px]">
                <div class="flex items-center gap-1">Ödeme Tipi</div>
              </th>
              <th (click)="recentPayments.sortData('invoice')" class="text-start py-5 min-w-[100px]">Tarih</th>

              <th (click)="recentPayments.sortData('date')" class="text-start py-5 min-w-[130px]">Client IP</th>
              <th (click)="recentPayments.sortData('time')" class="text-start py-5 min-w-[130px]">Toplam Tutar</th>
            </tr>
          </thead>
          <tbody>
            @for (item of recentPayments.paginatedData; track item.id;let i = $index) {
            <tr
              class="hover:bg-primary/5 dark:hover:bg-bg3 duration-500 border-b border-n30 dark:border-n500 first:border-t">
              <td class="py-5 px-6">
                <div class="flex items-center gap-3">
                  <span class="font-medium"> {{ item.medium }} </span>
                </div>
              </td>
              <td class="py-5">
                <p class="font-medium">{{ item.date }}</p>
              </td>
              <td class="py-5">
                <p class="font-medium">{{ item.invoice }}</p>
              </td>
              <td class="py-5">$ {{ item.money.toLocaleString() }}</td>
            </tr>
            }
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="col-span-12 md:col-span-5 xxl:col-span-4">

    <div class="box">
      <div class="flex justify-between items-center bb-dashed border-secondary1/20 pb-4 mb-4 lg:pb-6 lg:mb-6">
        <h4 class="h4">Müşteri Detayı</h4>
      </div>
      <ul class="flex flex-col gap-4 lg:gap-6">
        <li>
          <a href="#" class="flex justify-between items-center gap-2">
            <div class="flex items-center gap-3 sm:gap-4 xxl:gap-6">
              <span
                class="circleAvatar border border-n30 dark:border-n500 bg-primary/5 w-10 h-10 flex items-center justify-center xxl:w-14 xxl:h-14 shrink-0 rounded-full">
                <img src="../../../../assets/icons/user.svg" alt="">
              </span>
              <div>
                <p class="text-lg font-medium">Müşteri Adı:</p>
                <span class="text-xs">Nihai Tüketici</span>
              </div>
            </div>
          </a>
        </li>
        <li>
          <a href="#" class="flex justify-between items-center gap-2">
            <div class="flex items-center gap-3 sm:gap-4 xxl:gap-6">
              <span
                class="circleAvatar border border-n30 dark:border-n500 bg-primary/5 w-10 h-10 flex items-center justify-center xxl:w-14 xxl:h-14 shrink-0 rounded-full">
                <img src="../../../../assets/icons/phone.svg" alt="">
              </span>
              <div>
                <p class="text-lg font-medium">İletişim Bilgileri:</p>
                <span class="text-xs">+90 (532) 000 *******pluspay.com.tr</span>
              </div>
            </div>
          </a>
        </li>
        <li>
          <a href="#" class="flex justify-between items-center gap-2">
            <div class="flex items-center gap-3 sm:gap-4 xxl:gap-6">
              <span
                class="circleAvatar border border-n30 dark:border-n500 bg-primary/5 w-10 h-10 flex items-center justify-center xxl:w-14 xxl:h-14 shrink-0 rounded-full">
                <img src="../../../../assets/icons/customer-code.svg" alt="">
              </span>
              <div>
                <p class="text-lg font-medium">Müşteri Kodu:</p>
                <span class="text-xs">#880704</span>
              </div>
            </div>
          </a>
        </li>
        <li>
          <a href="#" class="flex justify-between items-center gap-2">
            <div class="flex items-center gap-3 sm:gap-4 xxl:gap-6">
              <span
                class="circleAvatar border border-n30 dark:border-n500 bg-primary/5 w-10 h-10 flex items-center justify-center xxl:w-14 xxl:h-14 shrink-0 rounded-full">
                <img src="../../../../assets/icons/localization.svg" alt="">
              </span>
              <div>
                <p class="text-lg font-medium">Vergi Dairesi:</p>
                <span class="text-xs">11111*****</span>
              </div>
            </div>
          </a>
        </li>
      </ul>
    </div>

    <div class="box mt-4">
      <div class="flex justify-between items-center bb-dashed border-secondary1/20 pb-4 mb-4 lg:pb-6 lg:mb-6">
        <h4 class="h4">Fatura Detayı</h4>
      </div>
      <ul class="flex flex-col gap-4 lg:gap-6">
        <li>
          <div class="flex items-center gap-3 sm:gap-4 xxl:gap-6">
            <p class="text-lg font-medium">Ara Toplam:</p>
            <p class="text-m">77.27 ₺</p>
          </div>
        </li>
        <li>
          <div class="flex items-center gap-3 sm:gap-4 xxl:gap-6">
            <p class="text-lg font-medium">KDV Tutarı :</p>
            <p class="text-m">7.73 ₺</p>
          </div>
        </li>
        <li>
          <div class="flex items-center gap-3 sm:gap-4 xxl:gap-6">
            <p class="text-lg font-medium">İndirim:</p>
            <p class="text-m">0 ₺</p>
          </div>
        </li>
        <li>
          <div class="flex items-center gap-3 sm:gap-4 xxl:gap-6">
            <p class="text-lg font-medium">Genel Toplam:</p>
            <p class="text-m">85 ₺</p>
          </div>
        </li>
        <li>
          <div class="flex items-center gap-3 sm:gap-4 xxl:gap-6">
            <p class="text-lg font-medium">Alacak Miktarı:</p>
            <p class="text-m">0 ₺</p>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>