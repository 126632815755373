<section class="topbar-container z-30">
  <nav [ngClass]="isSidebarOpen?'topbarmargin':'topbarfull'"
    class="navbar-top z-20 gap-3 bg-n0 py-3 shadow-sm duration-300 border-b border-n0 dark:border-n700 dark:bg-bg4 xl:py-4"
    id="topbar">
    <div class="topbar-inner flex items-center justify-between">
      <div class="flex grow items-center gap-4 xxl:gap-6">
        <a routerLink="/dashboards" class="topbar-logo hidden shrink-0">
          <img width="145" height="38"
            [src]="colorMode=='light'?'assets/images/logo-with-text.png':'assets/images/logo-with-text.png'" alt="logo"
            class="logo-full2 hidden lg:block" />
        </a>
        <button (click)="toggleSidebar()" aria-label="sidebar-toggle-btn"
          class="flex items-center bg-primary px-0.5 py-3 text-xl text-n0 arrowToggle"
          id="sidebar-toggle-btn">
          <i class="las la-angle-left text-lg"></i>
        </button>
      </div>
      <div class="flex items-center gap-3 sm:gap-4 xxl:gap-6">
        <!-- mobile Search  -->
        <div class="relative lg:hidden">
          <button id="mobile-search-btn"
            class="flex h-10 w-10 cursor-pointer select-none items-center justify-center gap-2 rounded-full border border-n30 bg-primary/5 dark:border-n500 dark:bg-bg3 md:h-12 md:w-12">
            <i class="las la-search"></i>
          </button>
          <div id="mobile-search"
            class="hide invisible absolute -left-8 top-full z-20 flex min-w-max max-w-[250px] origin-[20%_20%] gap-3 overflow-y-auto rounded-md bg-n0 p-3 opacity-0 shadow-[0px_6px_30px_0px_rgba(0,0,0,0.08)] duration-300 dark:bg-bg4">
            <form
              class="flex w-full items-center justify-between gap-3 rounded-[30px] border border-n30 bg-secondary1/5 p-1 focus-within:border-primary dark:border-n500 dark:bg-bg3 xxl:p-2">
              <input type="text" placeholder="Search" class="w-full bg-transparent py-1 ltr:pl-4 rtl:pr-4" />
              <button
                class="flex h-7 w-7 shrink-0 items-center justify-center rounded-full bg-primary text-n0 lg:h-8 lg:w-8">
                <i class="las la-search text-lg"></i>
              </button>
            </form>
          </div>
        </div>
        <!-- Search bar -->
        <form class="topnav-search">
          <input type="text" placeholder="Arama yapınız..." [formControl]="searchControl"
            class="searchArea w-full border-none bg-transparent py-2 focus:border-none focus:shadow-none focus:outline-none md:py-2.5 xxl:py-3 ltr:pl-4 rtl:pr-4" />
          <button aria-label="search btn"
            class="flex h-8 w-9 items-center justify-center rounded-full bg-primary text-n0">
            <i class="las la-search text-lg"></i>
          </button>
          <ul *ngIf="suggestions.length > 0" class="suggestions-list">
            <li *ngFor="let suggestion of suggestions" (click)="selectSuggestion(suggestion)">
              {{ suggestion }}
            </li>
          </ul>
        </form>
        <!-- dark mode toggle -->
        <!-- <button (click)="toggleDarkMode()" id="darkModeToggle" aria-label="dark mode switch"
          class="h-10 w-10 shrink-0 rounded-full border border-n30 bg-primary/5 dark:border-n500 dark:bg-bg3 md:h-12 md:w-12">
          <i class="las la-sun text-2xl dark:hidden"></i>
          <span class="hidden text-n30 dark:block">
            <i class="las la-moon text-2xl"></i>
          </span>
        </button> -->
        <!-- Notification -->
        <app-notification-dropdown />
        <!-- Chat Link -->
        <!-- <a routerLink="/private/chat" class="relative flex h-10 w-10 shrink-0 items-center justify-center rounded-full border border-n30 bg-primary/5 dark:border-n500 dark:bg-bg3 max-[620px]:hidden md:h-12 md:w-12">
          <i class="lab la-facebook-messenger"></i>
          <span class="absolute -right-1 -top-1 flex h-5 w-5 items-center justify-center rounded-full bg-primary text-xs text-n0"> 3 </span>
        </a> -->
        <!-- language dropdown -->
        <!-- <app-language /> -->
        <!-- Profile dropdown -->
        <app-profile-dropdown />
      </div>
    </div>
  </nav>

  <!-- Vertical -->
  <aside id="sidebar" [ngClass]="isSidebarOpen?'sidebarshow':'sidebarhide'" class="sidebar bg-n0 dark:!bg-bg4">
    <div class="sidebar-inner relative">
      <div class="logo-column menu-wrapper">
        <div class="logo-container">
          <div class="logo-inner">
            <a routerLink="/dashboards" class="logo-wrapper">
              <img
                [src]="colorMode=='light'?'assets/images/logo-with-text-dark.png':'assets/images/logo-with-text-dark.png'"
                width="145" height="38" class="logo-full" alt="logo" />
              <img [src]="colorMode=='light'?'assets/images/logo.png':'assets/images/logo-with-text-dark.png'"
                width="50" height="50" class="logo-icon hidden" alt="logo" />
            </a>
            <img [width]="141" [height]="38" class="logo-text !pb-[26px] dark:!pb-[38px] hidden"
              [src]="colorMode=='light'?'assets/images/logo-with-text-dark.png':'assets/images/logo-with-text-dark.png'"
              alt="logo text" />
            <button class="sidebar-close-btn xl:hidden" id="sidebar-close-btn" (click)="toggleSidebar()">
              <i class="las la-times"></i>
            </button>
          </div>
        </div>
        <div class="menu-container pb-28">
          <div class="menu-wrapper">
            <ul class="menu-ul">
              <button (click)="goToDashboard('Menü')" [ngClass]=" 'Menü' === activeMenu ? 'active':''"
                class="menu-btn border-n30 bg-n0 dark:!border-n500 dark:bg-bg4" routerLinkActive="text-primary">
                <span class="flex items-center justify-center gap-2">
                  <span class="menu-icon">
                    <i class="las la-home"></i>
                  </span>
                  <span class="menu-title font-medium">Pano</span>
                </span>
              </button>
              <li *ngFor="let menu of sidebarData" class="menu-li">
                <button (click)="setActiveMenu(menu.name)" [ngClass]="menu.name === activeMenu ? 'active':''"
                  class="menu-btn border-n30 bg-n0 dark:!border-n500 dark:bg-bg4">
                  <span class="flex items-center justify-center gap-2">
                    <span class="menu-icon">
                      <i [ngClass]="menu.icon"></i>
                    </span>
                    <span class="menu-title font-medium">{{ menu.name }}</span>
                  </span>
                  <span class="plus-minus">
                    <i class="las la-plus text-xl"></i>
                    <i class="las la-minus text-xl"></i>
                  </span>
                  <span class="chevron-down hidden">
                    <i class="las la-angle-down text-base"></i>
                  </span>
                </button>
                <ul class="submenu" [ngClass]="menu.name === activeMenu ? 'submenu-show':'submenu-hide'">
                  <li *ngFor="let submenu of menu.submenus" (click)="clickRoute()" routerLinkActive="text-primary">
                    <a [routerLink]="submenu.url" class="submenu-link">
                      <span class="subItemTitle">{{ submenu.title }}</span>
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div class="px-4 xxl:px-6 xxxl:px-8">
            <span class="copyright">© 2024 PlusPay. Tüm Hakları Saklıdır.</span>
          </div>
        </div>
      </div>
    </div>
  </aside>
</section>

<main class="main-content" [ngClass]="{'has-sidebar':isSidebarOpen}">
  <div class="main-inner">
    <router-outlet></router-outlet>
  </div>
</main>

<div class="messageStore">
  <div class="store-buttons">
    <div class="storeArea">
      <img class="logos" src="../../../../assets/images/logo-with-text-dark.png">
      <p>
        Mobil olarak<br>
        kullanmak için uygulamamızı
        indirin.
      </p>
    </div>
    <div class="storeArea">
      <a href="#" target="_blank" class="store-button apple">
        <img src="../../../../assets/images/app-store.png" alt="Download on the App Store">
      </a>
      <a href="#" target="_blank" class="store-button apple">
        <img src="../../../../assets/images/google-play.png" alt="Download on the App Store">
      </a>
    </div>

    <span>© 2024 PlusPay. Tüm Hakları Saklıdır.</span>
  </div>
</div>
<!-- Footer -->
<footer class="footer bg-n0 dark:bg-bg4">
  <div class="flex flex-col items-center justify-center gap-3 px-4 py-5 lg:flex-row lg:justify-between xxl:px-8">
    <p class="text-sm max-md:w-full max-md:text-center lg:text-base">
      Tüm Hakları Saklıdır &#64; <span>{{getCurrentYear()}}</span>
      <a class="text-primary" routerLink="/"> Boss+ </a>
    </p>
    <div class="justify-center max-md:flex max-md:w-full"></div>
    <ul class="flex gap-2 xxxl:gap-3">
      <li>
        <a href="#" aria-label="social link"
          class="inline-flex rounded-full border border-primary p-1 text-primary duration-300 hover:bg-primary hover:text-n0 md:p-1.5 xxxl:p-2">
          <i class="lab la-instagram"></i>
        </a>
      </li>
      <li>
        <a href="#" aria-label="social link"
          class="inline-flex rounded-full border border-primary p-1 text-primary duration-300 hover:bg-primary hover:text-n0 md:p-1.5 xxxl:p-2">
          <i class="lab la-linkedin-in"></i>
        </a>
      </li>
    </ul>

    <ul class="flex gap-3 text-sm max-lg:w-full max-lg:justify-center lg:gap-4 lg:text-base">
      <li>
        <a href="https://www.pluspay.com.tr/kvkk.html" target="_blank">K.V.K.K</a>
      </li>
      <li>
        <a href="https://www.pluspay.com.tr/aydinlatma-metni.html" target="_blank">Aydınlatma Metni</a>
      </li>
    </ul>
  </div>
</footer>

<app-theme-customizer />