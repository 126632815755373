import { CommonModule } from '@angular/common'
import { Component } from '@angular/core'
import { DropdownComponent } from '@component/shared/dropdown/dropdown.component'
import { OptionsHorizComponent } from '@component/shared/options-horiz/options-horiz.component'
import { TopBannerComponent } from '@component/shared/top-banner/top-banner.component'
import { NgApexchartsModule } from 'ng-apexcharts'
import { latestTransactions } from '@data/transactions/latestTransactions'
import { ModalService } from 'ngx-modal-ease'
import { TableService } from '@service/table.service'
import { transactionsData } from '@data/dashboards/style2Transactions'
import { DocumentDetailModalComponent } from '@component/shared/document-detail-modal/document-detail-modal.component'
import { FormsModule } from '@angular/forms'
import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

@Component({
  selector: 'rp-app-commission-report',
  standalone: true,
  imports: [TopBannerComponent, FormsModule, OptionsHorizComponent, DropdownComponent, NgApexchartsModule, CommonModule],
  templateUrl: './commission-report.component.html'
})
export class CommissionReportComponent {
  transactions
  paymenyType = [
    'Kredi Kartı',
    'Ön Ödemeli'
  ]
  paymentMethod = [
    'Paye Kart',
    'Paye Kart -2',
    'Paye Kart -3',
  ]

  searchCommisDate: string = '';
  searchPaymentType: string = '';
  searchCustomerName: string = '';
  searchID: number | undefined;
  searchCommission: number | undefined;
  searchAmount: number | undefined;
  searchTotal: number | undefined;


  pages: number[] = []
  filters = ['Tümü', 'Başarılı', 'İptal Edildi', 'Devam Ediyor']
  currentFilter = this.filters[0]
  constructor(private modalService: ModalService) {
    this.transactions = new TableService()
    this.transactions.initialize(latestTransactions, 14)
  }

  areAllItemsChecked(): boolean {
    return this.transactions.tableData.length > 1 && this.transactions.tableData.every((item) => item.isChecked)
  }

  ngOnInit() {
    this.pages = Array.from({ length: this.transactions.totalPages }, (_, i) => i + 1)
  }
  setFilter(filter: string) {
    this.currentFilter = filter
    if (filter == 'Tümü') {
      this.transactions.initialize(transactionsData, 14)
      this.pages = Array.from({ length: this.transactions.totalPages }, (_, i) => i + 1)
    } else if (filter == 'Başarılı') {
      const result = transactionsData.filter((item) => item.status === 'successful');
      this.transactions.initialize(result, 14);
      this.pages = Array.from({ length: this.transactions.totalPages }, (_, i) => i + 1)
    } else if (filter == 'İptal Edildi') {
      const result = transactionsData.filter((item) => item.status === 'cancelled');
      this.transactions.initialize(result, 14);
      this.pages = Array.from({ length: this.transactions.totalPages }, (_, i) => i + 1)
    }
    else if (filter == 'Devam Ediyor') {
      const result = transactionsData.filter((item) => item.status === 'pending');
      this.transactions.initialize(result, 14);
      this.pages = Array.from({ length: this.transactions.totalPages }, (_, i) => i + 1)
    }
    else {
      const result = transactionsData.filter((item) => item.status == filter)
      this.transactions.initialize(result, 14)
      this.pages = Array.from({ length: this.transactions.totalPages }, (_, i) => i + 1)
    }
  }
  openModal() {
    this.modalService.open(DocumentDetailModalComponent, {
      modal: {
        enter: 'enter-going-down 0.3s ease-out',
        leave: 'fade-out 0.5s'
      },
      overlay: {
        leave: 'fade-out 0.5s'
      },
      data: {
        type: 'Angular modal library'
      }
    })
  }

  get filteredTransactionsCombined() {
    const paymenyType = this.searchPaymentType.toLowerCase();
    const customerName = this.searchCustomerName.toLowerCase();

    return this.transactions.tableData.filter(item => {
      const itemPaymenyType = item.title.toLowerCase();
      const itemCustomerName = item.title.toLowerCase();

      return (!this.searchCommisDate || item.dueDate.includes(this.searchCommisDate)) &&
        (!this.searchID || item.speciealCode.toString().includes(this.searchID.toString())) &&
        (!this.searchCommission || item.id.toString().includes(this.searchCommission.toString())) &&
        (!this.searchAmount || item.id.toString().includes(this.searchAmount.toString())) &&
        (!this.searchTotal || item.collectedAmount.toString().includes(this.searchTotal.toString())) &&
        (!paymenyType || itemPaymenyType.includes(paymenyType)) &&
        (!customerName || itemCustomerName.includes(customerName));
    });
  }

  exportToExcel() {
    var table = document.getElementById("commissionListTable");
    var excel = new ExcelJS.Workbook();
    var sheet = excel.addWorksheet('Sheet1');

    var rows = table!.querySelectorAll('tr');

    rows.forEach(function (row) {
      var rowData: string[] = [];
      var cells = row.querySelectorAll('th, td');
      Array.from(cells).forEach(function (cell: Element) {
        rowData.push((cell as HTMLInputElement).innerText);
      });
      sheet.addRow(rowData);
    });

    excel.xlsx.writeBuffer().then(function (buffer) {
      var blob = new Blob([buffer], { type: 'application/octet-stream' });
      saveAs(blob, 'comission_list.xlsx');
    });
  }
}