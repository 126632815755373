import { Component } from '@angular/core'
import { providersTabData } from '@data/payment/providers'
import { PasswordInputComponent } from '@component/shared/password-input/password-input.component'
import { DropdownComponent } from '@component/shared/dropdown/dropdown.component'

@Component({
  selector: 'app-general-settings',
  standalone: true,
  imports: [PasswordInputComponent,DropdownComponent],
  templateUrl: './general-settings.component.html'
})
export class GeneralSettingsComponent {
  providers = providersTabData
  activeTab = this.providers[0].title
  languge = [
    "Türkçe",
    "İngilizce"
  ]
  gmtType=[
    "(GMT+3) İstanbul",
    "(GMT/24) İstanbul"
  ]
  currencyType = [
    'TRY',
    'USD',
    'EUR'
  ]
}
