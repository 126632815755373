import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CategoriesProductsModel } from '../../models/products/categories-product.model';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class CategoryProductService {

    private apiUrl = `${environment.apiUrl}/category/`;
    private token = localStorage.getItem('authToken');
    constructor(private http: HttpClient) { }

    getCategoryProduct(categoriesId:string): Observable<CategoriesProductsModel> {
        const urlPath = `${this.apiUrl}`;
        const headers = new HttpHeaders()
            .set('Authorization', `Bearer ${this.token}`);
        return this.http.get<any>(urlPath+categoriesId+'/products', { headers });
    }

}
