import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ProductSalesModel } from '../../models/reports/product-sales-report.model';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProductSalesReportService {

  private apiUrl = `${environment.apiUrl}/reports/product-sales`;
  private token = localStorage.getItem('authToken');
  constructor(private http: HttpClient) { }

  // allProdReport(bodyParams: any, queryParams: any): Observable<ProductSalesModel> {
  //   const headers = new HttpHeaders().set('Authorization', `Bearer ${this.token}`);
  //   let httpParams = new HttpParams();
  //   for (const key in queryParams) {
  //     if (queryParams.hasOwnProperty(key)) {
  //       httpParams = httpParams.set(key, queryParams[key]);
  //     }
  //   }

  //   return this.http.post<any>(this.apiUrl, bodyParams, { params: httpParams, headers });
  // }

  allProdReport(
    startDate: string,
    endDate: string,
    firmSelected: boolean = false,
    firms?: string,
  ): Observable<ProductSalesModel> {
    const urlPath = `${this.apiUrl}`;
    const body = {
      "firm_ids": [firms]
    };
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.token}`);
    let params = new HttpParams()
      .set('start_date', startDate)
      .set('end_date', endDate)
      .set('is_firm_select', firmSelected);

    return this.http.post<ProductSalesModel>(urlPath, body, { headers, params });
  }
}
