import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ProductDetailModel } from '../../models/products/product-detail.model';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ProductDetailService {
    
    private apiUrl = `${environment.apiUrl}/products/`;
    private token = localStorage.getItem('authToken');
    constructor(private http: HttpClient) { }

    getProductDetail(product_id: string): Observable<ProductDetailModel> {
        const urlPath = `${this.apiUrl}`;
        const headers = new HttpHeaders()
            .set('Authorization', `Bearer ${this.token}`);
        return this.http.get<ProductDetailModel>(urlPath + product_id, { headers });
    }

}
