<app-top-banner title="Trading Style 03" />

<!-- markets overview -->
<div class="grid grid-cols-12 gap-4 xxl:gap-6">

  <div class="box col-span-12">
    <div class="flex justify-between items-center gap-4 flex-wrap bb-dashed mb-4 pb-4 lg:mb-6 lg:pb-6">
      <h4 class="h4">Markets Overview</h4>
      <div class="flex items-center gap-4 flex-wrap grow sm:justify-end">
        <form class="bg-primary/5 dark:bg-bg3 border border-n30 dark:border-n500 flex gap-3 rounded-[30px] focus-within:border-primary p-1 items-center justify-between min-w-[200px] xxl:max-w-[319px] w-full">
          <input type="text" placeholder="Search" class="bg-transparent border-none text-sm ltr:pl-4 rtl:pr-4 py-1 w-full" />
          <button class="bg-primary shrink-0 rounded-full w-7 h-7 lg:w-8 lg:h-8 flex justify-center items-center text-n0">
            <i class="las la-search text-lg"></i>
          </button>
        </form>
        <div class="flex items-center gap-3 whitespace-nowrap">
          <span>Sort By : </span>
          <app-dropdown />
        </div>
      </div>
    </div>
    <div class="overflow-x-auto mb-4 lg:mb-6">
      <table class="table w-full border-0 whitespace-nowrap">
        <thead>
          <tr>
            <th>
              <span>Coin Name</span>
            </th>
            <th>
              <span>Price</span>
            </th>
            <th>
              <span>24h Change</span>
            </th>
            <th>
              <span>Market Cap</span>
            </th>
            <th>
              <span>Circulating Supply</span>
            </th>
            <th>
              <span>Volume (24h)</span>
            </th>
          </tr>
        </thead>
        <tbody cdkDropList (cdkDropListDropped)="drop($event)">
          <tr *ngFor="let market of marketData" cdkDrag [cdkDragData]="market">
            <td>
            </td>
            <td>
              <h5 class="h5">${{market.price}}</h5>
            </td>
            <td>
              <h5 class="h5 text-danger">
                {{ market.name }}%
              </h5>
            </td>
            <td>
              <h5 class="h5">${{market.id}}</h5>
            </td>
          </tr>
        </tbody>
      </table>
      
    </div>

    <div class="flex col-span-12 gap-4 sm:justify-between justify-center items-center flex-wrap">
      <p>Showing {{ marketOverview.startIndex + 1 }} to {{ marketOverview.endIndex + 1 }} of {{ marketOverview.totalData }} entries</p>
      <ul class="flex gap-2 md:gap-3 flex-wrap md:font-semibold items-center">
        <li>
          <button [disabled]="marketOverview.currentPage == 1" (click)="marketOverview.prevPage()" class="hover:bg-primary text-primary rtl:rotate-180 hover:text-n0 border md:w-10 duration-300 md:h-10 w-8 h-8 flex items-center rounded-full justify-center border-primary">
            <i class="las la-angle-left text-lg"></i>
          </button>
        </li>
        <li *ngFor="let page of pages">
          <button (click)="marketOverview.paginate(page)" [ngClass]="{ 'bg-primary !text-n0': marketOverview.currentPage == page }" class="hover:bg-primary bg-primary hover:text-n0 border md:w-10 duration-300 md:h-10 w-8 h-8 flex text-primary items-center rounded-full justify-center border-primary">
            {{ page }}
          </button>
        </li>
        <li>
          <button [disabled]="marketOverview.currentPage == marketOverview.totalPages" (click)="marketOverview.nextPage()" class="hover:bg-primary text-primary hover:text-n0 rtl:rotate-180 border md:w-10 duration-300 md:h-10 w-8 h-8 flex items-center rounded-full justify-center border-primary">
            <i class="las la-angle-right text-lg"></i>
          </button>
        </li>
      </ul>
    </div>
  </div>
</div>
